.manage-area-groups {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 250px 250px;
  height: 350px;
  min-width: 550px;
  &__form {
    display: flex;
    flex-direction: column;
    // height: 100%;
  }
  .buttons {
    display: flex;
    flex-direction: column;
    // margin-top: auto;
    button:first-child {
      margin-right: 10px;
    }
  }
  .area-groups {
    max-height: 300px;
    overflow: auto;
  }
}
