.time-series-graph-settings-form {
  padding: 24px 20px 20px;
  .graphing-group {
    border-bottom: 1px var(--comments-tab-bg) solid;
    margin-bottom: 25px;
  }
  .buttons {
    .btn {
      &:not(:first-child) {
        margin-top: 12px;
      }
    }
  }
  .rw-dropdown-list {
    svg {
      height: 24px;
      width: 24px;
      path {
        stroke: var(--primary-font-color);
      }
    }
  }
  .search {
    padding: 16px 13px;
    .icon-chevron-down {
      height: 24px;
      width: 24px;
      path {
        stroke: var(--primary-font-color);
      }
      &:hover {
        path {
          stroke: var(--secondary-theme-color);
        }
      }
    }
  }
}
