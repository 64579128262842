.work-order-details-form {
  .split-input {
    width: 50%;
  }
  .priority-dropdown-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
    .priority-icon {
      border-radius: 50%;
      height: 20px;
      margin-right: 4px;
      width: 20px;
    }
  }
  .without-padding {
    padding: 0;
  }
}
