@import '../../../common/styles/variables';
@import '../../../common/styles/mixins';
.search-small {
  background-color: var(--box-items-theme-color);
  // border: solid 1px var(--box-items-separator-color);
  // border: none;
  // border-radius: 4px;
  display: flex;
  min-width: 100px;
  padding: 9px 14px;
  position: relative;
  &__icon {
    fill: var(--secondary-theme-color);
    height: 15px;
    margin-right: 14px;
    min-width: 10px;
    width: 15px;
  }
  &__input {
    background: transparent;
    border: 0;
    color: var(--primary-font-color);
    flex: 1;
    font-family: $primary-font-family;
    font-size: 14px;
    font-weight: normal;
    outline: none;
    
    @include placeholder {
      color: var(--secondary-font-color-dark);
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
  .items-dropdown {
    background: white;
    border: solid 1px var(--box-items-separator-color);
    left: 0;
    max-height: 300px;
    overflow-y: auto;
    padding: 5px;
    position: absolute;
    top: 46px;
    width: 50%;
    .item {
      padding: 5px 0;
    }
  }
  .empty-state {
    align-items: center;
    display: flex;
    height: 50px;
  }
}
