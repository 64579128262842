.isolation-components-modal {
  padding-bottom: 40px;
  width: 100%;
  .selected-components {
    padding-top: 16px;
    .selected-components-container {
      padding-top: 16px;
      .component-title-wrapper {
        align-items: flex-start;
        display: flex;
        .component-item {
          padding-bottom: 30px;
          padding-left: 40px;
          width: 100%;
          .component-name-container {
            align-items: center;
            display: flex;
            margin-bottom: 16px;
            .delete-action {
              cursor: pointer;
              margin-left: auto;
            }
          }
        }
      }
      .isolation-component-form {
        .fields-container {
          column-gap: 20px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          .order-input {
            .input-wrapper {
              input {
                border: 0;
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }
  .isolations-modal-footer {
    padding-top: 20px;
    .action-title {
      cursor: pointer;
      text-align: right;
    }
    .action-buttons-container {
      align-items: center;
      display: flex;
      padding-top: 40px;
      .save-btn {
        margin-left: auto;
      }
    }
  }
}
.isolations-modal-footer-with-border {
  border-top: 1px solid var(--box-items-separator-color);
}
