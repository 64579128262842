@import '../../../common/styles/variables';
.login-form {
  display: flex;
  flex-direction: column;
  width: 300px;
  &__link {
    align-self: center;
    color: var(--secondary-font-color-dark);
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    text-decoration: none;
    text-transform: capitalize;
    &.space {
      margin-top: 15px;
    }
  }
  &__btn {
    margin: 20px 0;
  }
  .error-placeholder {
    color: var(--validation-error-color);
    display: flex;
    font-size: 12px;
    justify-content: center;
    min-height: 20px;
    padding: 0 10px;
  }
}
