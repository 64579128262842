.measurement-point-question-section {
  border: 1px solid var(--comment-input-border);
  border-radius: 8px;
  margin: 16px 0;
  min-height: 76px;
  padding: 16px;
  width: 100%;
  label {
    font-weight: 400;
  }

  .measurement-point-section-top {
    align-items: center;
    cursor: pointer;
    display: flex;

    .measurement-point-header {
      display: flex;
      flex-grow: 1;

      .item {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
      .mp-name-col {
        min-width: calc(100% - 21% - 135px - 24px) !important;
        max-width: calc(100% - 21% - 135px - 24px) !important;
        padding-right: 18px;
        .item {
          width: 260px;
          .name {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden !important;
            white-space: nowrap;
            cursor: default;
          }
          .names {
            display: flex;
            align-items: center;
          }

          .truncated-text {
            cursor: default;
            display: inline-block;
            max-width: 50%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
      .mp-unit-col {
        min-width: 21% !important;
        max-width: 21% !important;
      }
      .mp-exp-range-col {
        min-width: 135px !important;
        max-width: 135px !important;

        .name {
          display: block;
          text-overflow: ellipsis;
          overflow: hidden !important;
          white-space: nowrap;
          max-width: 105px;
          cursor: default;
        }
      }
      .toggle-action {
        align-items: center;
        cursor: pointer;
        display: flex;
        min-width: 24px !important;
        max-width: 24px !important;
      }
    }
  }
  &__expanded {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 16px;
    &__alarms {
      border-top: 1px solid var(--comment-input-border);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 16px;
      .alarm {
        width: 114px;
        display: flex;
        flex-direction: column;
        gap: 4px;

        &:last-child {
          margin-right: 50px;
        }
        &__name {
          align-items: center;
          display: flex;
          gap: 4px;
          &__text {
            text-overflow: ellipsis;
            overflow: hidden !important;
            white-space: nowrap;
            max-width: 60px !important;
            min-width: 60px !important;
            cursor: default;
          }
        }
      }
    }
    &__location {
      border-top: 1px solid var(--comment-input-border);
      display: flex;
      gap: 119px;
      padding-top: 16px;
      .show-location {
        display: flex;
        flex-direction: column;
        gap: 8px;
        div {
          align-items: center;
          display: flex;
          gap: 4px;
          width: fit-content;
        }
        .location-text {
          color: var(--secondary-theme-color);
          cursor: pointer;
          font-weight: 500;
        }
        .no-location {
          color: var(--primary-theme-dark-color);
          font-weight: 400;
        }
        .info-popover {
          border: 1px solid var(--notification-close-icon);
          border-radius: 4px;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 1px 0 rgba(0, 0, 0, 0.06), 0 1px 1px 0 rgba(0, 0, 0, 0.08);
          max-width: 310px;
          min-height: 216px;
          .custom-component-container {
            .container {
              align-items: baseline;
              display: flex;
              flex-direction: column;
              span {
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }
}
.location-modal {
  .location-modal__picker {
    padding: 0 !important;
    .modal-wrapper__title {
      padding-left: 20px;
    }
    .modal-with-absolute-title {
      margin-top: 72px;
    }
  }
}
