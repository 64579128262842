.add-quick-point-modal-content {
  max-width: 600px;
  min-width: 450px;
  width: 100%;
  .input-wrapper {
    opacity: 1;
    padding-top: 0;
    .rw-widget-input.rw-widget-picker.rw-widget-container {
      background-color: transparent;
      border: 0;
      border-bottom: 1px solid var(--box-items-separator-color);
      border-radius: 0;
      box-shadow: none;
      .rw-btn-select {
        svg {
          fill: var(--primary-font-color);
        }
      }
    }
    .rw-input {
      color: var(--primary-font-color);
      padding: 0;
    }
    // date picker
    .react-datepicker-wrapper {
      display: block;
      input {
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid var(--box-items-separator-color);
        border-radius: 0;
        box-shadow: none;
        color: var(--primary-font-color);
        padding: 0;
      }
    }
  }
  .inline-wrapper {
    div {
      flex: 1;
    }
    .inspection-input {
      margin-right: 20px;
    }
  }
  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0;
    button {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}
