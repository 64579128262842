.work-order-section-work-areas {
  .notification-WO-tab__table {
    height: auto;
    .table-template-container {
      padding-bottom: 20px;
    }
  }
  .twp-header {
    display: flex;
    .twp-header__cell {
      margin-right: auto;
      padding-left: 10px;
      padding-top: 8px;
      &.permit-status-icon,
      &.permit-id,
      &.focus-icon {
        margin-right: 0;
      }
    }
  }
  .twp-body__row__cells {
    color: var(--primary-font-color);
    width: 100%;
    &__cell {
      color: var(--primary-font-color);
      max-width: 50%;
      padding-left: 12px;
    }
    .permit-status-icon,
    .permit-id,
    .focus-icon {
      max-width: 15%;
      min-width: 15%;
    }
    .focus-icon {
      flex-grow: 0;
    }
    .full-width {
      max-width: 85%;
    }
  }
  .work-areas {
    &__name {
      align-items: center;
      display: flex;
      gap: 8px;
      width: 100%;
      &__status-icon {
        border-radius: 50%;
        height: 16px;
        width: 16px;
      }
      &__text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: calc(100% - 13px - 8px);
      }
    }
  }
  .work-areas__name__text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
