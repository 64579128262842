.issue-permit-modal {
  display: flex;
  flex-direction: column;
  height: 700px;
  width: 800px;
  .contractors-dropdown {
    margin-top: 24px;
    max-width: 50%;
    .rw-widget-input {
      background-color: var(--input-background-color) !important;
      border: 1px solid var(--box-items-separator-color) !important;
      border-radius: 4px !important;
    }
  }
  .step-title {
    margin-top: 20px;
  }
  .double {
    display: flex;
    > div {
      margin-right: 20px;
    }
  }
  .step-desc {
    margin: 20px 0;
  }
  .b-top {
    border-top: 1px solid var(--separator-color);
    padding-top: 20px;
  }
  .issue-permit-form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    &__content {
      flex-grow: 1;
      overflow: auto;
      padding: 0 10px;
    }
    .assigned-keys {
      display: grid;
      flex-wrap: wrap;
      grid-gap: 20px;
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
      .keybox-name {
        margin-bottom: 12px;
      }
    }
    .buttons {
      display: flex;
      justify-content: flex-end;
      padding-top: 5px;
      button {
        margin-left: 10px;
      }
    }
  }
}
