.load-more {
  margin-top: 10px;
  padding-bottom: 10px;
  &__link {
    cursor: pointer;
    display: inline-block;
    left: 50%;
    margin: 10px 0;
    position: relative;
    transform: translateX(-50%);
    white-space: nowrap;
    &.disabled {
      cursor: not-allowed;
    }
  }
  &__button {
    margin: auto;
    margin-top: 20px;
  }
  &__results {
    background-color: var(--background-color);
    bottom: 0;
    min-width: 320px;
    padding-bottom: 10px;
    padding-top: 10px;
    z-index: 999999;
  }
}
