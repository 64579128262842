.dms-components-picker-modal {
  height: 100%;
  width: 100%;
  &__picker {
    height: 20%;
    margin-bottom: 32px;
    width: 100%;
    .icon-chevron-down path {
      stroke: #000;
    }
    .search-wrapper .items-dropdown {
      z-index: 10;
    }
  }
  .document-management__modal__action-modal {
    .modal-button-custom {
      .button-text {
        font-weight: 500;
      }
    }
  }
  .checkbox-wrapper {
    cursor: pointer;
    height: 20px;
    margin-right: 8px;
    width: 20px;
    .custom-checkbox {
      height: 20px;
      padding-left: 0;
      width: 20px;
      .checkmark {
        border-radius: 4px;
        height: 20px;
        width: 20px;
        &::after {
          left: 5px;
          top: 2px;
        }
      }
    }
  }
  .selected-components-wrapper {
    height: 100%;
    width: 100%;
  }
  .selected-components {
    height: 100%;
    max-height: 350px;
    min-height: 200px;
    overflow-y: scroll;
    padding-top: 16px;
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera */
    }
    &__header-wrapper {
      background-color: #fff;
      border-bottom: 1px solid #eee;
      border-radius: 0;
      padding-bottom: 8px;
      position: sticky;
      top: -16px;
      z-index: 9;
    }
    &__header {
      display: flex;
      justify-content: space-between;
      .multiple-category-select-cta {
        align-content: center;
        align-items: center;
        color: var(--secondary-theme-color);
        cursor: pointer;
        display: flex;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        text-align: right;
        .icon-multiSelect {
          height: 20px;
          margin-right: 4px;
          width: 20px;
        }
      }
    }
    .selected-components-container {
      padding-top: 16px;
      &__header {
        align-items: center;
        color: #999;
        display: flex;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        .input-wrapper {
          display: flex;
          margin-right: 8px;
          padding-top: 0;
        }
        .left-side {
          width: 33%;
        }
        .right-side {
          margin-left: 4px;
        }
      }
      .component-info-wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;
        .component-item {
          align-items: flex-start;
          border-bottom: 1px solid var(--box-items-separator-color);
          display: flex;
          gap: 8px;
          justify-content: space-between;
          padding: 12px 0;
          width: 100%;
          .component-name-container {
            align-items: center;
            display: flex;
            gap: 8px;
            justify-content: flex-start;
            max-width: 230px;
            min-width: 200px;
            padding-top: 12px;
            width: 35%;
            .input-wrapper {
              display: flex;
              margin-right: 4px;
              padding-top: 0;
            }
            .name {
              max-width: 135px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              word-break: break-word;
            }
          }
          .component-cat-container {
            width: 60%;
            .search-dropdown-items-wrapper {
              margin-top: 4px;
              .empty-state {
                height: 100%;
              }
            }
            .search-wrapper {
              width: 100%;
              .highlight-placeholder {
                .search__input {
                  &::placeholder {
                    color: #000 !important;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 142.857% */
                  }
                }
              }
            }
            .items-dropdown {
              background: #fff;
              border: 1px solid var(--dms-linked-components-bg);
              border-radius: 4px;
              box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 1px 0 rgba(0, 0, 0, 0.06), 0 1px 1px 0 rgba(0, 0, 0, 0.08);
              max-height: 365px;
              overflow-y: scroll;
              padding: 8px;
              width: 100%;
              .category-item-wrapper {
                align-items: center;
                cursor: pointer;
                display: flex;
                padding: 8px;
                &:hover {
                  background-color: #e9ecef;
                  border-color: #e9ecef;
                }
                .checkbox-wrapper {
                  label {
                    margin-left: 0;
                  }
                }
              }
            }
          }
          .component-delete-container {
            align-items: center;
            display: flex;
            justify-content: flex-end;
            padding-top: 12px;
            width: 10%;
            .delete-action {
              cursor: pointer;
            }
          }
        }
      }
      .dms-components-picker-form {
        .fields-container {
          column-gap: 20px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          .order-input {
            .input-wrapper {
              input {
                border: 0;
                padding-left: 0;
              }
            }
          }
        }
      }
      @media screen and (min-height: 1329px) {
        max-height: 29vh;
      }
    }
  }
  .dms-components-picker-footer {
    .action-title {
      cursor: pointer;
      text-align: right;
    }
    .action-buttons-container {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-top: 40px;
      position: relative;
      top: auto;
      .save-btn {
        margin-left: auto;
      }
    }
  }
  .change-category-modal {
    align-items: flex-start;
    top: 8.5%;
  }
  @media screen and (min-height: 1329px) {
    padding-bottom: 20px;
  }
}
