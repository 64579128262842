@import '../../../common//styles/variables';
$total-bottom-height: 668px;
.header-slider {
  height: 100%;
  .header {
    height: 100%;
    &__slider {
      display: flex;
      flex-direction: column;
      height: 100%;
      .main-container {
        height: calc(100vh - #{$total-bottom-height});
      }
      .tools-container {
        align-items: center;
        background-color: var(--primary-theme-light-color);
        display: flex;
        height: $tools-container-height;
        padding: 0 16px;
        position: relative;
        &__rounded-container {
          align-items: center;
          background-color: var(--box-items-theme-color);
          border-radius: 50%;
          cursor: pointer;
          display: flex;
          height: 36px;
          justify-content: center;
          padding: 2px;
          width: 36px;
          &.transparent {
            background: transparent;
          }
          &:not(:last-child) {
            margin-right: 8px;
          }
          &:hover {
            .image {
              fill: var(--secondary-theme-color);
              g {
                fill: var(--secondary-theme-color);
              }
              path {
                fill: var(--secondary-theme-color);
              }
            }
          }
        }
        &__controls {
          align-items: center;
          display: flex;
          left: 50%;
          position: absolute;
          transform: translateX(-50%);
          .rounded {
            align-items: center;
            background-color: var(--box-items-theme-color);
            border-radius: 50%;
            cursor: pointer;
            display: flex;
            height: 24px;
            justify-content: center;
            padding: 2px;
            width: 24px;
            &:hover {
              .image {
                fill: var(--secondary-theme-color);
                g {
                  fill: var(--secondary-theme-color);
                }
                path {
                  fill: var(--secondary-theme-color);
                }
              }
            }
          }
          .prev {
            margin-left: auto;
            margin-right: 16px;
          }
          .next {
            margin-left: 16px;
            margin-right: auto;
          }
          .text {
            user-select: none;
          }
        }
        .drawing-tools {
          align-items: center;
          display: flex;
          margin-left: auto;
        }
        .image {
          max-height: 15px;
          max-width: 100%;
          &.reverse {
            transform: rotate(180deg);
          }
          &.active {
            fill: var(--primary-font-color);
            g {
              fill: var(--primary-font-color);
            }
            path {
              fill: var(--primary-font-color);
            }
            circle {
              fill: var(--primary-font-color);
            }
          }
        }
      }
     
    }
    &__slider-item {
      height: 100%;
      width: 100%;
      .image-loader {
        align-items: center;
        display: flex;
        justify-content: center;
      }
    }
    .image-list-view {
      max-height: unset;
    }
    .slider {
      border: 2px solid transparent;
      height: calc(100% - #{$tools-container-height} - #{$image-description-height});
      &.highlighted {
        border: 2px solid var(--secondary-theme-color);
      }
      .custom-overlay {
        display: none;
        visibility: hidden;
      }
      .image-details {
        display: none;
        visibility: hidden;
      }
      .tools-container {
        display: none;
        padding: 16px 8px;
        position: fixed;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        &__rounded-container {
          margin-right: 0;
          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }
      }
      .image-gallery-content {
        &.fullscreen {
          .custom-overlay {
            align-items: center;
            background: rgba(15, 17, 19, 0.4);
            bottom: 0;
            display: flex;
            justify-content: space-between;
            padding: 6px 20px;
            position: fixed;
            visibility: visible;
            width: 100%;
          }
          .image-details {
            display: block;
            margin-left: 20px;
            visibility: visible;
          }
          .tools-container {
            background: transparent;
            display: block;
            z-index: 1;
            .color-picker__circle {
              margin-right: 0;
            }
            .inspection-input {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
      .galery-empty {
        align-items: center;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    
  }
}
