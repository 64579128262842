.key-boxes-form {
  .key-boxes-select {
    margin-bottom: 35px;
    .rw-list {
      padding-top: 0;
    }
    .rw-popup-container {
      .rw-slide-transition {
        background-color: var(--input-background-color);
        border-color: var(--input-background-color);
      }
    }
    .key-boxes-select-item {
      align-items: center;
      cursor: pointer;
      display: flex;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 20px;
      p {
        margin-right: 4px;
      }
      .key-box-status {
        align-items: center;
        display: flex;
        margin-left: auto;
        .locked-icon {
          height: 16px;
          margin-right: 4px;
          width: 16px;
        }
      }
    }
    .key-boxes-select-item-disabled {
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  .display-item {
    margin-top: 16px;
  }
  .rw-popup-container {
    position: relative;
  }
}
