@import '../../../common/styles/variables';
.time-series-graph {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
  /* Special class for when there are many measurement points - works in both preview and non-preview modes */
  .highcharts-scrolling {
  }
  .empty-state-active {
    align-items: center;
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 1;
    .empty-state-title {
      color: var(--ui-color-secondary);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: right;
    }
  }
  &__tooltip-container {
    background: var(--dropdown-bg);
    min-width: 150px;
    padding: 8px;
    &__title {
      margin-bottom: 4px;
      span {
        margin-right: 4px;
      }
    }
    &__date {
      margin-bottom: 8px;
    }
    &__label {
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 4px;
    }
    &__value {
      font-size: 14px;
      font-weight: normal;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
  .time-series-graph-tooltip {
    pointer-events: auto;
    position: absolute;
    z-index: 1000;
  }
  .x-axis-label {
    align-items: center;
    border-radius: 2px;
    cursor: pointer;
    display: inline-flex;

    /* Ensure minimum width for labels to prevent overlapping */
    min-width: 60px;
    padding: 2px 4px;
    transition: background-color 0.2s ease;
    white-space: nowrap;
    &__text {
      color: #999;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* Prevent text from pushing the icon out of view */
      max-width: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__icon {
      display: inline-block;
      margin-left: 4px;
      min-height: 24px;
      /* Ensure icon has enough click area */
      min-width: 24px;
      position: relative;
      z-index: 5; /* Higher z-index to ensure clickability */
      .x-axis-tooltip {
        background: var(--background-color);
        border: 1px solid #b3b3b3;
        border-radius: 4px;
        bottom: 100%;
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1), 0 1px 18px 0 rgba(0, 0, 0, 0.06), 0 6px 10px 0 rgba(0, 0, 0, 0.08);
        color: var(--primary-font-color, #333);
        font-family: 'DM Sans';
        font-size: 12px;
        font-size: 14px;
        left: 50%;
        margin-bottom: 8px;
        max-width: auto;
        opacity: 0;
        overflow: hidden;
        padding: 4px 8px;
        padding: 16px;
        pointer-events: none;
        position: absolute;
        text-overflow: ellipsis;
        transform: translateX(-50%);
        transition: opacity 0.2s ease, visibility 0.2s ease;
        visibility: hidden;
        white-space: nowrap;
        z-index: 1050;
        &::after {
          border-color: var(--dropdown-bg, #fff) transparent transparent transparent;
          border-style: solid;
          border-width: 5px;
          content: '';
          font-style: normal;
          font-weight: 400;
          left: 50%;
          line-height: 20px;
          position: absolute;
          top: 100%;
          transform: translateX(-50%);
        }
      }
      &:hover .x-axis-tooltip {
        opacity: 1;
        visibility: visible;
      }
    }
    &:hover {
      background-color: var(--background-color);
    }
    .f-primary {
      color: var(--primary-font-color);
    }
  }
}
.alarm-plot-line {
  cursor: pointer;
  pointer-events: all;
  &:hover {
    stroke-width: 3px;
  }
}
