.collapsable-info {
  border-radius: 12px 12px 0 0;
  bottom: 0;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
  position: absolute;
  right: 20px;
  width: 375px;
  z-index: 1000;
  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    &__actions {
      align-items: center;
      display: flex;
      gap: 12px;
      justify-content: space-between;
      .collapse-icon,
      .close-icon {
        cursor: pointer;
        path {
          stroke: var(--notification-close-icon);
          stroke-width: 1.5;
        }
      }
      .collapse-icon {
        transition: all 0.8s ease;
        &.expanded {
          transform: rotate(180deg);
        }
      }
    }
  }
  &__content {
    overflow-y: hidden;
    transition: height 0.7s ease;
    width: 100%;
    &.expanded {
      height: 250px;
      width: 100%;
    }
    &.collapsed {
      height: 0;
    }
  }
}
