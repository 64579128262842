.module-change-status-section {
  padding-bottom: 12px;
  .status-title-container {
    display: flex;
    align-items: center;
    padding-top: 12px;
    cursor: pointer;
    &.disabled {
      cursor: default;
    }
    .toggle-status-change-details {
      margin-left: auto;
    }
  }
  .status-signatures-wrapper {
    padding-top: 12px;
    .signatures-title-separator {
      margin-bottom: 2px;
    }
    .signatures-separator {
      margin-bottom: 16px;
    }
    .user-signatures-wrapper {
      padding-bottom: 16px;
      .user-signature-field {
        width: 100%;
        height: 120px;
        border: 1px solid var(--box-items-separator-color);
        border-radius: 8px;
        margin-top: 4px;
      }
    }
  }
  .change-status-questions-container {
    margin-top: 16px;
    .change-status-question {
      margin-top: 32px;
      .question-title-separator {
        margin-bottom: 20px;
      }
      .question-description-separator {
        margin-bottom: 8px;
      }
      .question-default-separator {
        margin-bottom: 16px;
      }
      .question-options-separator {
        margin-bottom: 4px;
      }
      .default-font-weight {
        font-weight: 400;
      }
      .question-font-weight {
        font-weight: 700;
      }
      .checkbox-field-answer {
        display: flex;
        align-items: center;
        .icon-checkmark-rounded {
          width: 20px;
          height: 20px;
          circle {
            fill: var(--secondary-theme-color);
            stroke: var(--secondary-theme-color);
          }
          path {
            stroke: #fff;
          }
        }
        p {
          margin-left: 4px;
        }
      }
    }
  }
  border-bottom: 1px solid var(--box-items-separator-color);
}
