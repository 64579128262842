.ndt-measurement-details-grouped {
  .line-chart {
    position: relative;
    .empty-state {
      display: none;
    }
    [id^='google-visualization-errors-all'] {
      display: none !important;
    }
    .empty-state-active {
      align-items: center;
      bottom: 0;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  }
  .buttons {
    display: flex;
    flex-direction: column;
    button:first-child {
      margin-bottom: 10px;
    }
  }
}
