.verification-form {
  max-width: 300px;
  .center {
    text-align: center;
  }
  .resend-button {
    margin-bottom: 10px;
  }
  button[type='submit'] {
    margin-bottom: 10px;
  }
  .field-wrapper {
    margin: 30px 0 0;
  }
}
