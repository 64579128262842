.component-files-modal {
  width: 40vw;
  .change-hierarchy-modal__container__left-side {
    width: 100%;
  }
  .document-management__modal__action-modal {
    max-width: 493px;
    min-width: 493px;
  }
  .modal-button-custom {
    margin-bottom: 12px;
    width: 100%;
  }
  .dms-pdf-viewer {
    .modal {
      max-height: 70vh;
    }
  }
  .category-wrapper {
    .title {
      color: var(--primary-font-color);
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
    }
    .category-files-wrapper {
      display: flex;
      flex-flow: column;
      .header {
        p {
          color: var(--dms-grey-section-title);
          font-size: 13px;
          font-weight: 500;
          line-height: 20px;
          margin-bottom: 13px;
          margin-top: 12px;
          text-align: left;
        }
      }
      .row {
        align-items: center;
        border-bottom: 1px solid #eee;
        border-radius: 4px;
        display: flex;
        flex-flow: row;
        margin-bottom: 16px;
        padding-bottom: 8px;
        &:hover {
          svg {
            path {
              stroke: var(--secondary-theme-color);
            }
          }
        }
        svg {
          path {
            stroke: #b3b3b3;
          }
        }
        .title {
          color: var(--primary-font-color);
          flex: 1;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          margin-left: 8px;
          margin-right: 7px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .action {
          margin-left: auto;
        }
      }
    }
    .buttons-wrapper {
      align-items: center;
      display: flex;
      justify-content: space-between;
      .dropzone-wrapper {
        align-items: center;
        background-color: transparent;
        border: 1px solid var(--secondary-theme-color);
        border-radius: 4px;
        color: var(--secondary-theme-color);
        display: flex;
        flex: 1 0;
        height: 52px;
        justify-content: center;
        margin-bottom: 32px;
        margin-left: 20px;
        margin-top: 36px;
        width: auto;
        .dropzone {
          height: 100%;
          width: 100%;
        }
        .drop-field {
          p {
            color: var(--secondary-theme-color);
            font-weight: 700;
          }
          svg {
            height: 20px;
            margin-left: 8px;
            width: 20px;
            path {
              stroke: var(--secondary-theme-color);
            }
          }
        }
      }
      .btn {
        flex: 1 0;
        height: 52px;
        margin-bottom: 32px;
        margin-top: 36px;
        padding: 16px;
        width: auto;
        svg {
          height: 20px;
          width: 20px;
          path {
            stroke: var(--secondary-theme-color);
          }
        }
        &:nth-child(2) {
          margin-left: 20px;
        }
      }
    }
  }
}
