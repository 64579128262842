.component-picker-with-list {
  .input-with-dropdown {
    .question-field {
      .search {
        height: 52px;
        svg {
          g {
            stroke: var(--secondary-font-color-dark);
            path {
              stroke: var(--secondary-font-color-dark);
            }
          }
        }
        &__input::placeholder {
          color: var(--secondary-font-color-dark);
        }
      }
      .items-dropdown {
        top: 51px;
      }
    }
  }
  .field-wrapper {
    .input-wrapper {
      .checkbox-wrapper {
        input {
          height: 20px;
          width: 20px;
        }
        label {
          text-transform: none;
        }
      }
    }
  }
  .added-items-list {
    .item-r {
      align-items: center;
      justify-content: initial;
      .info-tooltip {
        margin-left: 5px;
        margin-top: 3px;
      }
      .icon-close {
        margin-left: auto;
      }
    }
  }
  .selected-components {
    .added-label {
      margin-top: 20px;
    }
  }
  .selected-components-container {
    @media screen and (min-height: 1329px) {
      max-height: 29vh;
    }
    .component-info-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%;
      .component-item {
        align-items: center;
        border-bottom: 1px solid var(--box-items-separator-color);
        display: flex;
        gap: 8px;
        justify-content: space-between;
        padding: 12px 0;
        width: 100%;
        .component-name-container {
          align-items: center;
          display: flex;
          gap: 8px;
          justify-content: flex-start;
          width: 30%;
          &.name {
            word-break: break-word;
          }
        }
        .component-cat-container {
          align-items: center;
          display: flex;
          justify-content: center;
          width: 60%;
          .multiselect {
            width: 100%;
            .input-wrapper {
              width: 100%;
            }
          }
        }
        .component-delete-container {
          align-items: center;
          display: flex;
          justify-content: flex-end;
          width: 10%;
          .delete-action {
            cursor: pointer;
          }
        }
      }
    }
  }
}
