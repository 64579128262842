.modules-history {
  background-color: var(--background-color);
  height: 100vh;
  overflow-y: auto;
  padding: 0 20px;
  &__header {
    background-color: var(--background-color);
    padding: 20px 0;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  &__items {
    margin-top: 30px;
  }
  &__footer {
    background-color: var(--background-color);
    bottom: 0;
    padding-top: 20px;
    position: sticky;
    z-index: 1;
  }
}
