.choose-notification-form {
  .inputs-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    .single-input-wrapper {
      padding-right: 10px;
      width: 50%;
    }
    .inputs-row {
      display: flex;
      width: 100%;
      .multi-input-wrapper {
        width: 50%;
      }
      .multi-input-wrapper:first-child {
        padding-right: 10px;
      }
      .multi-input-wrapper:last-child {
        padding-left: 10px;
      }
    }
  }
  .input-with-dropdown {
    .search-input-label {
      color: var(--secondary-font-color-light);
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
    .search {
      background-color: var(--input-background-color);
      border: 1px solid var(--input-border-color);
      margin-top: 4px;
      padding: 15px 14px;
      .items-dropdown {
        top: 52px;
        width: 100%;
      }
      input::placeholder {
        color: var(--secondary-font-color-dark);
      }
      &__icon {
        fill: var(--secondary-font-color-dark);
        g,
        path {
          stroke: var(--secondary-font-color-dark);
        }
      }
    }
  }
  .input-with-dropdown-without-items {
    margin-bottom: 100px;
  }
}
