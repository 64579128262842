.checklists-procedures-management {
  height: 100%;
  width: 100%;
  &__container {
    max-width: 50%;
    padding: 24px 30px;
    &__title {
      font-weight: 500;
      margin-top: 4px;
    }
    &__stepper-wrapper {
      margin-bottom: 32px;
      margin-top: 20px;
    }
    &__first-step {
      &__inputs {
        .field-wrapper {
          .input-wrapper {
            .input-wrapper__input {
              border-radius: 4px;
              height: 52px;
            }
          }
        }
      }
      &__input_name {
        max-width: 50%;
      }
      &__buttons {
        align-items: center;
        display: flex;
        margin-top: 10px;
        &__submit-btn {
          margin-left: auto;
        }
      }
    }
    &__second-step {
      &__add-button-wrapper {
        margin-top: 20px;
      }
      &__section-pagination-navigator {
        margin-top: 32px;
        &:first-child {
          margin-bottom: 32px;
        }
      }
      &__description {
        border: 1px solid var(--wo-flow-label-color);
        border-radius: 12px;
        display: flex;
        margin-bottom: 32px;
        padding: 12px;
        svg {
          flex-shrink: 0;
          margin-right: 8px;
        }
      }
      &__buttons {
        align-items: center;
        display: flex;
        margin-top: 40px;
        &__wrapper {
          align-items: center;
          display: flex;
          margin-left: auto;
          &__btn {
            margin-right: 20px;
            font-weight: 400;
          }
        }
      }
    }
    .advanced-filter-custom-property-field {
      background-color: transparent;
      padding: 0;
    }
    .back-button {
      svg {
        path {
          stroke: var(--secondary-font-color-dark);
        }
      }
    }

    .add-button {
      &__text {
        font-weight: 400;
      }
    }
  }
}
