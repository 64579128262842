@import '../../../common/styles/variables';
@import '../../../common/styles/mixins';
@import './date-range-picker-base';

.week-range-picker {
  @extend %range-picker-container;

  &__header {
    @extend %range-picker-header;
  }

  &__nav-btn {
    @extend %range-picker-nav-btn;
  }

  &__month-year-display {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__current-month-year {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    cursor: pointer;
    color: var(--primary-font-color);

    .dropdown-icon {
      margin-left: 4px;
      color: var(--secondary-theme-color);
    }
  }

  &__year-display {
    @extend %range-picker-display;
  }

  &__month-display {
    @extend %range-picker-display;
  }

  &__calendar {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__weekdays {
    @extend %range-picker-weekdays;
    .weekday {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 166.667% */
      color: var(--ui-color-secondary);
    }
  }

  &__weeks {
    @extend %range-picker-weeks;
  }

  &__week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 0;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease;

    &:hover:not([disabled]):not(.selected) {
      .week-range-picker__day {
        &:first-child,
        &:last-child {
          background-color: var(--secondary-theme-color);
          color: #fff;
        }
        &:first-child {
          border-radius: 8px 0px 0px 8px;
          &--today {
            border-radius: 8px 0px 0px 8px;
          }
        }
        &:last-child {
          border-radius: 0px 8px 8px 0px;
          &--today {
            border-radius: 0px 8px 8px 0px;
          }
        }

        color: var(--secondary-theme-color);
        background-color: rgba(var(--secondary-theme-color-rgb), 0.5);

        &--today {
          background-color: var(--secondary-theme-color);
          color: #fff;
          &:not(:first-child) {
            border-radius: 0px 8px 8px 0px;
          }
        }

        &--future {
          color: var(--ui-color-secondary) !important;
          opacity: 0.4;
          background-color: var(--background-color) !important;
        }
      }
    }

    &.selected,
    &--selected {
      .week-range-picker__day {
        &:first-child,
        &:last-child {
          background-color: var(--secondary-theme-color);
          color: #fff;
        }
        &:first-child {
          border-radius: 8px 0px 0px 8px;
          &--today {
            border-radius: 8px 0px 0px 8px;
          }
        }
        &:last-child {
          border-radius: 0px 8px 8px 0px;
          &--today {
            border-radius: 0px 8px 8px 0px;
          }
        }

        color: var(--secondary-theme-color);
        background-color: rgba(var(--secondary-theme-color-rgb), 0.5);

        &--today {
          background-color: var(--secondary-theme-color);
          color: #fff;
          &:not(:first-child) {
            border-radius: 0px 8px 8px 0px;
          }
        }

        &--future {
          color: var(--ui-color-secondary) !important;
          opacity: 0.4;
          background-color: var(--background-color) !important;
        }
      }
    }

    &--disabled,
    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.current,
    &--current {
      background-color: var(--background-color);
    }
  }

  &__day {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 37px;
    font-size: 16px;

    // in case we need to show other month days in a other UI
    // &--other-month {
    //   color: blue;
    //   opacity: 0.6;
    // }

    &--today {
      position: relative;
      color: rgba(var(--secondary-theme-color-rgb), 1);

      &:after {
        content: '';
        position: absolute;
        bottom: 6px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: var(--primary-theme-color);
      }
    }

    &--future,
    &--past {
      color: var(--ui-color-secondary);
      opacity: 0.4;
    }
  }
}
