.robotic-live-streams {
  .live-streams__wrapper {
    .table-template {
      .twp {
        .twp-header {
          border-bottom: none;
          height: unset;
        }
      }
      .table-template-no-data-text {
        .empty-state-container {
          padding-top: 70px;
          .empty-state-container__content {
            p {
              text-wrap: nowrap;
            }
          }
        }
      }
      .icon-column {
        max-width: 28px !important;
        min-width: 28px !important;
      }
      .name-column {
        max-width: calc(100% - 28px - 175px) !important;
        min-width: calc(100% - 28px - 175px) !important;
        svg {
          margin-right: 4px;
        }
        .flex-center {
          gap: 8px;
          .chip {
            align-items: center;
            border: 1px solid var(--severity-red);
            border-radius: 64px;
            display: flex;
            font-size: 12px;
            gap: 4px;
            height: 25px;
            justify-content: center;
            padding: 8px;
            user-select: none; /* Standard property */
            -webkit-user-select: none; /* For Safari */
            -ms-user-select: none; /* For older versions of IE */
            .red-dot {
              background-color: var(--severity-red);
              border-radius: 50%;
              min-height: 9px;
              min-width: 9px;
            }
          }
        }
      }
      .started-at-column {
        max-width: 175px !important;
        min-width: 175px !important;
      }
    }
  }
}
