@import '../../../common/styles/variables';
@import '../../../common/styles/animations';
.upload-modal {
  width: 100%;
  &.pick-type {
    .options,
    .types {
      align-items: baseline;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-top: 20px;
      width: 650px;
      .modal__button {
        margin: auto;
      }
      .dropzone-wrapper {
        margin: 0;
        .project-dropzone {
          height: 45px;
          margin: 0;
          width: 45px;
        }
      }
      .upload-box {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 10px;
        min-width: 170px;
        p,
        h6 {
          opacity: 0.4;
        }
        .external-upload {
          align-items: center;
          border: 2px solid var(--upload-box-item-color);
          border-radius: 10px;
          display: flex;
          height: 54px;
          justify-content: center;
          margin-bottom: 10px;
          padding: 10px;
          width: 54px;
          svg {
            height: 54px;
            width: 54px;
          }
        }
        &.p-coming-soon {
          .external-upload {
            overflow: hidden;
            pointer-events: none;
            position: relative;
            user-select: none;
            &::before {
              background-color: var(--upload-box-item-color);
              bottom: -1px;
              color: var(--default-font-color);
              content: 'COMING SOON';
              font-size: 6px;
              font-weight: bold;
              left: 50%;
              padding: 2px;
              position: absolute;
              text-align: center;
              transform: translate(-50%, 0);
              width: 100%;
            }
          }
        }
        &.p-new-feature {
          .external-upload {
            overflow: hidden;
            position: relative;
            user-select: none;
            &::before {
              background-color: var(--upload-box-item-color);
              bottom: -1px;
              color: var(--default-font-color);
              content: 'NEW';
              font-size: 8px;
              font-weight: bold;
              left: 50%;
              padding: 2px;
              position: absolute;
              text-align: center;
              transform: translate(-50%, 0);
              width: 100%;
            }
          }
        }
        &:not(.p-coming-soon):hover,
        &.selected {
          .external-upload {
            border: 2px solid var(--secondary-theme-color);
            box-shadow: 0 0 15px 0 rgba(7, 8, 8, 0.2);
            cursor: pointer;
            &.p-new-feature {
              &::before {
                background-color: var(--secondary-theme-color);
              }
            }
          }
          p,
          h6 {
            opacity: 1;
          }
        }
      }
    }
    .options {
      align-items: flex-start;
      border-top: 1px solid var(--box-items-separator-color);
      justify-content: initial;
      margin-top: 40px;
      padding-top: 40px;
      .upload-box {
        min-width: 110px;
        p {
          font-size: 10px;
        }
      }
      &.anim {
        -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
        -o-animation: fadein 1s; /* Opera < 12.1 */
        animation: fadein 1s;
      }
    }
  }
  .dropzone-wrapper {
    margin: 20px 0;
    .project-dropzone {
      align-items: center;
      border: 2px solid var(--secondary-font-color-light);
      border-radius: 10px;
      border-style: dashed;
      display: flex;
      height: 100px;
      justify-content: center;
      margin-top: 10px;
      width: 100%;
      em {
        color: var(--secondary-theme-color);
        padding-left: 3px;
      }
      .p-above {
        max-width: 300px;
        word-break: break-all;
      }
      div {
        outline: none;
      }
    }
    .field-wrapper {
      margin-bottom: 20px;
    }
  }
  .inspection-limit {
    width: 800px;
    &__content-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 50px 0;
      padding: 0 20px;
      p {
        text-align: center;
      }
    }
    &__actions-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
      .btn {
        &:not(:last-child) {
          margin-right: 24px;
        }
        &:not(:first-child) {
          margin-left: 24px;
        }
      }
    }
  }
  &__title-container {
    align-items: center;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
  }
}
