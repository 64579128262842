.module-change-status-history-modal {
  width: 100%;
  min-height: 500px;
  max-height: 70vh;
  overflow: auto;
  .items-table-renderer {
    thead {
      th {
        background-color: var(--modal-color) !important;
        outline: var(--modal-color) !important;
      }
    }
    .empty-state {
      background-color: unset;
      height: auto;
    }
    tbody {
      tr {
        border-bottom: 1px solid var(--box-items-separator-color);
        &:hover {
          border-color: var(--box-items-separator-color);
          outline: 0;
          &::after {
            background-color: unset;
          }
        }
        &.selected {
          border-color: transparent;
          outline: 0;
          &::after {
            background-color: unset;
          }
        }
      }
    }
  }
  .status-signatures-wrapper {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
    padding-top: 12px;
    .user-signatures-wrapper {
      margin-top: 18px;
      .user-signature-field {
        border: 1px solid var(--box-items-separator-color);
        border-radius: 8px;
        height: 120px;
        margin-top: 4px;
        width: 100%;
      }
    }
  }
}
