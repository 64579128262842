.choose-components {
  border-top: 1px solid var(--separator-color);
  padding: 20px;
  .search {
    height: 100%;
    margin-top: 4px;
    .items-dropdown {
      z-index: 1;
      .item {
        padding: 0;
        p {
          padding: 5px 0;
        }
        .pointer {
          cursor: pointer;
        }
        .disabled {
          background-color: var(--box-items-separator-color);
          cursor: not-allowed;
        }
      }
    }
  }
  .component-details {
    display: flex;
    flex-direction: column;
    &__buttons {
      margin-top: 20px;
    }
    &__wrapper {
      flex: 1 0;
      margin-top: 20px;
      overflow: auto;
    }
  }
}
