.stream {
  .visible-area {
    margin-top: 103px;
    &__container {
      .split-screen {
        .resizable-box {
          padding: 20px;
          min-width: 505px;
        }
      }
    }
  }
}
