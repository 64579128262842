.resizable-box {
  .single-resizer {
    border-color: transparent !important;
    // transition: all 0.7s ease;
    -webkit-user-drag: none;
    user-drag: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 90;
  }
  &--hidden {
    display: none;
  }
  &.max-height {
    height: 100vh !important;
    .single-resizer {
      height: inherit !important;
    }
  }
  &.max-height-p {
    height: 100% !important;
    .single-resizer {
      height: inherit !important;
    }
  }
  &.max-width {
    width: 100% !important;
    .single-resizer {
      width: inherit !important;
    }
  }
  div.square {
    background-color: var(--primary-font-color);
  }
  //handlers
  // left
  .l {
    position: relative;
    &.resizable-handler {
      cursor: col-resize !important;
      height: 20px;
      margin-top: unset !important;
      width: 7px;
    }
    &.square {
      border: unset;
      border: 1px solid var(--filter-blocked);
      height: 20px;
      left: -6px;
      margin: unset;
      width: 7px;
      &::before {
        background-color: var(--filter-blocked);
        content: '';
        height: 100%;
        left: 2px;
        position: absolute;
        width: 1px;
      }
    }
  }
  // bottom
  .b {
    &.resizable-handler {
      cursor: row-resize !important;
      width: 20px;
    }
    &.square {
      border: unset;
      width: 20px;
    }
  }
  .fFutOK {
    border: unset;
  }

  //top
  .t {
    position: relative;
    &.resizable-handler {
      cursor: row-resize !important;
      height: 6px;
      margin-left: unset !important;
      width: 20px;
    }
    &.square {
      border: unset;
      border: 1px solid var(--filter-blocked);
      height: 7px;
      margin-left: unset !important;
      margin-top: -1px;
      width: 20px;
      &::before {
        background-color: var(--filter-blocked);
        content: '';
        height: 1px;
        position: absolute;
        top: 2px;
        width: 100%;
      }
    }
  }
  .fFutOK {
    border: unset;
  }
}
