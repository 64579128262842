.component-hierarchy {
  height: 100%;
  overflow: hidden;
  transition: all 0.7s ease;
  width: 100%;
  &.toolbar-opened {
    width: calc(100% - 360px);
  }
  .page-navigation {
    height: 108px;
    .button-text {
      text-transform: capitalize;
    }
  }
  &__container {
    height: calc(100% - 108px);
    padding: 16px 24px;
    width: 100%;
    &__path {
      margin-top: 20px;
      &.hide {
        display: none;
      }
      &.show {
        display: block;
      }
    }
    &__search {
      padding: 0;
      .search__input::placeholder {
        color: var(--ch-text);
        font-weight: 500;
      }
    }
  }
  .modal-button-custom {
    min-width: 450px;
    &:first-child {
      margin-top: 20px;
    }
    &:not(:first-child) {
      margin-top: 12px;
    }
  }
  &__modal {
    &__confirm {
      &__form {
        margin-top: 20px;
      }
    }
  }
}
