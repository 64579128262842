@import '../../../common//styles/variables';

$line-base-distance: -9px;
$line-thickness: 2px;
.create-role-popup {
  .modal {
    min-width: 400px;
  }
  .submit-btn {
    margin: 20px auto 0;
  }
  .section-content {
    overflow: auto;
    padding-left: 0;
    padding-right: 0;
  }
  form {
    height: 100%;
    overflow: auto;
    padding: 0 10px;
    width: 100%;
  }
  .expandable-section {
    overflow: hidden;
  }
  .sub-base {
    margin-left: 30px;
    position: relative;
    &::before {
      background-color: var(--primary-theme-light-color);
      bottom: 12px;
      content: '';
      left: $line-base-distance;
      position: absolute;
      top: 8px;
      width: $line-thickness;
    }
    .checkbox-wrapper {
      position: relative;
      &::before {
        background-color: var(--primary-theme-light-color);
        bottom: 12px;
        content: '';
        height: $line-thickness;
        left: $line-base-distance;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
      }
    }
  }
}
