.work-order-section-checklist-details {
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  .sections-container {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 0;
    margin: 20px 0;
    overflow: auto;
    width: 100%;

    .question-new-observation-section {
      display: flex;
      justify-content: space-between;
      padding-bottom: 15px;
      border-bottom: 1px solid var(--box-items-separator-color);
      margin-bottom: 35px;

      &__paragraph {
        max-width: 45%;
      }

      &__button {
        .cta {
          .button-text {
            font-weight: 500;
          }
          .green-plus-circle {
            margin-left: 4px;
            path {
              stroke: var(--secondary-theme-color);
              stroke-width: 1.5px;
            }
          }
        }
      }
    }

    .sections-item {
      padding-bottom: 16px;
      border-bottom: 1px solid var(--box-items-separator-color);
      .section-content-item {
        &__pagination-navigator {
          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }
    }
    .expandable-section {
      border: 0;
      padding: 0;
      .header {
        &__wrapper {
          cursor: pointer;
          display: flex;
          justify-content: flex-start;
        }
        &__actions {
          margin-left: 2px;
        }
      }
    }
    &__loader-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
    }
    &__custom-properties {
      border-bottom: 1px solid var(--box-items-separator-color);
      margin-bottom: 32px;
      padding: 20px 0;
      .toggle-action {
        cursor: pointer;
      }
      .toggle-action-edit {
        margin-right: 15px;
      }
      .toggle-action-expand {
        path {
          stroke: var(--secondary-font-color-dark);
        }
      }
      &-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
      }
      &-content {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
      &-item {
        width: 100%;
        .label {
          margin-bottom: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .questions-answered-progress {
      background-color: var(--modal-color);
      padding: 14px 0;
      position: sticky;
      top: 0;
      z-index: 1;
      &-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        &__submitted-wrapper {
          align-items: center;
          display: flex;
          p {
            margin-left: 4px;
          }
        }
      }
    }
    .question-field {
      border: 1px solid var(--box-items-separator-color);
      border-radius: 4px;
      margin-bottom: 12px;
      .question-field-container {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        padding: 27px 24px;
        .question-input-field {
          flex: 50%;
          .create-measurement-reading-form__preview {
            border-bottom: 1px solid var(--separator-color);
            border-top: 1px solid var(--separator-color);
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-top: 30px;
            padding: 20px 0;
            .reading-stat {
              display: flex;
              font-weight: 500;
              &__value {
                color: var(--primary-font-color);
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                margin-left: 4px;
              }
            }
          }
          .reading-field {
            align-items: center;
            display: flex;
            gap: 8px;
            position: relative;
            width: 100%;
            .field-wrapper {
              input {
                padding-right: 40px;
              }
              input,
              textarea {
                background-color: var(--input-background-color) !important;
                border: 1px solid var(--input-border-color);
                border-radius: 4px;
                height: 52px !important;
                max-width: 195px;
                min-width: 195px;
              }
            }
            .unit-text {
              display: flex;
              left: 150px;
              position: absolute;
              top: 20px;
              &::after {
                content: attr(data-after);
                display: inline-block;
                transform: translateX(calc(-100% + 30px));
              }
            }
          }
          .button-with-icon:disabled {
            opacity: 0.5;
          }
          .measurement-point-question-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            &__expected-range-wrapper {
              display: flex;
              flex-direction: column;
              max-width: 112px;
              min-width: 112px;
              label {
                font-size: 14px;
                font-style: normal;
                line-height: 20px;
              }
              &__label {
                font-weight: 500;
                margin-bottom: 8px;
              }
              &__label-value {
                font-weight: 400;
                color: var(--primary-font-color);
                word-break: break-all;
              }
            }
          }
        }
        .question-field-name-container {
          flex: calc(50% - 20px);
          margin-right: 20px;
          .question-label {
            margin-bottom: 4px !important;
            .no-answer-label {
              display: flex;
              gap: 8px;
              align-items: center;
            }
            .name {
              margin-left: 36px;
            }

            .section-question-state {
              width: 28px !important;
              height: 28px !important;
              min-width: 28px;
              min-height: 28px;
              font-size: 14px;
              margin-right: 0px;
            }
          }
        }
        .question-field-details-container {
          align-items: center;
          display: flex;
          margin-left: 36px;
        }
        .multi-answers-container {
          display: flex;
          flex-wrap: wrap;
          .question-option {
            flex: calc(50% - 4px);
            margin-right: 4px;
          }
        }
      }
      &-details-wrapper {
        border-top: 1px solid var(--box-items-separator-color);
        padding: 27px 24px;
        .inline-row {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 20px;
          .item {
            flex: 50%;
            &__label {
              margin-bottom: 8px;
              word-break: break-word;
            }
            &__value {
              max-height: 200px;
              overflow-y: auto;
              word-break: break-word;
            }
            &:not(:last-child) {
              flex: calc(50% - 4px);
              margin-right: 4px;
            }
          }
        }
      }
      .category-wrapper {
        padding: 10px 10px 10px 0;
        width: 50%;
        .title {
          color: var(--primary-font-color);
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
        }
        .category-files-wrapper {
          display: flex;
          flex-flow: column;
          margin-top: 10px;
          .header {
            p {
              color: var(--dms-grey-section-title);
              font-size: 13px;
              font-weight: 500;
              line-height: 20px;
              margin-bottom: 13px;
              margin-top: 12px;
              text-align: left;
            }
          }
          .row {
            align-items: center;
            border-bottom: 1px solid #eee;
            border-radius: 4px;
            display: flex;
            flex-direction: row;
            margin-bottom: 16px;
            padding-bottom: 8px;
            width: 100%;
            cursor: pointer;
            .title {
              color: var(--primary-font-color);
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              margin-left: 8px;
              margin-right: 7px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 90%;
            }
            .action {
              cursor: pointer;
              margin-left: auto;
              width: 10%;
            }
          }
        }
      }
    }
    .progress-bar {
      background-color: var(--box-items-separator-color);
      border-radius: 4px;
      .percentage-bars {
        &__progress {
          cursor: default;
        }
      }
    }
    &__section-pagination-navigator {
      margin-bottom: 32px;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--box-items-separator-color);
    }
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    &--with-submit-btn {
      justify-content: space-between;
    }
  }
  .field-wrapper {
    .invalid.input-wrapper__input {
      border: 1px solid var(--validation-error-color) !important;
      border-radius: 4px;
    }
    .additional-data {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }
  .section-question-state {
    display: flex;
    align-self: flex-start;
    align-items: center;
    border: 1px solid #b3b3b3;
    border-radius: 50%;
    color: var(--primary-font-color);
    display: inline-flex;
    min-height: 40px;
    justify-content: center;
    // should be 8px but the .section-name has by default margin-left: 5px
    margin-right: 3px;
    padding: 6px;
    text-align: center;
    min-width: 40px;
    &__question-label {
      margin-left: 8px;
      &__no-answer {
        font-size: 14px;
        font-weight: 500;
      }
    }
    &__started {
      border: 1px solid var(--secondary-theme-color);
    }
    &__completed {
      background-color: var(--secondary-theme-color);
      border: 1px solid var(--secondary-theme-color);
      color: #fff;
    }
    &__error {
      border: 1px solid var(--validation-error-color);
    }
  }

  // MP question styles
  .measurement-point-question-section__expanded {
    &__alarms,
    &__location,
    &__readings {
      padding: 20px;
    }
    &__readings {
      border-top: 1px solid var(--comment-input-border);
      .reading-equipment-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 16px;

        .equipment {
          display: flex;
          flex-flow: column;
        }
      }
    }
  }
}
