.add-contributors {
  &-container {
    display: block;
    max-height: 60vh;
    min-height: 60vh;
    width: 100%;
    &__buttons {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }
}
