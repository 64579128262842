.dms-upload-drawer {
  &__uploaded_info {
    background-color: var(--dms-upload-drawer-info-bg);
    height: 36px;
    padding: 8px 20px;
  }
  &__table-container {
    height: 214px;
    overflow-y: auto;
    padding: 20px 8px;
    &.full-height {
      height: 250px;
    }
  }
  &__table {
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    width: 100%;
    .twp {
      min-width: 0;
      width: 100%;
    }
    .upload-icon-col {
      max-width: 32px !important;
      min-width: 32px !important;
    }
    .upload-name-col {
      max-width: calc(60% - 32px) !important;
      min-width: calc(60% - 32px) !important;
      &__title {
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
      }
    }
    .upload-progress-col {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      max-width: 40% !important;
      min-width: 40% !important;
      padding-right: 12px;
      &__loader {
        align-items: center;
        display: flex;
        gap: 4px;
        &__icon {
          animation: rotate-animation 2s infinite linear;
        }
      }
    }
  }
  @keyframes rotate-animation {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
