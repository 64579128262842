.warning-indicator {
  background: rgba(var(--severity-orange-rgb), 0.1);
  border: 1px solid var(--severity-orange);
  border-radius: 4px;
  margin-top: 12px;
  width: 100%;
  &__inner {
    padding: 10px 12px;
    width: 100%;
  }
  &__title {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: flex-start;
    margin-bottom: 4px;
  }
  &__text {
    color: var(--primary-font-color);
  }
}
