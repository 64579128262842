$nav-width: 81px;
$inspection-left-toolbar: 433px; // 433px - NEW FEATURE, PAST VALUE - 360px
$inspection-right-toolbar: 360px;
$inspection-top-toolbar: 86px;
$upload-bar-height: 50px;
$page-title-bar-height: 122px;
$workflow-header-height: 34px;
$workflow-defects-box-height: 234px;
$scrollbar-size-default: 7px;
$image-description-height: 38px;
$tools-container-height: 45px;
$ml-time-series-graph-right-toolbar: 360px;

// MARGINS AND PADDINGS

$content-padding: 30px;
$left-toolbar-p-left: 27px;
$left-toolbar-p-right: 19px;
$left-toolbar-p-top: 25px;
$left-toolbar-p-bottom: 20px;

//form
$input-size-sm: 36px;
$input-size-lg: 52px;
$input-size-xl: 104px;

// color-palette
$color-item-size-sm: 16px;
$color-item-size-md: 20;
$color-item-size-lg: 24px;
$color-item-size-xl: 32px;

$keyboard-key-size-modifier: 1.5;
$keyboard-base-key-size: 20px;
:export {
  inspectionRightToolbar: $inspection-right-toolbar;
  mlTimeSeriesGraphRightToolbar: $ml-time-series-graph-right-toolbar;
}
