.keybox-create {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  min-width: 650px;
  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }
  .question-options {
    margin: 20px 0;
    padding: 0;
  }
  .question-field {
    min-width: 250px !important;
  }
  .question-options__wrapper {
    display: flex;
    flex-direction: column;
  }
  .question-options__wrapper {
    counter-reset: css-counter 0;
    list-style-type: none;
  }
  .question-options__wrapper__field {
    counter-increment: css-counter 1;
  }
  .question-options__wrapper__field::before {
    content: counter(css-counter) '. ';
    margin-right: 10px;
  }
  .add-button {
    margin-right: auto;
  }
}
