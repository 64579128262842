@import '../../../common/styles/variables';
.download-defect-report-modal {
  .modal-wrapper {
    max-height: 95%;
    overflow: auto;
  }
  .email-modal {
    .modal {
      min-height: unset !important;
    }
  }
  .modal {
    max-width: unset;
    min-height: 730px;
    overflow: unset;
    width: auto;
    .download-report {
      &__table,
      &__form {
        display: grid;
        grid-template-rows: 1.5fr 600px;
        height: 700px;
        max-width: 70vw;
        overflow-y: auto;
        width: 900px;
      }
      &__table {
        padding: 0 10px;
        tbody {
          tr {
            &.pointer {
              &:hover {
                background-color: var(--secondary-font-color-dark);
                cursor: pointer;
              }
            }
          }
        }
      }
      .custom-toolbar {
        margin-bottom: 10px;
      }
      .workflow-stepper {
        margin: 10px 0;
        .step {
          display: flex;
          flex-direction: column-reverse;
        }
      }
      .screenshot {
        &::after {
          animation-duration: 0.4s;
          animation-iteration-count: 1;
          animation-name: takeScreenshot;
          background-color: transparent;
          content: '';
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }
      .email-input {
        margin-bottom: auto;
        .inspection-input__input-wrapper {
          height: 25px;
        }
      }
    }
    .download-report-form {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      height: 600px;
      .report-complete {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: inherit;
        justify-content: center;
        text-align: center;
        width: 100%;
      }
      .screenshot-description {
        margin: 10px;
      }
      .inspection {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: inherit;
        width: 100%;
      }
      .field-wrapper,
      .inspection-input {
        padding: 0 20px;
        width: 100%;
        &.email-input {
          width: 300px;
        }
        textarea {
          border: 1px solid var(--box-items-separator-color);
          color: var(--default-font-color);
          min-height: 150px;
          width: 100% !important;
        }
        .rw-popup-container {
          padding: 0;
        }
        .error-message {
          min-height: 25px;
        }
      }
      .email-explanation {
        margin: 20px;
      }
      .btn {
        margin-top: 20px;
      }
      .multiple-buttons {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 200px 200px;
        justify-content: center;
        width: 100%;
      }
      .potree-area {
        height: 100%;
        width: 100%;
      }
    }
    .custom-toolbar {
      justify-content: flex-start;
      margin: 20px 20px 30px;
    }
    .three-dots {
      display: inline-block;
      overflow: hidden !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 145px;
    }
  }
}

@keyframes takeScreenshot {
  0% {
    background-color: rgba(255, 255, 255, 0.8);
  }
  40% {
    background-color: transparent;
  }
  80% {
    background-color: rgba(255, 255, 255, 0.8);
  }
  100% {
    background-color: transparent;
  }
}
