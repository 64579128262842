.permit-section-components {
  .notification-WO-tab__table {
    border-radius: 8px;
    height: auto;
    .table-template-container {
      padding-bottom: 20px;
    }
  }
  .twp-header {
    display: flex;
    .twp-header__cell {
      margin-right: auto;
      padding-left: 10px;
      padding-top: 8px;
      &.critical-icon {
        align-items: center;
        display: flex;
        margin-left: 4px;
        max-width: 15%;
      }
      &.code {
        max-width: 15%;
      }
      &.name {
        max-width: 70%;
      }
    }
  }
  .twp-body__row__cells {
    color: var(--primary-font-color);
    display: flex;
    width: 100%;
    &__cell {
      box-sizing: border-box;
      color: var(--primary-font-color);
      padding-left: 12px;
    }
    .code {
      flex: 0 0 15%;
      max-width: 15%;
    }
    .name {
      flex: 0 0 70%;
      max-width: 70%;
    }
    .critical-icon {
      flex: 0 0 15%;
      max-width: 15%;
    }
    .full-width {
      max-width: 85%;
    }
    .name-component {
      align-items: center;
      display: flex;
    }
  }
  .name-content {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: left;
    p {
      max-width: 130px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
    .info-tooltip {
      display: flex;
      margin-left: 4px;
    }
  }
  .critical-tooltip {
    .info-popover {
      min-height: 0;
      width: 158px;
    }
  }
}
