.files-items {
  padding-top: 10px;
  .files-item-header-separated {
    align-items: flex-start !important;
    flex-direction: column;
    .files-item-actions {
      align-items: flex-start !important;
      flex-direction: column;
      margin-left: 0 !important;
      .action-item {
        border-right: 0 !important;
        margin-top: 16px;
        padding-left: 0 !important;
      }
    }
  }
  .files-item-header {
    align-items: center;
    display: flex;
    .files-item-actions {
      align-items: center;
      display: flex;
      margin-left: auto;
      .action-item {
        align-items: center;
        cursor: pointer;
        display: flex;
        padding-left: 12px;
        p {
          margin-left: 5px;
        }
      }
      .take-screenshot-item {
        border-right: 1px solid var(--box-items-separator-color);
        padding-right: 12px;
      }
    }
  }
  .file-display-item {
    align-items: center;
    display: flex;
    padding: 12px 0;
    &.with-border {
      border-bottom: 1px solid var(--box-items-separator-color);
    }
    .file-item-name {
      align-items: center;
      cursor: pointer;
      display: flex;
      .file-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 250px;
      }
      .file-image {
        height: 28px;
        margin-right: 8px;
        width: 28px;
      }
      .file-other {
        margin-right: 8px;
      }
    }
    .delete-icon {
      cursor: pointer;
      margin-left: auto;
    }
  }
}
