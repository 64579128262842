$top-part-height: 455px;
.work-order-list {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 35px);
  .advanced-filter {
    .search-small {
      background-color: transparent;
      &__input::placeholder {
        color: var(--secondary-theme-color);
      }
    }
  }
  .table-container {
    height: calc(100vh - 295px);
    overflow: auto;
    .empty-state {
      height: 25vh;
    }
    .items-table-renderer {
      height: auto !important;
      .small-table-column {
        min-width: 30px;
      }
      .start-date {
        min-width: 105px;
      }
      .work-order-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 125px;
      }
      .work-order-priority {
        align-items: center;
        display: flex;
        .work-order-priority-color {
          border-radius: 50%;
          height: 16px;
          margin-right: 4px;
          width: 16px;
        }
        .work-order-hazard-icon {
          margin-left: 4px;
        }
      }
      .work-order-hazard-icon {
        path:nth-child(1) {
          fill: #f3ad3d;
          stroke: #f3ad3d;
        }
        path:nth-child(2),
        path:nth-child(3) {
          stroke: #000;
        }
      }
    }
  }
  .status-icon {
    border-radius: 50%;
    height: 13px;
    margin-left: 0;
    margin-right: 10px;
    width: 13px;
  }
  .def-icon {
    margin: 0 0 0 10px;
  }
  .advanced-filter {
    align-items: center;
    display: flex;
    justify-content: space-between;
    p {
      cursor: pointer;
    }
    .search-small {
      background-color: transparent;
    }
  }
  .status-filters {
    margin-top: 32px;
    .status-filters-title {
      margin-bottom: 8px;
    }
  }
}
