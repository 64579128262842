.create-permit-form {
  display: flex;
  flex: 1;
  flex-flow: column;
  max-width: 900px;
  min-width: 740px;
  padding-top: 20px;
  form {
    display: flex;
    flex: 1;
    flex-flow: column;
    min-height: 100%;
  }
  .input-name {
    max-width: 50%;
  }
  .permit-template {
    .template-placeholder {
      align-items: center;
      display: flex;
      .template-placeholder-icon {
        margin-right: 4px;
      }
    }
  }
  .permit-checkbox {
    .input-wrapper__input {
      height: 20px;
      width: 20px;
    }
    .field-wrapper__label {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-transform: none;
    }
    .field-wrapper__label.text-gray {
      color: #999;
      margin-left: 5px;
    }
  }
  .date-pickers {
    display: flex;
    width: 100%;
    .start-date-wrapper {
      padding-right: 10px;
      width: 50%;
    }
    .end-date-wrapper {
      padding-left: 10px;
      width: 50%;
    }
  }
  .buttons-container {
    display: flex;
    margin-top: auto;
    padding-top: 30px;
    .submit-button {
      margin-left: auto;
    }
  }
}
