.augmented-reality {
  height: calc(100% - 103px);
  transition: all 0.7s ease;
  width: 100%;
  .page-navigation {
    background-color: var(--background-color);
  }
  &__tools-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding-bottom: 100px;
    width: 100%;
    h5 {
      padding-bottom: 60px;
    }
    &__content {
      align-items: center;
      display: flex;
      gap: 20px;
      justify-content: center;
      padding: 0 20px;
    }
  }
}
