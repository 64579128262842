@import '../../../common/styles/variables';
.external-upload {
  display: grid;
  grid-template-columns: 440px;
  .title {
    margin-bottom: 10px;
  }
  .upload-form {
    margin-top: 10px;
    .upload-fields {
      display: grid;
      height: 100%;
      max-height: 60vh;
      min-height: 40vh;
      overflow: auto;
    }

    @import '../../../common/styles/selectable-image';
    button {
      margin: auto;
      margin-top: 10px;
    }
  }
}
