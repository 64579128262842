.isolations {
  .empty-state {
    background-color: transparent;
    height: 40vh;
    margin: auto;
  }
  .header {
    display: flex;
    flex-wrap: wrap;
    .search {
      margin-right: auto;
      width: 320px;
    }
  }
  .isolations-table {
    th:last-child {
      min-width: auto;
      text-align: center;
    }
    .description {
      min-width: 350px;
    }
    .id-column {
      min-width: 50px;
    }
    .shortened {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 350px;
    }
  }
  &__table {
    height: calc(100vh - 410px);
    margin-top: 12px;
    overflow: auto;
  }
  &__load-more-wrapper {
    bottom: 0;
    left: 50%;
    margin: 15px auto;
    margin-top: auto;
    padding: 8px 10px;
    position: absolute;
    transform: translateX(-50%);
    width: 100%;
  }
}
.isolation-template-confirm-modal {
  .modal-wrapper {
    max-width: 500px;
    .modal {
      &__content {
        text-align: center;
      }
    }
  }
}
