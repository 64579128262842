.editor {
  background: var(--comment-input-bg);
  border: 1px solid var(--comment-input-border);
  border-radius: 4px;
  box-sizing: border-box;
  color: var(--primary-font-color);
  cursor: text;
  height: 56px;
  padding: 16px;
  transition: all ease 0.5s;
  overflow: auto;
  &.expanded {
    height: 156px;
    margin-bottom: 20px;
  }
  .icon-users {
    cursor: pointer;
  }
}
.editor :global(.public-DraftEditor-content) {
  max-height: 156px;
  min-height: 140px;
}
.editor {
  .public-DraftEditorPlaceholder-root {
    color: var(--ch-text);
    position: absolute;
  }
}
.mention-suggestions {
  background: var(--background-color);
  border: 1px solid var(--input-border-color);
  border-radius: 4px;
  bottom: 170px;
  max-height: 440px;
  min-height: 50px;
  overflow: scroll;
  padding: 12px;
  position: absolute;
  right: 2px;
  width: 100%;
  &__section-title {
    border-bottom: 1px solid var(--ch-text);
    color: var(--ch-text);
    padding: 4px 11px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-color);
    -webkit-border-radius: 100px;
    border-radius: 100px;
  }
}
