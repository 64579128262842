.view-modes {
  //
  .view-mode-item {
    p.bold {
      margin-bottom: 4px;
    }
    &:not(:first-child) {
      margin-top: 36px;
    }
  }
}
