
.sync-modal-wrapper {
  .modal {
    overflow: unset;
  }
  .sync-modal {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    width: 100%;
    &__description {
      align-self: center;
      border-bottom: 1px solid var(--box-items-separator-color);
      margin-bottom: 30px;
      padding-bottom: 30px;
    }
    &__list {
      flex: 1;
      margin-bottom: 20px;
      overflow: auto;
      padding-right: 7px;
    }
    &__button {
      align-self: center;
      min-height: 36px;
    }
    .loader-wrapper {
      display: flex;
      justify-content: center;
      padding: 20px 0;
      .equipment-loader {
        p {
          color: var(--default-font-color);
          margin: -15px;
        }
      }
    }
  }
}
