.small-dropdown {
  &__settings-icon {
    background: var(--secondary-font-color-dark);
    border-radius: 50%;
    height: 3px;
    position: relative;
    width: 3px;
    &::before {
      background: var(--secondary-font-color-dark);
      border-radius: 50%;
      content: '';
      height: 3px;
      position: absolute;
      transform: translateY(6px);
      transition: transform 0.3s ease;
      width: 3px;
    }
    &::after {
      background: var(--secondary-font-color-dark);
      border-radius: 50%;
      content: '';
      height: 3px;
      position: absolute;
      transform: translateY(-6px);
      transition: transform 0.3s ease;
      width: 3px;
    }
  }
  &__settings {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 18px;
    justify-content: center;
    position: relative;
    width: 100%;
    &.disabled {
      cursor: not-allowed;
    }
    //expand the clickable area
    &:hover {
      .small-dropdown__settings-icon {
        &::before {
          transform: translateY(7px);
        }
        &::after {
          transform: translateY(-7px);
        }
      }
    }
  }
  &__settings-as-button {
    background-color: #eee;
    border-radius: 50%;
    height: 36px;
    width: 36px !important;
  }
}
#root {
  .dropdown {
    background-color: var(--dropdown-bg);
    border: 1px var(--dropdown-border) solid;
    min-width: 140px;
    z-index: 100;
    &__item .wrapper {
      align-items: center;
      cursor: pointer;
      display: flex;
      padding: 8px 10px;
      &.highlighted p {
        color: var(--validation-error-color);
      }
      &.highlighted:hover {
        background: var(--dropdown-delete-highlight);
      }
      .icon,
      .title-icon {
        margin-right: 5px;
      }
      .check-icon {
        margin-left: auto;
      }
      p {
        color: var(--primary-font-color);
        display: inline;
        font-weight: 500;
        margin-right: 5px;
        white-space: nowrap;
      }
      &.disabled {
        cursor: not-allowed;
      }
      &.separator {
        border-top: 1px solid var(--dropdown-separator);
      }
      &.green-icon {
        svg {
          margin-right: 4px;
        }
        &.stroke {
          svg {
            path {
              stroke: var(--dms-green-section-title);
            }
          }
        }
        &.fill {
          svg {
            path {
              fill: var(--dms-green-section-title);
            }
          }
        }
      }
      &.red-icon {
        svg {
          margin-right: 4px;
        }
        p {
          color: var(--validation-error-color);
        }
        &.stroke {
          svg {
            path {
              stroke: var(--validation-error-color);
            }
          }
        }
        &.fill {
          svg {
            path {
              fill: var(--validation-error-color);
            }
          }
        }
      }
    }
    .hovered {
      background-color: var(--dropdown-hovered);
    }
  }
  .child-svg-mr-10 {
    svg {
      margin-right: 10px !important;
    }
  }
}
