.twp-header {
  align-items: center;
  border-bottom: 1px var(--ch-text) solid;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  &.sticky {
    align-items: center;
    background-color: var(--background-color);
    display: flex;
    height: 56px;
    justify-content: center;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  &__cell {
    display: flex;
    flex-grow: 1;
    max-width: 15%;
    min-height: 28px;
    min-width: 5%;
    padding: 4px;
    &.clickable {
      cursor: pointer;
    }
    &__container {
      display: flex;
      gap: 4px;
      &__text {
        color: var(--ch-text);
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}
