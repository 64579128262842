.notification-list {
  height: 95vh;
  .module-header {
    height: 90px;
  }
  &__wrapper {
    //110 px is 90 of module header and 2 more of padding/margin
    height: calc(100% - 110px);
    overflow: hidden;
  }
  &__table {
    &.three-d {
      .twp {
        min-width: 0;
      }
      .icon-column {
        align-items: center;
        justify-content: center;
        max-width: 32px !important;
        min-width: 32px !important;
      }
      .id-column {
        align-items: center;
        justify-content: flex-start;
        max-width: 80px !important;
        min-width: 80px !important;
      }
      .created-at-column {
        align-items: center;
        justify-content: flex-end;
        max-width: 120px !important;
        min-width: 120px !important;
        padding-right: 8px;
      }
      .name-column {
        align-items: center;
        justify-content: flex-start;
        max-width: calc(100% - 32px - 120px - 80px - 32px) !important;
        min-width: calc(100% - 32px - 120px - 80px - 32px) !important;
      }
      .toggle-column {
        align-items: center;
        justify-content: flex-start;
        max-width: 32px !important;
        min-width: 32px !important;
      }
    }
    &.full-screen {
      overflow-x: scroll;
      .twp {
        width: 2328px;
      }
      .id-column {
        align-items: center;
        justify-content: flex-start;
        max-width: 80px !important;
        min-width: 80px !important;
      }
      .name-column {
        align-items: center;
        justify-content: flex-start;
        max-width: 300px !important;
        min-width: 300px !important;
      }
      .status-column {
        align-items: center;
        justify-content: flex-start;
        max-width: 150px !important;
        min-width: 150px !important;
        .status-text {
          margin-left: 4px;
        }
      }
      .source-column {
        align-items: center;
        justify-content: flex-start;
        max-width: 150px !important;
        min-width: 150px !important;
      }
      .type-column {
        align-items: center;
        justify-content: flex-start;
        max-width: 150px !important;
        min-width: 150px !important;
      }
      .severity-column {
        align-items: center;
        justify-content: flex-start;
        max-width: 98px !important;
        min-width: 98px !important;
      }
      .created-at-column {
        align-items: center;
        justify-content: flex-start;
        max-width: 200px !important;
        min-width: 200px !important;
      }
      .created-by-column {
        align-items: center;
        justify-content: flex-start;
        max-width: 200px !important;
        min-width: 200px !important;
      }
      .actioned-at-column {
        align-items: center;
        justify-content: flex-start;
        max-width: 200px !important;
        min-width: 200px !important;
      }
      .actioned-by-column {
        align-items: center;
        justify-content: flex-start;
        max-width: 200px !important;
        min-width: 200px !important;
      }
      .closed-at-column {
        align-items: center;
        justify-content: flex-start;
        max-width: 200px !important;
        min-width: 200px !important;
      }
      .closed-by-column {
        align-items: center;
        justify-content: flex-start;
        max-width: 200px !important;
        min-width: 200px !important;
      }
      .work-orders-column {
        align-items: center;
        justify-content: flex-start;
        max-width: 200px !important;
        min-width: 200px !important;
      }
    }
  }
}
