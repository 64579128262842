.inspection-modal {
  .title {
    h5 {
      text-align: center;
    }
  }
  .options {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 20px;
    min-width: 300px;
    .modal__button {
      margin: auto;
    }
    .dropzone-wrapper {
      margin: 0;
      .project-dropzone {
        height: 45px;
        margin: 0;
        width: 45px;
      }
    }
    .external-upload {
      border: 2px solid var(--secondary-font-color-light);
      border-radius: 10px;
      border-style: dashed;
      height: 45px;
      padding: 10px;
      width: 45px;
      svg {
        height: 24px;
        width: 20px;
      }
    }
    .external-upload,
    .project-dropzone {
      &:hover {
        border: 2px solid var(--secondary-theme-color);
        border-style: dashed;
        cursor: pointer;
      }
    }
    .upload-box {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h6 {
        margin-top: 10px;
      }
    }
  }
}
