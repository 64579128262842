.stream-details-form {
  max-width: 445px;
  min-width: 445px;

  .field-wrapper {
    margin-top: 32px;
    label {
      margin-bottom: 4px;
    }
  }
}
