.advanced-filter-custom-property-field {
  background-color: var(--comments-tab-bg);
  margin-top: 16px;
  padding: 24px;
  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
  }
  &__fields-container {
    align-items: center;
    display: flex;
    gap: 20px;
    justify-content: center;
    width: 100%;
    &__field {
      width: 50%;
    }
  }
  .field-wrapper {
    &__label {
      line-height: 20px;
      margin-bottom: 0;
    }
  }
  .inline-wrapper {
    label {
      line-height: 20px;
    }
  }
}
.or-container {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 16px;
  &__text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--ch-text);
  }
}
