.work-tab {
  width: 100%;
  &__tabs {
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
    overflow-x: auto;
    white-space: nowrap;
  }
}
