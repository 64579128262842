.version-history-modal {
  max-height: 60vh;
  max-width: 50vw;
  min-height: 60vh;
  min-width: 50vw;
  overflow-x: hidden;
  overflow-y: auto;
  .twp {
    min-width: 0;
    .twp-header {
      &.sticky {
        height: 28px;
        margin-top: 12px;
      }
    }
    .is-active-column,
    .action-menu-column {
      align-items: center;
      display: flex;
      justify-content: center;
      max-width: 32px !important;
      min-width: 32px !important;
    }
    .name-column {
      max-width: calc(35% - 64px) !important;
      min-width: calc(35% - 64px) !important;
      &__container {
        align-items: center;
        display: flex;
        gap: 4px;
        width: 100%;
        .info-tooltip {
          align-items: center;
          display: flex;
        }
      }
      &__text {
        max-width: 70%;
      }
    }
    .system-version-column {
      max-width: 15% !important;
      min-width: 15% !important;
    }
    .version-number-column {
      max-width: 25% !important;
      min-width: 25% !important;
    }
    .version-date-column {
      max-width: 25% !important;
      min-width: 25% !important;
    }
  }
}
