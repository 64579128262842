@import '../../../common/styles/variables';
.info-popover {
  background: var(--background-color);
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.1);
  opacity: 0;
  padding: 16px;
  min-height: 100px;
  position: fixed;
  z-index: 1;
  min-width: 150px;
  .tooltip-title {
    margin-bottom: 12px;
  }
  &--visible {
    opacity: 1;
  }
  &--hidden {
    visibility: hidden;
  }
  .text-container {
    font-size: 12px;
    font-weight: normal;
    max-width: 300px;
    word-break: break-word;
  }
  &__item {
    display: flex;
    width: auto;
  }
  &__label-container {
    margin-bottom: 4px;
    .label {
      font-size: 14px;
      font-weight: normal;
      min-height: 20px;
    }
  }
  &__value-container {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    .value {
      font-size: 14px;
      font-weight: normal;
      min-height: 20px;
    }
  }
}
