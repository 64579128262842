@import '../../../common//styles//variables';

$main-content-height: 87vh;
.pdf-tag-list-wrapper {
  display: grid;
  grid-template-rows: auto 1fr;
  height: $main-content-height;
  padding: 24px 20px;
  &.tabs-hidden {
    height: $main-content-height;
  }
  table th:last-child {
    min-width: auto;
  }
  .section-wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .table-wrapper {
      height: 100%;
      overflow: auto;
      position: relative;
      .name {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        .dots {
          display: inline-block;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .loader {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      table tbody tr {
        border-bottom: 1px solid var(--box-items-separator-color);
      }
      .actions {
        align-items: center;
        display: grid;
        grid-template-columns: 80px 40px;
        justify-content: center;
        button {
          min-width: 80px;
        }
      }
      .info-tooltip {
        width: 20px;
      }
      table thead th:first-child,
      table thead td:first-child {
        min-width: 50px;
      }
      .info-popover {
        max-width: 400px;
      }
      .empty-state {
        align-items: center;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        button {
          margin-top: 10px;
        }
      }
    }
    .button {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 20px 0;
    }
    .title-section {
      align-items: center;
      display: flex;
      justify-content: space-between;
      min-height: 50px;
      position: relative;
      h5 {
        padding-right: 20px;
      }
      .search {
        width: 220px;
      }
    }
  }
  .edit-pdf-modal {
    .modal-wrapper {
      min-width: 400px;
    }
  }
  .edit-pdf-form {
    width: 100%;
    .react-datepicker-wrapper {
      width: 100%;
    }
    .buttons {
      display: flex;
      button:first-child {
        margin-right: 10px;
      }
    }
  }
  .password-form {
    .error-placeholder {
      justify-content: left;
      margin-top: -20px;
      text-align: end;
    }
  }
}
.footer-bar-opened {
  .pdf-tag-list-wrapper {
    height: #{$main-content-height};
  }
}
