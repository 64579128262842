.image-item {
  display: inline-block;
  margin: 2px;
  input[type='checkbox'] {
    display: none;
  }
  label {
    border: 3px solid  var(--modal-color);
    cursor: pointer;
    display: block;
    min-height: 82px;
    opacity: 0.6;
    position: relative;
    .new-tab {
      position: absolute;
      right: 2px;
      top: 2px;
      &:hover {
        g {
          fill: var(--secondary-theme-color);
        }
      }
    }

  }
  label::before {
    background-color: var(--modal-color);
    border-radius: 50%;
    bottom: 1px;
    content: ' ';
    display: block;
    height: 14px;
    position: absolute;
    right: 1px;
    transform: scale(0);
    transition-duration: 0.4s;
    width: 14px;
    z-index: 2;
  }
  label::after {
    bottom: 5px;
    content: ' ';
    display: block;
    height: 25px;
    position: absolute;
    right: 5px;
  }
  input:checked + label {
    border: 3px solid var(--secondary-theme-color);
    opacity: 1;
  }
  input:checked + label::before {
    background-color: var(--secondary-theme-color);
    transform: scale(1);
  }
  input:checked + label::after {
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    display: inline-block;
    height: 6px;
    transform: rotate(45deg);
    width: 3px;
    z-index: 3;
  }
}
