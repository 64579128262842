.confirm-password-modal {
  min-width: 450px;
  max-width: 450px;
  .modal-button-custom {
    min-width: 450px;
    &:first-child {
      margin-top: 20px;
    }
    &:not(:first-child) {
      margin-top: 12px;
    }
  }
  label,
  input,
  placeholder {
    text-transform: none !important;
  }
  .field-wrapper {
    .input-wrapper {
      &__input {
        height: 52px !important;
        &::placeholder {
          text-transform: none !important;
        }
      }
    }
  }
  .btn {
    .button-text {
      font-weight: 400;
    }
  }
}
