.alarms-section {
  .component-item-header {
    .header__wrapper {
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 0px;
    }
    .component-header {
      .capitalize {
        text-transform: capitalize;
      }
      width: 100%;
      align-items: center;
      border-bottom: 1px solid var(--box-items-separator-color);
      display: flex;
      gap: 6px;
      padding: 20px 0;
      padding-top: 0px;
      padding-bottom: 12px;
      .component-header-title {
        font-weight: 400;
        font-size: 16px !important;
      }
      .component-header-toggle {
        cursor: pointer;
        height: 20px;
        margin-left: auto;
        width: 20px;
        path {
          stroke: var(--secondary-font-color-dark);
        }
      }
    }
  }
  .section-content {
    padding-left: 0px;
  }
  .section-content--visible {
    padding-left: 0px !important;
  }
  .alarms-item-container {
    .alarms-item {
      padding: 16px 0;
    }
  }
}
