.equipment-item {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  &.expandable > .item {
    cursor: pointer;
  }
  &.expandable > .item > .item__dropdown-arrow {
    display: block;
  }
  &.expandable {
    border-bottom: 1px solid var(--box-items-separator-color);
  }
  .children {
    padding: 10px 0;
  }
  .item {
    align-items: center;
    display: flex;
    height: 27px;
    padding-bottom: 8px;
    &__checkbox {
      margin-right: 10px;
      input[type='checkbox'] {
        display: none;
      }
      label {
        background-color: #f4f4f4;
        border: solid 1px #b8c5d3;
        cursor: pointer;
        display: block;
        height: 16px;
        position: relative;
        width: 16px;
      }
      label::before {
        background-color: #f4f4f4;
        bottom: 0;
        content: ' ';
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: scale(0);
        transition-duration: 0.4s;
        z-index: 2;
      }
      label::after {
        bottom: 4px;
        content: ' ';
        display: block;
        height: 25px;
        position: absolute;
        right: 4.5px;
      }
      input:checked + label::before {
        background-color: var(--secondary-theme-color);
        transform: scale(1);
      }
      input:checked + label::after {
        border-bottom: 2px solid white;
        border-right: 2px solid white;
        display: inline-block;
        height: 6px;
        transform: rotate(45deg);
        width: 3px;
        z-index: 3;
      }
    }
    &__equipment-type {
      align-items: center;
      border-right: 1px solid var(--box-items-separator-color);
      display: flex;
      height: 100%;
      justify-content: center;
      margin-right: 10px;
      padding: 0 5px;
      width: 25px;
    }
    &__name {
      align-items: center;
      display: flex;
      flex: 1;
      margin-right: 10px;
      overflow: hidden;
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      label {
        color: var(--default-font-color);
        font-size: 14px;
        font-weight: 700;
        margin-left: 5px;
      }
    }
    &__dropdown-arrow {
      display: none;
      .arrow {
        align-items: center;
        display: flex;
        &--up {
          transform: rotate(180deg);
        }
      }
    }
  }
  
}

