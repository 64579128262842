@import '../../styles/variables';
.btn {
  align-items: center;
  border: 0;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  justify-content: center;
  outline: 0;
  text-align: center;
  transition: 0.2s all;
  &:hover {
    opacity: 0.8;
  }

  //btn size W and H
  &.w-xs {
    min-width: auto;
    padding-left: 32px;
    padding-right: 32px;
  }
  &.w-sm {
    min-width: 166px;
  }
  &.w-md {
    min-width: 290px;
  }
  &.w-lg {
    width: 100%;
  }
  &.h-sm {
    height: 36px;
  }
  &.h-md {
    height: 52px;
  }

  //colors
  &.btn-success {
    background-color: var(--secondary-theme-color);
    border: 1px solid var(--secondary-theme-color);
  }
  &.btn-success-outline {
    background-color: transparent;
    border: 1px solid var(--secondary-theme-color);
    color: var(--secondary-theme-color);
  }
  &.btn-gray {
    background-color: var(--secondary-font-color-dark);
    border: solid 1px var(--secondary-font-color-dark);
  }
  &.btn-gray-outline {
    background-color: transparent;
    border: solid 1px var(--secondary-font-color-dark);
    color: var(--secondary-font-color-dark);
  }
  &.btn-warning {
    background-color: var(--warning-color);
    border: 1px solid var(--warning-color);
  }
  &.btn-warning-outline {
    background-color: transparent;
    border: 1px solid var(--warning-color);
    color: var(--warning-color);
  }
  &.btn-danger {
    background-color: var(--severity-red);
    border: solid 1px var(--severity-red);
  }
  &.btn-danger-outline {
    background-color: transparent;
    border: solid 1px var(--severity-red);
    color: var(--severity-red);
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  // modifiers
  &.load-more {
    bottom: 0;
    left: 50%;
    margin: 15px auto;
    margin-left: -80px;
    padding: 8px 10px;
    position: absolute;
  }
  &.auto {
    margin: auto;
  }
  .button-text {
    align-items: center;
    display: flex;
    font-family: $primary-font-family;
    font-weight: 700;
    justify-content: center;
    line-height: 20px;
    .icon {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-right: 8px;
    }
    .with-icon {
      &__position-left {
        margin-right: 8px;
      }
      &__position-right {
        margin-left: 8px;
      }
    }
  }
  .capitalized {
    text-transform: capitalize;
  }
  .loader-icon {
    animation: rotate-animation 2s infinite linear;
    margin-left: 4px;
  }
}

@keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
