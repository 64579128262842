.create-measurement-point-modal {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-height: 500px;
  min-width: 800px;
  .component-list {
    .items-table-renderer {
      table {
        min-width: auto;
      }
    }
  }
  .step-title {
    margin-top: 20px;
  }
  .step-desc {
    margin: 20px 0;
  }
  .b-top {
    border-top: 1px solid var(--separator-color);
    padding-top: 20px;
  }
  .pointer {
    cursor: pointer;
  }
}
