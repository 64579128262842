@import '../../styles/variables';
.empty-state {
  align-items: center;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding: 15px;
  &__icon-wrapper {
    align-items: center;
    display: flex;
    max-width: 50%;
    &--icon {
      min-height: 120px;
      min-width: 120px;
    }
  }
  &__title {
    font-family: $primary-font-family;
    margin-bottom: 10px;
    text-align: center;
  }
  &__message {
    font-family: $primary-font-family;
    text-align: center;
  }
  &__title-small {
    font-size: 14px;
  }
  &__title-medium {
    font-size: 18px;
  }
  &__title-large {
    font-size: 24px;
  }
  &__message-small {
    font-size: 12px;
  }
  &__message-medium {
    font-size: 14px;
  }
  &__message-large {
    font-size: 16px;
  }
}
