.generate-report-modal {
  .modal {
    overflow-x: hidden;
    &::-webkit-scrollbar {
      margin-left: 25px;
      width: 4px;
    }
  }
}
.generate-report-modal-wrapper {
  margin-right: 4px;
  min-height: 540px;
  min-width: 500px;
  overflow-x: hidden;
  &.show-dates-only {
    min-height: 200px;
    min-width: 500px;
  }
  &.expand-modal {
    min-height: 350px;
    min-width: 550px;
    transition: min-height 0.5s;
    .date-pickers-wrapper {
      margin-bottom: 50%;
    }
  }
  .search-input-wrapper {
    margin-bottom: 32px;
    &__title {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      text-transform: capitalize;
    }
    .empty-state {
      height: 100%;
    }
    .search-dropdown-items-wrapper {
      margin-top: 4px;
      .empty-state {
        height: 100%;
      }
    }
    .search-wrapper {
      width: 100%;
      .highlight-placeholder {
        .search__input {
          &::placeholder {
            color: #000 !important;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
          }
        }
      }
    }
    .items-dropdown {
      background: #fff;
      border: 1px solid var(--dms-linked-components-bg);
      border-radius: 4px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 1px 0 rgba(0, 0, 0, 0.06), 0 1px 1px 0 rgba(0, 0, 0, 0.08);
      max-height: 365px;
      overflow-y: scroll;
      padding: 8px;
      width: 100%;
      .group-item-wrapper {
        align-items: center;
        cursor: pointer;
        display: flex;
        padding: 8px;
        &:hover {
          background-color: #e9ecef;
          border-color: #e9ecef;
        }
        .checkbox-wrapper {
          label {
            margin-left: 0;
          }
        }
      }
    }
  }
  .toggle-field-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 32px;
    width: 100%;
    .label {
      color: var(--primary-font-color);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-right: auto;
    }
    .field-wrapper {
      margin-bottom: 0;
    }
  }
  .date-pickers-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    .single-date-picker-wrapper:last-of-type {
      .rw-popup-container.rw-calendar-popup {
        left: -4.6em;
      }
    }
    .single-date-picker-wrapper {
      min-width: 230px;
      .label {
        color: var(--primary-font-color);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 4px;
        margin-right: auto;
      }
      .rw-widget-picker {
        border: 1px solid #c6c6c6;
        display: flex;
        .rw-widget-input {
          color: var(--primary-font-color);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          max-width: 80%;
          padding: 16px 0 16px 16px;
        }
        .rw-input-addon,
        .rw-input-addon.rw-picker-btn {
          border-left: 0;
          width: 20%;
        }
      }
    }
  }
  .button-wrapper {
    .button-text {
      font-weight: 500;
    }
  }
}
