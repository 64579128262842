.manage-groups-modal {
  max-width: 45vw;
  min-width: 45vw;
  max-height: 65vh;
  min-height: 65vh;

  .table-template {
    .table-template-no-data-text {
      .empty-state-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50vh;
        .empty-state-container__content {
          p {
            width: 100%;
          }
        }
      }
    }
  }
  .groups-table {
    max-height: 60vh;
    min-height: 60vh;

    .twp {
      min-width: 100%;
    }
    .name-column {
      max-width: 40% !important;
      min-width: 40% !important;
      p {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
    .created-at-column,
    .created-by-column {
      // using 32px here, since it is split on 2 columns instead of 1
      max-width: calc(30% - 32px) !important;
      min-width: calc(30% - 32px) !important;
    }
    .action-column {
      max-width: 32px !important;
      min-width: 32px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .manage-groups-action__delete {
    .modal-wrapper {
      padding: 20px 24px;

      p {
        margin-top: 16px;
        margin-bottom: 32px;
      }
      .first-paragraph {
        margin-top: 16px;
        margin-bottom: 0px;
      }
      .second-paragraph {
        margin-top: 0px;
        margin-bottom: 20px;
      }

      .button-text {
        font-weight: 500;
        text-transform: capitalize;
      }
    }
  }
}

.delete-and-move-modal {
  .modal-wrapper {
    padding: 20px 24px;
    p {
      padding-top: 16px;
    }
  }
}
