$load-more-height: '100px';
$header-height-sm: '35px';
$header-height-lg: '35px';
.permits {
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{$header-height-sm});
  .status-filters {
    padding: 25px 0 15px;
    .status-filters-title {
      margin-bottom: 10px;
    }
  }
  .advanced-filter {
    align-items: center;
    display: flex;
    justify-content: space-between;
    .search-small {
      background-color: transparent;
      &__input::placeholder {
        color: var(--secondary-theme-color);
      }
    }
  }
  .table-container {
    flex: 1 0;
    overflow: auto;
    .items-table-renderer {
      .display-color-item {
        margin-right: 4px;
      }
    }
    .permits-popover {
      align-items: center;
      display: flex;
      gap: 4px;
    }
  }
  .icon-plus-rounded {
    margin-right: 10px;
  }
  .empty-state {
    height: 37vh;
    margin: auto;
  }
}
.full-screen {
  .permits {
    height: calc(100vh - #{$header-height-lg});
  }
}
