@import '../../../common/styles/variables';
.editable-date {
  align-items: center;
  display: flex;
  justify-content: center;
  .small-dropdown__settings {
    margin-left: 5px;
    width: 10px;
  }
  .label-container {
    &__icon {
      cursor: pointer;
      margin-left: 10px;
      transition: all 0.3s ease;
      &:hover {
        fill: var(--secondary-theme-color);
        g {
          fill: var(--secondary-theme-color);
        }
        path {
          fill: var(--secondary-theme-color);
        }
      }
    }
  }
  .react-datepicker-popper {
    .react-datepicker__triangle {
      left: unset;
      right: 50px;
    }
  }
  .input-wrapper {
    padding-top: 0;
    .input-wrapper__input {
      font-size: 10px;
      min-width: 162px;
      padding: 9px 8px;
      text-align: center;
    }
  }
  .react-datepicker-wrapper {
    input {
      border: unset !important;
      font-size: 14px !important;
      height: 14px;
      padding: 0 !important;
      width: 200px;
    }
  }
}
