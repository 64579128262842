.create-measurement-point-form {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-height: 500px;
  min-width: 800px;
  overflow: auto;
  margin-top: 32px;
  .field-wrapper {
    .input-wrapper {
      input,
      textarea {
        background-color: var(--input-background-color) !important;
        border: 1px solid var(--input-border-color) !important;
        border-radius: 4px;
        height: 52px !important;
      }
    }
  }
  &__content {
    flex-grow: 1;
    overflow: auto;
  }
  .select-d {
    padding-right: 15px;
  }
  .added-items-list,
  .added-items {
    margin-top: 20px;
  }
  .added-items-list {
    max-height: 350px;
    overflow: auto;

    .empty-state {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .added-items {
    margin-bottom: 32px;
    width: 100%;
  }
  .buttons {
    align-items: center;
    display: flex;
    flex-direction: row !important;
    padding: 40px 0px 0px 0px !important;
    .btn {
      .button-text {
        font-weight: 500;
      }
    }
    .right-buttons {
      align-items: center;
      display: flex;
      margin-left: auto;
      .button-submit {
        margin-left: 20px;
      }
    }
  }
  .ghost-field {
    display: none;
  }
  .x-hover:hover {
    path {
      fill: var(--severity-red);
    }
  }
}
