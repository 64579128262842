@import '../../../common/styles/mixins';
@import '../../../common/styles/variables';

$checkbox-width: 25px;
$chekbox-height: 25px;
.field-wrapper {
  &--disabled {
    .input-wrapper {
      opacity: 0.25;
      user-select: none;
    }
  }
  &--only-disabled {
    .input-wrapper,
    .time-picker-wrapper {
      cursor: not-allowed;
      user-select: none;
      input,
      textarea {
        pointer-events: none;
        user-select: none;
      }
    }
  }
  &__label {
    align-items: center;
    color: var(--secondary-font-color-light);
    display: flex;
    gap: 4px;
    margin-bottom: 9px;
    text-transform: capitalize;
    &.sublabel {
      display: inline-block;
      font-weight: lighter;
      margin: 10px 0 20px;
      width: 100%;
    }
    .info-tooltip {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }
  &.hidden-field {
    display: none;
    visibility: hidden;
  }
  .input-wrapper {
    padding-top: 4px;
    position: relative;
    &__input {
      background-color: var(--input-background-color);
      border: 1px solid var(--input-border-color);
      border-radius: 4px;
      color: var(--primary-font-color);
      font-family: $primary-font-family;
      font-size: 14px;
      font-weight: normal;
      outline: none;
      padding: 9px 14px;
      padding-bottom: 9px;
      transition: 0.2s all;
      width: 100%;

      @include placeholder {
        font-size: 14px;
        text-transform: capitalize;
      }
      &.sm {
        height: $input-size-sm;
      }
      &.lg {
        height: $input-size-lg;
      }
      &.xl {
        height: $input-size-xl;
      }
      &.has-icon {
        padding-left: 36px;
      }
      &.has-password-icon {
        padding-right: 36px;
      }
    }
    .error-message {
      min-height: 25px;
      visibility: hidden;
      &--visible {
        display: block !important;
        padding: 5px 0;
        visibility: visible;
        color: var(--validation-error-color);
        font-size: 12px;
        &::first-letter {
          text-transform: capitalize;
        }
        .error {
          color: var(--validation-error-color);
          font-size: 12px;
        }
        .warning {
          color: var(--warning-color);
          font-size: 12px;
        }
        .red-warning {
          font-size: 12px;
          color: var(--validation-error-color);
        }
      }
    }
    .with-hidden-error-message {
      display: none;
    }
    .invalid {
      border: 1px solid var(--validation-error-color) !important;
      color: var(--secondary-font-color);
    }
    .warning-border {
      border: 1px solid var(--warning-color) !important;
    }
    .red-warning-border {
      border: 1px solid var(--validation-error-color) !important;
    }

    input[disabled] {
      cursor: not-allowed;
    }
  }
  @import './checkbox';
  @import './select';
  @import './textarea';
  @import './multiselect';
}
.field-wrapper.inline {
  align-items: center;
  display: flex;
  margin-bottom: 24px;
  .field-wrapper {
    &__label {
      margin-bottom: 0;
      margin-right: auto;
    }
    .input-wrapper {
      padding: 0;
    }
  }
}

//labels for toggle
.field-wrapper {
  .labels {
    margin-right: auto;
  }
  &.multiple-labels-toggle {
    align-items: flex-start;
    display: flex;
    .labels {
      display: flex;
      flex-direction: column;
      margin-right: 0;
      padding-top: 7px;
      width: 100%;
      label {
        text-transform: unset;
        width: 90%;
        &:nth-child(1) {
          color: var(--primary-font-color);
          font-size: 14px;
          padding-bottom: 20px;
        }
      }
    }
  }
  &.include-values {
    .input-wrapper {
      align-items: center;
      display: flex;
      position: relative;
      .value {
        position: absolute;
        &.left {
          left: -13px;
        }
        &.right {
          right: -13px;
        }
      }
    }
  }
  .signature-input {
    position: relative;
    .icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .time-picker-wrapper {
    align-items: center;
    display: flex;
    margin-top: 4px;
    .time-picker-hours {
      margin-right: 10px;
      position: relative;
      width: 50%;
    }
    .time-picker-minutes {
      margin-left: 10px;
      position: relative;
      width: 50%;
    }
    .fake-placeholder {
      position: absolute;
      right: 14px;
      top: 16px;
    }
    input::placeholder {
      text-align: right;
      text-transform: none;
    }
  }
}

// styles for combined toggle button which unlocks the radio buttons (advanced filters HAS FILTER -> YES/NO)
.toggle-radio-input-field-wrapper {
  .input-wrapper {
    align-items: center;
    display: flex;
    width: 100%;
    .radio-buttons-container {
      align-items: flex-start;
      display: flex;
      .grid {
        column-gap: 0;
      }
    }
    .radio-buttons-container.highlight-labels {
      .radio-label {
        color: var(--primary-font-color);
        &::before {
          border: 1px solid #b3b3b3;
        }
      }
    }
    .labels {
      padding: 0;
      .field-wrapper__label {
        padding-bottom: 0 !important;
        padding-left: 8px;
      }
    }
    .single-radio {
      border: 0;
      margin: 0;
      padding: 0;
    }
  }
}
.toggle-radio-input-field-wrapper.field-wrapper--disabled {
  cursor: not-allowed;
  .input-wrapper * {
    cursor: not-allowed !important;
    pointer-events: none;
  }
}
.input-icon {
  left: 10px;
  pointer-events: none;
  position: absolute;
  top: 18px;
}
.password-input-icon {
  right: 10px;
  position: absolute;
  cursor: pointer;
  &.lg {
    top: 22px;
  }
  &.sm {
    top: 12px;
  }
}
