.graphing-groups-list {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .table-template {
    height: calc(100% - 50px);
    .table-template-container {
      height: 100vh;
      .twp {
        min-width: 100%;
      }
      .twp-header {
        &.sticky {
          justify-content: flex-start;
        }
      }
      .name-column {
        max-width: 40% !important;
        min-width: 40% !important;
      }
      .type-column {
        max-width: 20% !important;
        min-width: 20% !important;
      }
      .measurement-points-column {
        // using 22px here, since it is split on 2 columns instead of 1
        max-width: calc(25% - 22px) !important;
        min-width: calc(25% - 22px) !important;
      }
      .is-default-column {
        max-width: 10% !important;
        min-width: 10% !important;
      }
      .action-column {
        max-width: 44px !important;
        min-width: 44px !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  &__empty-state {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: auto;
  }
  &__measurement-points {
    &-popover {
      p {
        margin-right: 4px;
      }
      display: flex;
      align-items: center;
    }
  }
  &__toggle {
    display: flex;
    align-items: center;
  }
}

.graph-action__delete {
  .modal-wrapper {
    padding: 20px 24px;
    p {
      margin-bottom: 24px;
      margin-top: 16px;
    }
  }
}
