.contractor-management {
  .icon-plus-rounded {
    margin-right: 10px;
  }
  .tabs {
    padding: 24px 20px;
    &__navigation {
      border-bottom: 1px solid var(--box-items-separator-color);
    }
    &__content {
      height: calc(100vh - 310px);
      margin-top: 20px;
      overflow: auto;
    }
  }
}
