.isolation-components-modal {
  .component-title-wrapper {
    padding-bottom: 12px;
    padding-top: 12px;
    border-bottom: 1px solid var(--box-items-separator-color);
    .component-item {
      padding-bottom: 0 !important;
      padding-left: 5px !important;
      .component-name-container {
        margin: 0 !important;
      }
      .info-tooltip {
        margin-left: 5px;
      }
    }
  }
}
.isolations-modal-footer-with-border {
  border-top: 0;
}
