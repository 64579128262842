.split-screen {
  display: grid;
  height: 100%;
  position: relative;
  width: 100%;
  > div {
    height: 100%;
  }
  .resizable-box {
    height: 100% !important;
    overflow: auto;
    position: relative;
    width: 100% !important;
    > div {
      height: 100% !important;
      width: 100% !important;
    }
  }
  .pdf-with-drawing {
    height: 100%;
    overflow: hidden;
  }
  &.w {
    .resizable-box {
      padding-left: 10px;
    }
    .single-resizer {
      bottom: 0;
      left: 7px !important;
      right: 0;
      top: 0;
      z-index: unset;
    }
  }
  &.n {
    .resizable-box {
      padding-top: 10px;
    }
    .single-resizer {
      bottom: 0;
      left: 50% !important;
      right: 0;
      top: 7px !important;
      transform: translateX(-50%) !important;
      z-index: unset;
    }
  }
}
