.add-new-prop-button {
  align-items: center;
  background-color: inherit;
  border: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: flex-start;
  &__text {
    color: var(--secondary-theme-color);
  }
}
