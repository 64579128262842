.component-assets {
  .label-container {
    align-items: center;
    display: flex;
    padding-bottom: 12px;
    &__label {
      margin-right: auto;
    }
    &__upload {
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
      &.disabled {
        cursor: not-allowed;
        opacity: 0.7;
      }
    }
    .mr-space {
      margin-right: 5px;
    }
  }
  .empty-container {
    margin-top: 10px;
  }
  .file-container {
    align-items: center;
    display: flex;
    margin: 22px 0;
    position: relative;
    &:first-child {
      margin-top: 0;
    }
    .image-container {
      align-items: center;
      display: flex;
      height: 30px;
      justify-content: center;
      margin-right: 7px;
      width: 30px;
      &__file-image {
        max-height: 100%;
        max-width: 100%;
      }
    }
    .image-container-clickable {
      cursor: pointer;
    }
    .filename {
      flex: 1;
      margin-right: 7px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  // override image list
  .image-list {
    margin-top: 16px;
    .image-with-drawing {
      &:hover {
        opacity: 0.7;
      }
    }
    .small-dropdown__settings .dropdown {
      min-width: 90px;
    }
  }
  .small-dropdown {
    &__settings {
      position: absolute;
      right: 4px;
      top: 4px;
      width: 10px;
    }
    &__settings-icon {
      background: var(--notification-close-icon);
      height: 4px;
      width: 4px;
      &::before,
      &::after {
        background: var(--notification-close-icon);
        height: 4px;
        width: 4px;
      }
    }
  }
  .dot {
    border-radius: 50%;
    height: 8px;
    left: -10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    &.green {
      background: var(--severity-green);
    }
  }
  .thumbnail-view {
    display: grid;
    grid-column-gap: 13px;
    grid-row-gap: 10px;
    grid-template-columns: 1fr 1fr;
    .file-container {
      .image-container {
        height: 100px;
        margin: 0;
        width: 100%;
      }
      .filename {
        display: none;
      }
      .small-dropdown__settings {
        z-index: 1;
      }
    }
  }
}
.icon-path-stroke {
  path {
    stroke: var(--notification-close-icon);
  }
}
