.work-order-section-permits {
  overflow-x: auto;
  width: 100%;
  .permit-item {
    align-items: center;
    display: flex;
    padding: 12px 2px;
    &.clickable {
      border: 1px solid transparent;
      cursor: pointer;
      &:hover {
        background-color: rgba(var(--secondary-theme-color-rgb), 0.1);
        border: 1px solid var(--secondary-theme-color);
        border-radius: 4px;
      }
    }
    .permit-item-name-container {
      display: grid;
      grid-template-columns: 40px 1fr;
      .permit-name {
        align-self: flex-start;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 150px;
      }
    }
    .permit-item-status-container {
      margin-left: auto;
      padding-left: 10px;
      width: 100%;
    }
  }
}
