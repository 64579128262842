.post-video-relocalisation {
  height: calc(100% - 103px);
  transition: all 0.7s ease;
  width: 100%;

  .page-navigation {
    background-color: var(--background-color);
  }

  &__wrapper {
    padding: 0 20px;
    height: 100%;
    &__navigation {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-top: 20px;
      padding-bottom: 32px;

      .back-button {
        width: fit-content;
      }

      .title {
        font-weight: 500;
      }

      .description {
        max-width: 422px;
        color: var(--ch-text);
      }
    }
    &__content {
      &-title {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      .actions {
        display: flex;
        gap: 18px;
        height: 100%;
        margin-top: 24px;
        height: 52px;
        .btn {
          min-width: 316px;
          border: 1px solid var(--comment-input-border);
          svg {
            height: 20px;
            width: 20px;
            path {
              stroke: var(--secondary-theme-color);
            }
          }
          .button-text {
            font-weight: 500;
          }
        }
        .dropzone-wrapper {
          align-items: center;
          background-color: transparent;
          border: 1px solid var(--comment-input-border);
          border-radius: 4px;
          color: var(--secondary-theme-color);
          display: flex;
          height: 52px;
          justify-content: center;
          min-width: 316px;
          .dropzone {
            height: 100%;
            width: 100%;
          }
          .drop-field {
            p {
              color: var(--secondary-theme-color);
              font-weight: 500;
            }
            svg {
              height: 20px;
              margin-left: 8px;
              width: 20px;
              path {
                stroke-width: 2;
                stroke: var(--secondary-theme-color);
              }
            }
          }
        }
      }
      .table-title {
        padding-top: 32px;
        padding-bottom: 24px;
        font-weight: 500;
      }
      .table-template {
        .post-video-relocalisation-table {
          .twp {
            .twp-header {
              border-bottom: none;
              height: unset;
            }
          }
          .table-template-no-data-text {
            .empty-state-container {
              padding-top: 70px;
              .empty-state-container__content {
                p {
                  text-wrap: nowrap;
                }
              }
            }
          }
          .name-column {
            max-width: calc(100% - 80px - 180px - 60px) !important;
            min-width: calc(100% - 80px - 180px - 60px) !important;

            .flex-center {
              gap: 8px;
              padding-left: 8px;
            }
          }

          .created-at-and-time-column {
            max-width: 180px !important;
            min-width: 180px !important;
          }
          .duration-column {
            max-width: 80px !important;
            min-width: 80px !important;
          }
          .actions-column {
            max-width: 60px !important;
            min-width: 60px !important;
            padding-left: 16px;
          }
        }
      }
    }
  }
}
