.single-item-table {
  height: calc(100vh - 135px);
  &__table-heading {
    padding-top: 14px;
  }
  .table-template {
    .table-template-search {
      .search-wrapper {
        .search {
          padding-left: 0;
        }
      }
    }
  }
  .circle {
    border-radius: 50%;
    height: 12px;
    min-height: 12px;
    min-width: 12px;
    width: 12px;
  }
  .measurement-name {
    align-items: center;
    display: flex;
    gap: 8px;
  }
  .twp {
    min-width: 0;
  }
  &.group {
    .table-full-screen {
      .name-column {
        max-width: 60% !important;
        min-width: 60% !important;
        svg {
          margin-right: 4px;
        }
      }
      .created-at-column,
      .created-by-column {
        max-width: 20% !important;
        min-width: 20% !important;
      }
    }
    .table-three-d {
      .name-column {
        max-width: calc(100% - 32px) !important;
        min-width: calc(100% - 32px) !important;
        svg {
          margin-right: 4px;
        }
      }
    }
    .toggle-column {
      align-items: center;
      justify-content: center;
      max-width: 32px !important;
      min-width: 32px !important;
    }
    .additional-options {
      p {
        align-items: flex-start;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 8px 12px;
        transition: background-color 0.3s ease;
        width: 100%;
        &:hover {
          background-color: var(--ch-table-even-row);
        }
      }
    }
    .table-template {
      height: calc(100% - 87px);
    }
  }
  &.location {
    .table-template {
      height: calc(100% - 53px);
    }
    .table {
      &-three-d {
        .id-column {
          align-items: center;
          justify-content: flex-start;
          max-width: 60px !important;
          min-width: 60px !important;
        }
        .name-column {
          align-items: center;
          justify-content: flex-start;
          max-width: calc(100% - 60px - 32px - 30px) !important;
          min-width: calc(100% - 60px - 32px - 30px) !important;
        }
        .toggle-column {
          align-items: center;
          justify-content: flex-start;
          max-width: 32px !important;
          min-width: 32px !important;
        }
        .alarm-column {
          max-width: 30px !important;
          min-width: 30px !important;
          .info-popover {
            min-height: 0;
            min-width: 0;
          }
        }
      }
      &-full-screen {
        overflow-x: scroll;
        .id-column {
          align-items: center;
          justify-content: flex-start;
          max-width: 80px !important;
          min-width: 80px !important;
        }
        .name-column {
          align-items: center;
          justify-content: flex-start;
          max-width: calc(60% - 80px - 30px) !important;
          min-width: calc(60% - 80px - 30px) !important;
        }
        .created-at-column,
        .created-by-column {
          max-width: 20% !important;
          min-width: 20% !important;
        }
        .alarm-column {
          max-width: 30px !important;
          min-width: 30px !important;
          .info-popover {
            min-height: 0;
            min-width: 0;
          }
        }
      }
    }
  }
  &.points {
    .table-template {
      height: calc(100% - 53px);
    }
    .table {
      &-three-d {
        .id-column {
          align-items: center;
          justify-content: flex-start;
          max-width: 60px !important;
          min-width: 60px !important;
        }
        .name-column {
          align-items: center;
          justify-content: flex-start;
          max-width: calc(100% - 60px - 32px - 30px - 30px) !important;
          min-width: calc(100% - 60px - 32px - 30px - 30px) !important;
        }
        .toggle-column {
          align-items: center;
          justify-content: flex-start;
          max-width: 32px !important;
          min-width: 32px !important;
        }
        .add-reading-column {
          max-width: 30px !important;
          min-width: 30px !important;
          .circle {
            align-items: center;
            background-color: var(--secondary-theme-color);
            border-radius: 50%;
            display: flex;
            height: 20px;
            justify-content: center;
            width: 20px;
            &.disabled {
              cursor: not-allowed;
              opacity: 0.6;
            }
            svg {
              height: 16px;
              width: 16px;
              path {
                stroke: #fff;
              }
            }
          }
        }
        .alarm-column {
          max-width: 30px !important;
          min-width: 30px !important;
          .info-popover {
            min-height: 0;
            min-width: 0;
          }
        }
      }
      &-full-screen {
        overflow-x: scroll;
        .id-column {
          align-items: center;
          justify-content: flex-start;
          max-width: 80px !important;
          min-width: 80px !important;
        }
        .name-column {
          align-items: center;
          justify-content: flex-start;
          max-width: calc(60% - 80px - 30px - 30px) !important;
          min-width: calc(60% - 80px - 30px - 30px) !important;
        }
        .created-at-column,
        .created-by-column {
          max-width: 20% !important;
          min-width: 20% !important;
        }
        .add-reading-column {
          max-width: 30px !important;
          min-width: 30px !important;
          .circle {
            align-items: center;
            background-color: var(--secondary-theme-color);
            border-radius: 50%;
            display: flex;
            height: 20px;
            justify-content: center;
            width: 20px;
            &.disabled {
              cursor: not-allowed;
              opacity: 0.6;
            }
            svg {
              height: 16px;
              width: 16px;
              path {
                stroke: #fff;
              }
            }
          }
        }
        .alarm-column {
          max-width: 30px !important;
          min-width: 30px !important;
          .info-popover {
            min-height: 0;
            min-width: 0;
          }
        }
      }
    }
  }
}
.hover-able-background {
  background-color: var(--ch-table-even-row);
  &:hover {
    background-color: var(--ch-table-row-hover) !important;
    transition: background-color 0.3s ease;
  }
}
.flex-center {
  align-items: center;
  display: flex;
  gap: 4px;
}
.table-template-groups-fullscreen {
  .table-template {
    height: calc(100% - 130px) !important;
  }
}
