@import '../../../common/styles/variables';
.toolbar-resizable {
  &.resizable-box {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 90;
    .single-resizer {
      position: static !important;
    }
    &.contents {
      .single-resizer {
        display: contents;
        .react-resizable-handle {
          display: none;
        }
      }
    }
  }
}
.toolbar-container {
  background-color: var(--background-color);
  position: absolute;
  transition: all 0.7s ease;
  z-index: 90;
  .toolbar {
    height: 100%;
    // default configuration
    position: relative;
    width: 100%;
    &__collapse-btn {
      border-radius: 5px;
      cursor: pointer;
      position: absolute;
      transition: all 0.3s ease;
      z-index: 1;
      svg {
        rect {
          fill: var(--background-color);
        }
      }
    }
  }

  // PLACEMENT TYPES
  // LEFT TOOLBAR
  &__left {
    height: 100%;
    top: 0;
    width: $inspection-left-toolbar;
    //  z-index: 91;
    .toolbar {
      &__collapse-btn {
        padding: 13px 7px;
        right: 0;
        top: 90px;
        transform: translate(50%);
        transition: all 0.3s ease;
        transition-delay: 0.75s;
        .reverse {
          transform: rotate(180deg);
        }
      }
    }
    // COLLAPSED
    &--visible {
      left: 0;
      .toolbar__collapse-btn {
        transition-delay: 0s;
      }
    }
    &--hidden {
      left: -$inspection-left-toolbar;
      .toolbar__collapse-btn {
        padding: 13px 7px 13px 30px;
      }
    }
  }

  // TOP TOOLBAR
  &__top {
    height: $inspection-top-toolbar;
    width: 100%;
    .toolbar {
      &__collapse-btn {
        bottom: 0;
        left: 50%;
        padding: 3px 17px;
        transform: translate(-50%, 50%);
        transition: all 0.3s ease;
        transition-delay: 0.75s;
        svg {
          transform: rotate(90deg);
        }
        .reverse {
          transform: rotate(-90deg);
        }
      }
    }
    // COLLAPSED
    &--visible {
      top: 0;
      .toolbar__collapse-btn {
        transition-delay: 0s;
      }
    }
    &--hidden {
      top: -$inspection-top-toolbar;
      .toolbar__collapse-btn {
        padding: 30px 17px 3px;
      }
    }
  }

  // BOTTOM TOOLBAR
  &__bottom {
    width: 100%;
    .toolbar {
      &__collapse-btn {
        left: 50%;
        padding: 3px 17px;
        top: 0;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease;
        transition-delay: 0.75s;
        svg {
          transform: rotate(-90deg);
          transition: all 0.3s ease;
        }
        .reverse {
          transform: rotate(90deg);
        }
      }
    }
    // COLLAPSED
    &--visible {
      bottom: 0;
      .toolbar__collapse-btn {
        transition-delay: 0s;
      }
    }
    &--hidden {
      bottom: -$inspection-top-toolbar;
      .toolbar__collapse-btn {
        padding: 3px 17px 20px;
      }
    }
  }
  // RIGHT TOOLBAR
  &__right {
    height: 100%;
    top: 0;
    width: $inspection-right-toolbar;
    &.inherit-size {
      width: inherit;
    }
    .toolbar {
      &__collapse-btn {
        left: 0;
        padding: 13px 7px;
        top: 0;
        transform: translate(-50%);
        transition: all 0.3s ease;
        transition-delay: 0.75s;
        svg {
          transform: rotate(180deg);
        }
        .reverse {
          transform: rotate(0deg);
        }
      }
    }
    // COLLAPSED
    &--visible {
      right: 0;
      .toolbar__collapse-btn {
        transition-delay: 0s;
      }
    }
    &--hidden {
      right: -$inspection-right-toolbar;
      .toolbar__collapse-btn {
        padding: 13px 30px 13px 7px;
      }
    }
    & + span.react-resizable-handle {
      cursor: col-resize;
      height: 100%;
      position: absolute;
      top: 150px;
      width: 20px;
      z-index: 90;
    }
  }
}
