.dms-right-side {
  padding: 20px 12px 20px 20px;
  .details-form {
    border-left: 0;
    display: flex;
    flex-flow: column;
    padding: 0;
    padding-right: 8px;
    .btn {
      margin-top: auto;
      min-height: 50px;
    }
    &__info-row {
      display: flex;
      gap: 20px;
      width: 100%;
      &__cell {
        &__system-version {
          width: calc(40% - 10px);
        }
        &__version-number {
          width: calc(60% - 10px);
        }
      }
    }
    &__separator-line {
      border: 1px solid var(--dms-linked-components-bg);
    }
  }
  .linked-components {
    .section-content--visible {
      padding: 0 !important;
      position: relative;
      z-index: 0;
    }
  }
}
