.stepper {
  border: 1px solid var(--separator-color);
  border-radius: 8px;
  padding: 16px;
  &__steps {
    column-gap: 2px;
    display: grid;
    justify-content: center;
    .step {
      background: linear-gradient(to right, var(--secondary-theme-color) 50%, var(--separator-color) 50%);
      background-position: right;
      background-size: calc(200% + 2px) 100%;
      border-radius: 4px;
      height: 4px;
      transition: all 0.5s ease-out;
      &.active {
        background-position: left;
      }
    }
  }
  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    label {
      align-items: center;
      display: flex;
    }
    svg.icon-info {
      margin-right: 6px;
    }
    &--description {
      color: var(--ui-color-secondary);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-left: 4px;
      text-transform: lowercase;
    }
  }
}
