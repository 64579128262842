.time-period-field {
  .modal__title {
    width: 100% !important;
  }
  &__date-picker-modal {
    &__content {
      width: 438px;
    }
  }
  .modal-wrapper {
    padding: 20px 24px;
  }
}
