
.slider {
  position: relative;
  .image-gallery {
    height: 100%;
    width: 100%;
    &-content {
      height: 100%;
      width: 100%;
      // Arrows style
      @import './arrows';
      .image-gallery-fullscreen-button {
        bottom: unset;
        right: 3px;
        top: 0;
      }
      &.fullscreen {
        .image-gallery-fullscreen-button {
          opacity: 0.3;
          position: fixed;
          right: 13px;
          top: 13px;
          -webkit-transition: opacity 0.3s;
          transition: opacity 0.3s;
          z-index: 1;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    &-slide-wrapper {
      height: 100%;
      width: 100%;
    }
    &-swipe {
      height: 100%;
      width: 100%;
    }
    &-slides {
      height: 100%;
      width: 100%;
    }
    &-slide {
      background: black;
      height: 100%;
      width: 100%;
    }
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
      width: 20px;
    }
    &.no-images {
      .image-gallery-slides {
        background-color: var(--background-color);
        width: 100%;
      }
    }
  }
}
