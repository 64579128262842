.comments-container {
  .display-comment-item {
    padding: 20px 0;
    .title-wrapper {
      align-items: center;
      display: flex;
      margin-bottom: 8px;
      .create-date {
        margin-left: auto;
      }
    }
  }
  .add-comment-wrapper {
    margin-top: 12px;
    position: relative;
    .textarea-wrapper {
      background-color: var(--input-background-color);
      border: 1px solid var(--input-border-color);
      border-radius: 4px;
      padding-bottom: 35px;
      textarea {
        border: 0;
        height: 110px;
      }
      .additional-data {
        bottom: -85px;
        position: absolute;
        right: 0;
      }
    }
    .add-comment-button {
      button {
        bottom: 5px;
        min-width: auto;
        padding-left: 10px;
        padding-right: 10px;
        position: absolute;
        right: 5px;
      }
    }
  }
}
