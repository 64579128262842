.notification-details {
  height: 100%;
  &__tabs {
    height: 100%;
    .tabs__content {
      height: 100%;
    }
  }
  &__container {
    padding: 0 19px 0 27px;
  }
  &__title {
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 600;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 14px;
    padding: 35px 19px 0 27px;
    text-transform: uppercase;
  }
  &__status {
    margin: 10px 0 20px auto;
    min-width: unset !important;
    width: auto;
    label {
      color: var(--default-font-color);
      text-transform: uppercase;
    }
  }
  .header-container {
    padding: 10px 20px;
  }
  .info-popover {
    width: 240px;
  }
  .property {
    border-bottom: 1px solid var(--box-items-separator-color);
    margin-bottom: 20px;
    &__label {
      margin-bottom: 8px;
    }
    &__value {
      margin-bottom: 5px;
      overflow-wrap: break-word;
      &.current-value {
        align-items: center;
        color: var(--primary-font-color);
        display: flex;
        justify-content: center;
        .value {
          font-size: 46px;
          &.critical {
            color: var(--severity-red);
          }
        }
        .temp {
          font-size: 34px;
        }
      }
      .current-value {
        &__container {
          flex: 1;
          text-align: center;
        }
        &__history-icon {
          cursor: pointer;
          margin-right: 10px;
          transition: all 0.3s ease;
          &:hover {
            g {
              fill: var(--secondary-font-color-dark);
            }
            path {
              fill: var(--secondary-font-color-dark);
            }
            circle {
              fill: var(--secondary-font-color-dark);
            }
          }
        }
      }
    }
  }
  .section-content {
    &--visible {
      max-height: none;
      padding: 0;
      .location-section {
        padding: 24px;
      }
    }
  }
  &__files-section {
    .section-content {
      border-bottom: 0;
    }

    .section-content.section-content--visible {
      padding: 0 19px 0 24px;
      p.filename {
        margin-left: 0;
      }
    }
  }
  .upload-image {
    margin: 0 !important;
  }
  .location-section {
    padding: 0 24px 4px !important;
  }
  .header-container {
    margin: 0 !important;
    padding: 10px 20px 4px;
  }
  .critical-tooltip {
    .info-popover {
      min-height: 0;
      width: 158px;
    }
  }
}
