@import '../../../../../common/styles/variables';
.inspection-input {
  //disable scroll on input type number
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  &.clickable {
    input[disabled],
    input {
      cursor: pointer;
      pointer-events: all;
    }
  }
  &.email-input {
    .value-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
      min-height: inherit;
    }
    .add-icon {
      cursor: pointer;
      margin: 0 10px 0 auto;
    }
    .value {
      display: inline-block;
      overflow: hidden !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 64%;
    }
  }
  &.hidden-field {
    display: none;
    visibility: hidden;
  }
  .label-container {
    display: flex;
    justify-content: space-between;
    &__icon {
      cursor: pointer;
      opacity: 0;
      transition: all 0.3s ease;
      &--active {
        opacity: 1;
      }
      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
  &__label {
    font-size: 12px;
    font-weight: normal;
    // margin-bottom: 16px;
    &.enhanced-design-custom-prop {
      border: 0;
      color: var(--primary-font-color);
      font-family: $primary-font-family;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 4px;
      outline: none;
    }
    &.sublabel {
      display: inline-block;
      font-weight: lighter;
      margin: 10px 0 20px;
      width: 100%;
    }
    &--input {
      .enhanced-design-custom-prop {
        border: 0 !important;
        color: var(--primary-font-color) !important;
        font-family: $primary-font-family !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        margin-bottom: 4px !important;
        outline: none !important;
      }
      .input {
        background-color: transparent;
        border: 0;
        color: var(--secondary-font-color-dark);
        font-family: $primary-font-family;
        font-size: 12px;
        font-weight: normal;
        outline: none;
        width: 100%;
      }
      .multiselect {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        max-height: 300px;
        overflow: auto;
        &__tag {
          align-items: center;
          background-color: var(--box-items-theme-color);
          border-radius: 14px;
          cursor: pointer;
          display: flex;
          margin: 4px 7px 4px 0;
          padding: 2px 14px;
          transition: all 0.2s ease;
          .tag-text {
            transition: all 0.2s ease;
          }
          .tag-remove {
            fill: rgba(var(--secondary-theme-color-rgb), 0.8);
            height: 8px;
            margin-left: 6px;
            transition: all 0.2s ease;
            width: 8px;
          }
          &:hover {
            .tag-text {
              color: var(--secondary-font-color-dark);
            }
            .tag-remove {
              fill: var(--validation-error-color);
              margin-left: 9px;
            }
          }
        }
        input {
          flex: 1;
          min-width: 100px;
        }
      }
    }
  }
  .border {
    border-bottom: 1px solid var(--box-items-separator-color);
    transition: border 0.2s ease;
  }
  .no-border {
    border-bottom: 0;
  }
  .invalid {
    &--error {
      border-bottom: 1px solid var(--validation-error-color);
    }
    &--warning {
      border-bottom: 1px solid var(--warning-color);
    }
  }
  &__input-wrapper {
    &.enhanced-design-input {
      &.search-select {
        padding-top: 4px;
      }
    }
    .hidden {
      display: none;
    }
    .input {
      background-color: transparent;
      border: 0;
      font-family: $primary-font-family;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      outline: none;
      padding: 8px 0;
      width: 100%;
      &.enhanced-design-input {
        background-color: var(--input-background-color);
        border: 1px solid var(--input-border-color);
        border-radius: 4px;
        color: var(--primary-font-color);
        font-family: 'DM Sans', sans-serif;
        font-size: 14px;
        font-weight: normal;
        min-height: 52px;
        outline: none;
        padding: 9px 14px;
        padding-bottom: 9px;
        transition: 0.2s all;
        width: 100%;
        &.prop {
          padding: 16px 14px;
        }
      }
      &.has-icon {
        padding-right: 32px;
      }
      &-icon {
        left: auto !important;
        pointer-events: none;
        position: absolute;
        right: 12px !important;
        top: 18px !important;
      }
    }
    input[disabled] {
      cursor: not-allowed;
    }
    .multiselect {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      max-height: 300px;
      overflow: auto;
      background-color: var(--input-background-color);
      border: 1px solid var(--input-border-color);
      border-radius: 4px;
      color: var(--primary-font-color-dark);
      font-family: 'DM Sans', sans-serif;
      font-size: 14px;
      font-weight: normal;
      min-height: 52px;
      outline: none;
      padding: 9px 14px;
      padding-bottom: 9px;
      transition: 0.2s all;
      width: 100%;
      &__tag {
        align-items: center;
        background-color: var(--ui-background);
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        margin: 4px 7px 4px 0;
        padding: 8px 14px;
        transition: all 0.2s ease;
        .tag-text {
          transition: all 0.2s ease;
          color: var(--secondary-font-color-dark);
        }
        .tag-remove {
          fill: rgba(var(--default-font-color), 0.8);
          height: 8px;
          margin-left: 6px;
          transition: all 0.2s ease;
          width: 8px;
        }
        &:hover {
          .tag-text {
            color: var(--primary-font-color);
          }
          .tag-remove {
            fill: var(--validation-error-color);
          }
        }
      }
      &__disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.5;
      }
      input {
        flex: 1;
        min-width: 100px;
      }
    }
  }
  .input-wrapper {
    textarea {
      background: transparent;
      border: 0;
      border-bottom: 1px solid var(--box-items-separator-color);
      color: var(--primary-font-color);
      font-family: $primary-font-family;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      outline: none;
      padding: 5px;
      width: 100%;
    }
  }
  .error-message {
    min-height: 25px;
    padding: 0;
    transition: min-height 0.2s ease;
    visibility: hidden;
    &--visible {
      color: var(--validation-error-color);
      font-size: 12px;
      min-height: 25px;
      padding: 5px 0;
      transition: min-height 0.2s ease;
      visibility: visible;
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
  // below styles are applied for all input-ranges as requested by new & improved design initiative
  &__label {
    color: var(--primary-font-color);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  .input-range {
    margin-left: 5px;
    width: 95.5%;
    &__label-container {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
    &__label--min {
      transform: translateY(4px);
    }
    &__label--max {
      transform: translate(50%, 4px);
    }
    &__label--max .input-range__label-container {
      left: 50%;
    }
    &__track {
      height: 8px;
    }
    &__label--value {
      transform: translate(0, 190%);
      z-index: 3;
    }
    &__slider {
      height: 24px;
      transform: translateY(-5px);
      width: 24px;
    }
  }
}
.field-message {
  min-height: 25px;
  padding: 0;
  transition: min-height 0.2s ease;
  &__error {
    color: var(--validation-error-color);
    display: none;
    font-size: 12px;
    padding: 5px 0;
    &.show {
      display: block;
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
  &__warning {
    color: var(--warning-color);
    display: none;
    font-size: 12px;
    padding: 5px 0;
    &.show {
      display: block;
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
}
