@import '../../../common/styles/dimensions/dimensions';
.title-container {
  background-color: var(--primary-theme-light-color);
  position: sticky;
  top: 0;
  z-index: 1;
  &.no-text {
    background-color: unset;
    box-shadow: unset;
    z-index: 91;
  }
  &__title-wrapper {
    align-items: center;
    display: flex;
    padding: 20px 0;
    :first-child {
      margin-right: 5px;
    }
  }
  &.reduced-header {
    width: $inspection-left-toolbar;
  }
}
