.expandable-section {
  .header {
    cursor: pointer;
    transition: all 0.2s ease;
    &__wrapper {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 16px 23px;

      &__title {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
    &__title {
      transition: all 0.2s ease;
    }
    &__icon {
      height: 12px;
      position: relative;
      transition: all 0.2s ease;
      width: 12px;
      &::after,
      &::before {
        background-color: var(--secondary-font-color-dark);
        content: '';
        height: 2px;
        position: absolute;
        transform-origin: center center;
        transition: all 0.3s ease;
        width: 100%;
      }
      &::before {
        left: 0;
        right: 0;
        top: 50%;
      }
      &::after {
        top: 50%;
      }
      &.collapse {
        &::after {
          transform: rotate(0);
        }
      }
      &.expand {
        &::after {
          transform: rotate(90deg);
        }
      }
    }
    .arrow {
      display: inline-block;
      height: 20px;
      position: relative;
      width: 20px;
      span {
        background-color: var(--secondary-font-color-dark);
        display: inline-block;
        height: 2px;
        position: absolute;
        top: 7.5px;
        transition: all 0.2s ease;
        width: 12px;
      }
      :first-of-type {
        left: 0;
        transform: rotate(45deg);
      }
      :last-of-type {
        right: 0;
        transform: rotate(-45deg);
      }
      &.collapse {
        :first-of-type {
          transform: rotate(-45deg);
        }
        :last-of-type {
          transform: rotate(45deg);
        }
      }
    }
    &:hover {
      box-shadow: none;
      .header__title {
        color: var(--primary-font-color);
      }
      .header__icon {
        &::before,
        &::after {
          background-color: var(--primary-font-color);
        }
      }
      .arrow span {
        background-color: var(--primary-font-color);
      }
    }
    &__actions {
      align-items: center;
      display: flex;
      gap: 8px;
      justify-content: flex-end;
    }
  }
  .section-content {
    border-bottom: 1px solid var(--box-items-separator-color);
    overflow-x: hidden;
    overflow-y: auto;
    transition-duration: 0.5s, 0.3s;
    transition-property: max-height, padding;
    &--visible {
      max-height: 400px;
      padding: 24px 19px 24px 27px;
    }
    &--hidden {
      max-height: 0;
      overflow: hidden;
      padding: 0 19px 0 27px;
      transition-duration: 0.5s, 0.3s;
      transition-property: max-height, padding;
    }
  }
}
