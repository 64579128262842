.new-observation-modal {
  .modal-wrapper {
    padding-left: 24px;
    padding-right: 24px;
    &__title {
      padding-left: 4px;
    }
  }
  .modal {
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera */
    }
  }
}
.new-observation-form-wrapper {
  max-width: 45vw;
  padding-left: 4px;
  width: 100%;
  &__label {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    svg {
      align-self: flex-start;
      display: flex;
      margin-right: 4px;
    }
  }
}
