.component-picker-pdf {
  display: flex;
  height: calc(100vh - 250px);
  width: calc(100vw - 250px);
  .component-list {
    height: 30%;
  }
  .choose-components {
    height: 70%;
  }
  .pdf-with-drawing {
    flex: 1 0;
    height: inherit;
    overflow: auto;
    position: relative;
  }
  .sidebar {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 375px;
  }
  .top-toolbar {
    left: 0;
    position: absolute;
    top: 0;
    .pdf-select {
      align-items: center;
      display: flex;
      justify-content: left;
      padding: 10px;
      .input-wrapper {
        margin-left: 10px;
        padding-top: 0;
        .select-container {
          width: 300px;
        }
      }
      .error-message {
        display: none;
      }
    }
  }
}
