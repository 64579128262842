.keybox-wrapper {
  .tabs__content {
    max-height: 94vh;
    overflow: auto;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .space-top {
    margin-top: 20px;
  }
  .keybox-details {
    padding: 0 20px;
    .left {
      align-items: center;
      display: flex;
    }
    .empty-state {
      height: auto;
    }
    .section-content--visible {
      max-height: 200vh;
    }
  }
  .history {
    .empty-state-container {
      margin: 100px 0;
      text-align: center;
    }
  }
  .keybox-item {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .details {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      width: 100%;
      .single-detail-duo {
        align-items: center;
        display: flex;
        .info-tooltip {
          height: 20px;
          margin-left: 6px;
        }
      }
    }
  }
  .add-iso {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-top: 10px;
    svg {
      margin-right: 10px;
    }
  }
  .key-permit-popover {
    max-width: 200px;
    .single-detail-item {
      margin-top: 12px;
      .single-detail-item-title {
        margin-bottom: 4px;
      }
      .single-detail-item-duo {
        align-items: center;
        display: flex;
        .permit-template-color {
          border-radius: 50%;
          height: 16px;
          margin-right: 4px;
          width: 16px;
        }
      }
    }
  }
}
