@import '../../../common/styles/variables';
@import '../../../common/styles/mixins';
@import './date-range-picker-base';

.year-range-picker {
  @extend %range-picker-container;

  &__header {
    @extend %range-picker-header;
  }

  &__nav-btn {
    @extend %range-picker-nav-btn;
  }

  &__grid {
    @extend %range-picker-grid;
    grid-template-columns: repeat(4, 1fr);
  }

  &__year-btn {
    @extend %range-picker-item-btn;
    height: 76px;
    width: 66px;
  }
}
