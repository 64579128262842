.main-container {
  display: flex;
  flex-direction: row;
  &__content {
    display: block;
    width: 100%;
    .footer-text {
      bottom: 5px;
      font-size: 12px;
      position: absolute;
      right: 20px;
    }
    .footer-logo {
      width: 14px;
    }
  }
}
