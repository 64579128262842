.component-picker-ch {
  height: 80vh;
  width: 80vw;
  &__container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
    &__left-side {
      display: flex;
      flex-direction: column;
      height: calc(100% - 54px);
      width: 70%;
      &__path {
        &.hide {
          display: none;
        }
        &.show {
          display: block;
          height: 10%;
        }
      }
      &__table {
        align-items: flex-start;
        display: flex;
        height: calc(100% - 54px);
        justify-content: flex-start;
        width: 100%;
        &.path-opened {
          height: calc(90% - 54px);
        }
      }
    }
    &__component-info {
      border-left: 1px var(--dropdown-border) solid;
      height: 100%;
      width: 30%;
      padding: 0 5px 0 20px;
      overflow-y: scroll;
      &::-webkit-scrollbar-thumb {
        background: var(--secondary-theme-color);
        -webkit-border-radius: 100px;
        border-radius: 100px;
      }
      .component-list {
        padding: 20px 0;
      }
    }
    .search {
      padding: 9px 0;
    }
  }
  .icon-column {
    min-width: 16px;
  }
  .code-column {
    min-width: calc(10% - 16px);
  }
  .code-column {
    color: var(--primary-font-color);
    font-size: 14px;
    font-weight: 400;
    max-width: 200px !important;
    min-width: 50px !important;
    word-break: break-word;
  }
  .name-column,
  .owner-column,
  .cost-centre-column,
  .maintenance-id-column,
  .location-column {
    color: var(--primary-font-color);
    font-size: 14px;
    font-weight: 400;
    max-width: calc((100% - 268px) / 5) !important;
    min-width: calc((100% - 268px) / 5) !important;
  }
  .icon-column {
    align-items: center;
    justify-content: center;
    max-width: 32px !important;
    min-width: 32px !important;
  }
  .action-menu-column {
    align-items: center;
    justify-content: center;
    max-width: 36px !important;
    min-width: 36px !important;
    &__button {
      background-color: inherit;
      border: 0;
      cursor: pointer;
    }
  }

  .component-picker-ch-name {
    align-items: center;
    display: flex;
    gap: 8px;
  }
  &__details {
    height: 100%;
    width: 100%;
    &__title {
      align-items: center;
      display: flex;
      gap: 8px;
      margin-bottom: 20px;
      margin-top: 22px;
      width: 100%;
      &__color {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
      }
      &__text {
        height: 100%;
        word-break: break-word;
      }
    }
  }
}
