$top-part-height: 379px;
.defects-filter {
  .modal-wrapper {
    max-width: 800px; // makes sure that when dropdown items are added to not expand in width
  }
}
.defects-section {
  .items-table-renderer {
    height: calc(100vh - #{$top-part-height});
    overflow: auto;
  }
  .header-section {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    .report {
      cursor: pointer;
      svg {
        margin-right: 10px;
      }
    }
  }
  .status-n {
    margin-left: 5px;
  }
  .total {
    padding-top: 10px;
    text-align: end;
  }
  .visibility {
    display: flex;
    > p {
      cursor: pointer;
      margin-left: 5px;
    }
  }
  .def-icon {
    margin: 0 0 0 10px;
  }
  .status-icon-defect {
    margin: 0 8px 0 0;
  }
  .advanced-filter {
    align-items: center;
    display: flex;
    justify-content: space-between;
    p {
      cursor: pointer;
    }
  }
  .long-field {
    max-width: 200px;
  }
  .type-icon {
    margin-right: 5px;
    svg {
      height: 18px;
      width: 18px;
    }
  }
}
