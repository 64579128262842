$top-part-height: 255px;
.components-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  .items-table-renderer {
    height: calc(100vh - #{$top-part-height});
    overflow: auto;
    .column-name {
      min-width: 230px;
    }
  }
  .unresolved-def {
    background-color: var(--severity-red);
    border-radius: 50%;
    height: 18px;
    margin-right: 2px;
    position: relative;
    width: 18px;
    span {
      font-size: 10px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .advanced-filter {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .pointer {
    cursor: pointer;
  }
  .missing-pin,
  .critical-pin {
    margin-right: 0px;
    .info-popover {
      min-width: unset;
      min-height: unset;
      text-wrap: nowrap;
    }
    .info-tooltip {
      display: flex;
    }
  }

  .missing-pin {
    svg {
      width: 18px;
      height: 18px;
    }
  }

  .critical-pin {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
