&.textarea {
  .auto-resize {
    .textarea {
      overflow: hidden;
    }
  }
  .input-wrapper__input {
    border-radius: 4px;
    // height: unset;
    outline: none;
    resize: none;
    width: 100%;
    &::placeholder {
      text-transform: none;
    }
  }
  .additional-data {
    display: flex;
    flex-direction: column;
    label {
      text-align: right;
    }
  }
}
