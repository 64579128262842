.image-slider {
  height: 250px;
  .image-gallery-thumbnail {
    opacity: 0.5;
    &.active {
      border: 4px solid var(--secondary-theme-color);
      opacity: 1;
    }
  }
  .image-gallery-fullscreen-button {
    bottom: unset;
    right: 15px;
    top: 15px;
    &::before {
      font-size: 30px;
      padding: 0;
      transition: all 0.1s ease-in-out;
    }
    &:hover {
      &::before {
        color: var(--secondary-theme-color);
        opacity: 0.8;
        transform: scale(1.2);
      }
    }
  }
  .image-gallery-left-nav,
  .image-gallery-right-nav {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    &::before {
      font-size: 40px;
    }
    &:hover {
      &::before {
        color: var(--secondary-theme-color);
      }
    }
  }
  .image-gallery-image {
    position: relative;
  }
  .image-gallery-thumbnails-wrapper {
    background-color: var(--box-items-theme-color);
  }
  .drawings-loader {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
