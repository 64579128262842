.work-order-completed-form {
  width: 100%;
  .change-hierarchy-modal {
    height: auto;
    width: 100%;
  }
  .modal-subtitle {
    margin-bottom: 32px;
  }
  .field-wrapper {
    .input-wrapper {
      textarea {
        min-height: 105px;
      }
    }
  }
  .split-fields {
    align-items: center;
    display: flex;
    .split-field-item {
      width: 50%;
    }
    .date-picker-wrapper {
      .field-wrapper {
        padding-right: 10px;
        .input-wrapper {
          input {
            border-radius: 4px;
            padding-left: 14px;
          }
        }
      }
    }
    .time-picker-wrapper {
      padding-bottom: 12px;
      .field-wrapper {
        padding-left: 10px;
      }
    }
  }
  .upload-files-container {
    margin-top: 32px;
    .upload-label {
      margin-bottom: 4px;
    }
    .work-order-upload-btn {
      color: var(--secondary-theme-color);
    }
  }
  .buttons-container {
    align-items: center;
    display: flex;
    margin-top: 32px;
    .button-submit {
      margin-left: auto;
    }
  }
  .file-container {
    align-items: center;
    display: flex;
    margin-bottom: 12px;
    .filename {
      margin-left: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 400px;
    }
    .image-container {
      cursor: pointer;
      &__file-image {
        height: 28px;
        width: 28px;
      }
      .filename {
        cursor: pointer;
      }
    }
    .icon-trash {
      margin-left: auto;
    }
    .delete-icon-disabled {
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}
