// TODO: Add media queries
.card {
  width: 413px;
  display: flex;
  flex-direction: column;
  height: 100%;

  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: #fff;
  cursor: pointer;
  position: relative;

  &.disabled {
    pointer-events: none;

    .card__top {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #ffffff;
        opacity: 0;
        z-index: 1;
        pointer-events: none;
      }

      filter: grayscale(100%) brightness(100%);
    }

    &,
    & * {
      color: var(--ch-text) !important;
    }
  }

  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }

  &__top {
    position: relative;
    width: 414px;
    height: 246px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;

    .full-size {
      width: 100%;
      height: 100%;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    text-align: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    .card__title {
      font-size: 16px;
      margin-bottom: 8px;
      color: var(--secondary-font-color-light);
      font-weight: 500;
    }

    .card__description {
      font-size: 14px;
      color: var(--ch-text);
      margin-bottom: 16px;
      font-weight: 400;
    }

    .btn {
      padding-left: 16px;
      padding-right: 16px;
      .button-text {
        font-weight: 500;
      }
      svg {
        margin-left: 4px !important;
      }
    }
  }
}
