.module-actions {
  align-items: center;
  display: flex;
  .item {
    cursor: pointer;
    height: 20px;
    margin-right: 10px;
    width: 20px;
  }
  .action-disabled {
    cursor: not-allowed;
    pointer-events: none;
    path {
      stroke-opacity: 0.6;
    }
  }
  .small-dropdown__settings {
    width: auto;
  }
}
