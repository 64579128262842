.charts-view {
  width: 100%;
  .image-progress {
    padding: 10px 30px;
    &__title-container {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
    }
    &__legend-container {
      align-items: center;
      display: flex;
      margin-top: 15px;
      .legend-item {
        align-items: center;
        display: flex;
        &:not(:last-child) {
          margin-right: 14px;
        }
        &__text {
          margin-left: 4px;
        }
      }
    }
  }
}
