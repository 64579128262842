@import '../../styles/variables';
.keyboard-key {
  align-items: center;
  border: 1px solid #b2b2b2;
  border-radius: 4px;
  display: flex;
  height: calc($keyboard-base-key-size * $keyboard-key-size-modifier);
  justify-content: center;
  width: calc($keyboard-base-key-size * $keyboard-key-size-modifier);
  .letter-key {
    color: var(--default-font-color);
    font-family: $primary-font-family;
    font-size: calc(9px * $keyboard-key-size-modifier);
    font-weight: 700;
  }
  .arrow {
    height: 0;
    width: 0;
    &--down {
      border-left: calc(4px * $keyboard-key-size-modifier) solid transparent;
      border-right: calc(4px * $keyboard-key-size-modifier) solid transparent;
      border-top: calc(4px * $keyboard-key-size-modifier) solid var(--default-font-color);
    }
    &--up {
      border-bottom: calc(4px * $keyboard-key-size-modifier) solid var(--default-font-color);
      border-left: calc(4px * $keyboard-key-size-modifier) solid transparent;
      border-right: calc(4px * $keyboard-key-size-modifier) solid transparent;
    }
    &--right {
      border-bottom: calc(4px * $keyboard-key-size-modifier) solid transparent;
      border-left: calc(4px * $keyboard-key-size-modifier) solid var(--default-font-color);
      border-top: calc(4px * $keyboard-key-size-modifier) solid transparent;
    }
    &--left {
      border-bottom: calc(4px * $keyboard-key-size-modifier) solid transparent;
      border-right: calc(4px * $keyboard-key-size-modifier) solid var(--default-font-color);
      border-top: calc(4px * $keyboard-key-size-modifier) solid transparent;
    }
  }
  .wheel {
    align-self: flex-start;
    background-color: var(--modal-color);
    border: 1px solid #b2b2b2;
    border-radius: 4px;
    height: calc(11px * $keyboard-key-size-modifier);
    margin-top: calc(6px * $keyboard-key-size-modifier);
    width: calc(4px * $keyboard-key-size-modifier);
    z-index: 2;
  }
  .left-click {
    border-bottom: 1px solid var(--default-font-color);
    border-radius: 2px;
    border-right: 1px solid var(--default-font-color);
    height: calc(18px * $keyboard-key-size-modifier);
    left: 0;
    position: absolute;
    top: -1px;
    width: calc(12px * $keyboard-key-size-modifier);
    z-index: 1;
  }
  .right-click {
    border-bottom: 1px solid var(--default-font-color);
    border-left: 1px solid var(--default-font-color);
    border-radius: 2px;
    height: calc(18px * $keyboard-key-size-modifier);
    position: absolute;
    right: 0;
    top: -1px;
    width: calc(12px * $keyboard-key-size-modifier);
    z-index: 1;
  }
  &--scroll {
    .wheel {
      border: 1px solid var(--default-font-color);
    }
  }
  &--scroll,
  &--left-click,
  &--right-click {
    height: calc(20px * $keyboard-key-size-modifier * 2);
    position: relative;
    width: calc(24px * $keyboard-key-size-modifier);
  }
}
