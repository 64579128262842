@import '../../../common//styles//variables';
.icon-legend {
  display: flex;
  .icon-item {
    align-items: center;
    display: flex;
    padding-left: 10px;
    p {
      margin-right: 5px;
    }
  }
}
