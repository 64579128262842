.sort-icon {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  .small {
    height: 8px;
  }
  .svg-primary-g {
    path {
      fill: none;
      stroke: var(--secondary-theme-color);
    }
  }
  .svg-gray-g {
    path {
      fill: none;
      stroke: var(--secondary-font-color-dark);
    }
  }
}
