.isolation-certificate-details {
  &.loading {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
  }
  .tabs {
    .tabs__navigation {
      margin-left: 20px;
    }
    .details-sections {
      padding: 0 20px;
    }
  }
  .details-sections {
    .change-status-container,
    .sections-container {
      padding: 0;
    }
  }
  .isolation-certificate-default-modal,
  .isolation-components-order-modal {
    .modal-wrapper {
      padding: 0;
      .modal {
        padding: 30px;
        .modal__title {
          font-size: 24px;
          font-weight: 500;
          line-height: 36px;
        }
      }
    }
  }
  .isolation-certificate-default-modal {
    .modal-wrapper {
      min-width: 760px;
      padding: 30px;
      .modal {
        padding: 0;
      }
    }
  }
  .isolation-components-order-modal {
    .modal-wrapper {
      min-width: 480px;
    }
  }
  .change-status-confirm-modal {
    .modal-wrapper {
      width: 520px;
      .modal__content {
        text-align: center;
      }
    }
  }
  .isolation-confirm-modal {
    .modal-wrapper {
      border-radius: 4px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2), 0 4px 4px 4px rgba(0, 0, 0, 0.05);
      max-width: 325px;
      padding: 20px;
      text-align: left;
      .modal {
        &__title {
          font-size: 18px;
          font-weight: 700;
          line-height: 28px;
          margin-right: auto;
          text-align: left;
        }
        .delete-confirmation-wrapper {
          line-height: 20px;
          margin-bottom: 19px;
          word-break: break-word;
        }
        &__button,
        .btn {
          border-radius: 4px;
          font-size: 14px;
          font-weight: 700;
          gap: 10px;
          line-height: 20px;
          margin-bottom: 12px;
          max-width: 285px;
          min-width: 250px;
          padding: 20px;
        }
        &__button-container {
          flex-flow: column;
          width: 100%;
        }
        &__button_red {
          background-color: var(--severity-red);
          border: 1px solid var(--severity-red);
        }
      }
    }
  }
  .field-wrapper__label,
  #isolation-details-name::placeholder {
    text-transform: none;
  }
  .download-isolation-pdf-modal {
    .modal-wrapper {
      border-radius: 8px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2), 0 4px 4px 4px rgba(0, 0, 0, 0.05);
      max-width: 500px;
      min-width: 490px;
      padding: 20px 24px;
      text-align: left;
      .modal {
        &__content {
          margin-bottom: 0;
          width: 100%;
        }
        &__title {
          font-size: 18px;
          font-weight: 700;
          line-height: 28px;
          margin-right: auto;
          text-align: left;
        }
        .include-options-form {
          display: flex;
          flex-flow: column;
          width: 100%;
          label {
            color: #000;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
          }
          .include-label {
            font-weight: 400;
          }
          .download-option {
            align-items: center;
            border-bottom: 1px solid #eee;
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            padding-bottom: 12px;
            padding-top: 12px;
            .field-wrapper.inline {
              margin-bottom: 0;
              width: 100%;
              .field-wrapper__label {
                padding-bottom: 0;
              }
            }
          }
        }
        &__button,
        .btn {
          border-radius: 4px;
          font-size: 14px;
          font-weight: 700;
          gap: 10px;
          line-height: 20px;
          margin-bottom: 12px;
          max-width: 445px;
          min-width: 430px;
          padding: 24px;
          text-transform: capitalize;
        }
        .buttons {
          flex-flow: column;
          margin-top: 40px;
          width: 100%;
        }
      }
    }
  }
  .notification {
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 200;
  }

  .critical-equipment-info-container {
    padding-bottom: 20px;
    padding-top: 0px;
  }
}
