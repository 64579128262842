@import '../../../common/styles/variables';
$this-header-bar-height: 50px;
.image-list-view {
  height: 100%;
  max-height: 363px;
  overflow-y: auto;
  padding: 0 20px;
  &.section-view {
    overflow: hidden;
    overflow-y: hidden;
    padding: 0 !important;
    .list-wrapper {
      display: flex;
      height: 100%;
      &.grid {
        flex-direction: column;
        flex-wrap: nowrap;
        .expandable-section.open {
          max-height: calc(100% - #{$this-header-bar-height});
          transition-duration: 0.5s, 0.3s;
          transition-property: max-height;
        }
        .section-content--visible {
          height: calc(100% - #{$this-header-bar-height});
          max-height: unset;
        }
        .section-content {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }
      }
    }
  }
  .list-wrapper {
    &.number-list {
      counter-reset: section;
      .image-item {
        display: flex;
        &::before { 
          color: var(--primary-font-color);
          content: counter(section) '. ';
          counter-increment: section;
          display: flex;
          padding-right: 5px;
          white-space: nowrap;     
        }
        .image-wrapper {
          display: flex;
          flex: 1;
          min-width: 0;
          p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 140px;
          }
          .remove-image {
            white-space: nowrap;
          }
       
        }
      }
    }
    //image-item
    @import '../../../common/styles/selectable-image';
    .image-item {
      label {
        border: 3px solid var(--box-items-separator-color);
        display: flex;
        margin-right: auto;
        min-height: initial !important;
        p {
          flex: 1;
          height: 1.2em;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    &.grid {
      display: flex;
      flex-wrap: wrap;
      .image-item {
        display: flex;
        flex-direction: column;
        margin: 5px;
        position: relative;
        .image-wrapper {
          height: 90px;
          padding-bottom: 10px;
          width: 92px;
        }
        .reviewed {
          position: absolute;
          right: 1px;
          top: 1px;
        }
        .image-name {
          bottom: 0;
          display: flex;
          flex-grow: 0;
          font-size: 8px;
          overflow: hidden !important;
          position: absolute;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 60px;
        }
      }
    }
    &.list {
      .image-item {
        align-items: center;
        label {
          padding: 10px;
          width: 100%;
          word-break: break-all;
        }
      }
    }
  }
}
