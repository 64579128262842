.new-observation-form {
  width: 100%;
  .row {
    align-items: center;
    display: flex;
    flex-flow: row;
    gap: 20px;
    justify-content: space-between;
    width: 100%;
    &:first-child {
      .field-wrapper {
        padding-right: 10px;
      }
    }
    .inspection-input,
    .field-wrapper {
      max-width: 50%;
      min-height: 110px;
      width: 100%;
    }
    .inspection-input {
      display: flex;
      flex-flow: column;

      gap: 4px;
      .react-autosuggest__container,
      .inspection-input__input-wrapper {
        max-height: 52px;
      }
    }
    .field-wrapper {
      &__label {
        margin-bottom: 0;
      }
      .input-wrapper .error-message {
        max-height: 32px;
        min-height: 32px;
      }
      .dropdown-icon {
        cursor: pointer;
        height: 24px;
        width: 24px;
        path,
        g {
          stroke: var(--primary-font-color);
        }
        &:hover {
          path,
          g {
            stroke: var(--secondary-theme-color);
          }
        }
      }
      textarea {
        border-radius: 4px;
        height: 104px;
        outline: none;
        resize: none;
        width: 100%;
        &::placeholder {
          color: #999;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }
      .char-count {
        font-weight: 400;
      }
    }
    .comments-container {
      .expandable-section {
        .header {
          &__wrapper {
            padding: 16px 0;
          }
        }
        .section-content {
          padding: 12px 0;
          &--hidden {
            padding: 0;
          }
        }
      }
    }
    .warning-indicator {
      margin-bottom: 32px;
    }
    .observations-details-section {
      &__select-option {
        align-items: center;
        color: var(--primary-font-color);
        display: flex;
        gap: 4px;
      }
      &__select-options {
        align-items: center;
        cursor: pointer;
        display: flex;
        gap: 4px;
        padding: 8px;
      }
      .section-content--visible {
        max-height: none;
        padding: 0;
      }
      .rw-widget-input.rw-widget-picker.rw-widget-container {
        background-color: var(--input-background-color);
        border: 1px solid var(--input-border-color);
        border-radius: 4px;
      }
      .text-area {
        height: 104px;
      }
      .upload-image {
        margin: 0;
      }
    }
    .component-picker-with-list {
      margin-bottom: 40px;
      width: 100%;
    }
  }
  button.btn {
    .button-text {
      font-weight: 500;
    }
  }
  .btn-gray-outline {
    max-width: 110px;
    min-width: 110px;
  }
  .btn-success {
    max-width: 194px;
    min-width: 194px;
  }
}
