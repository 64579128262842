.work-order-section-files {
  .section-empty-state {
    margin-bottom: 12px;
  }
  .section-files-empty-state {
    align-items: center;
    cursor: pointer;
    display: flex;
    .upload-label {
      margin-left: 8px;
    }
  }
  .file-container {
    align-items: center;
    display: flex;
    margin-bottom: 12px;
    .filename {
      cursor: pointer;
      margin-left: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .image-container {
      cursor: pointer;
      &__file-image {
        height: 28px;
        width: 28px;
      }
    }
  }
  .work-order-other-files {
    margin-top: 32px;
  }
  .work-order-other-files-without-margin {
    margin-top: 0;
  }
  .files-title {
    margin-bottom: 12px;
  }
}
