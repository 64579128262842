.page-not-found {
  background-color: var(--background-color);
  display: flex;
  justify-content: center;
  min-height: 100%;
  padding: 20px;
  &__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    &--subtitle {
      margin-top: 8px;
    }
    &--message {
      margin-top: 24px;
    }
    &--button {
      margin-top: 48px;
    }
  }
  &__side-image {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
  }
}
