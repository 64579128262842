@import '../../../common//styles//variables';
.t-toolbar {
  height: 86px;
  padding-left: $inspection-left-toolbar;
  .pdf-select {
    align-items: center;
    display: flex;
    justify-content: left;
    padding: 20px 0;
    .input-wrapper {
      margin-left: 10px;
      padding-top: 0;
      .select-container {
        width: 300px;
      }

    }
    .error-message {
      display: none;
    }
  }
}
