@import '../../../common/styles/variables';
.google-map {
  flex: 1;
  height: 100vh;
  position: relative;
  &__marker {
    border-radius: 50%;
    cursor: pointer;
    height: 20px;
    transform: translate(-50%, -100%);
    transition: all 0.2s ease-out;
    width: 20px;
    &--default {
      background-color: var(--marker-inner);
      border: solid 4px var(--marker-outer);
      box-shadow: none;
    }
    &--selected {
      background-color: var(--secondary-theme-color-dark);
      border: solid 4px var(--secondary-theme-color);
      box-shadow: none;
      position: relative;
      z-index: 1;
      &::after {
        animation: circle-animation 1.5s infinite;
      }
      &::after {
        background-image: radial-gradient(circle at 50% 50%, var(--secondary-theme-color), rgba(64, 114, 238, 0.2));
        border: solid 1px var(--secondary-theme-color);
        border-radius: 50%;
        content: '';
        height: 40px;
        left: 50%;
        opacity: 0.3;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 40px;
      }
    }
    &:active {
      box-shadow: inset 0 0 3px 2px #0003;
    }
  }
  .gm-control-active.gm-fullscreen-control {
    display: none;
  }
}

@keyframes circle-animation {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(0.7);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
