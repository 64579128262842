.time-series-graph-small-preview {
  &__settings-grid {
    display: grid;
    grid-gap: 4px;
    grid-template-columns: 1fr 1fr 1fr;
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &:first-child {
        align-items: flex-start;
      }
      &:last-child {
        align-items: flex-end;
      }
      p {
        word-break: break-word;
      }
    }
  }
  &__description-info {
    display: flex;
    align-items: center;
    p {
      margin-left: 4px;
      font-weight: 400;
    }
  }
  &__graph {
    width: 100%;
    height: 272px;
  }
  .info-popover {
    .value {
      white-space: nowrap;
    }
  }
  .value-with-tooltip {
    &__container {
      display: flex;
      .info-tooltip {
        margin-left: 2px;
      }
    }
  }
}
