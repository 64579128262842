@import '../../../common/styles/variables';
@import '../../../common/styles/mixins';
.search {
  background-color: var(--box-items-theme-color);
  border: solid 1px var(--box-items-separator-color);
  border-radius: 4px;
  display: flex;
  padding: 16px;
  position: relative;
  width: 100%;
  height: 52px;
  &__icon {
    // fill: var(--secondary-theme-color);
    fill: #999;
    height: 20px;
    margin-right: 14px;
    min-width: 20px;
    width: auto;
    path,
    g {
      stroke: #999;
    }
  }
  &__input {
    background: transparent;
    border: 0;
    color: var(--primary-font-color);
    flex: 1;
    font-family: $primary-font-family;
    font-size: 14px;
    font-weight: normal;
    outline: none;

    @include placeholder {
      color: var(--ui-color-secondary) !important;
      font-weight: 500 !important;
      line-height: 20px;
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
  .items-dropdown {
    background: white;
    border: solid 1px var(--box-items-separator-color);
    left: 0;
    max-height: 300px;
    overflow-y: auto;
    padding: 5px;
    position: absolute;
    top: 50px;
    width: 50%;
    .item {
      padding: 5px 0;
    }
  }
  .empty-state {
    align-items: center;
    display: flex;
    height: 50px;
  }
}
.search-input-stripped {
  background-color: transparent;
  border-color: transparent;
  &__icon {
    margin-right: 7px;
  }
  &__input::-webkit-input-placeholder {
    color: var(--secondary-theme-color);
    font-weight: bold;
  }
}
.search-wrapper {
  &__label {
    color: var(--primary-font-color);
  }
  &__gray-label {
    color: var(--ui-color-secondary);
  }
  &__input {
    display: flex;
    align-items: stretch;
    width: 100%;
    .dropdown-icon {
      cursor: pointer;
      width: 24px;
      height: 24px;
      path,
      g {
        stroke: var(--primary-font-color);
      }
    }

    .chevron-up {
      transform: rotate(180deg);
      transition: transform 0.3s ease;
    }
    .chevron-down {
      transition: transform 0.3s ease;
    }
  }
}
