@import '../../../common/styles/variables';
.guset-user-message form {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 400px;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    h3,
    h5,
    p {
      margin-bottom: 20px;
    }
    hr {
      margin: 0 60px 20px;
    }
  }
  .message {
    padding-top: 10px;
    text-align: center;
    label {
      text-transform: none;
    }
    em.clickable {
      color: #1212be;
      cursor: pointer;
    }
  }
}
