
.slider {
  margin-bottom: 50px;
  padding: 20px 20px 0;
  position: relative;
  .image-gallery {
    align-items: center;
    display: flex;
    height: 400px;
    justify-content: center;
    position: relative;
    .image-gallery-content {
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      .image-gallery-slide-wrapper {
        display: flex;
        justify-content: center;
        .image-gallery-swipe,
        .image-gallery-slides {
          width: 100%;
        }
        .image-gallery-slide {
          background: transparent;
          .image-gallery-image {
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 400px;
            justify-content: center;
            .custom-overlay {
              background: rgba(15, 17, 19, 0.4);
              bottom: 0;
              display: none;
              // opacity: 0.45;
              padding: 20px;
              visibility: hidden;
              // pointer-events: none;
              width: 100%;
              z-index: 1;
            }
            img {
              height: auto;
              max-height: 332px;
              max-width: 100%;
              width: auto;
            }
          }
        }
       
        @import './arrows';
        .image-gallery-fullscreen-button {
          bottom: unset;
          top: 0;
        }
      }
      &.fullscreen {
        top: auto;
        .image-gallery-slide {
          &-wrapper {
            .image-gallery-fullscreen-button {
              opacity: 0.3;
              position: fixed;
              right: 13px;
              top: 13px;
              -webkit-transition: opacity 0.3s;
              transition: opacity 0.3s;
              z-index: 1;
              &:hover {
                opacity: 1;
              }
            }
          }
          .image-gallery-image {
            height: 100vh;
            width: 100vw;
            img {
              max-height: 100vh;
              max-width: 100vw;
            }
            .custom-overlay {
              align-items: center;
              bottom: 0;
              display: flex;
              justify-content: space-between;
              position: fixed;
              visibility: visible;
            }
            .image-details {
              display: block;
              margin-left: 20px;
              visibility: visible;
            }
          }
        }
      }
    }
    &.no-images {
      .image-gallery-content {
        bottom: 0;
        .image-gallery-slide-wrapper {
          height: 100%;
          .image-gallery-slides {
            background-color: var(--background-color);
            width: 100%;
          }
        }
      }
    }
  }
  .react-transform-component {
    display: flex;
    height: 100%;
    width: 100%;
    .react-transform-element {
      align-items: center;
      align-self: center;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;
    }
  }
  .galery-empty {
    align-items: center;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}
