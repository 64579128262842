.custom-toolbar {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  margin-bottom: 24px;
  .item {
    border-bottom: 1px solid var(--box-items-separator-color);
    cursor: pointer;
    padding-bottom: 8px;
    padding-right: 10px;
    padding-top: 8px;
    transition: border 0.3s ease;
    &:not(:first-child) {
      padding-left: 11px;
    }
    p {
      color: var(--secondary-font-color-dark);
    }
    &--active {
      border-bottom: 2px solid var(--secondary-theme-color);
      p {
        color: var(--secondary-theme-color);;
      }
    }
  }
}
