@import '../../../common/styles/variables';
.measurement-location-form {
  &__fields-container {
    height: 100%;
    width: 100%;
    &--wrapper {
      height: 100%;
      overflow-y: scroll;
      .critical-equipment-info-container {
        padding-top: 0;
      }
    }
  }
  .section-content--visible {
    max-height: 300vh;
    padding: 20px 20px 20px 24px;
  }
  .section-content--hidden {
    padding: 0 20px 0 24px; // disable glitch
  }
  .buttons {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    padding: 0 19px 20px 27px;
  }
  .defect-form__label {
    margin-bottom: 0;
  }
  .input,
  .multiselect {
    margin-top: 4px;
  }
  &__graphing-groups-modal {
    width: 45vw;
    height: 60vh;
    &__content-container {
      height: calc(100% - 52px - 12px);
      width: 100%;
      overflow: auto;
      margin-bottom: 12px;
    }
    &__actions-container {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
  }
}

.ml-measurement-points-table {
  .twp {
    .twp-header {
      margin-top: 12px;
    }
  }
}

.confirm-graphing-group-modal {
  p {
    word-break: break-word;
  }
}

.graphing-modal {
  .modal-wrapper {
    .modal {
      min-height: 665px;
      max-height: 665px;
    }
  }
  .custom-time-period-modal {
    .modal {
      max-height: 70vh;
      min-height: auto;
    }
  }
}
