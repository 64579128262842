.uneditable-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32px;
  &__header {
    align-items: center;
    display: flex;
    &__label {
      width: 100%;
    }
    &__top-right-button {
      button {
        background: inherit;
        border: 0;
        &:disabled {
          cursor: not-allowed;
        }
      }
    }
    .button-displayed {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      width: 20% !important;
    }
    .button-hidden {
      display: none;
    }
    .label-shortened {
      width: 80% !important;
    }
  }
}
