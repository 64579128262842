.maintenance-regime-critical-eq {
  padding-bottom: 20px;
  padding-top: 0px;
}
.work-order-right-side {
  &.loading {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
  }
  .tabs {
    .tabs__navigation {
      margin-left: 20px;
    }
  }
  .work-order-right-side-content {
    padding: 0 20px;
    .change-status-container {
      padding: 0;
    }
    .work-order-completion-form {
      padding-top: 20px;
    }
    .sections-container {
      margin-top: 20px;
      padding: 0;
      .sections-item {
        .expandable-section {
          .header {
            &__wrapper {
              .section-title {
                .section-name {
                  margin-left: 0;
                }
              }
            }
          }
        }
        #comment-add-container {
          textarea {
            border: 0 !important;
          }
        }
      }
    }
    .work-order-section-work-areas,
    .work-order-section-components,
    .work-order-section-observations,
    .work-order-section-permits {
      .notification-WO-tab__table {
        height: auto;
        .table-template-container {
          padding-bottom: 20px;
        }
      }
      .twp-header {
        display: flex;
        .twp-header__cell {
          margin-right: auto;
          padding-left: 10px;
          padding-top: 8px;
          &.permit-status-icon,
          &.permit-id,
          &.focus-icon {
            margin-right: 0;
          }
        }
      }
      .twp-body__row__cells {
        color: var(--primary-font-color);
        width: 100%;
        &__cell {
          color: var(--primary-font-color);
          max-width: 50%;
          padding-left: 12px;
        }
        .permit-status-icon,
        .permit-id,
        .focus-icon {
          min-width: 15%;
          max-width: 15%;
        }
        .focus-icon {
          flex-grow: 0;
        }
        .full-width {
          max-width: 85%;
        }
      }
      .work-areas {
        &__name {
          align-items: center;
          display: flex;
          gap: 8px;
          width: 100%;
          &__status-icon {
            border-radius: 50%;
            height: 16px;
            width: 16px;
          }
          &__text {
            width: calc(100% - 13px - 8px);
          }
        }
      }
    }
  }
  .work-order-section-observations {
    .observation-icon {
      color: var(--primary-font-color);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      max-width: 24px !important;
      min-width: 24px !important;
    }
    .observation-id {
      color: var(--primary-font-color);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      max-width: calc(25% - 24px) !important;
      min-width: calc(25% - 24px) !important;
    }
    .observation-name {
      color: var(--primary-font-color);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      // minus the padding left of the table
      max-width: calc(75% - 12px) !important;
      min-width: calc(75% - 12px) !important;
    }
  }
  .work-order-custom-select {
    .rw-widget-input {
      background-color: var(--input-background-color) !important;
      border: 1px solid var(--input-border-color) !important;
      border-radius: 4px !important;
      color: var(--primary-font-color) !important;
    }
  }
  .work-order-textarea {
    textarea {
      border-radius: 4px !important;
      min-height: 110px;
    }
  }
  #work-order-basic-details-priority_input {
    .rw-popup {
      background-color: var(--input-background-color);
      border-color: var(--input-background-color);
      color: var(--primary-font-color);
    }
  }
  .priority-dropdown-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
    .priority-icon {
      border-radius: 50%;
      height: 20px;
      margin-right: 4px;
      width: 20px;
    }
  }
  .without-padding {
    padding: 0;
  }
  .field-wrapper {
    .input-wrapper {
      input,
      textarea {
        background-color: var(--input-background-color) !important;
        border-radius: 4px;
      }
      .rw-dropdownlist-search {
        border: 0 !important;
      }
    }
  }
  .work-order-custom-date-picker {
    input {
      padding: 0 14px !important;
    }
  }
  .work-order-custom-props {
    .inspection-input__label {
      font-size: 14px;
      font-weight: 500;
    }
    .inspection-input__input-wrapper {
      border: 0;
      input {
        font-weight: 500;
      }
    }
  }
  .work-order-time-picker {
    margin-bottom: 20px;
    .field-wrapper {
      .time-picker-wrapper {
        .time-picker-hours,
        .time-picker-minutes {
          width: 75px;
        }
      }
    }
  }
  .no-flow-status {
    color: var(--wo-flow-label-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0 auto;
    text-align: center;
  }
  .work-order-uneditable-info {
    margin-top: 32px;
  }
  .component-assets {
    margin-bottom: 16px;
  }
  .notification {
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 200;
  }
  .warning-indicator {
    margin-bottom: 20px;
  }
  .checklist-details-modal {
    z-index: 100;

    > .modal-wrapper {
      // Selects only the first level .modal-wrapper inside .checklist-details-modal
      max-width: 65vw;
      min-width: 65vw;
      padding: 20px 24px;

      > .modal {
        // Selects only the direct child .modal inside the top-level .modal-wrapper
        max-width: 65vw;
        min-height: 70vh;
      }
    }
  }
  .work-order-section-work-areas,
  .work-order-section-components,
  .work-order-section-permits,
  .work-order-section-cp-templates,
  .work-order-section-checklist-procedures {
    .notification-WO-tab__table {
      height: auto;
      .table-template-container {
        padding-bottom: 20px;
      }
    }
    .twp-header.sticky {
      display: flex;
      .twp-header__cell {
        margin-right: auto;
        padding-left: 10px;
        &.permit-status-icon,
        &.permit-id,
        &.focus-icon,
        &.component-name {
          margin-right: 0;
        }
      }
    }
    .twp-body__row__cells {
      color: var(--primary-font-color);
      width: 100%;
      &__cell {
        color: var(--primary-font-color);
        max-width: 50%;
        padding-left: 12px;
      }
      .permit-status-icon,
      .permit-id,
      .focus-icon,
      .critical-icon {
        max-width: 15%;
        min-width: 15%;
      }
      .focus-icon {
        flex-grow: 0;
      }
      .full-width {
        max-width: 85%;
      }
      .component-name {
        text-wrap: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 15px;
      }
      .critical-icon {
        justify-content: end;
        margin-right: 12px;
      }
    }
  }
  .work-order-section-checklist-procedures,
  .work-order-section-cp-templates {
    .is-submitted-icon,
    .cp-id {
      max-width: 15%;
    }
    .cp-name {
      max-width: 60%;
    }
    .cp-template-name {
      max-width: 70%;
    }
    .checklist-progress {
      max-width: 25%;
    }
  }
  .work-order-section-checklist-loader {
    p.f-primary {
      position: relative;
      top: -15px;
    }
  }
  .linked-to-eq-container {
    align-items: center;
    display: flex;
    p {
      margin-left: 4px;
    }
  }
  .checklist-is-submitted-container {
    border: 1px solid var(--secondary-font-color-dark);
    border-radius: 50%;
    height: 21px;
    position: relative;
    width: 21px;
    &__icon {
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }
  .checklist-name-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: left;
    .info-tooltip {
      display: flex;
    }
    p {
      display: inline-block;
      margin-right: 4px;
      max-width: 130px;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .work-order-section-contractors {
    overflow-x: hidden;
  }

  .work-order-confirm-submit-modal {
    .modal-wrapper {
      padding: 20px 24px;
    }
  }

  .work-order-confirm-modal {
    .modal-wrapper {
      max-width: 450px;
      .modal {
        &__title {
          text-align: center;
        }
        &__button {
          height: 52px;
          padding: 9px;
        }
      }
    }
  }
  .change-status-confirm-modal {
    .modal-wrapper {
      width: 520px;
      .modal__content {
        text-align: center;
      }
    }
  }
  .work-order-completed-modal {
    z-index: 100;
    .modal-wrapper {
      min-width: 720px;
    }
  }
  .download-workorder-pdf-modal {
    .modal-wrapper {
      border-radius: 8px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2), 0 4px 4px 4px rgba(0, 0, 0, 0.05);
      max-width: 500px;
      min-width: 490px;
      padding: 20px 24px;
      text-align: left;
      .modal {
        &__content {
          margin-bottom: 0;
          width: 100%;
        }
        &__title {
          font-size: 18px;
          font-weight: 700;
          line-height: 28px;
          margin-right: auto;
          text-align: left;
        }
        .include-options-form {
          display: flex;
          flex-flow: column;
          width: 100%;
          label {
            color: #000;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
          }
          .include-label {
            font-weight: 400;
          }
          .download-option {
            align-items: center;
            border-bottom: 1px solid #eee;
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            padding-bottom: 12px;
            padding-top: 12px;
            .field-wrapper.inline {
              margin-bottom: 0;
              width: 100%;
              .field-wrapper__label {
                padding-bottom: 0;
              }
            }
          }
        }
        &__button,
        .btn {
          border-radius: 4px;
          font-size: 14px;
          font-weight: 700;
          gap: 10px;
          line-height: 20px;
          margin-bottom: 12px;
          max-width: 445px;
          min-width: 430px;
          padding: 24px;
          text-transform: capitalize;
        }
        .buttons {
          flex-flow: column;
          margin-top: 40px;
          width: 100%;
        }
      }
    }
  }
  .expanded-section {
    .header {
      background-color: transparent;
      box-shadow: none;
      &__wrapper {
        display: block;
        padding: 0;
      }
    }
    .section-content {
      border-bottom: 0;
      padding: 0;
    }
  }
  .completion-component-header {
    align-items: center;
    display: flex;
    padding: 20px 0;
    .component-actions {
      align-items: center;
      display: flex;
      gap: 8px;
      margin-left: auto;
    }
    .component-header-toggle {
      cursor: pointer;
      path {
        stroke: var(--secondary-font-color-dark);
      }
    }
  }
}
.work-order-default-modal {
  width: 100%;
  .modal-wrapper {
    min-width: 710px;
    .work-order-form {
      padding-top: 0;
      width: 100%;
    }
    .buttons-without-spacing {
      margin-top: 10px !important;
    }
    .work-order-custom-select {
      .rw-widget-input {
        background-color: var(--input-background-color) !important;
        border: 1px solid var(--input-border-color) !important;
        border-radius: 4px !important;
        color: var(--primary-font-color) !important;
      }
    }
    .work-order-default-modal-buttons {
      align-items: center;
      display: flex;
      margin-top: 100px;
      width: 100%;
      .modal-confirm-btn {
        margin-left: auto;
      }
    }
    .work-order-components,
    .add-assignees-container,
    .select-users-teams-container {
      width: 100%;
      .added-items {
        margin-top: 20px;
        .added-items-list {
          .item-r {
            display: flex;
          }
        }
      }
    }
    .work-order-textarea {
      textarea {
        border-radius: 4px !important;
        min-height: 110px;
      }
    }
    #work-order-basic-details-priority_input {
      .rw-popup {
        background-color: var(--input-background-color);
        border-color: var(--input-background-color);
        color: var(--primary-font-color);
      }
    }
    .priority-dropdown-item {
      align-items: center;
      cursor: pointer;
      display: flex;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 20px;
      .priority-icon {
        border-radius: 50%;
        height: 20px;
        margin-right: 4px;
        width: 20px;
      }
    }
    .without-padding {
      padding: 0;
    }
    .field-wrapper {
      .input-wrapper {
        input,
        textarea {
          background-color: var(--input-background-color) !important;
          border: 1px solid var(--input-border-color) !important;
          border-radius: 4px;
        }
        .rw-dropdownlist-search {
          border: 0 !important;
        }
      }
      .work-order-section-work-areas,
      .work-order-section-components,
      .work-order-section-observations,
      .work-order-section-permits {
        .notification-WO-tab__table {
          height: auto;
          .table-template-container {
            padding-bottom: 20px;
          }
        }
      }
      .work-order-custom-date-picker {
        input {
          padding: 0 14px !important;
        }
      }
      .work-order-custom-props {
        .inspection-input__label {
          font-size: 14px;
          font-weight: 500;
        }
        .inspection-input__input-wrapper {
          border: 0;
          input {
            font-weight: 500;
          }
        }
      }
      .work-order-time-picker {
        margin-bottom: 20px;
        .field-wrapper {
          .time-picker-wrapper {
            .time-picker-hours,
            .time-picker-minutes {
              width: 75px;
            }
          }
        }
      }
      .no-flow-status {
        color: var(--wo-flow-label-color);
      }
      .work-order-section-observations {
        .observation-icon {
          color: var(--primary-font-color);
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          max-width: 24px !important;
          min-width: 24px !important;
        }
        .observation-id {
          color: var(--primary-font-color);
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          max-width: calc(25% - 24px) !important;
          min-width: calc(25% - 24px) !important;
        }
        .observation-name {
          color: var(--primary-font-color);
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          // minus the padding left of the table
          max-width: calc(75% - 12px) !important;
          min-width: calc(75% - 12px) !important;
        }
      }
      .work-order-custom-select {
        .rw-widget-input {
          background-color: var(--input-background-color) !important;
          border: 1px solid var(--input-border-color) !important;
          border-radius: 4px !important;
          color: var(--primary-font-color) !important;
        }
      }
      .work-order-textarea {
        textarea {
          border-radius: 4px !important;
          min-height: 110px;
        }
      }
      #work-order-basic-details-priority_input {
        .rw-popup {
          background-color: var(--input-background-color);
          border-color: var(--input-background-color);
          color: var(--primary-font-color);
        }
      }
      .priority-dropdown-item {
        align-items: center;
        cursor: pointer;
        display: flex;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 20px;
        .priority-icon {
          border-radius: 50%;
          height: 20px;
          margin-right: 4px;
          width: 20px;
        }
      }
      .without-padding {
        padding: 0;
      }
      .field-wrapper {
        .input-wrapper {
          input,
          textarea {
            background-color: var(--input-background-color) !important;
            border: 1px solid var(--input-border-color) !important;
            border-radius: 4px;
          }
          .rw-dropdownlist-search {
            border: 0 !important;
          }
        }
      }
      .work-order-custom-date-picker {
        input {
          padding: 0 14px !important;
        }
      }
      .work-order-custom-props {
        .inspection-input__label {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          margin: 0 auto;
          text-align: center;
        }
        .work-order-uneditable-info {
          margin-top: 32px;
        }
        .component-assets {
          margin-bottom: 16px;
        }
        .notification {
          left: 0;
          position: fixed;
          top: 0;
          width: 100vw;
          z-index: 200;
        }
        .warning-indicator {
          margin-bottom: 20px;
        }
      }

      .expanded-section {
        .header {
          background-color: transparent;
          box-shadow: none;
          &__wrapper {
            display: block;
            padding: 0;
          }
        }
        .section-content {
          border-bottom: 0;
          padding: 0;
        }
      }
      .completion-component-header {
        align-items: center;
        display: flex;
        padding: 20px 0;
        .component-actions {
          align-items: center;
          display: flex;
          gap: 8px;
          margin-left: auto;
        }
        .component-header-toggle {
          cursor: pointer;
          path {
            stroke: var(--secondary-font-color-dark);
          }
        }
      }
      .files-empty-state {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        width: 100%;
        .upload-files-container {
          align-items: center;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          gap: 8px;
          .upload-files-icon {
            margin-bottom: 4px;
          }
        }
        .upload-description {
          font-size: 12px;
          margin-top: 4px;
        }
      }

      /**
* TODO: isolate this to a module-item-default-modal so it is re-used
* for now used in: permit-sections, isolation-certificate-sections,
* work-order-right-side, regime-table
*/
    }
  }
  .add-work-order-cp-templates {
    overflow-y: scroll;
    .added-items-list {
      max-height: max-content !important;
    }
  }
}
.wo-critical-equipment-info {
  padding-bottom: 20px;
  padding-top: 0px;
}
