.pointer-dot {
  &__outer {
    border-radius: 16px;
    height: 16px;
    width: 16px;
    align-items: center;
    display: flex;
    justify-content: center;
    &.success {
      border: 1px var(--secondary-theme-color) solid;
    }
  }
  &__inner {
    border-radius: 16px;
    height: 8px;
    width: 8px;
    &.success {
      background-color: var(--secondary-theme-color);
    }
  }
}
