.navigation-button {
  border: none;
  background: inherit;
  display: flex;
  margin: 12px 0;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 6px 0;
  cursor: pointer;
  &.soon {
    p:not(.soon-box) {
      opacity: 0.3;
    }
  }
  &__new-or-soon-box {
    border: 1px solid var(--secondary-theme-color-dark);
    margin-left: 10px;
    padding: 0 5px;
  }
  &__info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    width: 90%;
  }
  &__icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 10%;
  }
  &:hover {
    background-color: rgba(var(--secondary-theme-color-dark-rgb), 0.2);
  }
}
