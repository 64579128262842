.project-container {
  display: grid;
  grid-template-columns: 495px auto;
  .project-assets-modal {
    .upload-modal {
      label {
        display: block;
        text-align: center;
      }
    }
  }
  .delete-project-modal {
    .btn-medium {
      margin-bottom: 10px;
    } 
  }
}
