.modules-change-status-modal-default {
  .modal-wrapper {
    width: 800px;
  }
}
.change-status-modal {
  padding-top: 24px;
  width: 100%;
  .status-disclaimer {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .contractors-dropdown {
    margin-top: 24px;
    max-width: 50%;
    .rw-widget-input {
      background-color: var(--input-background-color) !important;
      border: 1px solid var(--box-items-separator-color) !important;
      border-radius: 4px !important;
    }
  }
  .signature-field-container {
    .signature-field {
      .input-wrapper {
        .icon-pen {
          cursor: pointer;
        }
        .signature-input {
          min-height: 120px;
        }
      }
    }
  }
  .change-status-buttons-container {
    align-items: center;
    display: flex;
    margin-top: 32px;
    .small-buttons {
      min-width: 110px;
    }
    .large-button {
      min-width: 190px;
    }
    .right-buttons {
      align-items: center;
      display: flex;
      margin-left: auto;
      .go-back-button {
        margin-right: 20px;
      }
    }
  }
  .change-status-questions,
  .change-status-isolation-components {
    padding-top: 32px;
    .questions-buttons-container {
      align-items: center;
      display: flex;
      margin-top: 30px;
      .save-btn {
        margin-left: auto;
      }
    }
    .section-isolation-component {
      display: flex;
      margin-bottom: 20px;
      border-bottom: 1px solid var(--box-items-separator-color);

      .isolation-component-checkbox {
        .input-wrapper {
          display: flex;
          align-items: center;
          padding-top: 0;
          .checkbox-wrapper input {
            height: 18px;
            width: 18px;
            cursor: pointer;
            &:checked {
              appearance: auto;
              filter: hue-rotate(240deg);
              border: 1.5px solid #58b895;
            }
          }
        }
      }
    }
    .isolation-component-wrapper {
      min-height: 50px;
      width: 100%;
      margin-left: 6px;
      .isolation-component-item {
        width: 100%;
        .isolation-component-item-aligned {
          align-items: center;
          display: flex;
          justify-content: flex-start;
          width: 100%;
          .isolation-component-title {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            margin-left: 12px;
            margin-right: auto;
          }
          svg {
            cursor: pointer;
          }
        }
      }
      .isolation-component-details {
        .detail-title {
          &:first-child {
            margin-top: 16px;
          }
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 4px;
        }
        .detail-description {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 16px;
        }
        .files-items {
          .f-secondary-dark {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
    .section-question {
      display: flex;
      margin-bottom: 12px;
      flex-direction: row;
      .question-number {
        align-items: center;
        border: 1px solid var(--secondary-theme-color);
        border-radius: 50%;
        display: flex;
        height: 40px;
        justify-content: center;
        margin-top: 12px;
        width: 10%;
        max-width: 40px;
        max-height: 40px;
      }
      .question-wrapper {
        border: 1px solid var(--box-items-separator-color);
        border-radius: 8px;
        margin-left: 20px;
        min-height: 70px;
        padding: 0 20px;
        width: 90%;
        .question-details {
          padding: 24px 0;
          .label-separator {
            margin-bottom: 8px;
          }
          .details-item-aligned {
            align-items: center;
            display: flex;
          }
          .moved-right {
            margin-left: auto;
          }
          .details-section-separator {
            margin-top: 30px;
          }
          .details-section-separator-with-border {
            border-bottom: 1px solid var(--box-items-separator-color);
            padding-bottom: 12px;
          }
          .work-area-container {
            padding: 12px 0;
            .work-area-name {
              font-size: 12px;
            }
          }
          .work-area-container-with-border {
            border-bottom: 1px solid var(--box-items-separator-color);
          }
          .screenshots-container {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            .screenshot-image {
              margin-bottom: 20px;
              min-height: 160px;
              width: 33%;
              .basic-image {
                margin-bottom: 4px;
                min-height: 160px;
                width: 100%;
              }
              .screenshot-name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 180px;
              }
            }
            .screenshot-image-with-padding {
              padding-right: 20px;
            }
            .show-more-text {
              cursor: pointer;
              margin: auto;
            }
          }
        }
        .question-wrapper-item {
          align-items: center;
          display: flex;
          .toggle-question-details {
            cursor: pointer;
            margin-left: 12px;
          }
          .toggle-to-right {
            margin-left: auto;
          }
          .question-item {
            height: 100%;
            padding: 8px 0;
            width: 100%;
            .question-title {
              margin-right: 10px;
              width: 65%;
              word-wrap: break-word;
              word-break: break-word;
            }
            .form-error {
              color: var(--validation-error-color);
              font-size: 12px;
              margin-top: 12px;
            }
            .procedure-title {
              margin-bottom: 12px;
            }
            .question-input-aligned {
              margin-left: auto;
              width: 35%;
            }
            .multi-answers-container {
              display: flex;
              flex-flow: row;
              flex-wrap: wrap;
              padding-top: 20px;
              .question-option {
                width: 33%;
                .input-wrapper {
                  .error-message {
                    display: none;
                  }
                  .checkbox-wrapper {
                    input {
                      height: 20px;
                      width: 20px;
                      cursor: pointer;
                    }
                    label {
                      font-size: 14px;
                      max-width: 130px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }
                  }
                }
              }
            }
            .checkboxes-question {
              align-items: center;
              display: flex;
              margin-left: auto;
              min-width: 36%;
              .checkbox-wrapper {
                input {
                  -webkit-appearance: none;
                  appearance: none;
                  border: 1px solid #c6c6c6;
                  border-radius: 50%;
                  cursor: pointer;
                  height: 28px;
                  outline: none;
                  vertical-align: middle;
                  width: 28px;
                }
                label {
                  font-size: 14px;
                }
                input:checked {
                  appearance: auto;
                  clip-path: circle(44% at 50% 50%);
                  filter: hue-rotate(240deg);
                }
              }
              .field-wrapper:nth-child(1) {
                margin-right: 20px;
              }
            }
            .input-wrapper {
              .error-message,
              .error-message--visible {
                display: none !important;
              }
            }
            .input-wrapper {
              .react-datepicker-wrapper {
                input {
                  background-color: var(--input-background-color);
                  border: 1px solid var(--input-border-color);
                  border-radius: 4px;
                  color: var(--primary-font-color);
                  padding: 9px 14px;
                  padding-bottom: 9px;
                }
              }
            }
          }
          .question-item-aligned {
            align-items: center;
            display: flex;
            flex-direction: row;
          }
          .question-item-checkboxes {
            min-height: 52px;
          }
        }
      }
    }
  }
}
