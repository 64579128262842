.stream-view {
  .back-button {
    z-index: 10;
    position: relative;
  }
  &__title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    &__top {
      padding-top: 8px;
      padding-bottom: 32px;
      display: flex;
      justify-content: space-between;

      .left {
        .chip {
          user-select: none; /* Standard property */
          -webkit-user-select: none; /* For Safari */
          -ms-user-select: none; /* For older versions of IE */
          .red-dot {
            min-width: 9px;
            min-height: 9px;
            background-color: var(--severity-red);
            border-radius: 50%;
          }
          height: 25px;
          padding: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 64px;
          border: 1px solid var(--severity-red);
          gap: 4px;
          font-size: 12px;
        }
      }

      .right {
        z-index: 12;
        gap: 10px;
        .icon-pen {
          cursor: pointer;
        }
        .localisation-badge {
          display: flex;
          padding: 2px 10px 2px 4px;
          align-items: center;
          gap: 4px;
          border-radius: 90px;
          border: 1px solid #b3b3b3;
        }
        p {
          color: var(--ch-text);
        }
      }
    }

    &__bottom {
      .field-wrapper {
        margin-bottom: 0;
      }
      .show-tags {
        width: 100%;
        height: 100%;

        .input-wrapper {
          width: 164px;

          .right {
            right: 0;
            left: 36px;
          }

          .custom-toggle {
            .react-switch-handle {
              width: 13.5px !important;
              height: 13.5px !important;
            }
          }
        }
      }
    }

    .video-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  .stream-video {
    width: 100% !important;
    margin: 12px 0px;
  }
}

.stream-edit-modal {
  .modal-wrapper {
    padding: 20px 24px 24px;
  }
}
