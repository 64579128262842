@import '../../../common/styles/variables';
$defect-item-height: 48px;
.defect-list {
  height: $workflow-defects-box-height;
  position: relative;
  .defects-empty {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    max-width: 70%;
    padding: 10px 0;
    &__icon {
      margin-bottom: 18px;
    }
    &__title {
      margin-bottom: 18px;
      text-align: center;
    }
    &__description {
      text-align: center;
    }
  }
  .header {
    align-items: center;
    display: flex;
    height: $defect-item-height;
    padding: 0 12px;
    &__left {
      display: flex;
      flex: 1;
      height: 100%;
    }
    &__right {
      margin-left: 10px;
    }
    .custom-toolbar {
      margin: 10px 0;      
    }
    .search {
      max-width: 300px;
      padding: 4px 14px;
    }
  }
  .defects-wrapper {
    .defects-table {
      tr {
        height: $defect-item-height;
        td,
        th {
          &:first-child {
            padding-left: 30px;
          }
          &:last-child {
            text-align: center;
          }
        }
      }
      tbody tr {
        background-color: var(--box-items-theme-color-light);
        cursor: pointer;
        &:hover {
          background-color: rgba(var(--box-items-theme-color-light-rgb), 0.8);
        }
        &.selected {
          background-color: var(--secondary-theme-color);
          color: var(--default-font-color);
          .icon {
            fill: var(--default-font-color);
            opacity: 1;
          }
        }
        &__actions {
          align-items: center;
          display: flex;
          margin-left: auto;
        }
        .defect-item {
          &__actions {
            align-items: center;
            display: flex;
            justify-content: center;
          }
        }
        &:not(:last-child) {
          border-bottom: 1px solid var(--box-items-theme-color);
        }
      }
    }
  }
 
}
