.basic-image-loader {
  height: 100%;
  width: 100%;
  z-index: 1000;
  > div {
    height: 100% !important;
    width: 100% !important;  
  }
}
.basic-image {
  display: none;
  z-index: -1;
  &.loaded {
    display: initial;
    z-index: 1;
  }
}
