.dms-linked-equipment {
  width: 100%;
  .module-item-title-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 8px;
    .icon {
      margin-right: 8px;
    }
    .title {
      color: var(--primary-font-color);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .table-template-no-data-text {
    margin-bottom: 20px;
    margin-top: 20px;
    .empty-state-container {
      &__content {
        &__text {
          margin-bottom: 12px;
        }
        .btn {
          min-height: initial !important;
        }
      }
    }
  }
  &__table {
    background-color: var(--dms-linked-components-bg);
    border-radius: 8px;
    margin-bottom: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 12px;
    padding-top: 0px;
    width: 100%;
    .twp {
      margin: 0;
      max-width: 100% !important;
      min-width: 100% !important;
      width: 100%;
      &-header {
        background-color: inherit;
        border: 0;
      }
      &-body {
        &__row {
          background-color: var(--background-color);
          margin-top: 8px;
        }
      }
    }
    .icon-col {
      color: var(--primary-font-color);
      font-size: 14px;
      font-weight: 400;
      max-width: 32px !important;
      min-width: 32px !important;
    }
    .type-col,
    .name-col,
    .code-col,
    .critical-col {
      color: var(--primary-font-color);
      font-size: 14px;
      font-weight: 400;
      max-width: calc((100% - 32px) / 4) !important;
      min-width: calc((100% - 32px) / 4) !important;
    }

    .critical-col {
      display: flex;
      justify-content: flex-end;
      padding-right: 12px;

      .info-tooltip {
        display: flex;
      }
    }
    .name-col {
      p {
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
      }
    }
  }
}
