.path-viewer {
  width: 100%;
  &__info {
    align-items: center;
    display: flex;
    gap: 12px;
    justify-content: flex-start;
    svg {
      rotate: 180deg;
      path {
        stroke: #b3b3b3;
      }
    }
    h5 {
      color: var(--primary-font-color);
    }
  }
  &__path {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    &.has-chevron {
      padding-left: 36px;
    }
    &__level {
      align-items: center;
      display: flex;
      gap: 0; // set to 0 to remove the gap between the levels, which is causing the text to break
      justify-content: flex-start;
      p {
        color: var(--ch-text);
      }
      &__title {
        &.button {
          border-bottom: 1px var(--background-color) solid;
          color: var(--secondary-theme-color);
          cursor: pointer;
        }
        &.button:hover {
          border-bottom: 1px var(--secondary-theme-color) solid;
        }
        &.last {
          color: var(--ch-text);
        }
      }
    }
  }
}
