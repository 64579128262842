.menu_item {
  align-items: center;
  display: flex;
  padding: 6px 10px;
  width: 100%;
  &__text {
    font-weight: 500;
  }
  &__icon {
    margin-right: 10px;
  }
  .arrow {
    margin-right: 5px;
  }
}
