.dms-change-location {
  height: 80vh;
  width: 85vw;
  &__container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
    &__left-side {
      height: calc(100% - 54px);
      width: 75%;
      &__path {
        height: 10%;
        width: 100%;
      }
      &__table {
        align-items: center;
        display: flex;
        height: 90%;
        justify-content: center;
        width: 100%;
        .twp-header.sticky {
          justify-content: flex-start;
        }
        .icon-column {
          align-items: center;
          justify-content: center;
          max-width: 36px !important;
          min-width: 36px !important;
        }
        .name-column {
          color: var(--primary-font-color);
          font-size: 14px;
          font-weight: 400;
          max-width: 50% !important;
          min-width: 50% !important;
        }
        .at-column,
        .by-column {
          color: var(--primary-font-color);
          font-size: 14px;
          font-weight: 400;
          max-width: calc((50% - 72px) / 2) !important;
          min-width: calc((50% - 72px) / 2) !important;
        }
        .name-column {
          color: var(--primary-font-color);
          font-size: 14px;
          font-weight: 400;
          max-width: 50% !important;
          min-width: 50% !important;
        }
        .file-name-column {
          color: var(--primary-font-color);
          font-size: 14px;
          font-weight: 400;
          max-width: 30% !important;
          min-width: 30% !important;
        }
        .up-at-column {
          color: var(--primary-font-color);
          font-size: 14px;
          font-weight: 400;
          max-width: 200px !important;
          min-width: 200px !important;
        }
        .up-by-column,
        .size-column,
        .status-column,
        .type-column {
          color: var(--primary-font-color);
          font-size: 14px;
          font-weight: 400;
          max-width: calc((70% - 200px - 36px) / 4) !important;
          min-width: calc((70% - 200px - 36px) / 4) !important;
        }
      }
    }
    &__folder-info {
      border-left: 1px var(--dropdown-border) solid;
      height: 100%;
      width: 30%;
      &__form {
        border-left: 1px var(--dropdown-border) solid;
        box-shadow: 0 0 8px -6px var(--default-font-color);
        height: 100%;
        overflow-y: scroll;
        padding: 0 5px 0 20px;
        width: 100%;
        &__title {
          align-items: center;
          display: flex;
          gap: 8px;
          margin-bottom: 20px;
          margin-top: 22px;
          width: 100%;
          &__color {
            align-items: center;
            display: flex;
            height: 100%;
            justify-content: center;
          }
          &__text {
            height: 100%;
            word-break: break-word;
          }
        }
        &::-webkit-scrollbar-thumb {
          background: var(--secondary-theme-color);
          -webkit-border-radius: 100px;
          border-radius: 100px;
        }
      }
    }
  }
  .category-files-wrapper {
    display: flex;
    flex-flow: column;
    .header {
      p {
        color: var(--dms-grey-section-title);
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 13px;
        margin-top: 12px;
        text-align: left;
      }
    }
    .row {
      align-items: center;
      border-bottom: 1px solid #eee;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      margin-bottom: 16px;
      padding-bottom: 8px;
      width: 100%;
      .title {
        color: var(--primary-font-color);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-left: 8px;
        width: 90%;
      }
      .action {
        cursor: pointer;
        margin-left: auto;
        width: 10%;
      }
    }
  }
  .choose-documents-table {
    .action-menu-column {
      max-width: 36px !important;
      min-width: 36px !important;
    }
    .up-by-column,
    .size-column,
    .status-column,
    .type-column {
      max-width: calc((70% - 200px - 36px - 36px) / 4) !important;
      min-width: calc((70% - 200px - 36px - 36px) / 4) !important;
    }
  }
}
