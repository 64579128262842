.live-streams {
  height: calc(100% - 103px);
  transition: all 0.7s ease;
  width: 100%;

  .page-navigation {
    background-color: var(--background-color);
  }

  &__wrapper {
    padding: 0 20px;
    height: 100%;
    &__navigation {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-top: 20px;
      padding-bottom: 32px;

      .back-button {
        width: fit-content;
      }

      .title {
        font-weight: 500;
      }

      .description {
        max-width: 422px;
        color: var(--ch-text);
      }
    }
    &__tools-wrapper {
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      h5 {
        margin-top: 100px;
        margin-bottom: 60px;
      }

      &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding: 0 20px;
      }
    }
  }
}
