.dropdown_custom {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 15%);
  min-width: 150px;
  position: absolute;
  z-index: 1;
  &__item {
    cursor: pointer;
    position: relative;
  }
  .hovered {
    background-color: #f3f3f3;
  }
  &.top-left {
    left: 0;
    top: 0;
    transform: translate(0, -100%);
  }
  &.top-right {
    right: 0;
    top: 0;
    transform: translate(100%, -100%);
  }
  &.center-top {
    left: 50%;
    top: 0;
    transform: translate(-50%, -100%);
  }
  &.center-bottom {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
  }
  &.bottom-left {
    bottom: 0;
    left: 0;
    transform: translate(-100%, 100%);
  }
  &.bottom-right {
    bottom: 0;
    right: 0;
    transform: translate(100%, 100%);
  }
  &.center {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
