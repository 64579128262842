@import '../../../common/styles/variables';
.register-container {
  .bg-wrapper {
    @import '../../../common/slider/styles/image-slider-variant';
    .slider {
      padding: 0;
      position: relative;
      .image-gallery {
        height: 100vh;
        .image-gallery-slide-wrapper {
          position: relative;
          > span {
            bottom: unset;
            padding: 0 10px;
            top: calc(50% - 25px);
            width: 100%;
            .slider-nav {
              border: 3px solid white;
              box-shadow: 1px gray;
              height: 30px;
              position: absolute;
              transform: rotate(45deg);
              width: 30px;
              &::before {
                color: #fff;
                content: ' ';

                line-height: 0.7;
                transition: color 0.2s ease-out;
              }
              &-right {
                border-bottom: unset;
                // border
                border-left: unset;
                right: 20px;
              }
              &-left {
                border-right: unset;
                border-top: unset;
                left: 20px;
              }
              &:hover {
                border-color: var(--secondary-theme-color);
              }
            }
          }
        }
      }
    }
  }
  .login-wrapper {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    .login-form {
      display: flex;
      flex-direction: column;
      width: 300px;
      h4 {
        font-weight: bold;
        padding-bottom: 40px;
        text-align: center;
      }
      .logo {
        margin: auto;
        padding-bottom: 40px;
        img {
          height: 100%;
          width: 100%;
        }
        svg {
          height: 65px;
          width: auto;
          #eye {
            fill: var(--primary-font-color);
          }
        }
      }
      .space-bottom {
        margin-bottom: 15px;
      }
      &__link {
        align-self: center;
        color: var(--secondary-font-color-dark);
        cursor: pointer;
        font-size: 13px;
        font-weight: 500;
        text-decoration: none;
        text-transform: capitalize;
        &.space {
          margin-top: 15px;
        }
      }
      &__btn {
        margin: 20px 0;
      }
      .error-placeholder {
        color: var(--validation-error-color);
        display: flex;
        font-size: 12px;
        justify-content: center;
        min-height: 20px;
        padding: 0 10px;
      }
    }
  }
}
