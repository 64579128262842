.visibility-box {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding-left: 10px;
  .controls {
    display: flex;
    flex-direction: row;
    >p {
      margin-right: 5px;
    }
  }
}
