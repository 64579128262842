.component-list {
  display: flex;
  flex: 1 0;
  flex-direction: column;
  overflow: auto;
  padding: 20px;
  .items-table-renderer {
    display: flex;
    flex: 1 0;
    flex-direction: column;
    overflow: auto;
    table {
      thead {
        tr {
          border: 0;
          th {
            background-color: var(--modal-color);
            color: var(--secondary-font-color-dark);
            outline: unset;
          }
        }
      }
      tr {
        &:hover {
          border-color: unset;
          outline: unset;
          &::after {
            background-color: unset;
          }
        }
      }
    }
    .empty-state {
      background-color: unset;
      flex: 1;
      height: auto;
      margin: 0;
    }
  }
  &__table-actions {
    display: flex;
    svg {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
