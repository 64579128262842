.project-settings {
  background: var(--background-color);
  width: 100%;
  flex-direction: column;
  flex: 1;
  display: flex;
  &__container {
    width: 100%;
    padding: 12px 24px 0 24px;
  }
}
