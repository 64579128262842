.profile {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  &__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    min-height: 100%;
    width: 100%;
    &__content-wrapper {
      margin: 40px 0;
      width: 436px;
    }
  }
  .profile-settings {
    background-color: var(--box-items-theme-color);
    border-radius: 4px;
    padding: 25px 24px;
    width: 100%;
    &__title {
      margin-bottom: 16px;
    }
    .dropdown-group {
      display: flex;
      justify-content: space-between;
    }
  }
  &__alert-settings {
    background-color: var(--box-items-theme-color);
    border-radius: 4px;
    margin-top: 16px;
    padding: 25px 24px;
    width: 100%;
  }
}
