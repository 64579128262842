.create-isolation-certificate-form {
  max-width: 900px;
  min-width: 740px;
  padding-top: 20px;
  .input-name {
    max-width: 50%;
  }
  .isolation-template {
    .template-placeholder {
      align-items: center;
      display: flex;
      .template-placeholder-icon {
        margin-right: 4px;
      }
    }
  }
  .date-pickers {
    display: flex;
    width: 100%;
    .start-date-wrapper {
      padding-right: 10px;
      width: 50%;
    }
    .end-date-wrapper {
      padding-left: 10px;
      width: 50%;
    }
  }
  .buttons-container {
    display: flex;
    padding-top: 30px;
    .submit-button {
      margin-left: auto;
    }
  }
}
