.permit-details {
  .permit-details-critical-equipment {
    padding-bottom: 20px;
    padding-top: 0px;
  }

  .header-container {
    margin-bottom: 0px;
  }

  &.loading {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
  }
  .tabs {
    .tabs__navigation {
      margin-left: 20px;
    }
    .details-sections {
      padding: 0 20px;
    }
  }
  .details-sections {
    .change-status-container,
    .sections-container {
      padding: 0;
    }
  }
  .components-picker-modal {
    .modal-wrapper {
      min-width: 760px;
      padding: 30px;
      .modal {
        padding: 0;
        .modal__title {
          font-size: 24px;
          font-weight: 500;
          line-height: 36px;
        }
      }
    }
  }
  .change-status-confirm-modal {
    .modal-wrapper {
      width: 520px;
      .modal__content {
        text-align: center;
      }
    }
  }
  .permit-confirm-modal {
    .modal-wrapper {
      max-width: 450px;
      .modal {
        &__title {
          text-align: center;
        }
        &__button {
          height: 52px;
          padding: 9px;
        }
      }
    }
  }
  .rw-popup-container {
    position: relative;
  }
  .field-wrapper__label,
  #permit-details-name::placeholder {
    text-transform: none;
  }
}
