.isolation-template-modal {
  max-width: 900px;
  min-width: 740px;
  .step-title {
    margin-top: 20px;
  }
  .step-desc {
    margin: 20px 0;
  }
  .b-top {
    border-top: 1px solid var(--separator-color);
    padding-top: 20px;
  }
  .pointer {
    cursor: pointer;
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    button {
      margin-left: 10px;
    }
  }
  .ghost-field {
    display: none;
  }
  .x-hover:hover {
    path {
      fill: var(--severity-red);
    }
  }
  .c-hover:hover {
    path {
      fill: var(--severity-green);
      stroke: var(--severity-green);
    }
  }
  .range-selector {
    padding-left: 9px;
    width: 98%;
  }
  &__sections {
    .header {
      display: initial !important;
    }
    .section {
      .expanded-section {
        .header {
          .field-wrapper__label {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
          }
        }
      }
    }
  }
  .field-wrapper__label,
  .input-wrapper__input::placeholder {
    text-transform: none;
  }
}
