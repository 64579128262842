@import '../../../common/styles/variables';
.measurement-point-form {
  &__fields-container {
    height: 100%;
    width: 100%;
    &--wrapper {
      height: 100%;
      overflow-y: scroll;
      .search-wrapper {
        margin-bottom: 28px;
        .search {
          background-color: var(--input-background-color);
        }
        .items-dropdown {
          background-color: var(--dropdown-bg);
          border: 1px solid var(--input-border-color);
          border-radius: 4px;
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 4px rgba(0, 0, 0, 0.05);
          left: 20;
          max-height: 400px;
          overflow-y: auto;
          position: absolute;
          top: 5.5;
          max-width: 302px;
          min-width: 302px;
          z-index: 1;

          .field-wrapper {
            height: unset;
          }

          .group-item-wrapper {
            align-items: center;
            cursor: pointer;
            display: flex;
            padding: 8px;
            &:hover {
              background-color: var(--dropdown-hovered);
              border-color: var(--dropdown-hovered);
            }
            .checkbox-wrapper {
              label {
                margin-left: 0;
              }
            }
          }
          .load-more {
            .load-more__results {
              margin-top: 10px;
              min-width: 0;
            }
          }
        }
      }
    }
  }
  .defect-form__save-cta-wrapper {
    z-index: 2;
  }
  .section-content--visible {
    max-height: 300vh;
    padding: 20px 20px 20px 24px;
  }
  .section-content--hidden {
    padding: 0 20px 0 24px; // disable glitch
  }
  .buttons {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    padding: 0 19px 20px 27px;
  }
  .defect-form__label {
    margin-bottom: 0;
  }
  .input,
  .multiselect {
    margin-top: 4px;
  }

  .measurement-point-form-measurement-readings__actions {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .button-text {
      font-weight: 400 !important;
      text-transform: capitalize;
      svg {
        margin-left: 4px;
        width: 20px;
        height: 20px;
        path {
          stroke: #fff;
          stroke-width: 1.5px;
        }
      }
    }
  }

  .status-alarms-triggered {
    display: flex;
    gap: 8px;
    flex-direction: column;
    margin-bottom: 32px;

    .alarm-icon {
      display: flex;
      align-items: center;
      gap: 4px;
      width: 100%;
      p {
        text-transform: capitalize;
      }
    }
  }
}
