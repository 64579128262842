.react-autosuggest {
  &__container {
    position: relative;
    .enhanced-design-input {
      input {
        background-color: var(--input-background-color);
        border: 1px solid var(--input-border-color);
        border-radius: 4px;
        color: var(--primary-font-color);
        font-family: 'DM Sans', sans-serif;
        font-size: 14px;
        font-weight: normal;
        min-height: 52px;
        outline: none;
        padding: 9px 14px;
        padding-bottom: 9px;
        transition: 0.2s all;
        width: 100%;
      }
    }
  }
  &__suggestions-container {
    background-color: var(--box-items-theme-color);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    left: 0;
    max-height: 150px;
    overflow: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1;
    &.enhanced-design-custom-prop {
      position: relative;
      ul {
        border: 1px solid var(--input-border-color);
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        box-shadow: 0 5px 5px 5px rgba(0, 0, 0, 0.05);
      }
    }
  }
  &__suggestion {
    cursor: pointer;
    padding: 5px 14px;
    transition: all 0.2s ease;
    &--highlighted {
      background-color: var(--primary-theme-light-color);
    }
    span {
      // color: #24f4a7;
    }
    strong {
      // color: #939394;
    }
  }
}
