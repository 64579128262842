.pdf-viewer {
  display: inline-block;
  .react-pdf__Page__svg {
    margin: auto;
  }
  &.full-size {
    max-height: 100%;
    max-width: 100%;
  }
}
