$top-part-height: 250px;
.areas-list {
  .items-table-renderer {
    height: calc(100vh - #{$top-part-height});
    overflow: auto;
  }
  .advanced-filter {
    align-items: center;
    display: flex;
    justify-content: space-between;
    p {
      cursor: pointer;
    }
  }
}
