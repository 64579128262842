.linked-images-slider {
  .modal {
    max-height: unset;
  }
  .header__slider {
    height: 70vh;
    min-width: 60vw;
  }
  &.image-preview-modal {
    .main-container {
      height: 100%;
      max-width: 70vw;
    }
    .icon-legend {
      display: none;
    }
  }
  .slider .image-gallery-content .image-gallery-fullscreen-button {
    bottom: unset;
    right: 46px !important;
    top: 0;
  }
  .small-dropdown__settings {
    height: 28px;
    svg {
      height: 28px;
      width: 28px;
      transition: all 300ms;
      path {
        // color to match the one of gallery
        stroke: #fff;
      }
      &:hover {
        transform: scale(1.1);
        path {
          // color to match the one of gallery
          stroke: #337ab7;
        }
      }
    }
  }
  .image-slider-custom-render-button {
    padding: 20px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
  }
}
