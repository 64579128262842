.multiple-category-select {
  align-content: center;
  align-items: center;
  background: #f7f7f7;
  border-radius: 74px;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  padding: 8px 16px;
  svg {
    cursor: pointer;
  }
  &__content {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    > div {
      align-items: center;
      display: flex;
      svg {
        margin-right: 4px;
      }
    }
    &.left-side {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      svg {
        height: 20px;
        margin-right: 8px;
        width: 20px;
      }
      span {
        text-transform: lowercase;
      }
    }
    .danger {
      color: var(--severity-red);
      margin-left: 16px;
      svg {
        path {
          stroke: var(--severity-red);
        }
      }
    }
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
      > div {
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }
}
