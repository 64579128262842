.flow-status-wrapper-content {
  padding: 0 20px;
  .labels {
    color: var(--wo-flow-label-color);
    display: flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 16px;
    div {
      flex: 1;
    }
  }
  .status-wrapper {
    display: flex;
    &__inner {
      display: flex;
      flex-flow: column;
      margin-right: 13px;
      svg {
        margin: 0 auto;
      }
    }
    &__label {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), var(--wo-flow-status-color);
      border: 1px solid var(--wo-flow-status-color);
      border-radius: 4px;
      color: var(--wo-flow-status-color);
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      max-width: 168px;
      min-width: 148px;
      padding: 8px 0;
      text-align: center;
    }
    &__status {
      color: var(--primary-font-color);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
