@import '../../../common/styles/variables';
.create-user-container {
  .create-user-form {
    .form-fields {
      display: grid;
      flex-wrap: wrap;
      grid-gap: 20px;
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
      .add-user {
        &__option {
          font-weight: bold;
        }
      }
      .rw-popup {
        max-height: 150px;
      }
    }
    .contact-fields {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &__group-name {
        display: flex;
        margin-bottom: 20px;
        text-transform: capitalize;
      }
      .field-wrapper {
        width: 32%;
      }
    }
    .buttons {
      display: flex;
      flex-flow: wrap row;
      justify-content: space-between;
      button {
        height: auto;
        max-width: 112px;
        padding: 16px 32px;
        width: auto;
      }
    }
  }
  .input-wrapper__input,
  .rw-multiselect-input {
    height: auto !important;
    padding: 16px;
  }
  &__google-map {
    background-color: var(--box-items-theme-color);
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    flex: 1;
    height: auto;
    margin-bottom: 32px;
    margin-top: 9px;
    padding: 16px;
    .marker-container {
      align-items: center;
      display: flex;
      height: 26px;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      transform: translate(-50%, -100%);
      width: 18px;
    }
    .address-input {
      border: solid 1px #ddd;
      border-radius: 18px;
      font-family: $primary-font-family;
      font-size: 14px;
      font-weight: normal;
      height: 36px;
      margin-bottom: 10px;
      outline: none;
      padding: 9px 14px;
      padding-bottom: 9px;
      transition: 0.2s all;
      width: 100%;
    }
    .map-view {
      height: 280px;
      margin-bottom: 16px;
    }
    .description {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-bottom: 28px;
      opacity: 0.5;
      &__wrapper {
        align-items: center;
        display: flex;
      }
      &__text {
        font-size: 12px;
        margin-left: 20px;
      }
    }
  }
}
