.ndt-alarms-modal-email {
  display: flex;
  flex-direction: column;
  min-width: 600px;
  .main-form {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .inspection-input {
    min-width: 400px;
  }
  .emails-list {
    display: flex;
    flex-direction: column;
    height: 200px;
    margin: 20px 0;
    overflow-x: auto;
    .single-item {
      margin: 10px;
    }
    .border {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
    }
    .remove-btn {
      cursor: pointer;
    }
  }
}
