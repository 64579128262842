.select-users-teams-container {
  .work-area-add-btn {
    height: 60px;
    width: 100%;
    .button-text {
      font-size: 14px;
      font-weight: 700;
    }
  }
  .work-area-color {
    border-radius: 50%;
    height: 16px;
    margin-right: 4px;
    width: 16px;
  }
  .input-with-dropdown {
    margin-bottom: 25px;
    .items-dropdown {
      padding: 16px 12px;
      z-index: 1000;
      .heading {
        border-bottom: 1px solid var(--input-border-color);
        margin: 0 8px 4px;
        padding: 4px 0 !important;
      }

      .dropdown-render-item-container {
        align-items: center;
        border: 1px solid transparent;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        margin-bottom: 2px;
        padding: 8px;
        &:hover {
          background-color: rgba(var(--secondary-theme-color-rgb), 0.1);
          border: 1px solid var(--secondary-theme-color);
        }
        .module-name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 230px;
        }
        .user-checkbox-wrapper {
          align-items: flex-start;
          display: flex;
          flex-wrap: wrap;
          .field-wrapper {
            margin-right: 4px;
            .input-wrapper {
              padding-top: 0;
              .checkbox-wrapper {
                input {
                  height: 16px;
                  width: 16px;
                }
                .custom-checkbox {
                  margin-left: 0px;
                }
                align-items: center;
                display: flex;
                height: 18px;
                label {
                  margin: 0;

                  em.clickable {
                    color: -webkit-link;
                    cursor: pointer;
                  }
                }
                input {
                  height: 20px;
                  width: auto;
                }
                .custom-checkbox {
                  height: 14px;
                  padding-left: 20px;
                  -webkit-user-select: none;
                  -moz-user-select: none;
                  -ms-user-select: none;
                  user-select: none;

                  input {
                    cursor: pointer;
                    height: 0;
                    opacity: 0;
                    position: absolute;
                    width: 0;
                  }

                  .checkmark {
                    background-color: #fff;
                    border: 1.5px solid darkgray;
                    border-radius: 3px;
                    height: 16px;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 16px;
                  }
                  .checkmark::after {
                    border: solid white;
                    border-width: 0 2.5px 2.5px 0;
                    height: 10px;
                    left: 3.5px;
                    top: -1.5px;
                    width: 4px;
                    -webkit-transform: rotate(40deg);
                    -ms-transform: rotate(40deg);
                    transform: rotate(40deg);
                  }

                  &:hover input ~ .checkmark {
                    background-color: #fff;
                  }
                  input:checked ~ .checkmark {
                    background-color: var(--secondary-theme-color);
                    border: 1.5px solid var(--secondary-theme-color);
                  }
                }
              }
              .error-message {
                display: none;
              }
            }
          }
          p {
            padding: 0 !important;
          }
          label {
            display: block;
          }
        }
        .user-email-wrapper {
          margin-left: auto;
          width: 50%;
        }
      }
    }
    .search-input-label {
      color: var(--secondary-font-color-light);
      display: block;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 13px;
    }
    .search {
      background-color: var(--input-background-color);
      border: 1px solid var(--input-border-color);
      margin-top: 4px;
      padding: 15px 14px;
      .items-dropdown {
        top: 52px;
        width: 100%;
      }
      input::placeholder {
        color: var(--secondary-font-color-dark);
      }
      &__icon {
        fill: var(--secondary-font-color-dark);
        g,
        path {
          stroke: var(--secondary-font-color-dark);
        }
      }
    }
  }
  .added-items-list {
    .item-r {
      align-items: center;
      justify-content: initial;
      .info-tooltip {
        margin-left: 5px;
        margin-top: 3px;
      }
      .icon-close {
        margin-left: auto;
      }
    }
  }
  .interval-section {
    .interval-section-number {
      input {
        background-color: var(--input-background-color);
        border: 1px solid var(--input-border-color);
        border-radius: 4px;
      }
    }
    .select-d {
      padding-left: 0;
    }
  }
}
.add-work-order-permits {
  width: 100%;
  .added-items {
    margin-top: 20px;
    .item-r {
      border-bottom: 1px solid var(--box-items-separator-color);
      display: flex;
      padding: 12px 0;
    }
  }
}
