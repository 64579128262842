.global-loader {
  align-items: center;
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
  + div {
    background-size: cover;
    filter: blur(4px);
    pointer-events: none;
    user-select: none;
    .common-component-wrapper {
      overflow: hidden;
      pointer-events: none;
      user-select: none;
    }
  }
  &.computer-vision {
    background-color: black;
  }
}
