.alarm-section-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .alarm-section-list-item {
    .item {
      padding-top: 4px;
    }
  }
}
