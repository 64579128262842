table {
  border-collapse: collapse;
  position: relative;
  width: 100%;
  thead {
    vertical-align: baseline;
    .table-header {
      font-size: 12px;
      color: var(--secondary-font-color-dark);
    }
    th,
    td {
      min-width: 150px;
      &.sticky {
        background-color: var(--primary-theme-light-color);
        position: sticky;
        top: 0;
        z-index: 1;
      }
      &.clickable {
        cursor: pointer;
      }
      span {
        vertical-align: sub;
      }
      .sort-icon {
        display: inline-flex;
        flex-direction: column;
        .small {
          height: 8px;
        }
        .svg-primary-g {
          path {
            fill: none;
            stroke: var(--secondary-theme-color);
          }
        }
        .svg-gray-g {
          path {
            fill: none;
            stroke: var(--secondary-font-color-dark);
          }
        }
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background-color: var(--box-items-theme-color);
        cursor: default;
      }
      &.pointer {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
