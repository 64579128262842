.critical-equipment-info-container {
  padding: 20px;
  padding-bottom: 0;

  &__wrapper {
    background-color: #ffe92c;
    border-radius: 4px;
    border: 1px solid var(--Main-Text, #000);
    padding: 12px 15px 12px 16px;
  }
  &__title {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 4px;

    display: flex;
    align-items: center;
    gap: 4px;
  }
  &__paragraph {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
