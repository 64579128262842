.ndt-measurement-form {
  .chart-wrapper {
    .line-chart {
      position: relative;
      .empty-state {
        display: none;
      }
      [id^='google-visualization-errors-all'] {
        display: none !important;
      }
      .empty-state-active {
        align-items: center;
        bottom: 0;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 1;
      }
    }
  }
  .alarm-status {
    align-items: center;
    display: flex;
    justify-content: space-between;
    .status-icon {
      background-color: red;
      border-radius: 50%;
      height: 13px;
      margin-left: auto;
      margin-right: 10px;
      width: 13px;
    }
  }
  .settings-btn {
    margin: 0 10px 0 auto;
  }
}
