.add-area {
  height: 350px;
  &__form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .buttons {
    display: flex;
    margin-top: auto;
    button:first-child {
      margin-right: 10px;
    }
  }
  .select-container {
    .rw-popup {
      max-height: 180px;
    }
  }
}
