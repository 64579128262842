.measurement-point-alarms-form {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-height: 500px;
  min-width: 800px;
  overflow: auto;

  .measurement-point-alarms-form-heading {
    padding-top: 24px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 16px;
  }

  .buttons {
    align-items: center;
    display: flex;
    padding-top: 40px;
    .right-buttons {
      align-items: center;
      display: flex;
      margin-left: auto;
      .button-submit {
        margin-left: 20px;
      }
    }
  }
}
