.measurement-readings {
  .table-template-search {
    margin-top: 20px;
    .add-button {
      border: 1px solid #58b895;
      border-radius: 4px;
      display: flex;
      flex-direction: row-reverse;
      gap: 4px;
      height: 36px;
      padding: 0 16px;
      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
  .measurement-readings-table {
    .twp-header.sticky {
      justify-content: start;
    }
    .id-column {
      max-width: 80px !important;
      min-width: 80px !important;
      p {
        align-items: center;
        display: flex;
        gap: 4px;
      }
    }
    .values-per-reading-column {
      max-width: 12% !important;
      min-width: 12% !important;
    }
    .files-per-reading-column {
      max-width: 10% !important;
      min-width: 10% !important;
    }
    .measurement-reading-column {
      max-width: calc(45% - 80px - 30px) !important;
      min-width: calc(45% - 80px - 30px) !important;
      .info-tooltip {
        margin-right: 8px;
        .info-popover--visible {
          max-width: 200px;
          min-height: auto;
        }
      }
    }
    .measurement-date {
      max-width: 15% !important;
      min-width: 15% !important;
    }
    .measured-by-column {
      max-width: 18% !important;
      min-width: 18% !important;
    }
    .edit-reading-column {
      max-width: 30px !important;
      min-width: 30px !important;
    }
  }
  .reading-popover {
    align-items: center;
    display: flex;
    gap: 8px;
    .info-popover--visible {
      max-width: 240px;
      min-height: auto;
      min-width: 240px;
    }
  }
  .reading-popover-content-wrapper {
    .info-popover--visible {
      max-width: 240px;
      min-height: auto;
      min-width: 240px;
    }
  }
  .reading-popover-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    &__item {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    &__title {
      border-bottom: 1px solid #b3b3b3;
      padding-bottom: 12px;
    }
    &__description {
      display: flex;
      flex-direction: column;
      gap: 4px;
      span.f-primary {
        margin-bottom: 8px;
      }
      .legend {
        color: #999;
        display: flex;
      }
    }
  }
  .reading-files-wrapper {
    cursor: pointer;
    display: flex;
    span.f-primary {
      margin-right: 8px;
    }
    &:hover {
      svg {
        path {
          stroke: var(--secondary-theme-color);
        }
      }
    }
  }
  .reading-files-modal {
    &__picker {
      padding: 24px;
      .buttons-wrapper {
        display: flex;
        justify-content: flex-start;
        margin-left: 0;
        margin-right: auto;
      }
    }
  }
}
.delete-reading-modal {
  .delete-reading-modal__picker {
    padding: 20px 24px 24px;
    .first-paragraph {
      margin-bottom: 24px;
      margin-top: 16px;
    }
    .btn {
      .button-text {
        font-weight: 400;
      }
    }
  }
}
