.defect-notification-creation-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 65vh;
  max-width: 50vw;
  min-height: 65vh;
  min-width: 50vw;

  &__row {
    column-gap: 20px;
    row-gap: 20px;
    display: grid;
    grid-template-columns: 47.5% 47.5%;
    width: 100%;
    margin-bottom: 20px;
    &__field {
      width: 100%;
    }
  }

  &__component-title {
    display: flex;
    gap: 4px;
    margin: 20px 0 32px;
  }

  &__button-section {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    .modal-button-custom {
      max-width: 120px;
      min-width: 120px;
    }
  }

  .inline-wrapper label {
    margin-bottom: 0 !important;
  }

  .locked-popover {
    .info-popover {
      min-height: unset;
    }
  }

  .input-range {
    margin-left: 0;
    margin-top: 4px;
  }

  // Properties grid layout
  .properties-grid {
    display: grid;
    grid-template-columns: 47.5% 47.5%;
    column-gap: 20px;
    row-gap: 20px;
    width: 100%;

    .linked-equipment {
      grid-column: 1;
      grid-row: 1;
      p.f-primary.light-bold {
        margin-top: 4px;
      }
    }

    .property-container {
      display: contents;

      & > div {
        width: 100%;
      }

      .inspection-input {
        margin-top: 4px;
      }
      .field-wrapper,
      .inspection-input {
        display: flex;
        flex-direction: column;
        gap: 8px;
        max-height: 100px;

        label {
          margin-bottom: 0;
        }

        input,
        select {
          width: 100%;
        }
      }
    }
  }
}
