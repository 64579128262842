.checkbox-wrapper {
  align-items: center;
  display: flex;
  height: $chekbox-height;
  label {
    margin: 0;
    margin-left: 10px;
    em.clickable {
      color: -webkit-link;
      cursor: pointer;
    }
  }
  input {
    height: 20px;
    width: auto;
  }
  .custom-checkbox {
    cursor: pointer;

    display: block;
    font-size: 22px;
    height: $chekbox-height;
    // margin-bottom: 12px;
    padding-left: 35px;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    /* Hide the browser's default checkbox */
    input {
      cursor: pointer;
      height: 0;
      opacity: 0;
      position: absolute;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      background-color: #eee;
      border: 1px solid darkgray;
      height: $chekbox-height;
      left: 0;
      position: absolute;
      top: 0;
      width: $checkbox-width;
    }
    &:hover input ~ .checkmark {
      background-color: #ccc;
    }
    input:checked ~ .checkmark {
      background-color: var(--secondary-theme-color);
    }
    .checkmark::after {
      content: '';
      display: none;
      position: absolute;
    }
    input:checked ~ .checkmark::after {
      display: block;
    }
    .checkmark::after {
      border: solid white;
      border-width: 0 3px 3px 0;
      height: 10px;
      left: 8px;
      top: 4px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      width: 5px;
    }
  }
}
