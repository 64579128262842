@import '../../../common/styles/variables';
.visible-area {
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: padding 0.3s ease;
  z-index: 1;
  &__container {
    height: 100%;
    position: relative;
    width: 100%;
  }
  &.interactable {
    pointer-events: all;
  }
  &.right-padding {
    padding-right: $inspection-right-toolbar;
  }
  &.left-padding {
    padding-left: $inspection-left-toolbar;
  }
  &.top-padding {
    padding-top: $inspection-top-toolbar;
  }
}
