@import'../../../common//styles//variables';
.generic-upload-container {
  align-items: center;
  background-color: var(--modal-color);
  bottom: -$upload-bar-height;
  color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: $upload-bar-height;
  .title {
    align-items: center;
    display: flex;
    svg {
      margin-right: 10px;
    }
  }
  .smaller {
    display: inline-block;
    font-size: 12px;
    overflow: hidden !important;
    padding-left: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .upload-line {
    margin-left: 10px;
    min-width: 300px;
  }
}
