.table-template {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  &-search {
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: space-between;
    width: 100%;
    &.adv-filter {
      &-yes {
        .search-wrapper {
          width: 60%;
        }
      }
      &-no {
        .search-wrapper {
          width: 100%;
        }
      }
    }
    &.button {
      &-yes {
        .search-wrapper {
          width: 60% !important;
        }
      }
      &-no {
        .search-wrapper {
          width: 100%;
        }
      }
    }
    &__wrapper {
      .search__input::placeholder {
        color: var(--ch-text);
        font-weight: 500;
      }
    }
    &__advanced-filter {
      padding-right: 12px;
      text-wrap: nowrap;
    }
  }
  &-advanced-filters {
    padding-top: 11px;
  }
  &-no-data-text {
    color: var(--ch-text);
    margin-top: 12px;
  }
  &-quick-filters {
    margin-top: 7px;
    padding: 0 12px;
    &__text {
      color: var(--ch-text);
      font-weight: 500;
    }
    .statuses-filter__wrapper {
      padding-left: 1px;
    }
  }
  &-show-3d-toggle {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-right: 15px;
    width: 100%;
  }
  &-container {
    flex-grow: 1;
    // max-height: calc(100% - 40px);
    overflow-y: scroll;
    &__table {
      flex: 1 0;
      .sticky {
        background-color: var(--background-color);
      }
    }
  }
  .add-button__text {
    font-weight: 500;
  }
}
