.history-item {
  border-bottom: 1px solid #eee;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  &__top {
    &--title {
      &__link {
        cursor: pointer;
      }
    }
    &--changed {
      margin-top: 8px;
      &__link {
        cursor: pointer;
      }
    }
  }
  &__date {
    margin-top: 8px;
  }
  &__date-aligned {
    margin-left: auto;
    min-width: 40%;
    text-align: right;
  }
}
.history-item-aligned {
  align-items: center;
  flex-direction: row;
}
.history-item-display {
  align-items: center;
  display: flex;
  &__value {
    word-break: break-all;
  }
  &__icon {
    margin: 0 8px;
  }
}
.history-item-color-display {
  align-items: center;
  display: flex;
  &__item {
    border-radius: 50%;
    height: 20px;
    width: 20px;
  }
}
.history-item-defect-type-display {
  align-items: center;
  display: flex;
  &__item {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  &__icon {
    margin-right: 8px;
  }
}
.history-item-defect-status-display {
  align-items: center;
  display: flex;
  &__item {
    align-items: center;
    border: 1px solid var(--marker-outer);
    border-radius: 50px;
    display: flex;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: center;
    padding: 5px 8px 5px 0;
  }
  &__icon {
    margin-right: 8px;
  }
}
.history-item-component-display {
  align-items: center;
  display: flex;
  &__item {
    cursor: pointer;
    word-break: break-all;
    &.disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }
}
