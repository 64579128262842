.stream-history {
  .live-streams__wrapper {
    .table-template {
      .twp {
        .twp-header {
          border-bottom: none;
          height: unset;
        }
      }
      .table-template-no-data-text {
        .empty-state-container {
          padding-top: 70px;
          .empty-state-container__content {
            p {
              text-wrap: nowrap;
            }
          }
        }
      }
      .name-column {
        max-width: calc(100% - 80px - 180px) !important;
        min-width: calc(100% - 80px - 180px) !important;

        .flex-center {
          gap: 8px;
          padding-left: 8px;
        }
      }

      .created-at-and-time-column {
        max-width: 180px !important;
        min-width: 180px !important;
      }
      .duration-column {
        max-width: 80px !important;
        min-width: 80px !important;
      }
    }
  }
}
