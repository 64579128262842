$section-horizontal-padding: 34px;
.predictive-settings {
  display: flex;
  flex-direction: column;
  height: 100%;
  .section {
    padding: $section-horizontal-padding;
  }
  .horizontal-padding {
    padding: 0 30px;
  }
  .predictive-settings-form {
    border-bottom: 1px solid var(--box-items-separator-color);
    display: flex;
    flex-basis: 0;
    flex-direction: column;
    flex-grow: 1;
    max-height: 70%;
    .form-wrapper {
      display: flex;
      flex-basis: 0;
      flex-grow: 1;
      overflow: auto;
      .section {
        flex: 1;
      }
    }
    .select-container {
      .error-message {
        min-height: 0;
      }
    }
    .description {
      margin-bottom: 25px;
      margin-top: 16px;
    }
    .title {
      margin-top: $section-horizontal-padding;
      padding: 0 $section-horizontal-padding;
    }
    .single-radio {
      border: 0;
      border-radius: 0;
      margin-bottom: 13px;
      margin-top: 13px;
      padding: 0;
      label {
        color: var(--primary-font-color);
      }
      //big circle
      [type='radio']:checked + label::before,
      [type='radio']:not(:checked) + label::before {
        left: 0;
      }
      //end big circle

      //small circle
      [type='radio']:checked + label::after,
      [type='radio']:not(:checked) + label::after {
        left: 4px;
      }
    }
    &__date-selector {
      display: flex;
      &.visible {
        .date-wrapper {
          height: auto;
          overflow: hidden;
        }
      }
      .date-wrapper {
        align-items: center;
        display: flex;
        height: 0;
        overflow: hidden;
        transition: all 0.3s ease;
        .field-wrapper {
          margin: 0 10px;
        }
      }
    }
    .toggle-card {
      margin-bottom: 32px;
      width: 100%;
      .field-wrapper {
        margin-bottom: 13px;
      }
    }
  }
  .predictive-settings-results {
    height: 30%;
    .results-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: $section-horizontal-padding;
    }
    .results-loader {
      height: 90px;
      width: 90px;
    }
    .alarms {
      display: flex;
      flex-basis: 0;
      flex-direction: column;
      flex-grow: 1;
      flex-wrap: wrap;
      margin-top: 25px;
      overflow-y: auto;
      width: 100%;
      .alarm-status {
        align-items: center;
        display: flex;
        margin: 0 21px 21px 0;
        max-width: 350px;
        min-width: 250px;
        .status-icon {
          border-radius: 50%;
          height: 16px;
          margin-left: 0;
          margin-right: 10px;
          width: 16px;
        }
        .predicted-date {
          align-items: center;
          display: flex;
          margin-left: auto;
          .days {
            color: var(--primary-font-color);
            font-size: 14px;
          }
        }
      }
    }
  }
}
