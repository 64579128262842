@import '../../../common/styles/variables';
@import '../../../common/styles/mixins';
@import './date-range-picker-base';

.day-range-picker {
  @extend %range-picker-container;

  &__header {
    @extend %range-picker-header;
  }

  &__nav-btn {
    @extend %range-picker-nav-btn;
  }

  &__month-year-display {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__current-month-year {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    cursor: pointer;
    color: var(--primary-font-color);

    .dropdown-icon {
      margin-left: 4px;
      color: var(--secondary-theme-color);
    }
  }

  &__calendar {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__weekdays {
    @extend %range-picker-weekdays;
  }

  &__weeks {
    @extend %range-picker-weeks;
  }

  &__week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 2px;
    margin-bottom: 2px;
  }

  &__day {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s ease, color 0.2s ease;

    &:hover:not(&--disabled):not(&--selected) {
      background-color: var(--background-color);
      color: var(--secondary-theme-color);
    }

    &--other-month {
      color: var(--ui-color-secondary);
      opacity: 1;
    }

    &--today {
      position: relative;
      color: rgba(var(--secondary-theme-color-rgb), 1);
    }

    &--selected {
      background-color: var(--secondary-theme-color);
      color: white;
    }

    &--disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }

    &--future,
    &--past {
      color: var(--ui-color-secondary);
      opacity: 0.4;
    }
  }
}
