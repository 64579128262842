@import '../../../common/styles/variables';
.full-screen {
  background-color: var(--background-color);
  height: 100vh;
  .module-picker__half-width ~ .pdf-viewer-modal.fit-pdf-component-modal {
    transition: all 700ms ease;
    width: calc(100% - #{$inspection-right-toolbar});
    .modal {
      min-width: calc(100% - #{$inspection-right-toolbar}) !important;
    }
    .modal-wrapper {
      min-width: 100%;
    }
    .pdf-with-drawing {
      min-width: 100%;
      .react-transform-wrapper {
        min-width: 100%;
      }
    }
  }

  .description-column__container {
    max-width: 322px !important;
    min-width: 50px !important;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 4.5em;
    word-wrap: break-word;
  }
}
