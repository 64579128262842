.ndt-modal-content {
  display: flex;
  flex-direction: column;
  // NDT MODAL
  height: 100%;
  width: 100%;
  #google-visualization-errors-all-13 {
    // display: none !important;
  }
  .horizontal-padding {
    padding: 0 69px;
  }
  .section {
    display: flex;
    flex-direction: column;
    &--scrollable {
      flex-basis: 0;
      flex-grow: 1;
      min-height: 0;
      overflow: auto;
      padding: 0;
    }
    .load-more {
      margin: 20px auto;
    }
  }
  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 24px 69px;
    h5 {
      flex: 1;
      margin-right: 21px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .tabs {
    display: flex;
    flex: 1;
    flex-direction: column;
    &__navigation {
      border-bottom: 2px solid var(--background-color-light);
      padding: 0 69px;
    }
    &__navigation-item {
      margin-bottom: -2px;
    }
    &__content {
      flex: 1;
      .section {
        height: 100%;
      }
    }
  }
  .points-header {
    justify-content: flex-start;
    margin-bottom: 20px;
    margin-top: 20px;
    h6 {
      margin-right: 21px;
    }
  }
  .search {
    margin-right: auto;
    max-width: 400px;
  }
  .filter-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
  }
  .field-wrapper {
    .input-wrapper {
      padding-top: 5px;
    }
  }
  &__filter {
    align-items: center;
    display: flex;
    &:not(:last-child) {
      margin-right: 20px;
    }
    .field-wrapper {
      height: auto;
      height: 45px;
      margin: 0 20px;
      width: 180px;
    }
    .input-wrapper {
      &__input {
        text-align: center;
      }
    }
  }
  .chart-section {
    background-color: var(--background-color-light);
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px;
  }
  .toggle-group {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .grouping-tags {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    max-height: 300px;
    min-height: 32px;
    p {
      margin-left: 60px;
      margin-right: 15px;
    }
    &__tag {
      align-items: center;
      border: solid 1px var(--primary-font-color);
      border-radius: 14px;
      cursor: pointer;
      display: flex;
      margin: 4px 7px 4px 0;
      padding: 2px 14px;
      transition: all 0.2s ease;
      .tag-text {
        transition: all 0.2s ease;
      }
      &.not-active {
        &:hover {
          border: solid 1px var(--secondary-font-color-dark);
          .tag-text {
            color: var(--secondary-font-color-dark);
          }
        }
      }
      &.active {
        background-color: var(--secondary-theme-color);
        border: solid 1px var(--default-font-color);
        .tag-text {
          color: var(--default-font-color);
        }
      }
    }
  }
  .alarm-toggle-wrapper {
    display: flex;
    flex-wrap: wrap;
    .alarm-toggle {
      display: flex;
      margin-right: 30px;
      p {
        margin-right: 10px;
      }
    }
  }
  .chart-wrapper {
    height: 100%;
    .line-chart {
      height: 100%;
      padding: 0;
      position: relative;
      .empty-state {
        display: none;
      }
      .empty-state-active {
        align-items: center;
        bottom: 0;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 1;
      }
    }
  }
  .ndt-points-empty {
    align-items: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 14px;
  }
  .ndt-points-table {
    .files-count {
      align-items: center;
      display: flex;
      svg {
        margin-right: 6px;
      }
    }
    thead {
      tr {
        color: var(--secondary-font-color-dark);
      }
      th {
        background-color: var(--box-items-theme-color);
      }
    }
    tr {
      &.pointer {
        background-color: var(--box-items-theme-color-light);
        border-bottom: 1px solid var(--background-color-light);
        &:hover {
          background-color: rgba(var(--box-items-theme-color-light-rgb), 0.8);
        }
      }
      &.selected {
        background-color: var(--background-color-light);
        &:hover {
          background-color: var(--background-color-light);
        }
      }
      td,
      th {
        padding-bottom: 14px;
        padding-top: 14px;
        &:first-child {
          padding-left: 69px;
        }
        &:last-child {
          padding-right: 69px;
        }
      }
      .details {
        padding-bottom: 0;
      }
    }
  }
}
.ndt-modal {
  .modal-wrapper {
    padding: 0;
  }
  .modal {
    height: 95vh;
    max-height: unset;
    overflow: unset;
    width: 85vw;
  }
}
