.location-section {
  .add-location {
    margin-bottom: 10px;
    &__button {
      margin-bottom: 10px;
      .button-text {
        font-weight: 500;
      }
    }
    &__warning-wrapper {
      background: rgba(var(--severity-orange-rgb), 0.1);
      border: 1px solid var(--severity-orange);
      border-radius: 4px;
      margin-bottom: 12px;
      padding: 16px 12px;
      .missing-location {
        align-items: center;
        display: flex;
        margin-bottom: 3px;
        &__icon {
          margin-right: 4px;
        }
        &__text {
          margin-left: 4px;
        }
      }
    }
  }
  .adding-location,
  .editing-location {
    &__icon {
      margin-bottom: 8px;
      width: 20px;
      height: 20px;
    }
    &__text {
      margin-bottom: 32px;
      text-align: center;
      font-size: 13px;
    }
  }
  .info-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .location-list {
    .location-list-item {
      border-bottom: 1px solid var(--box-items-separator-color);
      display: flex;
      padding: 12px 0;
      &__coordinates {
        flex: 1;
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &__actions {
        .icon {
          cursor: pointer;
          &:not(:last-child) {
            margin-right: 4px;
          }
        }
      }
    }
  }

  .camera-container {
    .set-camera {
      &__icon {
        margin-right: 4px !important;
      }
    }
  }
}
