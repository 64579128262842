.ch-table-container {
  margin-top: 1.25rem;
  max-height: calc(90% - 24px);
  overflow-y: scroll;
  &::-webkit-scrollbar-thumb {
    background: var(--secondary-theme-color);
    -webkit-border-radius: 100px;
    border-radius: 100px;
  }
  &__table {
    flex: 1 0;
    .sticky {
      background-color: var(--background-color);
    }
    .icon-column,
    .action-menu-column {
      min-width: 16px;
    }
    .code-column {
      min-width: calc(10% - 16px);
    }
  }
  .code-column {
    color: var(--primary-font-color);
    font-size: 14px;
    font-weight: 400;
    max-width: 200px !important;
    min-width: 50px !important;
  }
  .name-column,
  .owner-column,
  .cost-centre-column,
  .maintenance-id-column,
  .location-column {
    color: var(--primary-font-color);
    font-size: 14px;
    font-weight: 400;
    max-width: calc((100% - 64px - 200px) / 5) !important;
    min-width: calc((100% - 64px - 200px) / 5) !important;
  }
  .icon-column,
  .action-menu-column {
    align-items: center;
    justify-content: center;
    max-width: 32px !important;
    min-width: 32px !important;
  }
}
