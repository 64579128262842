.date-range-picker-form {
  width: 438px;
  display: flex;
  flex-direction: column;
  &__content {
    flex-grow: 1;
    flex-shrink: 0;
  }
  &__buttons {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 82px;
    .btn {
      height: 52px;
      max-width: 120px !important;
      min-width: 120px !important;
      padding: 0px;
    }
    button {
      &:nth-child(1) {
        margin-top: 0px;
        background-color: var(--secondary-theme-color);
        border: none;
      }
    }
  }
}
