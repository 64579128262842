>span {
  bottom: -40px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 3;
  .prev,
  .next {
    line-height: 2;
    position: relative;
  }
  .next {
    margin-right: 20px;
  }
  .arrow-left,
  .arrow-right {
    display: flex;
    width: 50%;
    p {
      cursor: pointer;
    }
   
  }
  .arrow-left {
    justify-content: flex-end;
    padding-right: 50px;
    position: relative;
    .count {
      position: absolute;
      right: -20px;
      top: 5px;
    }
  }
  .arrow-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 50px;
  }
  .prev::before,
  .next::before {
    border-color: transparent var(--primary-font-color) transparent transparent;
    border-style: solid;
    border-width: 5px 8px 5px 0;
    content: '\A';
    position: absolute;
    top: 10px;
  }
  .prev::before {
    left: -15px;
  }
  .next::before {
    right: -15px;
    transform: rotate(180deg);
  }
}
