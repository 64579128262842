.alarms-edit-modal {
  .alarms-edit-modal__picker {
    padding: 20px 24px 24px;
    .btn {
      .button-text {
        font-weight: 400 !important;
      }
    }
  }
}
.alarms-tab-expandable-section {
  .header__wrapper {
    padding-bottom: 20px !important;
  }
  .section-content {
    border-bottom: 0;
    overflow-y: visible;
    padding-left: 20px;
    padding-top: 0;
  }
  .section-content--visible {
    max-height: none !important;
  }
  .empty-state-container__content {
    .btn {
      min-width: 132px !important;
    }
  }
}
