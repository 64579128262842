@import '../../../common/styles/variables';
.point-images {
  height: 100vh;
  .padding-horizontal {
    padding: 0 20px;
  }
  .margin-horizontal {
    margin: 0 13px;
  }
  .quick-button-1 {
    margin-left: auto;
    p {
      cursor: pointer;
      font-size: 13px;
    }
    &.disabled {
      opacity: 0.7;
      * {
        cursor: not-allowed;
      }
    }
  }
  .quick-button-2 {
    position: absolute;
    right: 18px;
    top: 73px;
    svg {
      width: 12px;
    }
  }
  .custom-toolbar {
    margin-bottom: 12px;
  }
  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &__title {
    display: flex;
    margin-top: 12px;
  }
  &__slider {
    margin-top: 6px;
    .defect-message {
      background-color: white;
      border-radius: 10px;
      left: 50%;
      padding: 0 10px;
      position: absolute;
      top: 20px;
      transform: translate(-50%, -50%);
    }
  }
  &__dropdown {
    .field-wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      .inline-wrapper {
        padding-right: 30px;
        label {
          font-size: 12px;
        }
      }
      .error-message {
        display: none;
      }
      .select-container {
        min-width: 100px;
      }
      .input-wrapper {
        padding: 0;
      }
    }
  }
  &__section {
    flex: 1;
    margin: 6px 0 0;
    overflow-y: scroll;
    padding: 0 calc(20px - #{$scrollbar-size-default}) 0 20px;
  }
  &__start-workflow {
    padding: 10px 13px;
  }
  .input-range__label--max {
    transform: translateX(-50%);
  }
  .input-range__label--min {
    transform: translateX(50%);
  }
}
