.header-container {
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  padding: 30px 20px;
  &.with-blue-background {
    background: rgba(201, 233, 222, 0.8);
  }
  .inline {
    align-items: center;
    display: flex;
    .display-id {
      margin-left: 4px;
    }
  }
  .header-actions {
    margin-left: auto;
  }
}
