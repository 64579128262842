.defect-form {
  .defect-type-select {
    .rw-dropdown-list-value {
      color: var(--primary-font-color);
    }
    .rw-picker-caret {
      svg {
        path {
          fill: var(--primary-font-color);
        }
      }
    }
  }
  .comments-container {
    .expandable-section {
      .header {
        &__wrapper {
          padding: 16px 0;
        }
      }
      .section-content {
        padding: 12px 0;
        &--hidden {
          padding: 0;
        }
      }
    }
  }
  .warning-indicator {
    margin-bottom: 32px;
  }
  .source-section {
    .section-content {
      &--visible {
        padding: 0;
      }
    }
  }
  .work-orders-section {
    .section-content {
      &--visible {
        padding: 0;
      }
    }
    .notification-WO-tab__table {
      height: auto;
      max-height: 400px;
      .table-template-container {
        padding-bottom: 20px !important;
      }
    }
  }
  .link-components-modal {
    &__picker {
      max-height: 800px;
      min-width: 710px;
    }
  }
  .critical-tooltip {
    .info-popover {
      min-height: 0;
      width: 158px;
    }
  }
}
