@import '../../../common/styles/variables';
.items-table-renderer {
  position: relative;
  &.loading {
    pointer-events: none;
    .loader {
      align-items: center;
      bottom: 0;
      display: flex;
      height: 80%;
      justify-content: center;
      position: sticky;
      width: 100%;
      z-index: 1;
    }
    table {
      filter: blur(8px);
    }
  }
  table {
    margin: 1px;
    width: 99%;
    thead th,
    thead td {
      min-width: auto;
      white-space: nowrap;
      &.sticky {
        background-color: var(--background-color);
        margin-top: 10px;
        outline: thin solid var(--background-color);
      }
    }
    .max-width {
      width: 100%;
    }
    tbody {
      &::before {
        content: '';
        display: block;
        height: 2px;
      }
      tr {
        border-radius: 4px;
        td {
          padding: 10px 5px !important;
        }
        &.selected,
        &:hover {
          background-color: unset;
          border-color: var(--secondary-theme-color-dark);
          outline: thin solid var(--secondary-theme-color-dark);
          position: relative;
          position: relative;
          &::after {
            background-color: var(--secondary-theme-color-dark);
            color: white;
            content: '';
            display: block;
            height: 100%;
            left: 0;
            opacity: 0.1;
            pointer-events: none;
            position: absolute;
            text-align: center;
            top: 0;
            width: 100%;
            z-index: 0;
          }
        }
      }
    }
  }
  .load-more {
    cursor: pointer;
    display: inline-block;
    left: 50%;
    margin: 10px 0;
    position: relative;
    transform: translateX(-50%);
    white-space: nowrap;
    &.disabled {
      cursor: not-allowed;
    }
  }
  .empty-state {
    margin: 100px auto;
    text-align: center;
  }
  .inline-left {
    > * {
      display: inline-block;
      vertical-align: middle;
      word-wrap: break-word;
    }
    p,
    span {
      max-width: 80%;
    }
  }
  .inline-right {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }
  .ellipsis {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .date-column {
    white-space: nowrap;
  }
}
