.spot-statistics {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 100%;

  font-size: 14px;
  color: var(--ch-text);

  &__title {
    font-weight: 500;
  }

  &__info {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  &__xyz {
    display: flex;
    flex-direction: row;
    gap: 15px;
  }
}
