.work-orders-section {
  .component-item-header {
    .component-header {
      align-items: center;
      border-bottom: 1px solid var(--box-items-separator-color);
      display: flex;
      padding: 20px 0;
      .component-header-title {
        font-weight: 400;
      }
      .component-header-toggle {
        cursor: pointer;
        height: 20px;
        margin-left: auto;
        width: 20px;
        path {
          stroke: var(--secondary-font-color-dark);
        }
      }
    }
  }
  .work-order-item-container {
    .work-order-item {
      padding: 16px 0;
    }
  }
}
