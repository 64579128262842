@import '../../../common/styles/variables';
.right-toolbar {
  box-shadow: 0 0 8px -6px var(--default-font-color);
  height: 100%;
  overflow-y: auto;
  .space-bottom {
    margin-bottom: 10px;
  }
  .defect-details {
    &__title {
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 600;
      letter-spacing: normal;
      line-height: normal;
      margin-bottom: 14px;
      padding: 35px 19px 0 27px;
      text-transform: uppercase;
    }
    .workflow-external {
      .image-with-drawing {
        height: 155px;
      }
    }
    .tabs {
      .header-container {
        margin: 0 !important;
        padding: 10px 20px 4px;
      }
    }
  }
  .content {
    height: 100%;
    overflow-x: hidden;
    .padding {
      padding: 35px 19px 0 27px;
    }
  }
  .input-wrapper {
    .rw-widget-input.rw-widget-picker.rw-widget-container {
      background-color: transparent;
      border: 0;
      border-bottom: 1px solid var(--box-items-separator-color);
      border-radius: 0;
      box-shadow: none;
      .rw-btn-select {
        svg {
          fill: var(--primary-font-color);
        }
      }
    }
    &.enhanced-design-custom-prop {
      .rw-widget-input.rw-widget-picker.rw-widget-container {
        background-color: var(--input-background-color);
        border: 1px solid var(--input-border-color);
        border-radius: 4px;
        color: var(--primary-font-color);
        font-family: $primary-font-family;
        font-size: 14px;
        font-weight: normal;
        outline: none;
        padding: 9px 12px 9px calc(16px - 0.857em);
        padding-bottom: 9px;
        transition: 0.2s all;
        width: 100%;
        .rw-btn-select {
          svg {
            fill: var(--primary-font-color);
          }
        }
      }
    }
    &.enhanced-design-custom-prop {
      .rw-dropdown-list {
        .rw-dropdown-list-value {
          font-weight: 500;
          p.f-primary {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }
        }
      }
    }
    .rw-input {
      color: var(--primary-font-color);
      padding: 0;
    }
    // date picker
    // .react-datepicker-wrapper {
    //   display: block;
    //   input {
    //     background-color: transparent;
    //     border: 0;
    //     border-bottom: 1px solid var(--box-items-separator-color);
    //     border-radius: 0;
    //     box-shadow: none;
    //     color: var(--primary-font-color);
    //     padding: 0;
    //   }
    // }
  }
  .missing-pin {
    align-items: center;
    display: flex;
    &__icon {
      margin-right: 8px;
    }
    &__text {
      margin-left: 8px;
    }
  }
  .details-sections {
    .section-content--visible {
      max-height: 300vh;
    }
  }
  .create-permit-form {
    .rw-widget-input.rw-widget-picker.rw-widget-container {
      border: 1px solid var(--input-border-color) !important;
      border-radius: 4px;
    }
  }
}
.defect-form {
  overflow-y: auto;
  &__fields {
    padding: 0 20px;
  }
  &__save-cta-wrapper {
    bottom: 0;
    box-shadow: 0 1px 0 0 #eee inset;
    margin-top: auto;
    padding: 20px 16px 20px 20px;
    position: sticky;
    width: 100%;
    z-index: 2;
    .btn {
      width: 100%;
    }
  }
  .observations-details-section {
    &__select-option {
      display: flex;
      align-items: center;
      gap: 4px;
      color: var(--primary-font-color);
    }
    &__select-options {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 8px;
      cursor: pointer;
    }
    .section-content--visible {
      max-height: none;
      padding: 0;
    }
    .change-status-container {
      padding: 0;
    }
    .rw-widget-input.rw-widget-picker.rw-widget-container {
      background-color: var(--input-background-color);
      border: 1px solid var(--input-border-color);
      border-radius: 4px;
    }
    .text-area {
      height: 104px;
    }
    .upload-image {
      margin: 0;
    }
  }
  .files-section {
    .section-content--visible {
      max-height: none;
    }
  }
  .expandable-section-info {
    padding: 20px;
    background-color: var(--work-tab-table-bg);

    &.empty-state-section {
      background-color: transparent;
      .empty-state-container {
        background-color: transparent;
      }
    }

    &__no-data {
      color: var(--comments-date);
      text-align: center;
    }
    &__container {
      &__id {
        width: 90px;
        max-width: 90px;
        display: flex;
        align-items: center;
        word-break: break-word;

        .id {
          display: inline-block;
          max-width: 80px;
          min-width: 80px;
          overflow: hidden;
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &__name {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 190px;
        gap: 8px;

        .name_container {
          display: flex;
          gap: 2px;
          align-items: center;
          .name {
            display: -webkit-box;
            max-height: 40px;
            max-width: 140px;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            white-space: normal;
            word-break: break-all;
          }

          svg {
            width: 100%;
            max-width: 20px;
            max-height: 20px;
            display: flex;
            align-self: center;
          }
        }

        .info-tooltip {
          display: flex;
          align-items: center;

          svg {
            width: 100%;
            max-width: 20px;
            max-height: 20px;
          }
        }
      }
      &__row {
        display: flex;
        padding: 12px;
        &.header {
          color: var(--comments-date);
          font-weight: 700;
        }
        &.data {
          background-color: var(--work-tab-row-bg);
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
  }
  .comment-text {
    word-break: break-word;
  }
  .ob-notifications {
    margin-bottom: 20px;
    span {
      margin-right: 5px;
    }
    .empty-state {
      left: 50%;
      padding: 0;
      /* margin: auto; */
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
    .items-renderer__container {
      flex: unset;
      height: 150px;
      position: relative;
    }
  }
  .header-wrapper {
    padding: 0 20px 10px;
  }
  .section-content {
    margin-bottom: 10px;
  }
  .defect-status {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin: 20px auto;
    position: relative;
    p {
      margin-left: 10px;
    }
    .error-message {
      display: none;
    }
    .input-wrapper {
      padding-top: 0;
      .rw-widget-input.rw-widget-picker.rw-widget-container {
        border-bottom: unset;
      }
    }
    .field-wrapper {
      // margin-left: 20px;
      width: 100%;
    }
    .rw-widget-input {
      padding: 0 15px 0 30px;
    }
    .def-icon {
      left: 10px;
      position: absolute;
    }
  }
  .upload-image {
    cursor: pointer;
    display: flex;
    margin: 10px 0 0 auto;
    &:hover {
      .upload-image__text {
        text-decoration: underline;
      }
    }
    &__icon {
      margin-right: 16px;
    }
    &__text {
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
    }
    &__input {
      display: none;
    }
  }
  .component-assets {
    margin-bottom: 16px;
  }
  .slider-edit-drawing {
    margin-left: auto;
  }
  .add-property {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    &.disabled {
      opacity: 0.5;
      .upload-image {
        cursor: not-allowed;
        &__text {
          text-decoration: none;
        }
      }
    }
  }
  &__container {
    padding: 0 19px 0 27px;
  }
  &__label {
    &::first-letter {
      text-transform: capitalize;
    }
  }
  .inline-wrapper {
    align-items: center;
    display: flex;
    &__left-content {
      flex: 1;
    }
    &__right-content {
      margin-left: 10px;
    }
  }
  &__footage {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    .filename {
      align-items: center;
      display: inline-flex;
      margin-right: auto;
      padding-bottom: 5px;
      a {
        color: var(--primary-font-color);
      }
      img {
        height: 25px;
        margin-right: 10px;
        width: 25px;
      }
    }
  }
  .comments-container {
    margin-bottom: 20px;
    .horizontal-padding {
      padding-left: 27px;
      padding-right: 20px;
    }
    .add-comment {
      background: var(--box-items-theme-color);
      margin-top: 8px;
      padding-bottom: 29px;
      padding-top: 10px;
      position: relative;
      &.hidden {
        display: none;
        visibility: hidden;
      }
      textarea {
        background: transparent;
        border: 0;
        font-family: $primary-font-family;
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        outline: none;
        resize: none;
        width: 100%;
      }
      &__button-container {
        bottom: 8px;
        height: 21px;
        position: absolute;
        right: 20px;
      }
      &__button {
        background-color: var(--primary-font-color);
        border: 0;
        border-radius: 14.5px;
        color: var(--background-color);
        cursor: pointer;
        height: 100%;
        outline: none;
        width: 74px;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .comment-list {
      margin-top: 8px;
      &__row {
        background-color: var(--primary-theme-light-color);
        padding-bottom: 8px;
        padding-top: 8px;
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
      &__item {
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
      .author-text {
        font-size: 12px;
        font-weight: bold;
      }
      .comment-date {
        font-size: 12px;
        text-align: right;
      }
    }
  }
  .buttons {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0 19px 20px 27px;
  }
  .missing-pin {
    margin-bottom: 10px;
  }
  .add-pin {
    margin-bottom: 50px;
    &__description {
      margin-bottom: 20px;
    }
    &__button {
      margin-bottom: 10px;
    }
  }
}
.points-container {
  margin: 16px 0;
  &.hidden-field {
    display: none;
    visibility: hidden;
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    * {
      pointer-events: none;
    }
  }
  .point-list {
    margin-top: 7px;
    &__row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      .header-item {
        text-align: center;
      }
    }
    &__item {
      width: 25%;
    }
    &__point-name {
      background-color: transparent;
      border: 0;
      font-family: $primary-font-family;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      outline: none;
      padding: 8px 0;
    }
    &__point-location {
      background-color: transparent;
      border: 0;
      font-family: $primary-font-family;
      font-size: 12px;
      font-style: normal;
      font-weight: normal;
      outline: none;
      padding: 8px 0;
      text-align: center;
    }
  }
  .camera-container {
    display: flex;
    &.right-align {
      justify-content: flex-end;
    }
    &.left-align {
      justify-content: flex-start;
    }
    > p {
      margin-right: auto;
    }
    .set-camera {
      align-items: center;
      cursor: pointer;
      display: flex;
      &:hover {
        .set-camera__text {
          text-decoration: underline;
        }
      }
      &__icon {
        margin-right: 6px;
      }
      &__text {
        font-size: 13px;
        font-weight: 500;
      }
    }
  }
}
.input-range {
  height: 30px;
  margin-bottom: calc(16px + 1rem);
  margin-top: 0.8rem;
  &__label {
    &--value {
      transform: translateX(-50%);
    }
    &--min,
    &--max {
      bottom: -1rem;
    }
    &--min {
      transform: translateX(-50%);
    }
    &--max {
      transform: translateX(50%);
    }
  }
  &__label-container {
    font-family: $primary-font-family;
    font-size: 12px;
    font-weight: normal;
    left: 0;
  }
  &__track {
    background: #b3b3b3;
    &--active {
      background: var(--secondary-theme-color);
    }
  }
  &__slider {
    background: #fff;
    border: solid 2px var(--secondary-theme-color);
  }
}

.equipment-info-tooltip__wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  label {
    font-weight: 400;
  }
  p {
    margin-top: 4px;
  }
}

.equipment-info-tooltip {
  .info-popover {
    z-index: 3;
  }
}

.flex-center {
  display: flex;
  align-items: center;
}
