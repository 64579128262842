.checklist-details {
  display: flex;
  flex-direction: column;
  max-height: 600px;
  overflow: auto;
  padding: 0 20px;
  width: 400px;
  .question {
    padding: 10px;
    &:nth-child(2n+1) {
      background-color: var(--primary-theme-light-color);
    }
    &:last-of-type {
      margin-bottom: 20px;
    }
  }
  .question-type {
    margin-left: 13px;
    margin-top: 10px;
  }
  .empty-state {
    display: inline-block;
    margin: 20px auto;
  }
}
