.icon-legend-wrapper {
  height: 11px;
  min-height: 11px;
  min-width: 11px;
  width: 11px;
  .icon-legend-square {
    background-color: var(--severity-green);
    // border: 1px solid var(--primary-font-color);
    border-radius: 2px;
    height: 100%;
    width: 100%;
    &.yellow {
      background-color: var(--severity-yellow);
    }
    &.orange {
      background-color: var(--severity-orange);
  
    }
    &.red {
      background-color: var(--severity-red);
    }
  }
}
