.assign-components {
  display: flex;
  flex-direction: row;
  height: 100%;
  &__left-side {
    border-right: 1px solid var(--box-items-separator-color);
    height: 100%;
    width: 30%;
    &__unassigned-comp {
      height: calc(95% - 101px);
      padding: 0 8px 0 1.25rem;
      width: 100%;
      .twp {
        min-width: 30%;
      }
      .code-column {
        color: var(--primary-font-color);
        font-size: 14px;
        font-weight: 400;
        max-width: 30% !important;
        min-width: 30% !important;
        padding-left: 8px;
      }
      .name-column {
        color: var(--primary-font-color);
        font-size: 14px;
        font-weight: 400;
        max-width: calc(70% - 36px) !important;
        min-width: calc(70% - 36px) !important;
      }
      .action-menu-column {
        align-items: center;
        justify-content: center;
        max-width: 36px !important;
        min-width: 36px !important;
        &__button {
          background-color: inherit;
          border: 0;
          cursor: pointer;
        }
      }
      &__table {
        height: calc(100% - 100px);
        width: 100%;
        .search {
          padding: 9px 0 !important;
        }
      }
      &__title {
        height: 60px;
        h5 {
          margin: 2rem 0;
          .icon-column,
          .action-menu-column {
            min-width: 16px;
          }
          .code-column {
            min-width: calc(10% - 16px);
          }
        }
      }
    }
  }
  &__right-side {
    transition: all 0.7s ease;
    width: 70%;
    &.toolbar-opened {
      width: calc(70% - 360px);
    }
    &__title {
      align-items: center;
      border-bottom: 1px solid var(--box-items-separator-color);
      display: flex;
      height: 101px;
      padding: 0 20px;
    }
    &__path {
      margin-top: 1.5rem;
      padding: 0 0 0 1.25rem;
      &.hide {
        display: none;
      }
      &.show {
        display: block;
      }
    }
    &__body {
      height: calc(100% - 101px - 20px);
      padding: 20px 6px 0 1.25rem;
      &.has-path {
        height: calc(90% - 101px - 20px);
      }
      .search {
        padding: 9px 0;
      }
      .icon-column,
      .action-menu-column {
        min-width: 16px;
      }
      .code-column {
        min-width: calc(10% - 16px);
      }
    }
    .code-column {
      color: var(--primary-font-color);
      font-size: 14px;
      font-weight: 400;
      max-width: 200px !important;
      min-width: 50px !important;
    }
    .name-column,
    .owner-column,
    .cost-centre-column,
    .maintenance-id-column,
    .location-column {
      color: var(--primary-font-color);
      font-size: 14px;
      font-weight: 400;
      max-width: calc((100% - 68px - 200px) / 5) !important;
      min-width: calc((100% - 68px - 200px) / 5) !important;
    }
    .icon-column,
    .action-menu-column {
      align-items: center;
      justify-content: center;
      max-width: 36px !important;
      min-width: 36px !important;
      &__button {
        background-color: inherit;
        border: 0;
        cursor: pointer;
      }
    }
  }
  &__name {
    align-items: center;
    display: flex;
    gap: 8px;
    width: 100%;
    &__status-icon {
      border-radius: 50%;
      height: 16px;
      width: 16px;
    }
    &__text {
      width: calc(100% - 13px - 8px);
    }
  }
}
