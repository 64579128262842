.measurement-point-details {
  .tabs__navigation.component-details__tabs {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
  }
  .header-container {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .header__wrapper {
    padding: 16px 20px;
  }
  .tabs__navigation-wrap {
    justify-content: space-between;
    margin-top: 12px;
    .small-dropdown__settings {
      margin-right: 19px;
    }
  }
  .search-wrapper {
    .search-input-stripped__icon {
      margin-right: 4px;
    }
    svg {
      height: 20px;
      width: 20px;
      g {
        stroke: var(--ui-color-primary);
        stroke-width: 1.5;
        path {
          stroke: var(--ui-color-primary);
        }
      }
    }
  }
}
.confirmation-modal {
  .modal-wrapper {
    padding: 20px 24px;
    .modal__content {
      font-weight: 400 !important;
      margin-bottom: 0;
      margin-right: auto;
      max-width: 500px;
      padding-top: 16px;
    }
    .first-paragraph {
      margin-top: 16px;
    }
    .btn {
      .button-text {
        font-weight: 400;
        text-transform: capitalize;
      }
    }
  }
}
