.defect-components-picker-modal {
  padding-bottom: 40px;
  width: 100%;
  .input-with-dropdown {
    height: 20%;
    width: 100%;
  }
  @media screen and (min-height: 1329px) {
    padding-bottom: 20px;
  }
  .defect-components-picker-footer {
    height: 30%;
    padding-top: 20px;
    .action-title {
      cursor: pointer;
      text-align: right;
    }
    .action-buttons-container {
      align-items: center;
      display: flex;
      padding-top: 40px;
      .save-btn {
        margin-left: auto;
      }
    }
  }
}
