.instructions-screen {
  height: 50vh;
  overflow-y: auto;
  width: 50vw;
  .tabs {
    &__navigation {
      border-bottom: 1px solid var(--box-items-separator-color);
    }
    &__content {
      margin: 28px 0;
    }
  }
}
