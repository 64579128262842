.tagging-tools {
  padding: 24px;
  .buttons {
    button {
      margin-top: 10px;
    }
  }
  .tags {
    margin-top: 20px;
    min-height: 140px;
    overflow: auto;
    .table-template {
      max-height: 300px;
    }
    .single-tag {
      align-items: center;
      border-bottom: 1px solid var(--box-items-separator-color);
      cursor: pointer;
      display: flex;
      margin-bottom: 5px;
      padding: 0 0 5px 5px;
      transition: 0.2s ease-in-out;
      &.selected {
        border-bottom: 1px solid var(--secondary-theme-color);
      }
      &:hover {
        border-bottom: 1px solid var(--secondary-theme-color);
      }
      .delete {
        margin-left: auto;
      }
    }
    .component-tags-table {
      overflow-x: hidden;
      .twp {
        min-width: auto;
      }
      .twp-body__row .twp-body__row__cells,
      .twp-header.sticky {
        justify-content: space-between;
      }
      .twp-body__row__cells__cell.icon-column {
        max-width: 20%;
      }
      .twp-body__row__cells__cell.created-at-column {
        justify-content: flex-start;
        max-width: 15%;
      }
    }
  }
}
