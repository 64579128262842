.alarm-form-field {
  .split-input {
    width: 50%;
  }
  .without-padding {
    padding: 0;
  }
  .sublabel {
    color: var(--secondary-font-color-dark);
  }
  &__color-tag {
    height: 100%;
    display: flex;
    flex-direction: column;
    .colors-wrapper {
      flex: 1;
      margin-bottom: 25px;
    }
  }
}
