@import '../../../common/styles/variables';
.profiles-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  padding-left: #{$nav-width};
  h3 {
    padding-bottom: 150px;
    padding-top: 20px;
  }
  h5 {
    padding-bottom: 5%;
  }
  
}
