@import '../../../common/styles/variables';

$title-height: 20px;
$header-slider-height: 422px;
.inspection-container {
  display: flex;
  flex: 1;
  overflow-x: hidden;
  position: relative;
  .upload-from-inspection-modal {
    .label-before-image {
      display: block;
      max-width: 260px;
    }
  }
  .progress {
    background-color: rgba(var(--background-color-rgb), 0.4);
    border: 1px solid var(--secondary-theme-color-dark);
    border-radius: 12px;
    bottom: 15px;
    box-shadow: #a1a1a1 0 2px 2px inset;
    height: 20px;
    pointer-events: none;
    position: absolute;
    right: 50%;
    text-align: center;
    transform: translateX(50%);
    width: 200px;
    &__bar {
      background-image: linear-gradient(to bottom, var(--secondary-theme-color), var(--secondary-theme-color-dark));
      border-radius: 12px;
      position: absolute;
    }
    &__text {
      position: absolute;
      // color: white;
      text-transform: capitalize;
      width: 100%;
    }
  }
  .notification-status {
    border-radius: 4px;
    min-width: 45px;
    padding: 4px 5px;
    text-align: center;
    &--red {
      // CLOSED
      background-color: var(--validation-error-color);
    }
    &--orange {
      // PUBLISHED
      background-color: var(--warning-color);
    }
    &--green {
      // NEW
      background-color: var(--secondary-theme-color-dark);
    }
    &--gray {
      // IN PROCESS
      background-color: var(--box-items-theme-color-light);
    }
    &--blue {
      // COMPLETED
      background-color: var(--status-blue-color);
    }
    &--yellow {
      // RELEASED
      background-color: var(--severity-yellow);
    }
  }
  .ndt-modal {
    .modal-wrapper {
      padding: 0;
    }
    .modal {
      height: 95vh;
      max-height: unset;
      overflow: unset;
      width: 85vw;
    }
  }
  > .ndt-alarms-modal {
    .modal {
      height: 85vh;
      max-height: unset;
      overflow: unset;
    }
  }
}
.inspection {
  flex: 1;
  height: 100vh;
  position: relative;
  #potree_render_area {
    &.inspect-cursor {
      cursor: auto;
      cursor: url('../potree/assets/inspect-tool.cur') 10 10, auto;
    }
  }
  .top-toolbox,
  .bottom-toolbox {
    left: unset;
    right: 20px;
    transform: unset;
    z-index: 2;
  }
  .top-toolbox {
    top: 20px;
  }
  .bottom-toolbox {
    bottom: 20px;
  }
  &.workflow {
    background-color: var(--box-items-theme-color);
    border-radius: 4px 4px 0 0;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    flex: 1;
    height: auto;
    overflow: hidden;
    .progress {
      top: calc(#{$workflow-header-height} + 15px);
    }
    .screen-toolbox {
      bottom: unset;
      left: unset;
      right: 15px;
      top: calc(#{$workflow-header-height} + 15px);
      transform: unset;
      z-index: 2;
    }
    .settings-container {
      background-color: var(--background-color);
      border-radius: 8px;
      bottom: 15px;
      display: block;
      left: 15px;
      max-width: 50%;
      overflow-x: hidden;
      position: absolute;
      top: calc(#{$workflow-header-height} + 15px);
      z-index: 2;
      &--hidden {
        display: none;
        visibility: hidden;
      }
    }
    #potree_render_area {
      top: $workflow-header-height;
    }
  }
}
.workflow {
  &__header {
    align-items: center;
    display: flex;
    height: 34px;
    justify-content: space-between;
    margin: 0 12px;
    .camera-action {
      cursor: pointer;
      transition: 0.2s ease-in-out;
      &:hover {
        opacity: 0.7;
      }
    }
    .separator {
      margin: 0 10px;
    }
  }
  &__camera-actions {
    align-items: center;
    display: flex;
  }
  &__toolbar {
    &.toolbar-container {
      background-color: var(--box-items-theme-color);
      &__bottom {
        &--hidden {
          bottom: -$workflow-defects-box-height;
        }
      }
    }
    .defects-loader-w {
      height: $workflow-defects-box-height;
      .defects-loader {
        align-items: center;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: 0;
        margin: auto !important;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
        p {
          margin: -15px;
        }
      }
    }
  }
}
.inspection-container.workflow {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 50% 50%;
  height: calc(100vh - #{$title-height});
  max-height: calc(100vh - #{$title-height});
  > div {
    height: 100%;
  }
  .resizable-box {
    > div {
      height: 100% !important;
    }
    .start-workflow {
      position: relative;
      // .content {
      //   max-height: calc(100vh - #{$title-height} - #{$header-slider-height});
      // }
    }
  }
  .single-resizer {
    bottom: 0;
    left: unset !important;
    right: 0;
    top: 0;
    z-index: unset;
  }
}
.inspection-container.module-picker__half-width {
  width: 100%;
  .pdf-viewer-modal.fit-pdf-component-modal {
    transition: all 700ms ease;
    width: calc(100% - #{$inspection-right-toolbar});
    .modal {
      min-width: calc(100% - #{$inspection-right-toolbar}) !important;
    }
    .modal-wrapper {
      min-width: 100%;
    }
    .pdf-with-drawing {
      min-width: 100%;
      .react-transform-wrapper {
        min-width: 100%;
      }
    }
  }
}
