.integrations {
  display: flex;
  justify-content: center;
  &__wrapper {
    width: 341px;
  }
  &__title {
    margin-top: 30px;
    text-align: center;
  }
  &__list-container {
    margin-top: 100px;
  }
  &__form-container {
    margin-bottom: 30px;
    margin-top: 37px;
  }
  .error-placeholder {
    color: var(--validation-error-color);
    display: flex;
    font-size: 12px;
    justify-content: center;
    min-height: 20px;
    padding: 0 10px;
    width: 100%;
  }
}
