.thumbnail-assets {
  margin-bottom: 25px;
  .label-container {
    align-items: center;
    border-bottom: 1px solid var(--box-items-separator-color);
    display: flex;
    padding-bottom: 9px;
    &__label {
      margin-right: auto;
    }
    &__upload {
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
      &.disabled {
        cursor: not-allowed;
        opacity: 0.7;
      }
    }
    .mr-space {
      margin-right: 5px;
    }
  }
  .empty-container {
    margin-top: 10px;
  }
  .files {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr 1fr;
    .file-container {
      align-items: center;
      display: flex;
      position: relative;
      .image-container {
        align-items: center;
        display: flex;
        height: 100px;
        justify-content: center;
        width: 100%;
        label {
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
          border: 3px solid var(--primary-font-color);
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  // override image list
  .image-list {
    margin-top: 16px;
    .image-with-drawing {
      &:hover {
        opacity: 0.7;
      }
    }
    .small-dropdown__settings .dropdown {
      min-width: 90px;
    }
  }
  .small-dropdown {
    &__settings {
      position: absolute;
      right: 4px;
      top: 4px;
      width: 10px;
    }
    &__settings-icon {
      background: var(--secondary-theme-color);
      height: 4px;
      width: 4px;
      &::before,
      &::after {
        background: var(--secondary-theme-color);
        height: 4px;
        width: 4px;
      }
    }
  }
}
