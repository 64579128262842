.add-assignees-container,
.add-work-order-alerts,
.add-work-order-permits,
.add-work-order-cp-templates,
.add-work-areas-container {
  .work-area-add-btn {
    height: 60px;
    width: 100%;
    .button-text {
      font-size: 14px;
      font-weight: 700;
    }
  }
  .work-area-color {
    border-radius: 50%;
    height: 16px;
    margin-right: 4px;
    width: 16px;
  }
  .input-with-dropdown {
    .items-dropdown {
      padding: 0 20px;
      .item {
        padding: 8px 0 !important;
      }
      .dropdown-render-item-container.selected {
        background-color: var(--box-items-separator-color);
        .user-title {
          background-color: var(--background-color);
        }
      }
      .dropdown-render-item-container {
        align-items: center;
        cursor: pointer;
        display: flex;
        .user-title {
          font-size: 12px;
          width: 100%;
        }
        .module-name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 230px;
        }
        .user-checkbox-wrapper {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          width: 50%;
          .field-wrapper {
            margin-right: 8px;
            .input-wrapper {
              padding-top: 0;
              .checkbox-wrapper {
                input {
                  height: 20px;
                  width: 20px;
                }
              }
              .error-message {
                display: none;
              }
            }
          }
        }
        .user-email-wrapper {
          margin-left: auto;
          width: 50%;
        }
      }
    }
    .search-input-label {
      color: var(--secondary-font-color-light);
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
    .search {
      background-color: var(--input-background-color);
      border: 1px solid var(--input-border-color);
      margin-top: 4px;
      padding: 15px 14px;
      .items-dropdown {
        top: 52px;
        width: 100%;
      }
      input::placeholder {
        color: var(--secondary-font-color-dark);
      }
      &__icon {
        fill: var(--secondary-font-color-dark);
        g,
        path {
          stroke: var(--secondary-font-color-dark);
        }
      }
    }
  }
  .added-items-list {
    .item-r {
      align-items: center;
      justify-content: initial;
      .info-tooltip {
        margin-left: 5px;
        margin-top: 3px;
      }
      .link_component {
        align-items: center;
        cursor: pointer;
        display: flex;
        margin-left: auto;
        margin-right: 16px;
        &:hover {
          .link_component__text {
            text-decoration: underline;
          }
        }
        &__icon {
          margin-right: 4px;
        }
      }
      .icon-close {
        margin-left: auto;
      }
    }
  }
  .checklist-component-picker {
    padding-left: 30px;
  }
  .interval-section {
    .interval-section-number {
      input {
        background-color: var(--input-background-color);
        border: 1px solid var(--input-border-color);
        border-radius: 4px;
      }
    }
    .select-d {
      padding-left: 0;
    }
  }
}
.add-work-order-permits,
.add-work-order-cp-templates {
  width: 100%;
  .added-items {
    margin-top: 20px;
    .item-r {
      border-bottom: 1px solid var(--box-items-separator-color);
      display: flex;
      padding: 12px 0;
    }
  }
}
.add-work-order-cp-templates {
  &__close-icon {
    margin-left: 0 !important;
  }
}
