.isolation-certificates {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 35px);
  .empty-state {
    height: 38vh;
    margin: auto;
  }
  .advanced-filter {
    align-items: center;
    display: flex;
    justify-content: space-between;
    .search-small {
      background-color: transparent;
      &__input::placeholder {
        color: var(--secondary-theme-color);
      }
    }
  }
  .status-filters {
    padding: 25px 0 15px;
    .status-filters-title {
      margin-bottom: 10px;
    }
  }
  .table-container {
    height: calc(100vh - 310px);
    overflow: auto;
    .id-column {
      min-width: 45px;
    }
    .status-column {
      min-width: 150px;
    }
    .permits-popover {
      align-items: center;
      display: flex;
      p {
        margin-right: 4px;
      }
    }
  }
}
