.back-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-bottom: 8px;
  &__text {
    font-size: 14px;
    line-height: 20px;
    color: var(--ch-text) !important;
  }
  svg {
    transform: rotate(180deg);
    path {
      stroke: var(--ch-text);
    }
  }
}
