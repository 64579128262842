@import '../../styles//variables';
.modal-container {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 200;
  &.dms-component-picker-pdf-3d {
    .search-wrapper {
      .items-dropdown {
        width: 100%;
      }
    }
  }
  // rejection note modal styles
  &.rejection-note-modal {
    .modal-wrapper {
      max-width: 710px;
      min-width: 700px;
    }
    .modal {
      align-items: flex-start;
      &__content {
        color: #999;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 32px;
        max-width: 470px;
        text-align: left;
      }
      .add-comment-wrapper {
        width: 100%;
        .comment-label {
          color: #000;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
        .input-wrapper {
          &__input {
            height: 156px;
          }
        }
      }
      &__button-container {
        justify-content: space-between;
        width: 100%;
      }
      .btn {
        display: flex;
        height: 52px;
        min-width: auto;
        padding: 16px 32px;
      }
    }
  }
  // When modal is minimized
  &.minimized {
    bottom: 17px;
    height: auto;
    left: unset;
    right: 380px;
    top: unset;
    width: auto;
    .modal-minimizable {
      &__header {
        justify-content: flex-start;
        min-height: 30px;
        .center-content {
          padding: 0 150px 0 50px;
        }
      }
    }
  }
  &.interactable-backdrop {
    background-color: transparent;
    pointer-events: none;
    .modal-wrapper {
      pointer-events: auto;
    }
  }
  .modal-wrapper {
    background-color: var(--modal-color);
    border-radius: 4px;
    padding: 40px 47.5px;
    position: relative;
    border-radius: 8px;
    &__title {
      font-weight: 500;
      position: absolute;
      top: 20px;
    }
    &.no-padding {
      padding: 0;
    }
    .modal-action-items {
      display: flex;
      position: absolute;
      right: 20px;
      top: 24px;
      &__action-item {
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 4px;
        }
        &:last-child {
          margin-left: 12px;
        }
      }
    }
    .close-modal {
      z-index: 1;
      color: var(--default-font-color);
      font-weight: 900;
    }
    .back {
      color: var(--default-font-color);
      cursor: pointer;
      left: 20px;
      position: absolute;
      top: 20px;
    }
  }
  &.dark {
    .modal-wrapper {
      background-color: var(--box-items-theme-color);
      .close-modal {
        color: var(--primary-font-color);
      }
      .back {
        color: var(--primary-font-color);
      }
    }
  }

  // MINIMIZABLE MODAL STYLE
  .modal-minimizable {
    padding: 0;
    .modal {
      min-width: 350px;
    }
    .action-item {
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
    .modal-action-items {
      position: unset;
      right: unset;
      top: unset;
    }
    .minimize-icon-wrapper {
      align-self: flex-end;
      cursor: pointer;
      height: 15px;
      margin-right: 10px;
      width: 15px;
    }
    .minimize-icon {
      align-self: flex-end;
    }
    &__header {
      align-items: center;
      border-radius: 4px 4px 0 0;
      box-shadow: 0 4px 9px rgba(0, 0, 0, 0.15);
      display: flex;
      justify-content: center;
      max-width: 71vw;
      min-height: 47px;
      position: relative;
      z-index: 1;
      .left-content {
        align-items: center;
        display: flex;
        height: 100%;
        left: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        &__title {
          margin-left: 15px;
        }
      }
      .center-content {
        padding: 0 150px;
        width: 100%;
      }
      .right-content {
        align-items: center;
        display: flex;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  &.generic-modal {
    z-index: 201;
  }
}
.modal-with-absolute-title {
  margin-top: 35px;
}
.modal {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  overflow: auto;
  &__footer {
    padding-right: 7px;
  }
  &__button-container {
    display: flex;
    justify-content: center;
    &.yes-no,
    &.no-yes,
    &.cancel-confirm {
      flex-direction: column;
      button:nth-child(2) {
        margin-top: 10px;
      }
    }
  }
  &__title {
    font-weight: 500;
    margin-bottom: 14px;
  }
  &__content {
    margin-bottom: 24px;
  }
  &__button {
    padding: 9px 134px;
  }
}

.modal-large {
  .modal {
    max-width: 90vw;
  }
}
.modal-medium {
  .modal {
    max-width: 600px;
  }
}
.modal-small {
  .modal {
    max-width: 350px;
  }
}
.modal-no-max-height {
  .modal {
    max-height: unset;
  }
}
.modal-no-height {
  .modal {
    height: unset;
  }
}
.separate-page {
  display: flex;
  flex-direction: column;
  .action-item {
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
  .modal-action-items {
    position: unset;
    right: unset;
    top: unset;
  }
  &__header {
    align-items: center;
    background-color: var(--box-items-theme-color);
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    min-height: 47px;
    position: relative;
    z-index: 1;
    .left-content {
      align-items: center;
      display: flex;
      height: 100%;
      left: 20px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      svg {
        fill: var(--primary-font-color);
      }
      &__title {
        margin-left: 15px;
      }
    }
    .center-content {
      padding: 0 150px;
      width: 100%;
    }
    .right-content {
      align-items: center;
      display: flex;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

// revamped delete modal styles - apply as custom class
.modal-delete {
  .modal-wrapper {
    padding: 20px 24px;
    min-width: 493px;
    max-width: 493px;
  }
  .modal__title,
  .modal__content {
    align-self: flex-start;
  }
  .modal__button-container {
    flex-direction: column-reverse; /* Specific to modal-delete */
    gap: 10px;
    width: 100%;
    .button-text {
      font-weight: 500;
    }
  }
  button {
    height: 52px !important;
  }
}

.modal-revamped {
  .modal-wrapper {
    padding: 20px 24px;
    min-width: 493px;
    max-width: 493px;
  }
  .modal__title,
  .modal__content {
    align-self: flex-start;
  }
  .modal__button-container {
    flex-direction: column;
    gap: 10px;
    width: 100%;
    .button-text {
      font-weight: 500;
    }
  }
  button {
    height: 52px !important;
  }
}
