.selected-measurement-points-table-view {
  display: flex;
  flex-direction: column;
  height: 100%;

  .selected-measurement-points-table-view__content {
    flex: 1;

    &__title {
      padding-bottom: 10px;
    }

    .table-template {
      height: calc(75vh - 60px);
      .twp {
        min-width: 0;

        .twp-header {
          border-bottom: none;
          height: unset;
        }
      }

      .table-template-container {
        overflow-x: hidden;
      }

      .table-template-search {
        .search-wrapper {
          .search {
            padding-left: 0;
          }

          .search-input-stripped {
            padding-left: 0px;
            &__icon {
              margin-right: 4px;
            }
            .search-wrapper__input {
              svg {
                g {
                  stroke: var(--ui-color-primary);
                  path {
                    stroke: var(--ui-color-primary);
                  }
                }
              }
            }
          }
        }
      }

      .linked-mp-id-column {
        align-items: center;
        justify-content: flex-start;
        align-self: flex-start;
        max-width: 60px !important;
        min-width: 60px !important;
      }

      .linked-mp-name-column {
        align-items: center;
        justify-content: flex-start;
        max-width: calc(100% - 60px - 30px) !important;
        min-width: calc(100% - 60px - 30px) !important;
        .linked-measurement-point-name-wrapper {
          display: flex;
          flex-direction: column;
          width: 95%;
          &__name {
            display: flex;
            flex-direction: row;
            gap: 4px;
            align-items: center;
            p {
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          &__breadcrumb {
            padding-top: 4px;
            p {
              color: var(--ch-text);
              font-weight: 400;
            }
          }
        }
        .circle {
          border-radius: 50%;
          height: 12px;
          min-height: 12px;
          min-width: 12px;
          width: 12px;
        }
      }

      .linked-mp-id-column-action-column {
        max-width: 30px !important;
        min-width: 30px !important;
      }
    }

    .empty-state-container {
      height: 200px;
      &__content {
        &__text {
          width: 100%;
        }
      }
    }
  }

  .close-action {
    .btn {
      .button-text {
        font-weight: 500;
      }
    }
  }
}
