$split-list-height: 100px;
.create-work-order-modal {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-height: 500px;
  min-width: 800px;
  overflow: auto;
  .component-list {
    .items-table-renderer {
      table {
        min-width: auto;
      }
    }
  }
  .step-title {
    margin-top: 20px;
  }
  .double {
    display: flex;
    .split-input:nth-child(1) {
      padding-right: 10px;
    }
    .split-input:nth-child(2) {
      padding-left: 10px;
    }
  }
  .step-desc {
    margin: 20px 0;
  }
  .b-top {
    border-top: 1px solid var(--separator-color);
    padding-top: 20px;
  }
  .pointer {
    cursor: pointer;
  }
  .work-order-form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    padding-top: 32px;
    .field-wrapper {
      .input-wrapper {
        input,
        textarea {
          background-color: var(--input-background-color) !important;
          border: 1px solid var(--input-border-color) !important;
          border-radius: 4px;
          height: 52px !important;
        }
      }
    }
    &__content {
      flex-grow: 1;
      overflow: auto;
    }
    .select-d {
      padding-right: 15px;
    }
    .upload-handler {
      align-items: center;
      border-bottom: 1px solid var(--separator-color);
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
    }
    .items-dropdown {
      z-index: 1;
      .item {
        padding: 0;
        p {
          padding: 4px 8px;
        }
        .disabled {
          background-color: var(--ui-color-secondary);
          border-radius: 4px;
          color: #fff;
        }
      }
    }
    .upload-checklist {
      .title {
        font-weight: 500;
        margin-bottom: 4px;
      }
      .added-items-list {
        height: $split-list-height;
      }
      .empty-state {
        margin-top: 0 !important;
      }
    }
    .added-items-list,
    .added-items {
      margin-top: 20px;
    }
    .added-items-list {
      max-height: 350px;
      overflow: auto;
      &.checklists {
        height: $split-list-height;
        .empty-state {
          margin-top: 20px;
        }
      }
      .empty-state {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .add-files {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 10px;
        > p {
          margin-right: 10px;
        }
      }
    }
    .added-items {
      margin-bottom: 32px;
      width: 100%;
    }
    .image-thumbnail {
      align-items: center;
      display: flex;
      max-width: 100%;
      overflow-x: auto;
      width: 100%;
      &.added-items-list {
        height: 120px;
      }
      .empty-state {
        flex: 1;
      }
      .file-container {
        border-bottom: 0;
        flex: 0 0 15%;
        margin-bottom: 10px;
        padding-bottom: 0;
        position: relative;
        .image-container {
          align-items: center;
          cursor: pointer;
          display: flex;
          height: 100px;
          justify-content: center;
          width: 100%;
          &:hover {
            opacity: 0.8;
          }
          label {
            background-color: black;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            border: 3px solid var(--primary-font-color);
            cursor: pointer;
            height: 100%;
            width: 100%;
          }
        }
        .small-dropdown {
          &__settings {
            position: absolute;
            right: 4px;
            top: 4px;
            width: 30px;
          }
          &__settings-icon {
            background: var(--secondary-theme-color);
            height: 4px;
            width: 4px;
            &::before,
            &::after {
              background: var(--secondary-theme-color);
              height: 4px;
              width: 4px;
            }
          }
        }
      }
    }
    .file-container-with-border {
      border-bottom: 1px solid var(--separator-color);
    }
    .file-container {
      align-items: center;
      display: flex;
      margin-bottom: 10px;
      margin-right: 10px;
      padding-bottom: 10px;
      position: relative;
      .image-container {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: 30px;
        justify-content: center;
        margin-right: 7px;
        width: 30px;
        &__file-image {
          height: 28px;
          width: 28px;
        }
        .filename {
          cursor: pointer;
        }
      }
      .filename {
        flex: 1;
        margin-right: 7px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .item-r {
      align-items: center;
      border-bottom: 1px solid var(--separator-color);
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      margin-right: 10px;
      padding-bottom: 10px;
    }
    .left-side {
      align-items: center;
      display: flex;
      svg {
        cursor: pointer;
        &.disabled {
          path {
            fill: var(--separator-color);
          }
        }
      }
      .up {
        margin-left: 15px;
        transform: rotate(-90deg);
      }
      .down {
        margin: 0 15px;
        transform: rotate(90deg);
      }
      .c-title {
        min-width: 20px;
      }
    }
    .right-side {
      align-items: center;
      display: flex;
      svg {
        margin-left: 10px;
      }
    }
    .input-range {
      &__label {
        &--min {
          transform: translateX(0);
        }
        &--max {
          transform: translateX(0);
        }
      }
    }
    .grouped-input {
      align-items: center;
      display: grid;
      grid-column-gap: 20px;
      grid-template-columns: 1fr 1fr 1fr;
      &.interval-section {
        grid-template-columns: 150px 150px 150px;
        margin-top: 20px;
      }
    }
    .email-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 15px;
      &.notifications {
        margin-bottom: 20px;
        margin-top: 0;
      }
      .email {
        align-items: center;
        background-color: var(--separator-color);
        display: flex;
        justify-content: space-between;
        margin: 5px;
        padding: 10px 20px;
        svg {
          margin-left: 10px;
        }
      }
    }
    .notification-section {
      margin-top: 20px;
    }
  }
  .buttons {
    align-items: center;
    display: flex;
    padding-top: 40px;
    .right-buttons {
      align-items: center;
      display: flex;
      margin-left: auto;
      .button-submit {
        margin-left: 20px;
        &.disabled {
          cursor: not-allowed;
          opacity: 0.6;
        }
      }
    }
  }
  .ghost-field {
    display: none;
  }
  .x-hover:hover {
    path {
      fill: var(--severity-red);
    }
  }
  .added-items-list {
    .item-r {
      align-items: center;
      display: flex;
      justify-content: initial;
      .info-tooltip {
        margin-left: 5px;
        margin-top: 3px;
      }
    }
  }
  .permit-workorders {
    margin-bottom: 32px;
    .items-table-renderer {
      table {
        min-width: auto;
        padding: 20px;
      }
      #permit-work-order_listbox {
        padding: 20px;
      }
    }
    .add-work-order-permits {
      margin: 1px;
      width: 99%;
      thead th,
      thead td {
        min-width: auto;
        white-space: nowrap;
        &.sticky {
          background-color: var(--background-color);
          margin-top: 10px;
          outline: thin solid var(--background-color);
        }
      }
      .input-with-dropdown {
        .items-dropdown {
          overflow-y: auto;
          padding: 2px;
          z-index: 9;
          .item {
            padding: 16px !important;
            position: relative;
            &::before {
              content: '';
              display: block;
              height: 2px;
            }
            &:hover {
              background-color: unset;
              cursor: pointer;
              &::after {
                background-color: var(--secondary-theme-color-dark);
                color: white;
                content: '';
                display: block;
                height: 100%;
                left: 0;
                opacity: 0.1;
                pointer-events: none;
                position: absolute;
                text-align: center;
                top: 0;
                width: 100%;
                z-index: 0;
              }
            }
          }
          .item:first-child {
            &::after {
              height: 55%;
              top: 45%;
            }
            .module-name {
              margin-top: 8px;
            }
          }
          .item:last-child {
            &::after {
              bottom: 65%;
              height: 35%;
            }
            .module-name {
              margin-bottom: 8px;
            }
          }
          .item:only-child {
            &::after {
              height: 25%;
              top: 22%;
            }
          }
          .dropdown-render-item-container.selected {
            background-color: unset;
            cursor: pointer;
            &::before {
              content: '';
              display: block;
              height: 2px;
            }
            &::after {
              background-color: var(--secondary-theme-color-dark);
              color: white;
              content: '';
              display: block;
              height: 100%;
              left: 0;
              opacity: 0.1;
              pointer-events: none;
              position: absolute;
              text-align: center;
              top: 0;
              width: 100%;
              z-index: 0;
            }
            .heading-title {
              background-color: var(--background-color);
            }
          }
          .item:first-child {
            .dropdown-render-item-container.selected {
              &::after {
                height: 55%;
                top: 45%;
              }
            }
          }
          .item:last-child {
            .dropdown-render-item-container.selected {
              &::after {
                bottom: 65%;
                height: 35%;
              }
            }
          }
          .item:only-child {
            .dropdown-render-item-container.selected {
              &::after {
                height: 25%;
                top: 22%;
              }
            }
          }
          .dropdown-render-item-container {
            align-items: center;
            cursor: pointer;
            display: flex;
            .heading-title {
              color: var(--grey-background-icon);
              font-size: 12px;
              font-weight: 700;
              line-height: 20px;
              margin-bottom: 8px;
              width: 100%;
              &:hover {
                height: 20px;
              }
            }
            .module-name {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 230px;
            }
            .workorder-wrapper {
              align-items: center;
              display: flex;
              flex-wrap: wrap;
              width: 25%;
              .field-wrapper {
                margin-right: 8px;
                .input-wrapper {
                  padding-top: 0;
                  .checkbox-wrapper {
                    input {
                      height: 20px;
                      width: 20px;
                    }
                  }
                  .error-message {
                    display: none;
                  }
                }
              }
              &.workorder-id {
                width: 15%;
              }
              &.workorder-name {
                width: 35%;
                .module-name {
                  align-items: center;
                  display: flex;
                  span {
                    margin-right: 8px;
                  }
                }
              }
            }
            .user-email-wrapper {
              margin-left: auto;
              width: 50%;
            }
          }
        }
        .search-input-label {
          color: var(--secondary-font-color-light);
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
        .search {
          background-color: var(--input-background-color);
          border: 1px solid var(--input-border-color);
          margin-top: 4px;
          padding: 15px 14px;
          .items-dropdown {
            top: 52px;
            width: 100%;
          }
          input::placeholder {
            color: var(--secondary-font-color-dark);
          }
          &__icon {
            fill: var(--secondary-font-color-dark);
            g,
            path {
              stroke: var(--secondary-font-color-dark);
            }
          }
        }
      }
      thead {
        padding: 20px;
        th {
          color: var(--grey-background-icon);
          font-size: 12px;
          font-weight: 700;
          line-height: 20px;
        }
      }
      tbody {
        tr {
          border-radius: 4px;
          td {
            padding: 10px 5px !important;
          }
        }
        .row-data {
          color: var(--primary-font-color);
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          margin: 20px;
        }
      }
      .permit-workorder-popover {
        .field-value {
          color: var(--primary-font-color);
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          &.title {
            font-weight: 700;
            margin-bottom: 12px;
          }
        }
        .field-label {
          color: var(--grey-background-icon);
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          margin-bottom: 4px;
        }
      }
    }
    .workorder-name {
      align-items: center;
      color: var(--primary-font-color);
      display: flex;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      .permits-popover {
        align-items: center;
        display: flex;
      }
    }
  }
  .component-files-modal {
    height: auto;
    width: 100%;
  }
}
