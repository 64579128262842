.upload-button {
  cursor: pointer;
  display: flex;
  margin: 10px 0 0 auto;
  &:hover {
    .upload-image__text {
      text-decoration: underline;
    }
  }
  &__icon {
    margin-right: 6px;
  }
  &__text {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
  }
  &__input {
    display: none;
  }
}
