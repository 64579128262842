.twp {
  display: flex;
  flex-direction: column;
  min-width: 800px;
  width: 100%;
  .scroll-up {
    animation: scrollUp 0.5s ease-in-out;
  }
  .sticky-row-wrapper {
    background: #f8fbfa;
    border-bottom: 1px solid #eee;
    border-radius: 4px;
    padding-bottom: 8px;
    position: sticky;
    top: 36px;
    z-index: 1;
    .sticky-row {
      background: #f8fbfa;
      border: 1px solid #b5d8c8;
      border-radius: 4px;
    }
  }
  // TODO: tweak it so it starts from the actual row position (row.getBoundingClientRect().top)
  @keyframes scrollUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
}
