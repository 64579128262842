.member-form {
  .added-items {
    margin-top: 20px;
  }
  .added-items-list {
    height: 150px;
    margin: 10px 0;
    overflow: auto;
    .item-r {
      align-items: center;
      border-bottom: 1px solid var(--separator-color);
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      margin-right: 10px;
      padding-bottom: 10px;
    }
    .x-hover:hover {
      path {
        fill: var(--severity-red);
      }
    }
  }
  .items-dropdown {
    max-height: 100px;
    width: 100%;
    .empty-state {
      padding: 20px 0;
      text-align: center;
    }
    p {
      &.disabled {
        background-color: var(--separator-color);
      }
    }
    .item {
      cursor: pointer;
    }
  }
}
