.terms-and-conditions {
  padding: 0 12px;
  .p1,
  ul,
  ol,
  .space {
    padding-bottom: 20px;
  }
  .underline {
    text-decoration: underline;
  }
  ul,
  ol {
    text-indent: 20px;
  }
  ul.dashed,
  ol {
    list-style-type: none;
  }
  ul.dashed > li::before {
    color: black;
    content: '-';
    margin-left: -10px;
    margin-right: 10px;

  }
  .with-cildren {
    list-style-type: none;
    padding-left: 20px;
  }
  .with-cildren-and-dot {
    padding-left: 20px;
  }
  table {
    border: 1px solid gray;
    tr {
      border-bottom: 1px solid gray;
    }
    td {
      border-right: 1px solid gray;
      padding: 5px;
    }
  }
  a {
    color: -webkit-link;
  }
}
