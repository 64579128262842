.custom-toggle {
  // TODO: move the below colors to variables
  .react-switch-handle {
    background: #fff !important;
    border: 1px solid #58b895 !important;
    height: 18.5px !important;
    top: 1px !important;
    width: 18.5px !important;
  }
  .react-switch-bg {
    background: #58b895 !important;
    border: 1px solid #58b895;
  }
  &__info-icon {
    .info-popover {
      min-height: unset;
    }
  }
}
.unchecked {
  .react-switch-handle {
    background: #fff !important;
    border: 1px solid #b3b3b3 !important;
    height: 18.5px !important;
    left: 1px;
    top: 1px !important;
    width: 18.5px !important;
  }
  .react-switch-bg {
    background: #b3b3b3 !important;
    border: 1px solid #b3b3b3;
  }
}
