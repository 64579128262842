.dropdown {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 15%);
  min-width: 100px;
  opacity: 0;
  position: fixed;
  z-index: 1;
  p {
    color: var(--primary-font-color);
  }
  &--visible {
    opacity: 1;
  }
  &--hidden {
    visibility: hidden;
  }
}
