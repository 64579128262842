.version-modal {
  max-height: 68vh;
  max-width: 40rem;
  min-height: 68vh;
  min-width: 40rem;
  &__file-details-container {
    display: flex;
    flex-direction: column;
    &__title {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
    &__file-details {
      display: flex;
      .data-title {
        align-items: center;
        color: var(--dms-grey-section-title);
        display: flex;
        font-size: 14px;
        font-weight: 500;
        gap: 4px;
        justify-content: flex-start;
        line-height: 20px;
      }
      &__name {
        width: 50%;
        .name-container {
          align-items: center;
          display: flex;
          gap: 8px;
        }
        .file-name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: calc(100% - 32px);
        }
        &.edit-mode {
          width: 70%;
        }
      }
      &__version {
        width: 25%;
        &.edit-mode {
          width: 30%;
        }
      }
      &__active {
        width: 25%;
        .error-message {
          min-height: 0 !important;
        }
      }
    }
  }
  &__file-metadata-section {
    &__title {
      font-size: 16px;
      line-height: 24px;
    }
    &__row {
      display: flex;
      gap: 20px;
      width: 100%;
      &__field {
        width: 50%;
      }
    }
  }
  &__button-section {
    align-items: center;
    display: flex;
    justify-content: space-between;
    .modal-button-custom {
      max-width: 120px;
      min-width: 120px;
    }
  }
  &__description_field {
    max-height: 104px !important;
    min-height: 104px !important;
  }
  .checkbox-wrapper input {
    cursor: pointer;
    &:checked {
      appearance: auto;
      border: 1.5px solid #58b895;
      filter: hue-rotate(240deg);
    }
  }
}
