.add-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  &.disabled {
    cursor: none;
    pointer-events: none;
  }
  &__text {
    font-weight: 700;
    margin-left: 5px;
  }
  &__icon {
    border: 2px solid var(--secondary-theme-color);
    border-radius: 50%;
    circle {
      fill: transparent !important;
    }
    path {
      stroke: var(--secondary-theme-color);
    }
  }
  &__icon-disabled {
    border: 2px solid var(--secondary-font-color-dark);
    cursor: none;
    pointer-events: none;
    path {
      stroke: var(--secondary-font-color-dark);
    }
  }
}
