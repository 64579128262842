.work-order-section-components {
  overflow-x: auto;
  width: 100%;
  .component-item {
    align-items: center;
    display: flex;
    padding: 8px 0;
    .component-name {
      margin-right: 5px;
    }
    .info-tooltip {
      height: 20px;
      width: 20px;
    }
  }
  .critical-tooltip {
    .info-popover {
      min-height: 0;
      width: 158px;
    }
  }
}
