.custom-legend {
  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 10px;
  }
  &__item {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin: 0 10px;
    min-height: 25px;
    .circle {
      border-radius: 50%;
      height: 12px;
      min-height: 12px;
      min-width: 12px;
      width: 12px;
    }
    &-text {
      color: var(--primary-font-color);
      margin-left: 4px;
      max-width: 300px;
      &:hover {
        opacity: 0.6;
      }
    }
    .info-tooltip {
      margin-left: 4px;
      .info-popover {
        z-index: 2;
      }
    }
    &.disabled {
      .circle {
        opacity: 0.5;
      }
      .custom-legend__item-text {
        color: var(--secondary-font-color-dark);
        text-decoration: line-through;
      }
    }
  }
  .custom-tooltip {
    &__series {
      align-items: center;
      display: flex;
      margin-bottom: 10px;
      &.disabled {
        color: var(--secondary-font-color-dark);
        text-decoration: line-through;
      }
    }
  }
}
