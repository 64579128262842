@import '../../../common/styles/variables';
.section {
  margin-top: 10px;
  .expandable-section {
    .section-content--visible {
      max-height: 100%;
      overflow: initial;
    }
  }
  .question-add-button {
    margin-left: 30px;
  }
  .pagination-navigator {
    padding-left: 30px;
  }
  .question-actions-sticked {
    margin-top: 0;
  }
  &__container-expanded {
    padding-bottom: 20px;
  }
  &__container {
    align-items: center;
    display: flex;
    position: relative;
    width: 100%;
    .field-wrapper {
      width: 100%;
    }
    &__locked {
      align-items: center;
      border-bottom: 1px solid var(--box-items-separator-color);
      display: flex;
      padding: 16px 0;
      width: 100%;
      &__expandable-icon {
        margin-left: auto;
        path {
          stroke: var(--grey-background-icon);
        }
      }
      &__icon {
        cursor: pointer;
        margin-left: 4px;
      }
    }
    &__actions {
      align-items: center;
      display: flex;
      left: 100%;
      padding-left: 8px;
      position: absolute;
      top: 36px;
      &__expandable-icon {
        margin-right: 8px;
        path {
          stroke: var(--grey-background-icon);
        }
      }
      &__close-icon {
        margin-left: 10px;
      }
    }
    &__actions-locked-section {
      bottom: 16px;
    }
    &__actions-unlocked-sticked {
      margin-top: 30px;
    }
    &__actions-sticked {
      position: initial;
    }
    &__questions-actions {
      top: 35px;
    }
  }
  &__clickable {
    cursor: pointer;
  }
  &__disabled {
    cursor: not-allowed;
    pointer-events: none;
    path {
      stroke-opacity: 0.5;
    }
  }
  .expanded-section {
    .header {
      background-color: transparent;
      box-shadow: none;
      &__wrapper {
        display: block;
        padding: 0;
      }
    }
    .section-content {
      border-bottom: 0;
      padding: 0;
    }
  }
  .change-hierarchy-modal {
    height: unset;
    width: unset;
  }
}
.editable-fields-container {
  &__main-inputs {
    width: 100%;
    .error-message--visible {
      padding: 0;
    }
  }
  &__wrapper {
    align-items: center;
    display: flex;
    position: relative;
    &__question-number {
      font-weight: 700;
      margin-right: 20px;
    }
  }
  &__checkbox-wrapper {
    align-items: center;
    display: flex;
    padding-left: 28px;
    .question-field {
      .input-wrapper {
        .checkbox-wrapper {
          label {
            font-weight: 400;
          }
        }
      }
    }
  }
  &__description-field {
    padding-left: 28px;
    padding-right: 89px;
  }
}
.delete-confirm-modal {
  .modal-wrapper {
    max-width: 450px;
  }
}
.additional-fields-container {
  justify-content: center;
  margin-bottom: 32px;
  padding-top: 12px;
  &__title-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    p {
      font-weight: 500;
    }
    svg {
      path {
        stroke: var(--secondary-theme-color);
      }
    }
  }
  &__content {
    border-bottom: 1px solid var(--box-items-separator-color);
    margin-left: 28px;
    padding-bottom: 32px;
    padding-top: 8px;
    &__picker-wrapper {
      align-items: flex-start;
      display: flex;
      margin-bottom: 32px;
      .field-wrapper {
        .input-wrapper__input {
          height: 38px;
        }
      }
    }
    &__files {
      padding-top: 20px;
      .files-list {
        padding-right: 0;
      }
    }
  }
  .bottom-spacing {
    margin-bottom: 16px;
  }
}
.static-fields-container {
  padding-top: 20px;
}
.files-list {
  padding-right: 56px;
  &__subtitle {
    margin-top: 10px;
  }
  &__item-with-border {
    border-bottom: 1px solid var(--box-items-separator-color);
  }
  &__item {
    align-items: center;
    display: flex;
    padding: 12px 0;
    &__delete-icon {
      cursor: pointer;
      margin-left: auto;
    }
    &__title {
      align-items: center;
      display: flex;
      &__img,
      &__file-icon {
        cursor: pointer;
        height: 28px;
        margin-right: 8px;
        width: 28px;
      }
      .c-title {
        cursor: pointer;
      }
    }
  }
  &__button {
    margin-top: 10px;
    .button-text {
      color: var(--secondary-theme-color);
    }
  }
}
