@import '../../../common//styles//variables';
.upload-container,
.guest-message-container {
  align-items: center;
  background-color: var(--background-color);
  bottom: -$upload-bar-height;
  color: white;
  display: flex;
  flex-wrap: wrap;
  height: $upload-bar-height;
  padding-left: 25%;
  padding-right: 20px;
  position: absolute;
  right: 0;
  width: 100%;
  &.exclude-padding {
    padding-left: calc(#{$nav-width} + 20px);
  }
  .title {
    align-items: center;
    display: flex;
    svg {
      margin-right: 10px;
    }
    .text {
      display: flex;
      .progress {
        min-width: 40px;
      }
    }
  }
  .smaller {
    font-size: 12px;
    padding-left: 30px;
  }
  .upload-line {
    margin-left: 10px;
    min-width: 300px;
  }
  .upload-finished {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
.guest-message-container {
  padding-left: calc(#{$nav-width} + 20px);
}
