$header-height: 103px;
.mfa {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100% - #{$header-height});
  z-index: 0;
  .mfa-container {
    width: 600px;
    &.authenticator {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      .qr-code {
        max-width: 150px;
      }
      > * {
        margin-bottom: 20px;
      }
      .short {
        max-width: 60%;
      }
      .buttons {
        button:nth-child(1) {
          margin-bottom: 10px;
        }
      }
    }
    &.email {
      display: flex;
      justify-content: center;
    }
    .separator {
      background-color: var(--separator-color);
      height: 2px;
      width: 48%;
    }
  }
}

