.module-header {
  border-bottom: 1px solid var(--box-items-separator-color);
  margin: 0 0 20px 0;
  padding: 0 0 25px 0;
  .module-header-actions {
    align-items: center;
    display: flex;
    margin-left: auto;
    button {
      margin-right: 8px;
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    .small-dropdown__settings {
      width: 10px;
    }
  }
  .back-button {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    justify-content: flex-start;
    margin-bottom: 5px;
    svg {
      margin-right: 8px;
      path {
        stroke: var(--secondary-theme-color);
      }
    }
  }
  .text {
    align-items: center;
    display: flex;
    svg {
      margin-right: 5px;
    }
  }
}
