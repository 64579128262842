.image-filter {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  .filters {
    display: flex;
    padding: 20px 0;
    label {
      padding: 0 20px 0 2px;
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
  .change-view {
    align-items: center;
    display: flex;
    svg {
      margin: 0 10px;
      path {
        fill: var(--filter-blocked);
      }
      &.active {
        path {
          fill: var(--filter-color);
        }
      }
    }
  }
}
