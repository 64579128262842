// Common base styles for all date range pickers
@import '../../../common/styles/variables';
@import '../../../common/styles/mixins';

// Common styles for all range pickers
%range-picker-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--background-color);
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  border-radius: 8px;
  box-shadow: 0px 12px 16px -4px rgba(194, 204, 225, 0.08), 0px 4px 6px -2px rgba(194, 204, 225, 0.03);
  font-family: var(--primary-font-family);
}

// Header styles shared by all pickers
%range-picker-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  color: #262626;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

// Navigation button styles
%range-picker-nav-btn {
  cursor: pointer;
  color: var(--secondary-theme-color);
  align-items: center;
  background: #fff;
  border: 1px solid #d0d0d0;
  border-radius: 50%;
  display: flex;
  height: 36px;
  justify-content: center;
  padding: 8px;
  transition: background-color 0.2s ease;
  width: 36px;

  &.previous {
    transform: rotate(-180deg);
  }

  &:hover:not([disabled]) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

// Common grid layout
%range-picker-grid {
  display: grid;
  gap: 2px;
  margin-top: 12px;
}

// Common button styles
%range-picker-item-btn {
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  background-color: var(--background-color);
  color: var(--primary-font-color);
  transition: background-color 0.2s ease, color 0.2s ease;
  font-family: var(--primary-font-family);
  outline: none;
  line-height: 24px;
  justify-content: center;

  &:hover:not([disabled]):not(.selected) {
    color: var(--secondary-theme-color);
  }

  &.selected,
  &--selected {
    background-color: var(--secondary-theme-color) !important;
    color: #fff !important;
  }

  &--disabled,
  &[disabled] {
    color: #999;
    opacity: 0.5;
    cursor: not-allowed;
  }

  &--navigation {
    // Optional styles for when in navigation mode
    &:hover:not([disabled]) {
      background-color: var(--ui-color-secondary);
    }
  }
}

// Year/month display with dropdown
%range-picker-display {
  color: #262626;
  font-size: 16px;
  font-style: normal;
  line-height: 24px; /* 150% */
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  gap: 5px;
  padding: 4px 8px;
  transition: background-color 0.2s ease;

  .icon {
    color: var(--secondary-theme-color);
    height: 12px;
    margin-left: 4px;
    width: 12px;
  }
}

// Calendar weekdays header
%range-picker-weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  margin-bottom: 8px;
  font-weight: 500;
  color: var(--ui-color-secondary);
}

// Calendar weeks container
%range-picker-weeks {
  display: flex;
  flex-direction: column;
  gap: 0;
}
