.mp-observations-tab {
  height: 90.5vh;

  .twp {
    min-width: 0;
  }
  .table-template-search {
    height: 60px !important;
  }
  .table-template-no-data-text {
    padding: 12px;
  }
  .table-template-container {
    background-color: var(--work-tab-table-bg);
    height: 100%;
    overflow-y: auto;
    padding: 0 12px;
    .twp-header {
      border: 0;
      &.sticky {
        height: 28px;
        margin-top: 12px;
      }
    }
    &::-webkit-scrollbar {
      width: 4px;
    }
    .twp-body__row {
      background-color: var(--work-tab-row-bg);
      margin-top: 8px;
    }
    .load-more__results,
    .sticky {
      background-color: var(--work-tab-table-bg);
    }
    .icon-column {
      align-items: center;
      justify-content: flex-end;
      max-width: 32px !important;
      min-width: 32px !important;
    }
    .severity-column {
      align-items: center;
      justify-content: center;
      max-width: 32px !important;
      min-width: 32px !important;
    }
    .name-column {
      &__observations {
        max-width: calc(100% - 32px - 32px - 130px) !important;
        min-width: calc(100% - 32px - 32px - 130px) !important;
      }
    }
    .created-at-column {
      max-width: 130px !important;
      min-width: 130px !important;
    }
    .created-at-column,
    .severity-column {
      align-items: center;
      justify-content: flex-end;
      padding-right: 12px;
    }
    .severity-column {
      padding-right: 0;
    }
    .created-at-column {
      padding-right: 12px;
      padding-left: 8px;
      justify-content: flex-start;
    }
  }
}
