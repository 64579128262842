.time-series-graph-modal {
  max-width: 85vw;
  height: 70vh;
  min-width: 80vw;
  .tabs {
    height: 100%;
    display: flex;
    flex-direction: column;
    &__navigation {
      margin-left: 0;
      margin-top: 20px;
    }
    &__content {
      flex: 1 0 0;
      overflow: auto;
    }
  }

  .soon {
    border-radius: 28px;
    background: #58b895;
    text-transform: uppercase;
    color: #fff;
    padding: 0px 4px;
    display: flex;
    align-items: center;
    font-size: 12px;
  }
}
