.graphing-group-details-form {
  .double {
    display: flex;
    align-items: center;
    .split-input:nth-child(1) {
      padding-right: 10px;
    }
    .split-input:nth-child(2) {
      padding-left: 10px;
    }

    .error-message--visible {
      .error {
        text-wrap: nowrap !important;
      }
    }
  }
  .split-input {
    width: 50%;
  }
  .without-padding {
    padding: 0;
  }
  .is-default-field {
    margin: 0;
  }
  .rw-picker-caret {
    color: var(--primary-font-color);
    path {
      stroke: var(--primary-font-color);
    }
  }
  .date-range-picker {
    .select-container {
      .rw-popup {
        display: none;
      }
    }
  }
  .select-dropdown-component {
    position: relative;
    top: -20px;
  }
}
