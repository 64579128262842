.change-hierarchy-details-section {
  border-left: 1px var(--dropdown-border) solid;
  box-shadow: 0 0 8px -6px var(--default-font-color);
  height: 100%;
  overflow-y: scroll;
  padding: 0 5px 0 20px;
  width: 100%;
  &__title {
    align-items: center;
    display: flex;
    gap: 8px;
    margin-bottom: 20px;
    margin-top: 22px;
    width: 100%;
    &__color {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
    }
    &__text {
      height: 100%;
      word-break: break-word;
    }
  }
  &::-webkit-scrollbar-thumb {
    background: var(--secondary-theme-color);
    -webkit-border-radius: 100px;
    border-radius: 100px;
  }
  .empty-state {
    color: var(--ch-text);
    display: block;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    height: auto;
    line-height: 20px;
    padding-left: 0;
    padding-top: 0;
    text-align: left;
  }
}
