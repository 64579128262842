.profile-image {
  align-items: center;
  display: flex;
  justify-content: center;
 
  @import '../../../common/styles/selectable-image';
  .image-item label::before {
    height: 25px;
    width: 25px;
  }
  .image-item input:checked + label::after {
    border-bottom-width: 3px;
    border-right-width: 3px;
    height: 11px;
    width: 6px;
  }
  .image-item label::after {
    bottom: 7px;
    right: 8px;
  }
  .image-wrapper {
    height: 176px;
    margin-bottom: 24px;
    position: relative;
    width: 176px;
    &__image {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 100%;
      width: 100%;
      &.rounded {
        border-radius: 50%;
      }
    }
    &__overlay {
      align-items: center;
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 100%;
      bottom: 0;
      display: flex;
      justify-content: center;
      left: 0;
      outline: none;
      position: absolute;
      right: 0;
      top: 0;
      visibility: hidden;
      z-index: 2;
      &.visible {
        visibility: visible;
      }
    }

  }

}
