.mfa-form {
  width: inherit;
  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  .custom-checkmark {
    position: absolute;
    right: 20px;
  }
}
