.ch-no-data {
  margin-top: 40px;
  &__title {
    color: var(--ch-text);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
