$padding-horizontal: 16px;
$inspection-bottom-margin: 24px;
.explosive-zone-details {
  .buttons {
    align-items: center;
    display: flex;
    flex-direction: column;
    .btn {
      margin-top: $inspection-bottom-margin; 
    }
  }
}
