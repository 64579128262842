.observation-item-row {
  cursor: pointer;
  display: flex;
  width: 100%;
  &.added-item {
    border-bottom: 1px var(--ui-background) solid;
    padding: 12px 0;
  }
  .id-cell {
    width: 10%;
  }
  .name-cell {
    width: 40%;
    &__content {
      display: flex;
      gap: 4px;
    }
  }
  .type-cell,
  .status-cell {
    width: 25%;
    &__content {
      display: flex;
      gap: 4px;
    }
    &.has-unlink {
      width: 20%;
    }
  }
  .unlink-cell {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 5%;
    &.first {
      margin-top: 20px;
    }
  }
}
.column-header {
  color: var(--input-border-color);
  font-size: 12px;
}
