.add-measurement-point-field {
  .added-items {
    .added-items-list {
      min-height: 200px;
    }
  }
  .input-with-dropdown {
    .items-dropdown {
      background-color: var(--dropdown-bg);
      border: 1px solid var(--input-border-color);
      border-radius: 4px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 4px rgba(0, 0, 0, 0.05);
      left: 20;
      max-height: 400px;
      overflow-y: auto;
      position: absolute;
      top: 364px;
      width: 54.5%;
      z-index: 9999;
      .empty-state {
        height: 100%;
      }
      .item {
        padding: 8px 0 !important;
      }
      .section-header-title {
        align-items: center;
        border-bottom: 1px solid var(--box-items-separator-color);
        display: flex;
        width: 100%;
        height: 30px;
        margin-bottom: 8px;
        padding: 4px 8px;
      }
      .dropdown-render-item-container {
        align-items: center;
        cursor: pointer;
        display: flex;
        padding-left: 12px;
        .user-title {
          font-size: 12px;
          width: 100%;
        }
        .name {
          display: block;
          width: 80%;
        }
        .module-name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .measurement-point-name {
          width: 60%;
          .field-wrapper {
            margin-right: 8px;
            .input-wrapper {
              padding-top: 0;
              .checkbox-wrapper {
                input {
                  height: 20px;
                  width: 20px;
                }
              }
              .error-message {
                display: none;
              }
            }
          }
          &-value {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
          }
          .user-title {
            padding-left: 12px;
          }
        }
        .measurement-location-name {
          align-items: center;
          border-bottom: 1px solid var(--box-items-separator-color);
          display: flex;
          width: 100%;
          height: 30px;
          margin-bottom: 8px;
          padding: 0 8px;
        }
        .measurement-point-unit {
          // margin-right: 12px;
          text-align: left;
          width: 15%;
          .module-name {
            height: 25px;
          }
        }
        .measurement-point-exp-range {
          // margin-right: 12px;
          text-align: left;
          width: 25%;
          .module-name {
            height: 25px;
          }
        }
      }
    }
    .error-message {
      min-height: 25px;
      visibility: hidden;
      &--visible {
        color: var(--validation-error-color);
        display: block !important;
        font-size: 12px;
        padding: 5px 0;
        visibility: visible;
        &::first-letter {
          text-transform: capitalize;
        }
        .error {
          color: var(--validation-error-color);
          font-size: 12px;
        }
        .warning {
          color: var(--warning-color);
          font-size: 12px;
        }
        .red-warning {
          color: var(--validation-error-color);
          font-size: 12px;
        }
      }
    }
    .search-input-label {
      color: var(--secondary-font-color-light);
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
    .search {
      background-color: var(--input-background-color);
      border: 1px solid var(--input-border-color);
      margin-top: 4px;
      padding: 15px 14px;
      position: relative;
      .items-dropdown {
        top: 52px;
        width: 100%;
      }
      input::placeholder {
        color: var(--secondary-font-color-dark);
      }
      &__icon {
        fill: var(--secondary-font-color-dark);
        g,
        path {
          stroke: var(--secondary-font-color-dark);
        }
      }
    }
  }
  .table-template {
    .twp {
      .sticky {
        .twp-header__cell {
          &__container {
            gap: 2px;
            .info-tooltip {
              display: flex;
              margin-right: 10px;
            }
            .info-popover {
              border: 1px solid var(--notification-close-icon);
              border-radius: 4px;
              box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 1px 0 rgba(0, 0, 0, 0.06), 0 1px 1px 0 rgba(0, 0, 0, 0.08);
              min-height: fit-content;
              width: 258px;
              label {
                color: var(--default-font-color);
                font-weight: 400;
              }
            }
          }
        }
      }
    }
    .table-template-no-data-text {
      .empty-state-container {
        .empty-state-container__content {
          p {
            text-wrap: nowrap;
          }
        }
      }
    }
    .expected-range,
    .unit {
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .name {
      display: block;
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .twp {
      min-width: unset;
    }
    .twp-header {
      &.sticky {
        justify-content: flex-start;
      }
    }
    .name-column {
      max-width: 40% !important;
      min-width: 40% !important;
      &.reorder-enabled {
        max-width: 32% !important;
        min-width: 32% !important;
      }
    }
    .unit-column {
      max-width: 50px !important;
      min-width: 50px !important;
    }
    .expected-range-column {
      max-width: 15% !important;
      min-width: 15% !important;
    }
    .inverted-column {
      max-width: 50px !important;
      min-width: 50px !important;
    }
    .scale-factor-column {
      max-width: calc(45% - 50px - 50px - 28px) !important;
      min-width: calc(45% - 50px - 50px - 28px) !important;
    }
    .action-column {
      align-items: center;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      max-width: 28px !important;
      min-width: 28px !important;
      .actions-container {
        display: flex;
        gap: 4px;
      }
    }
  }
}
