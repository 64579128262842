@import '../../../common/styles/variables';
.module-item-history {
  height: 100%;
  max-width: $inspection-right-toolbar;
  padding: 20px 20px 20px 27px;
  position: fixed;
  width: 100%;
  &__wrapper {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 60px;
  }
}
