$top-part-height: 375px;
.measurements-section {
  .items-table-renderer {
    height: calc(100vh - #{$top-part-height});
    overflow: auto;
  }
  .rest-items {
    display: flex;
    justify-content: flex-end;
    > p {
      cursor: pointer;
      margin-left: 5px;
    }
  }
  .custom-item {
    margin-left: 5px;
    svg {
      height: 15px;
      width: 15px;
    }
  }
  .advanced-filter {
    align-items: center;
    display: flex;
    justify-content: space-between;
    p {
      cursor: pointer;
    }
  }
}
