.measurement-location-details {
  .confirmation-modal {
    .modal-wrapper {
      padding: 20px 24px;
      .modal__content {
        font-weight: 400 !important;
        margin-bottom: 0;
        max-width: 500px;
        padding-top: 16px;
      }
    }
  }
  .header-container {
    margin-bottom: 0;
  }
}
.section-content {
  .ml-measurement-points-section {
    .actions-container {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 0 20px;
      padding-bottom: 20px;
      .button-text {
        font-weight: 400 !important;
        text-transform: capitalize;
        svg {
          height: 20px;
          margin-left: 4px;
          width: 20px;
          path {
            stroke: #58b895;
            stroke-width: 1.5px;
          }
        }
      }
    }
    .twp {
      min-width: 0;
    }
    .table-template-search {
      height: 60px !important;
    }
    .table-template-no-data-text {
      padding: 12px;
    }
    .table-template-container {
      background-color: var(--work-tab-table-bg);
      height: 100%;
      overflow-y: auto;
      padding: 0 16px;
      .twp-header {
        border: 0;
        &.sticky {
          height: 28px;
          margin-top: 12px;
        }
      }
      &::-webkit-scrollbar {
        width: 4px;
      }
      .twp-body__row {
        background-color: var(--work-tab-row-bg);
        margin-top: 8px;
      }
      .load-more__results,
      .sticky {
        background-color: var(--work-tab-table-bg);
      }
      .mp-id-column {
        align-items: center;
        justify-content: center;
        max-width: 36px !important;
        min-width: 36px !important;
      }
      .mp-name-column {
        max-width: calc(100% - 36px - 30px - 30px) !important;
        min-width: calc(100% - 36px - 30px - 30px) !important;
        padding-left: 24px;
      }
      .sort-up-column,
      .sort-down-column {
        max-width: 30px !important;
        min-width: 30px !important;
        padding-right: 0 !important;
      }
    }
    .table-template-container__info {
      .load-more {
        .btn {
          background-color: var(--background-color);
          font-weight: 500;
          min-width: 102px;
        }
      }
    }
  }
  .empty-state-container {
    .empty-state-container__content {
      .btn {
        min-width: 230px;
        svg {
          height: 20px;
          width: 20px;
          path {
            stroke-width: 1.5px;
          }
        }
      }
    }
  }
}
