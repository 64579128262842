@import '../../../common/styles/variables';
.sidebar-container {
  background-color: var(--background-color);
  height: 100vh;
  padding: 40px 16px 0 30px;
}
.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__header-container {
    padding-right: 20px;
  }
  &__search {
    padding-bottom: 10px;
    width: 100%;
    .filter {
      margin-top: 10px;
      .move-right {
        text-align: right;
      }
      .filter-items {
        align-items: center;
        display: flex;
        padding-top: 10px;
        .order {
          cursor: pointer;
          margin-left: 20px;
          .sort-icon {
            display: flex;
            flex-direction: column;
            svg {
              height: 12px;
              width: 12px;
            }
          }
        }
        .error-message {
          display: none;
        }
        .input-wrapper {
          min-width: 100px;
          padding-top: 0;
        }
      }
    }
    &--input {
      padding-left: 0 !important;
    }
  }
  &__title {
    margin-bottom: 16px;
    text-transform: capitalize;
  }
}
.add-project {
  cursor: pointer;
  display: inline-flex;
  margin: 20px 0;
  &:hover {
    .add-project__text {
      text-decoration: underline;
    }
  }
  &__image {
    fill: var(--secondary-theme-color);
    height: 16px;
    margin-right: 16px;
    width: 16px;
  }
  &__text {
    color: var(--secondary-theme-color);
    font-size: 13px;
    font-weight: 500;
    text-transform: capitalize;
  }
}
.project-list {
  flex: 1;
  margin: 0 -16px;
  overflow-y: auto;
  padding: 0 15px 16px 16px;
  position: relative;
  .separated-list {
    margin: 40px 0;
    &:first-child {
      margin-top: 0;
    }
    header {
      margin-bottom: 32px;
    }
    .empty-state {
      padding: 10px 0 0 10px;
    }
  }
}
