@import '../../../common/styles/variables';
.inspection-details-modal {
  margin-bottom: 20px;
  width: 100%;
  &__info {
    margin-bottom: 16px;
  }
  &__label {
    margin-bottom: 7px;
    p {
      font-size: 12px;
    }
  }
  &__value {
    &.clickable {
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
      svg {
        margin-right: 10px;
      }
    }
  }
}
.inspection-details {
  .main-lists {
    height: calc(100vh - 235px);
    overflow: auto;
  }
  p {
    margin-top: 10px;
  }
  .divider {
    background-color: var(--box-items-separator-color);
    height: 1px;
    margin: 20px 0;
    width: 100%;
  }
  .single-module {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin: 8px 0;
    padding: 6px 10px 6px 0;
    position: relative;
    p {
      display: inline-flex;
      font-weight: 500;
      margin: 0;
      margin-left: 10px;
    }
    .text {
      display: flex;
      justify-content: center;
    }

    &::before {
      bottom: 0;
      content: '';
      left: -10px;
      opacity: 0.2;
      position: absolute;
      right: 0;
      top: 0;
    }
    &.active,
    &:hover {
      &::before {
        background-color: var(--secondary-theme-color-dark);
      }
    }
    &.soon {
      p:not(.soon-box) {
        opacity: 0.3;
      }
      .soon-box {
        border: 1px solid var(--secondary-theme-color-dark);
        margin-left: 10px;
        padding: 0 5px;
      }
    }
    .new-tab {
      g {
        fill: var(--primary-font-color);
      }
    }
  }
  .tools {
    margin-top: 30px;
  }
  .chevron {
    path {
      stroke: var(--primary-font-color);
    }
  }
  .space {
    margin-bottom: 10px;
  }
}
