@import '../../../common/styles/variables';
.image-list {
  display: grid;
  grid-column-gap: 13px;
  grid-row-gap: 10px;
  grid-template-columns: 1fr 1fr;
  position: relative;
  .image-item {
    border: 3px solid  var(--modal-color);
    border-radius: 4px;
    height: 100px;
    position: relative;
    transition: all 0.3s ease;
    &.active {
      border: 3px solid  var(--secondary-theme-color);
    }
    .new-tab {
      cursor: pointer;
      position: absolute;
      right: 2px;
      top: 2px;
      &:hover {
        g {
          fill: var(--secondary-theme-color);
        }
      }
    }
    .icon-item {
      &.reviewed {
        left: 1px;
        position: absolute;
        top: 1px;
      }
    }
    &__image-container {
      border-radius: 4px;
    }
  }
}
