.user-name-and-email-col {
  color: var(--default-font-color);
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  max-width: 50% !important;
  min-width: 50% !important;
}
.show-team-user-table {
  width: 50vw;
  .twp {
    max-width: 100%;
    min-width: 100%;
  }
}
