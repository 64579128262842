@import '../../../common//styles//variables';
.inspection-settings {
  padding-bottom: 20px;
  .padding {
    padding: 35px 19px 0 24px;
  }
  &__title {
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 600;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 20px;
    text-transform: capitalize;
  }
  .settings-form {
    .field-wrapper__label,
    .inspection-input__label {
      // color: var(--primary-font-color);
      font-size: 14px;
      font-weight: 500;
    }
    .section-content--visible {
      max-height: 300vh;
    }
    .inline {
      align-items: center;
      display: flex;
    }
    .label-color {
      margin-bottom: 0;
      margin-right: auto;
    }
    .inspection-input {
      margin-bottom: 24px;
    }
    .section {
      margin-bottom: 24px;
      &__label {
        margin-bottom: 20px;
      }
      &.with-border {
        border-bottom: 1px solid var(--box-items-separator-color);
        border-top: 1px solid var(--box-items-separator-color);
      }
    }
    .warning-message {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      &__icon {
        margin-right: 4px;
        font-weight: normal;
      }
    }
  }
  .share-link {
    padding: 0 19px 20px 27px;
    .header {
      align-items: center;
      display: flex;
      label {
        padding-left: 10px;
      }
      &__add-icon {
        cursor: pointer;
        fill: var(--secondary-theme-color);
        margin-left: auto;
        transition: all 0.2s ease;
        &:hover {
          fill: var(--secondary-theme-color-dark);
        }
      }
    }
    .links {
      margin-top: 10px;
      max-height: 400px;
      overflow: auto;
    }
    .share-toggle-wrapper {
      border-bottom: 1px solid var(--box-items-separator-color);
      display: flex;
      flex-direction: column;
      padding: 20px 0 10px;
      label {
        padding-top: 20px;
        word-break: break-all;
      }
      .actions {
        align-items: center;
        display: flex;
        &__user {
          align-items: center;
          display: flex;
          margin-right: auto;
          .user-icon {
            margin-right: 8px;
          }
          label {
            padding: 0;
          }
        }
        &__item {
          &:not(:first-child) {
            margin-left: 5px;
          }
          &.disabled {
            opacity: 0.5;
            pointer-events: none;
          }
        }
        .copy {
          cursor: pointer;
          fill: var(--primary-font-color);
          transition: all 0.2s ease;
          &:hover {
            fill: var(--secondary-theme-color);
          }
        }
        .delete {
          cursor: pointer;
          fill: var(--primary-font-color);
          transition: all 0.2s ease;
          &:hover {
            fill: var(--validation-error-color);
          }
        }
      }
    }
  }
}
//workflow inspection modal
.inspection-settings-modal {
  .modal {
    height: 75vh;
    max-height: 75vh;
    .inspection-settings {
      padding: 20px;
      &__title {
        font-size: 20px;
        padding-top: 0;
      }
      .settings-form {
        min-width: 360px;
      }
    }
  }
}
