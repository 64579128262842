.pick-level-modal {
  height: 80vh;
  width: 85vw;
  &__container {
    height: 100%;
    width: 100%;
    &__buttons {
      align-items: flex-end;
      display: flex;
      height: 54px;
      justify-content: space-between;
      width: 100%;
    }
    &__table {
      height: calc(100% - 54px);
      width: 100%;
      &.path_opened {
        height: calc(92.5% - 54px);
      }
    }
    &__path {
      &.hide {
        display: none;
      }
      &.show {
        display: block;
        height: 7.5%;
      }
    }
    .search {
      padding: 9px 0;
    }
  }
  .icon-column {
    min-width: 16px;
  }
  .code-column {
    min-width: calc(10% - 16px);
  }
  .code-column {
    color: var(--primary-font-color);
    font-size: 14px;
    font-weight: 400;
    max-width: 200px !important;
    min-width: 50px !important;
  }
  .name-column,
  .owner-column,
  .cost-centre-column,
  .maintenance-id-column,
  .location-column {
    color: var(--primary-font-color);
    font-size: 14px;
    font-weight: 400;
    max-width: calc((100% - 232px) / 5) !important;
    min-width: calc((100% - 232px) / 5) !important;
  }
  .icon-column {
    align-items: center;
    justify-content: center;
    max-width: 32px !important;
    min-width: 32px !important;
  }
  .search-term-text {
    background-color: rgba(var(--secondary-theme-color-rgb), 0.3);
  }
}
