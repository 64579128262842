@import '../../styles/variables';
.controls {
  &__row {
    align-items: center;
    border-bottom: 1px solid var(--box-items-separator-color);
    display: flex;
    padding: 24px 0;
    &__keys-visual-col {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-right: 40px;
      width: calc(3 * $keyboard-base-key-size * $keyboard-key-size-modifier + 10px);
    }
    &__keys-textual-col {
      align-items: center;
      display: flex;
      margin-right: 40px;
      width: calc(3 * $keyboard-base-key-size * $keyboard-key-size-modifier + 10px);
    }
    &__description-col {
      align-items: center;
      display: flex;
    }
  }
  &__item {
    height: 2 * $keyboard-base-key-size * $keyboard-key-size-modifier + 10px;
  }
  .wasd-layout {
    height: calc($keyboard-base-key-size * 2 * $keyboard-key-size-modifier + 1px);
    position: relative;
    width: calc($keyboard-base-key-size * 3 * $keyboard-key-size-modifier + 3px);
    .keyboard-key {
      position: absolute;
      &:nth-child(1) {
        left: calc($keyboard-base-key-size * $keyboard-key-size-modifier + 1px);
        top: 0;
      }
      &:nth-child(2) {
        left: 0;
        top: calc($keyboard-base-key-size * $keyboard-key-size-modifier + 1px);
      }
      &:nth-child(3) {
        left: calc($keyboard-base-key-size * $keyboard-key-size-modifier + 1px);
        top: calc($keyboard-base-key-size * $keyboard-key-size-modifier + 1px);
      }
      &:nth-child(4) {
        left: calc($keyboard-base-key-size * 2 * $keyboard-key-size-modifier + 2px);
        top: calc($keyboard-base-key-size * $keyboard-key-size-modifier + 1px);
      }
    }
  }
  .inline-layout {
    display: flex;
    .keyboard-key {
      margin-right: 1px;
    }
  }
}
