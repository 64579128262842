.submit-checklist-modal {
  max-width: 493px !important;
  min-width: 493px !important;

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    .btn {
      .button-text {
        font-weight: 500;
        text-transform: capitalize;
      }
    }
  }

  .submit-checklist-wrapper {
    padding-top: 16px;
    padding-bottom: 20px;
  }

  .alarms-triggered {
    padding-top: 16px;
    padding-bottom: 60px;

    &__alarms-section {
      display: flex;
      gap: 12px;
      flex-direction: column;
      padding-top: 32px;
      padding-bottom: 32px;
    }
  }
}
