.profile-details {
  align-items: center;
  background-color: var(--box-items-theme-color);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-bottom: 21px;
  padding: 32px 0;
  padding-bottom: 24px;
  position: relative;
  width: 100%;
  &__edit {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 16px;
  }
  &__username {
    margin-bottom: 8px;
    text-align: center;
    width: 80%;
    word-wrap: break-word;
  }
  &__location {
    margin-bottom: 6px;
  }
  &__separator {
    background-color: var(--box-items-separator-color);
    height: 1px;
    margin: 24px 0;
    width: 100%;
  }
  &__link {
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
  }
  .integrations {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    .integration-item {
      border: 2px solid var(--box-items-separator-color);
      cursor: pointer;
      height: 40px;
      padding: 5px;
      width: 40px;
      &.active {
        border: 2px solid var(--secondary-theme-color);
      }
    }
  }
  .mfa-message {
    display: flex;
    svg {
      margin: 0 5px;
    }
  }
}
