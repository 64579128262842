@import '../../../common/styles/variables';

.popover {
  background-color: white;
  background-color: var(--dropdown-bg);
  border: 1px var(--dropdown-border) solid;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.1);
  max-height: 120px;
  max-width: 528px;
  padding: 20px;
  position: absolute;
  z-index: 2;
  p {
    color: var(--primary-font-color);
    font-size: 14px;
    font-weight: 400;
  }
  &.show {
    display: block;
  }
  &.hide {
    display: none;
  }
  &.without-theme {
    &:not(.dark) {
      @each $label, $value, $i in $light-theme {
        #{$label}: #{$value};
      }
    }
  }
}
