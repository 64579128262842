.integration-list {
  display: flex;
  justify-content: center;
  width: 100%;
  .integration-item {
    align-items: center;
    display: flex;
    flex-direction: column;
    &__image {
      background-color: var(--primary-font-color);
      border: 2px solid transparent;
      cursor: pointer;
      height: 80px;
      transition: all 0.3s ease-in-out;
      width: 80px;
      &:hover {
        border: 2px solid var(--secondary-theme-color);
      }
      .img {
        height: 100%;
        width: 100%;
      }
    }
    &__label {
      font-weight: 500;
      margin-top: 8px;
    }
  }
}
