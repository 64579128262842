.paginator {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 100%;
  &.disabled {
    p {
      cursor: not-allowed;

    }    
  }
}

