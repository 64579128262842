.drawing-board {
  border: 1px solid var(--box-items-separator-color);
  // drawing board
  border-radius: 4px;
  margin-bottom: 10px;
  position: relative;
  .drawing-board-container {
    height: 100%;
    width: 100%;
  }
  &__icon {
    cursor: pointer;
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 2;
  }
}
