.contributors-list {
  overflow-x: auto;
  width: 100%;
  .work-area-item {
    padding: 8px 0;
  }
  .assignee-list {
    margin-bottom: 20px;
  }
}
