.change-hierarchy-modal {
  height: 80vh;
  width: 85vw;
  &__container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
    &__left-side {
      display: flex;
      flex-direction: column;
      height: calc(100% - 54px);
      width: 70%;
      &__path {
        &.hide {
          display: none;
        }
        &.show {
          display: block;
          height: 10%;
        }
      }
      &__table {
        align-items: flex-start;
        display: flex;
        height: calc(100% - 54px);
        justify-content: flex-start;
        width: 100%;
        &.path-opened {
          height: calc(90% - 54px);
        }
      }
      .category-wrapper {
        .description {
          color: var(--ui-color-secondary);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          margin-left: 4px;
        }
        .row {
          svg,
          span.title {
            &:hover {
              cursor: pointer;
            }
          }
          span.title {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
    }
    &__component-info {
      border-left: 1px var(--dropdown-border) solid;
      height: 100%;
      width: 30%;
    }
    .search {
      padding: 9px 0;
    }
  }
  .icon-column {
    min-width: 16px;
  }
  .code-column {
    min-width: calc(10% - 16px);
  }
  .code-column {
    color: var(--primary-font-color);
    font-size: 14px;
    font-weight: 400;
    max-width: 200px !important;
    min-width: 50px !important;
  }
  .name-column,
  .owner-column,
  .cost-centre-column,
  .maintenance-id-column,
  .location-column {
    color: var(--primary-font-color);
    font-size: 14px;
    font-weight: 400;
    max-width: calc((100% - 232px) / 5) !important;
    min-width: calc((100% - 232px) / 5) !important;
  }
  .icon-column {
    align-items: center;
    justify-content: center;
    max-width: 32px !important;
    min-width: 32px !important;
  }
  .change_hierarchy_modal_name {
    align-items: center;
    display: flex;
    gap: 8px;
  }
}
