.issue-key {
  width: 600px;
  .title {
    margin-bottom: 24px;
  }
  .description {
    margin-bottom: 32px;
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 7px;
  }
  .rw-popup-container {
    position: relative;
  }
}
