@import '../../../common/styles/variables';
.quick-filter {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  .items {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  .filter {
    align-items: center;
    border: 1px solid var(--marker-outer);
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 10px 10px 0;
    opacity: 0.5;
    padding: 5px 8px 5px 0;
    * {
      cursor: pointer;
    }
    &.active {
      border-color: var(--marker-inner);
      opacity: 1;
    }
    .custom-item {
      display: flex;
      margin-right: 5px;
    }
  }
}
