.create-measurement-reading-modal {
  margin-top: 20px;
}
.create-measurement-reading-form {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  max-width: 100%;
  min-height: 500px;
  min-width: 710px;
  overflow: auto;
  .field-wrapper {
    .input-wrapper {
      max-width: 321px;
      input,
      textarea {
        background-color: var(--input-background-color) !important;
        border: 1px solid var(--input-border-color) !important;
        border-radius: 4px;
        height: 52px !important;
      }
    }
    .input-wrapper {
      .react-datepicker-wrapper {
        input {
          background-color: var(--input-background-color);
          border: 1px solid var(--input-border-color);
          border-radius: 4px;
          color: var(--primary-font-color);
          padding: 9px 14px;
          padding-bottom: 9px;
        }
      }
    }
  }
  &__content {
    flex-grow: 1;
    overflow: auto;
  }
  &__double-input {
    display: flex;
    .uneditable-info:first-child {
      margin-right: 81px;
      width: 260px;
    }
  }
  .select-d {
    padding-right: 15px;
  }
  .added-items-list,
  .added-items {
    margin-top: 20px;
  }
  .added-items-list {
    max-height: 350px;
    overflow: auto;
    .empty-state {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .added-items {
    margin-bottom: 32px;
    width: 100%;
  }
  .buttons {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    .right-buttons {
      align-items: center;
      display: flex;
      margin-left: auto;
      .button-submit {
        margin-left: 20px;
      }
    }
    .button-text {
      font-weight: 400 !important;
    }
  }
  .ghost-field {
    display: none;
  }
  .x-hover:hover {
    path {
      fill: var(--severity-red);
    }
  }
  .question-options {
    padding-left: 0;
    &__wrapper__field {
      align-items: center;
      display: flex;
      gap: 12px;
    }
  }
  .question-options__wrapper {
    grid-template-columns: none;
    .field-wrapper {
      min-width: 321px;
    }
  }
  &__preview {
    border-bottom: 1px solid var(--separator-color);
    border-top: 1px solid var(--separator-color);
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 30px;
    padding: 20px 0;
    .reading-stat {
      display: flex;
      font-weight: 500;
      &__value {
        color: var(--primary-font-color);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin-left: 4px;
      }
    }
  }
  .reading-field {
    align-items: center;
    display: flex;
    gap: 8px;
    position: relative;
    width: 100%;
    .field-wrapper {
      width: 321px;
      input {
        padding-right: 40px;
      }
    }
    .unit-text {
      display: flex;
      left: 280px;
      position: absolute;
      top: 50px;
      &::after {
        content: attr(data-after);
        display: inline-block;
        transform: translateX(calc(-100% + 30px));
      }
    }
  }
}
.confirm-leave-modal {
  .confirm-leave-modal__picker {
    padding: 20px 24px 24px;
    .first-paragraph {
      margin-bottom: 0;
      margin-top: 16px;
    }
    .btn {
      .button-text {
        font-weight: 400;
      }
    }
  }
}
.create-measurement-reading-form__files {
  .component-files-modal {
    height: 400px;
    overflow-y: scroll;
    width: 100%;
  }
  .drop-field {
    p {
      font-weight: normal !important;
    }
  }
}
