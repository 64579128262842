.change-category-form-wrapper {
  width: 474px;
  #search-dropdown-items {
    margin-top: 4px;
    .empty-state {
      height: 100%;
    }
  }
  .search-wrapper {
    .highlight-placeholder {
      .search__input {
        &::placeholder {
          color: #000 !important;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
      }
    }
  }
  .items-dropdown {
    background: #fff;
    border: 1px solid var(--dms-linked-components-bg);
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 1px 0 rgba(0, 0, 0, 0.06), 0 1px 1px 0 rgba(0, 0, 0, 0.08);
    max-height: 365px;
    overflow-y: scroll;
    padding: 8px;
    width: 100%;
    .category-item-wrapper {
      align-items: center;
      cursor: pointer;
      display: flex;
      padding: 8px;
      &:hover {
        background-color: #e9ecef;
        border-color: #e9ecef;
      }
      .checkbox-wrapper {
        label {
          margin-left: 0;
        }
      }
    }
  }
  .form-buttons {
    align-items: center;
    display: flex;
    justify-content: space-between;
    .btn {
      margin-top: 60px;
      min-width: 111px;
      width: 52px;
      .button-text {
        font-weight: 500;
      }
    }
  }
}
