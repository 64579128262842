.templates {
  table {
    thead tr,
    thead tr th {
      background-color: var(--background-color);
    }
  }
  .load-more {
    background-color: var(--background-color);
  }
  .tabs {
    padding: 24px 20px;
    &__navigation {
      border-bottom: 1px solid var(--box-items-separator-color);
      &-item {
        p.f-secondary-dark {
          color: var(--primary-font-color) !important;
          font-size: 16px;
          line-height: 24px;
        }
      }
      &-item.active {
        border-bottom: 3px solid var(--secondary-theme-color);
        p.f-secondary-dark {
          font-weight: 500;
        }
      }
    }
    &__content {
      height: calc(100vh - 410px);
      margin-top: 20px;
      overflow: auto;
    }
  }
}
