.return-keys-modal {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  width: 600px;
  .table-container {
    flex: 1;
    margin-bottom: 10px;
    overflow: auto;
    .items-table-renderer {
      .empty-state {
        background-color: unset;
        height: 100%;
        margin: 0;
      }
      tr {
        td {
          &:last-child {
            align-items: center;
            display: flex;
            justify-content: flex-end;
          }
        }
        th {
          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }
  .buttons {
    align-items: center;
    display: flex;
    &__submit {
      margin-left: auto;
    }
  }
  &__checkbox-container {
    align-items: center;
    display: flex;
    .field-wrapper {
      align-items: center;
      display: flex;
      &:not(:last-child) {
        margin-right: 20px;
      }
      &__label {
        margin-bottom: 0;
      }
      .input-wrapper {
        margin-left: 8px;
      }
      .error-message {
        display: none;
        height: 0;
      }
      .checkbox-wrapper {
        input {
          -webkit-appearance: none;
          appearance: none;
          border: 1px solid #c6c6c6;
          border-radius: 50%;
          cursor: pointer;
          height: 28px;
          outline: none;
          vertical-align: middle;
          width: 28px;
        }
        label {
          font-size: 14px;
        }
        input:checked {
          appearance: auto;
          clip-path: circle(44% at 50% 50%);
          filter: hue-rotate(240deg);
        }
      }
    }
  }
}
