.work-areas-modal {
  display: flex;
  height: 95vh;
  width: 95vw;

  .inspection {
    flex: 1;
    height: inherit;
  }
  .potree-area {
    height: 100%;
    width: 100%;
  }
  .progress {
    background-color: rgba(var(--background-color-rgb), 0.4);
    border: 1px solid var(--secondary-theme-color-dark);
    border-radius: 12px;
    bottom: 15px;
    box-shadow: #a1a1a1 0 2px 2px inset;
    height: 20px;
    pointer-events: none;
    position: absolute;
    right: 50%;
    text-align: center;
    transform: translateX(50%);
    width: 200px;
    &__bar {
      background-image: linear-gradient(to bottom, var(--secondary-theme-color), var(--secondary-theme-color-dark));
      border-radius: 12px;
      position: absolute;
    }
    &__text {
      position: absolute;
      // color: white;
      text-transform: capitalize;
      width: 100%;
    }
  }
  .sidebar {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 375px;
  }
  .h-container-padding {
    padding-left: 20px;
    padding-right: 20px;
  }
  .v-container-padding {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .work-areas-form {
    &--active {
      background-color: var(--box-items-theme-color-light);
    }
    &__cancel-btn {
      margin-top: 12px;
      background-color: var(--background-color);
      .button-text {
        font-weight: 400;
      }
    }
    &__add-btn {
      svg {
        width: 16px;
        height: 16px;
      }
      p {
        font-weight: 500;
      }
    }
    .action-buttons {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .btn-gray-outline {
        background-color: var(--background-color);
      }
    }

    .btn-success,
    .btn-gray-outline {
      .button-text {
        font-weight: 400;
      }
    }

    label {
      color: var(--secondary-font-color-light);
      margin-bottom: 2px;
    }
  }

  .zone-area-form {
    label {
      color: var(--secondary-font-color-light);
      margin-bottom: 2px;
    }
  }

  .adding-location,
  .editing-location {
    &__icon {
      margin-bottom: 8px;
    }
    &__text {
      margin-bottom: 32px;
      text-align: center;
    }
  }
  .info-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
