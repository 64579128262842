@import '../../../common/styles/variables';
.project-sidebar {
  border: 1px solid transparent;
  padding: 16px 0 8px;
}
.project {
  cursor: pointer;
  display: flex;
  position: relative;
  width: 100%;
  z-index: 1;
  &::before {
    border-radius: 8px;
    content: '';
    cursor: pointer;
    height: 100%;
    padding: 10px 0 10px 12px;
    position: absolute;
    transform: translate(-12px, -10px); // - padding left and right, padding top and bottom
    transition: background-color 0.3s ease;
    width: 100%;
    z-index: -1;
  }
  .small-dropdown__settings {
    height: auto;
    margin: -10px 0;
    min-width: 30px;
    width: auto;
  }
  &--selected {
    &::before {
      background-color: var(--secondary-theme-color);
    }
    .small-dropdown__settings {
      .small-dropdown__settings-icon,
      .small-dropdown__settings-icon::before,
      .small-dropdown__settings-icon::after {
        background: #fff;
      }
    }
  }
  &__dropdown-arrow {
    align-items: center;
    display: flex;
    margin-right: 9px;
    &--up {
      transform: rotate(180deg);
    }
  }
  &__name {
    align-items: center;
    display: flex;
    margin-right: auto;
    p {
      display: inline-block;
      overflow: hidden !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 175px;
    }
  }
  &__date {
    align-items: center;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
  }
  &__add-inspection {
    color: var(--secondary-theme-color);
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    &--selected {
      color: var(--primary-font-color);
    }
  }
  &__icon {
    path {
      stroke: var(--primary-font-color);
    }
  }
  &__icon-selected {
    path {
      stroke: #fff;
    }
  }
  .hidden {
    display: none;
  }
}
.sub-projects {
  margin-top: 26px;
  padding-left: 50px;
  .inspection-actions {
    display: flex;
  }
  &__wrapper {
    align-items: center;
    display: flex;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    .small-dropdown__settings {
      width: auto;
    }
  }
  &__title {
    font-size: 13px;
    font-weight: 500;
    margin-right: auto;
  }
  &__title-clickable {
    cursor: pointer;
  }
  &__title-clickable:hover {
    color: var(--secondary-theme-color);
  }
  &__processing {
    align-items: center;
    display: flex;
    position: relative;
    .text {
      font-size: 12px;
      font-weight: normal;
    }
  }
  &__link {
    color: var(--secondary-theme-color);
    cursor: pointer;
    font-size: 12px;
    font-weight: normal;
    margin-right: 10px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    &.space {
      padding-bottom: 10px;
    }
    &-wrapper {
      padding-bottom: 10px;
    }
  }
}
.project-border {
  border-bottom: 1px solid var(--box-items-separator-color);
}
