.pdf-tag-wrapper {
  align-items: center;
  display: flex;
  height: inherit;
  justify-content: center;
  overflow: hidden;
  .toolbar-container__left,
  .toolbar-container__right {
    height: 100vh;
  }
  .visible-area {
    // added since it overflows the other popups
    z-index: 0;
  }
  .pdf-with-drawing {
    height: 100vh;
    width: 100%;
  }
}
