@import '../../../common/styles/variables';
.project-details {
  background: var(--background-color);
  border-radius: 4px;
  left: 40px;
  padding: 16px;
  position: absolute;
  top: 20px;
  .container {
    display: flex;
  }
  &__item {
    display: flex;
    width: auto;
  }
  &__label-container {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    .label {
      font-size: 12px;
      font-weight: normal;
    }
  }
  &__value-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    .value {
      font-size: 12px;
      font-weight: normal;
    }
  }
}
