@import '../../../common/styles/variables';
.download-report-modal-measurements {
  .modal-wrapper {
    overflow: unset;
  }
  .modal {
    height: 100%;
    max-width: unset;
    overflow: auto;
    width: auto;
    .download-report-wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 700px;
      .main-screen-wrapper {
        margin-top: 30%;
        text-align: center;
      }
      .buttons {
        display: flex;
        margin-top: 20px;
        button {
          margin: 5px;
        }
      }
      .custom-toolbar {
        justify-content: flex-start;
        margin: 20px auto 30px 0;
      }
    }
    .download-report {
      display: flex;
      flex-direction: column;
      width: 100%;
      .custom-toolbar {
        margin-bottom: 10px;
      }
      .workflow-stepper {
        margin: 10px 0 20px;
        .step {
          display: flex;
          flex-direction: column-reverse;
        }
      }
    }
    .multiple-buttons {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 1fr 1fr;
      margin: auto 0 20px;
    }
    .form-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .download-report-form {
      align-items: center;
      display: flex;
      flex-direction: column;
      max-height: calc(100vh - 360px);
      overflow: scroll;
      .field-wrapper,
      .inspection-input {
        padding: 0 20px;
        width: 100%;
        textarea {
          border: 1px solid var(--box-items-separator-color);
          color: var(--default-font-color);
          min-height: 150px;
          width: 100% !important;
        }
        .rw-popup-container {
          padding: 0;
        }
        .error-message {
          min-height: 25px;
        }
      }
      .btn {
        margin-top: 20px;
      }
      .single-option {
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
        padding: 0 20px;
        width: 100%;
        .right {
          min-width: 150px;
          padding-top: 10px;
        }
        .left {
          p:first-child {
            margin-bottom: 20px;
          }
        }
      }
      .dates {
        display: flex;
        margin-right: auto;
        padding: 0 20px;
        .field-wrapper {
          padding-left: 0;
        }
      }
    }
  }
}
