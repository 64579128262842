.comments-tab {
  align-items: stretch;
  background: var(--comments-tab-bg);
  display: flex;
  flex-flow: column;
  height: calc(100vh - 36px);
  .comments-list-wrapper {
    overflow-y: scroll;
    padding: 32px 20px 0;
    .comment {
      margin-bottom: 32px;
      &__header {
        align-items: center;
        display: flex;
        font-size: 14px;
        font-weight: 500;
        justify-content: space-between;
        line-height: 20px;
        margin-bottom: 8px;
        .small-dropdown__settings {
          margin-left: 5px;
          width: auto;
        }
      }
      &__tag-highlight {
        color: var(--tag-highlight);
      }
      &__author {
        font-weight: 500;
      }
      &__date {
        color: var(--comments-date);
        margin-left: auto;
        text-align: right;
      }
      &__content {
        background: var(--comments-content-bg);
        border-radius: 4px;
        color: var(--primary-font-color);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        padding: 12px;
        // color: #58B895;
        word-break: break-word;
        .icon-users {
          cursor: pointer;
        }
      }
    }
  }
  .add-comment-wrapper {
    background: var(--comments-content-bg);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin-top: auto;
    padding: 20px;
    position: relative;
    .btn {
      width: 100%;
    }
    .textarea-wrapper {
      position: relative;
    }
  }
  .comments-empty-state-wrapper {
    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
    &__icon {
      height: 48px;
      margin-bottom: 12px;
      width: 48px;
    }
    &__title,
    &__description {
      color: #999;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 8px;
    }
  }
}
