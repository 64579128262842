.icon {
  &-projects,
  &-users,
  &-profile,
  &-support,
  &-feedback,
  &-logout,
  &-arrow-right,
  &-arrow-left,
  &-logout,
  &-components,
  &-observations,
  &-workorders,
  &-notifications,
  &-areas,
  &-documents,
  &-smartdrawing,
  &-workflow,
  &-regime,
  &-toggler,
  &-newTab,
  &-chevron,
  &-pen,
  &-arrow90,
  &-target,
  &-corrosion,
  &-cursor,
  &-zoomin,
  &-zoomout,
  &-view-mode,
  &-resize,
  &-settings,
  &-measurementDistance,
  &-measurementArea,
  &-measurementPoint,
  &-measurementAngle,
  &-measurementHeight,
  &-measurementCircle,
  &-componentPoints,
  &-componentBox,
  &-componentSphere,
  &-componentCone,
  &-componentCylinder,
  &-componentTube,
  &-observationHazard,
  &-observationConcern,
  &-observationDamage,
  &-observationDefect,
  &-observationAccident,
  &-observationNearMiss,
  &-eye,
  &-info,
  &-file-text,
  &-calendar,
  &-contractor,
  &-templates,
  &-arrow-up,
  &-arrow-down,
  &-close,
  &-list,
  &-key,
  &-shield,
  &-alert-octagon,
  &-alert-triangle,
  &-clock-rounded,
  &-flag,
  &-box,
  &-checkmark-rounded,
  &-share,
  &-maximize,
  &-download,
  &-plus-circle,
  &-chevron-up,
  &-chevron-down,
  &-camera,
  &-pdf-file,
  &-paperclip,
  &-document-checklist,
  &-not-visible,
  &-three-dot-eye,
  &-help-circle,
  &-3D,
  &-360,
  &-link,
  &-unlink,
  &-check,
  &-crosshair,
  &-bell,
  &-criticalHazard,
  &-move-to,
  &-min,
  &-max,
  &-med,
  &-avg,
  &-upload {
    &.active,
    &.handle-hover:hover {
      path {
        stroke: var(--secondary-theme-color);
      }
    }
    &.solid {
      path {
        stroke: var(--primary-font-color);
      }
    }
    &.white {
      path {
        //static value since it represent the color
        stroke: #fff;
      }
    }
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
  &-delete,
  &-trash {
    &.active,
    &.handle-hover:hover {
      path {
        stroke: var(--severity-red);
      }
    }
    &.solid {
      path {
        stroke: var(--primary-font-color);
      }
    }
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
  &-plus-rounded {
    &.active,
    &.handle-hover:hover {
      circle {
        fill: var(--secondary-theme-color);
      }
    }
    &.solid {
      circle {
        fill: var(--primary-font-color);
      }
    }
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
  &-measurements {
    &.active,
    &.handle-hover:hover {
      path,
      rect {
        stroke: var(--secondary-theme-color);
      }
    }
    &.solid {
      path,
      rect {
        stroke: var(--primary-font-color);
      }
    }
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
  &-ndt {
    &.active,
    &.handle-hover:hover {
      path {
        fill: var(--secondary-theme-color);
      }
      circle {
        stroke: var(--secondary-theme-color);
      }
    }
    &.solid {
      path {
        fill: var(--secondary-theme-color);
      }
      circle {
        stroke: var(--secondary-theme-color);
      }
    }
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
  &-zones {
    &.active,
    &.handle-hover:hover {
      path:nth-child(1) {
        stroke: var(--secondary-theme-color);
      }
      path:nth-child(2) {
        fill: var(--secondary-theme-color);
      }
    }
    &.solid {
      path:nth-child(1) {
        stroke: var(--primary-font-color);
      }
      path:nth-child(2) {
        fill: var(--primary-font-color);
      }
    }
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
  &-componentPoints {
    &.active,
    &.handle-hover:hover {
      circle {
        stroke: var(--secondary-theme-color);
      }
    }
    &.solid {
      circle {
        stroke: var(--primary-font-color);
      }
    }
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
  &-measurementAngle {
    &.active,
    &.handle-hover:hover {
      circle {
        stroke: var(--secondary-theme-color);
      }
    }
    &.solid {
      circle {
        stroke: var(--primary-font-color);
      }
    }
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
  &-exclamation-mark,
  &-checkmark,
  &-warning {
    &.active,
    &.handle-hover:hover {
      path {
        fill: var(--secondary-theme-color);
      }
    }
    &.solid {
      path {
        fill: var(--primary-font-color);
      }
    }
    &.warning {
      path {
        fill: var(--severity-orange);
      }
    }
    &.danger {
      path {
        fill: var(--severity-red);
      }
    }
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
  &-plus-circle {
    &.danger {
      path {
        stroke: var(--severity-red);
      }
    }
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
  &-alert-circle,
  &-bell {
    &.white {
      path {
        //static value since it represent the color
        stroke: #fff;
      }
    }
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
