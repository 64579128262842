$border-width: 3px;
$height: 13px;
$width: 8px;
.area-group {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  &__group_actions {
    display: flex;
    margin-right: 8px;
    margin-right: 8px;
    transition: 0.2s ease-in-out;
    svg {
      margin-right: 5px;
    }
    .icon {
      cursor: pointer;
      transition: all 0.3s ease;
    }
  }
  &__add-area {
    cursor: pointer;
    display: flex;
    margin-right: 10px;
    &:hover {
      p {
        text-decoration: underline;
      }
    }
    p {
      margin-left: 5px;
    }
  }
  &__form {
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;
    .inspection-input {
      flex: 1;
    }
    .input {
      font-stretch: normal;
      font-style: normal;
      font-weight: 500;
      letter-spacing: normal;
      line-height: normal;
      &::placeholder {
        font-size: 15px;
        font-stretch: normal;
        font-style: normal;
        font-weight: 500;
        letter-spacing: normal;
        line-height: normal;
      }
    }
    .edit-mode-actions {
      align-items: center;
      display: flex;
      // margin-left: 10px;
      flex-direction: row;
      margin-left: auto;
      margin-right: 10px;
      align-items: center;

      button {
        height: auto;
        margin-right: 10px;
        outline: 0;
        border: 0;
        background-color: transparent;
        cursor: pointer;
        &[type='submit'] {
          color: var(--secondary-theme-color);
        }
      }
      .checkmark {
        border-bottom: $border-width solid var(--secondary-theme-color);
        border-right: $border-width solid var(--secondary-theme-color);
        display: inline-block;
        height: $height;
        margin-bottom: 5px;
        transform: rotate(45deg);
        width: $width;
        margin-top: 3px;
      }
    }
  }
}
