@import '../../../common/styles/variables';

.colors-container {
  display: flex;
  flex-direction: column;
  &__label {
    align-items: center;
    color: var(--secondary-font-color-light);
    display: flex;
    gap: 4px;
    margin-bottom: 9px;
    text-transform: capitalize;
    &.sublabel {
      display: inline-block;
      font-weight: lighter;
      margin: 10px 0 20px;
      width: 100%;
    }
    .info-tooltip {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }
  .colors-wrapper {
    align-items: center;
    display: flex;
    gap: 8px;
    margin-top: 3px;
    margin-bottom: 16px;
    &__color {
      background-clip: content-box;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.2s ease;
      position: relative;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &--disabled {
        cursor: default;
      }
      &.selected {
        outline: 3px solid var(--ui-color-primary);
      }
      &.sm {
        height: $color-item-size-sm;
        width: $color-item-size-sm;
      }
      &.md {
        height: $color-item-size-md;
        width: $color-item-size-md;
      }
      &.lg {
        height: $color-item-size-lg;
        width: $color-item-size-lg;
      }
      &.xl {
        height: $color-item-size-xl;
        width: $color-item-size-xl;
      }
    }
    &__color--disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }
}
