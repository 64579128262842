.details-form {
  border-left: 1px var(--dropdown-border) solid;
  height: 100%;
  overflow-y: scroll;
  padding: 20px;
  width: 100%;
  &__title {
    border-bottom: 1px var(--dropdown-border) solid;
    margin-bottom: 20px;
    width: 100%;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--secondary-theme-color);
    -webkit-border-radius: 100px;
    border-radius: 100px;
  }
  .inspection-input__label {
    font-size: 14px;
    font-weight: 500;
  }
  .inspection-input__input-wrapper {
    border: 0;
    input {
      font-weight: 500;
    }
  }
}
