@import '../../../common/styles/variables';

$active-nav-width-offset: 10px;
.navigation {
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  transition: transform 1s ease;
  z-index: 100;
  &--inactive {
    transform: translateX(calc(-#{$nav-width} - 20px));
    .navigation-container {
      &__toggle {
        padding: 5px 5px 5px 70px;
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
  &--active {
    transform: translateX(0);
    .navigation-container {
      &__toggle {
        transition-delay: 0s;
      }
    }
  }
}
body[class^='modal-opened'] {
  .navigation.navigation--active {
    z-index: 201;
    .navigation-container {
      opacity: 0.7;
      pointer-events: all;
    }
  }
  .navigation,
  .toolbar-container {
    z-index: 2;
  }
}
.navigation-container {
  align-items: center;
  background-color: var(--background-color);
  border-right: 1px solid var(--navigation-border-color);
  box-shadow: 0 0 8px -6px var(--default-font-color);
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  padding: 40px 0;
  position: relative;
  width: $nav-width;
  .logo {
    .image-item {
      label {
        border: 5px solid var(--logo-background);
        min-height: 35px;
        opacity: 1;
      }
    }
    .image-wrapper {
      height: calc(#{$nav-width} - 25px);
      width: calc(#{$nav-width} - 25px);
    }
    svg,
    img {
      width: 55px;
    }
    svg {
      #eye {
        fill: var(--primary-font-color);
      }
    }
  }
  &__logout {
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  &__toggle {
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 80px;
    transform: translateX(50%);
    transition: all 0.3s;
    transition-delay: 0.8s;
    rect {
      fill: var(--background-color);
    }
  }
  .profile-box {
    left: 16px;
    position: absolute;
    top: 20px;
  }
  .main-menu {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    margin: 50px 0;
    width: 100%;
    .menu-item {
      align-items: center;
      cursor: pointer;
      display: flex;
      justify-content: center;
      padding: 20px 0;
    }
  }
}
#userback_button_container .userback-button {
  visibility: hidden;
}
div#hubspot-messages-iframe-container {
  &.widget-align-left {
    left: -6px !important;
  }
}
.userback-feedback-opened {
  #hubspot-messages-iframe-container {
    visibility: visible;
  }
}
.helpkit-launcherButton:not(.helpkit-shrink) {
  display: none;
}
.helpkit-launcherButton {
  right: 60px;
}
.helpkit-popover {
  right: 60px;
}
