.question-field {
  position: relative;
  &.full-width {
    width: 100%;
  }
  &.inline {
    display: inline-block;
  }
  &.with-right-padding {
    padding-right: 10px;
  }
  &.with-left-padding {
    padding-left: 10px;
  }
  &__label {
    color: var(--secondary-font-color-light);
    margin-bottom: 9px;
  }
  .default-input {
    input,
    textarea {
      border-radius: 4px;
      max-height: 36px;
    }
  }
  .search {
    border: 1px solid #c6c6c6;
    height: 52px;
    margin-top: 4px;
  }
  .items-dropdown {
    max-height: 150px;
    top: 50px;
    width: 100%;
    z-index: 1;
    .item {
      padding: 0;
      p {
        padding: 5px 0;
      }
      .pointer {
        cursor: pointer;
      }
      .disabled {
        background-color: var(--box-items-separator-color);
        cursor: not-allowed;
      }
    }
  }
  &__actions {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 5px 0;
    .choose-from-label {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-right: 12px;
    }
    p {
      cursor: pointer;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
    &__left {
      border-right: 1px solid var(--box-items-separator-color);
      padding-right: 16px;
    }
    &__middle {
      border-right: 1px solid var(--box-items-separator-color);
      padding-left: 16px;
      padding-right: 16px;
    }
    &__right {
      padding-left: 16px;
    }
  }
  &__locked-icon {
    position: absolute;
    right: 10px;
    top: 43px;
  }
  &__locked-icon-with-padding {
    right: 20px;
  }
}
.components-display {
  &__item-container {
    align-items: center;
    border-bottom: 1px solid var(--box-items-separator-color);
    display: flex;
    padding: 12px 0;
    &__title-wrapper {
      align-items: center;
      display: flex;
      width: 95%;
      &__title {
        font-weight: 700;
        margin-right: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .info-tooltip {
        height: 20px;
      }
    }
    &__close-icon {
      cursor: pointer;
      margin-left: auto;
    }
  }
}
.question-options {
  padding-bottom: 10px;
  padding-left: 28px;
  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    &__field {
      align-items: center;
      display: flex;
      &.with-padding {
        padding-right: 15px;
      }
      &__icon {
        cursor: pointer;
      }
    }
  }
}
