.button-with-icon {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 8px;
  justify-content: center;
  &.no-border {
    background-color: inherit;
    border: 0;
  }
  &__text {
    &.info {
      color: var(--grey-background-icon);
    }
    &.success {
      color: var(--secondary-theme-color);
    }
  }
  &__icon {
    &.info {
      path {
        stroke: var(--grey-background-icon);
      }
    }
    &.success {
      path {
        stroke: var(--secondary-theme-color);
      }
    }
  }
  &:disabled {
    cursor: not-allowed;
  }
}
