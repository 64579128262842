.ndt-alarms-modal-content {
  display: flex;
  flex-direction: column;
  // NDT MODAL
  height: 100%;
  &__header {
    margin-bottom: 20px;
  }
  .ndt-modal-alarms-form {
    max-height: 540px;
    overflow: auto;
    .variant-wrapper {
      display: flex;
      flex-direction: column;
    }
    .single-variant {
      display: flex;
      .inspection-input,
      .normal-input {
        margin-right: 40px;
      }
      .inspection-input {
        width: 170px;
      }
      &.disabled,
      &.full-disabled {
        .inspection-input,
        .normal-input {
          cursor: not-allowed;
          opacity: 0.6;
          > * {
            cursor: not-allowed;
            * {
              pointer-events: none;
            }
          }
        }
        .normal-input:first-child {
          opacity: 1;
          pointer-events: all;
          * {
            pointer-events: all;
          }
        }
      }
      &.full-disabled {
        .normal-input:first-child {
          opacity: 0.6;
          // pointer-events: none;
          * {
            pointer-events: none;
          }
        }
      }
      .notification-toggle {
        align-items: flex-start;
        display: flex;
        .info-icon {
          margin: 2px 0 0 2px;
        }
      }
    }
    .section-title {
      margin-bottom: 20px;
    }
    .email-input {
      > div {
        min-height: 36px;
      }
    }
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    button:not(:last-child) {
      margin-right: 10px;
    }
  }
  .ndt-dropdown {
    .input-wrapper {
      padding: 0;
    }
    .rw-widget-input {
      margin-top: -1px !important;
    }
  }
}
.ndt-alarms-modal {
  .modal {
    height: 85vh;
    max-height: unset;
    overflow: unset;
  }
}
