@import '../../../common/styles/variables';
.register-container {
  align-items: center;
  display: flex;
  width: 100%;
  .bg-wrapper {
    width: 40vw;
    .bg-image {
      align-items: center;
      background: url('../assets/register-bg.png') no-repeat center center;
      background-position: 48%;
      background-size: cover;
      display: flex;
      height: 100vh;
      justify-content: center;
      width: 100%;
    }
  }
  .register-wrapper {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    .register-form {
      display: flex;
      flex-direction: column;
      min-width: 320px;
      h4 {
        font-weight: bold;
        padding-bottom: 40px;
        text-align: center;
      }
      .logo {
        margin: auto;
        padding-bottom: 40px;;

      }
    }
  }
}
