$edge-distance: 40px;
$tool-width: 48px;
.top-toolbox,
.bottom-toolbox {
  left: 0;
  position: absolute;
  transform: translate(calc(-100% - 20px));
  .toolbox {
    .merged {
      &:last-child {
        margin-top: 20px;
      }
    }
  }
}
.bottom-left-toolbox {
  left: -$tool-width - 12px;
  position: absolute;
  transform: translate(calc(-100% - 20px));
  .toolbox {
    .merged {
      &:last-child {
        margin-top: 20px;
      }
    }
  }
}
.bottom-toolbox {
  bottom: $edge-distance;
  .toolbox:first-child {
    margin-bottom: 20px;
  }
}
.bottom-left-toolbox {
  bottom: $edge-distance;
}
.top-toolbox {
  top: $edge-distance;
}
