@import '../../../common//styles//variables';
$title-height: 53px;
$this-page-title-bar-height: 105px;
.workflow-container {
  // height: calc(100vh - #{$page-title-bar-height});
  .title-main {
    padding: 20px 10px;
  }
  .boxes-wrapper {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100% 100%;
    height: calc(100vh - #{$this-page-title-bar-height});
    .box {
      background-color: var(--box-items-theme-color);
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      .box-title {
        border-bottom: 1px solid var(--box-items-separator-color);
        display: flex;
        justify-content: space-between;
        padding: 10px;
        .upload-button {
          margin: 0;
        }
      }
      &.left {
        display: grid;
        grid-row-gap: 5px;
        grid-template-rows: 40px 50px auto;
        row-gap: 5px;
        .image-filter {
          align-items: center;
        }
        .image-list-view {
          max-height: unset;
          padding: 0 20px;
          &.section-view {
            .expandable-section .header__wrapper {
              .dropzone-wrapper input {
                outline: none;
              }
            }
            .upload-button {
              margin: 0;
            }
            &.list {
              .image-item {
                margin: 5px 15px;
                padding: 5px;
                word-break: break-all;
              }
            }
          }
        }
      }
      .title {
        background-color: transparent;
      }
      .box-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start;
        .slider-section {
          height: 70%;
        }
        .button-section {
          height: 30%;
          padding: 60px 0;
          button {
            margin: auto;
            max-width: 600px;
          }
        }
      }
    }
  }
}
.footer-bar-opened {
  overflow: hidden;
  .workflow-container {
    .boxes-wrapper {
      height: calc(100vh - #{$this-page-title-bar-height} - #{$upload-bar-height});
    }
  }
}
