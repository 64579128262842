&.multiselect {
  .rw-multiselect-taglist {
    align-items: center;
  }
  .rw-widget-input {
    height: auto;
    min-height: $input-size-sm;
  }
  .input-wrapper {
    width: 100%;
    &.enhanced-design-custom-prop {
      .search-wrapper {
        .search {
          background-color: var(--input-background-color);
          border: 1px solid var(--input-border-color);
          border-radius: 4px;
          color: var(--primary-font-color);
          font-family: $primary-font-family;
          font-size: 14px;
          font-weight: normal;
          outline: none;
          transition: 0.2s all;
          width: 100%;
          &__input {
            path {
              stroke: var(--primary-font-color);
            }
          }
        }

        .items-dropdown {
          top: 55px;
          border-radius: 4px;
          border: 1px solid #b2b2b2;
          background: #fff;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 2px 1px 0px rgba(0, 0, 0, 0.06), 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
          .dropdown-item-wrapper {
            .field-wrapper .input-wrapper {
              padding-top: 0;
              margin-right: 8px;
            }
            .checkbox-wrapper {
              width: 20px;
              height: 20px;
              .custom-checkbox,
              .checkmark {
                width: 20px;
                height: 20px;
              }
              .checkmark::after {
                left: 5px;
                top: 2px;
              }
            }
            p.pointer {
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              color: var(--primary-font-color);
            }
            .dropdown-item__info-content {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
              &--title {
                color: #6d6d6d;
                margin-bottom: 4px;
              }
              &--description {
                color: #262626;
              }
            }
          }
        }
      }
      &.dropdown-item-wrapper__grouped {
        .item:nth-child(even):not(:last-child) {
          border-bottom: 1px solid #b2b2b2;
        }
      }
    }
  }
  .rw-multiselect-taglist {
    input {
      display: block;
      height: auto;
      min-height: 34px;
    }
  }
  .lg {
    .rw-widget-input {
      height: auto;
      min-height: 52px;
    }
    .rw-multiselect-taglist {
      input {
        min-height: $input-size-lg;
      }
    }
  }
  .xl {
    .rw-multiselect-taglist {
      input {
        height: $input-size-xl;
      }
    }
  }
  .items-dropdown {
    background: #fff;
    border: 1px solid var(--dms-linked-components-bg);
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 1px 0 rgba(0, 0, 0, 0.06), 0 1px 1px 0 rgba(0, 0, 0, 0.08);
    max-height: 365px;
    overflow-y: scroll;
    // padding: 8px;
    width: 100%;
    z-index: 1;
    .dropdown-item-wrapper {
      align-items: center;
      cursor: pointer;
      display: flex;
      padding: 4px 8px;
      &:hover {
        background-color: #e9ecef;
        border-color: #e9ecef;
      }
      .checkbox-wrapper {
        label {
          margin-left: 0;
        }
      }
    }
  }
  .search-wrapper {
    width: 100%;
    .highlight-placeholder {
      .search__input {
        &::placeholder {
          color: #000 !important;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
      }
    }
  }
}
