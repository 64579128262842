@import '../../../common//styles//variables';
.members-container {
  height: calc(100vh - 230px);
  margin: 20px 20px 0 0;
  overflow: auto;
  table th:last-child {
    min-width: auto;
  }
  .search-combo {
    display: flex;
    flex-wrap: wrap;
    left: 0;
    margin-left: 250px;
    position: absolute;
    top: 30px;
    .search {
      margin: 0 20px 10px 0;
      width: 300px;
    }
  }
  .info-tooltip {
    height: 16px;
  }
  .info-box {
    align-items: center;
    display: flex;
    p {
      margin-right: 10px;
    }
  }
}
.footer-bar-opened {
  .members-container {
    height: calc(80vh - #{$upload-bar-height});
  }
}
