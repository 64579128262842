.permit-template-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  .empty-state {
    background-color: transparent;
    height: 40vh;
    margin: auto;
  }
  .id-column {
    min-width: 50px;
  }
  .shortened {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 350px;
  }
  &__search-input-wrapper {
    padding-left: 0;
  }
  &__table-container {
    height: calc(100vh - 410px);
    margin-top: 12px;
    overflow: auto;
  }
  &__load-more-wrapper {
    bottom: 0;
    left: 50%;
    margin: 15px auto;
    margin-top: auto;
    padding: 8px 10px;
    position: absolute;
    transform: translateX(-50%);
    width: 100%;
  }
}
