@import '../../../common/styles/variables';
.toolbox {
  &.individual {
    .tool {
      background-color: var(--background-color);
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
      margin-top: 10px;
    }
    .active-tool {
      .tool {
        background-color: #f8fbfa;
        border: 1px solid var(--secondary-theme-color);
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
      }
    }
  }
  &.merged {
    background-color: var(--background-color);
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  }
  .tool {
    align-items: center;
    display: flex;
    height: 48px;
    justify-content: center;
    position: relative;
    width: 48px;
    &.disabled {
      cursor: not-allowed;
    }
    .arrow {
      bottom: 0;
      left: 0;
      position: absolute;
    }
    .raw-text,
    svg {
      cursor: pointer;
    }
    .raw-text {
      font-size: 16px;
      &:hover {
        color: var(--secondary-theme-color);
      }
    }
  }
}
