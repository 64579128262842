.create-graphing-group-form {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-height: 550px;
  min-width: 800px;
  overflow: auto;
  margin-top: 32px;
  &__container {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    display: grid;
    grid-template-columns: minmax(0, 4fr) minmax(0, 3fr);
    column-gap: 12px;
  }
  &__content {
    // overflow: auto;
    height: 100%;
  }
  &__graph-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .empty-state {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      &__icon-wrapper {
        &--icon {
          width: 48px;
          height: 48px;
          min-width: unset;
          min-height: unset;
        }
      }
      &__title,
      &__title-medium {
        color: var(--secondary-font-color-dark);
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 0;
      }

      &__message,
      &__message-medium {
        color: var(--secondary-font-color-dark);
      }
    }
  }
  .select-d {
    padding-right: 15px;
  }
  .added-items-list {
    max-height: 200px;
    overflow: auto;

    .empty-state {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .added-items {
    margin-top: 20px;
    width: 100%;
  }
  .buttons {
    align-items: center;
    display: flex;
    flex-direction: row !important;
    padding: 30px 0px 0px 0px !important;
    .right-buttons {
      align-items: center;
      display: flex;
      margin-left: auto;
      .button-submit {
        margin-left: 20px;
      }
    }
  }
  .ghost-field {
    display: none;
  }
  .x-hover:hover {
    path {
      fill: var(--severity-red);
    }
  }
}
