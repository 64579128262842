@import '../../../common/styles/variables';
.component-form {
  .expanded-section {
    .header {
      background-color: transparent;
      box-shadow: none;
      &__wrapper {
        display: block;
      }
    }
    .section-title {
      display: flex;
    }
    .section-title-actions {
      margin-left: auto;
    }
    .section-content {
      border-bottom: 0;
    }
  }
  &__fields-container {
    height: 100%;
    margin-bottom: 8px;
    width: 100%;
    &--wrapper {
      height: 100%;
      overflow-y: scroll;
    }
  }
  .field-wrapper:not(:last-child),
  .inspection-input:not(:last-child) {
    margin-bottom: 6px;
  }
  .rw-widget-input {
    background-color: var(--input-background-color) !important;
    border: 1px solid var(--input-border-color) !important;
    border-radius: 4px !important;
    color: var(--primary-font-color);
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    min-height: 52px;
    outline: none;
    padding: 9px 14px;
    padding-bottom: 9px;
    transition: 0.2s all;
    width: 100%;
    .rw-dropdown-list-input {
      padding: 0;
    }
    .rw-placeholder {
      color: #999;
    }
  }
  .material-multi-select {
    .input-wrapper {
      width: 100%;
    }
    .rw-multiselect-input {
      padding-left: 0px;
    }
  }

  .section-content--visible {
    max-height: 300vh;
    padding: 20px 20px 20px 24px;
  }
  .section-content--visible {
    max-height: 300vh;
    padding: 20px 20px 20px 24px;
  }
  .section-content {
    border: none;
  }
  .details-section {
    .section-content {
      padding-top: 0;
    }
  }
  .buttons {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    padding: 0 19px 20px 27px;
  }
  .points-container {
    margin: 16px 0;
    &.hidden-field {
      display: none;
      visibility: hidden;
    }
    .point-list {
      margin-top: 7px;
      &__row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        .header-item {
          text-align: center;
        }
      }
      &__item {
        width: 25%;
      }
      &__point-name {
        background-color: transparent;
        border: 0;
        font-family: $primary-font-family;
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        outline: none;
        padding: 8px 0;
      }
      &__point-location {
        background-color: transparent;
        border: 0;
        font-family: $primary-font-family;
        font-size: 12px;
        font-style: normal;
        font-weight: normal;
        outline: none;
        padding: 8px 0;
        text-align: center;
      }
    }
    .camera-container {
      display: flex;
      justify-content: flex-end;
      &.right-align {
        justify-content: flex-end;
      }
      &.left-align {
        justify-content: flex-start;
      }
      .set-camera {
        align-items: center;
        cursor: pointer;
        display: flex;
        &:hover {
          .set-camera__text {
            text-decoration: underline;
          }
        }
        &__icon {
          margin-right: 16px;
        }
        &__text {
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
  }
  .component-assets {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
  .add-property,
  .uneditable-info:first-child {
    margin-bottom: 32px !important;
    .upload-image {
      margin: 0 !important;
    }
  }
  .no_transform_defect-form__label,
  .defect-form__label {
    margin-bottom: 0;
  }
  .no_transform_defect-form__label {
    text-transform: initial !important;
  }
  .input,
  .multiselect {
    margin-top: 4px;
  }
  .location-expandable-section {
    .section-content {
      border: none;
    }
  }
  .uneditable-info {
    margin-bottom: 12px;
  }
  .colors-container {
    border-bottom: 1px solid var(--box-items-separator-color);
    margin-bottom: 12px;
    padding-bottom: 3px;
  }
  .date-container {
    border-bottom: 1px solid var(--box-items-separator-color);
    padding-bottom: 12px;
  }
  .details__toggle {
    margin-bottom: 20px !important;
  }
}
