.create-graphing-group-modal {
  display: flex;
  flex-direction: column;
  max-width: 75vw;
  min-height: 500px;
  min-width: 75vw;
  margin-top: 16px;
  .step-title {
    margin-top: 20px;
  }
  .step-desc {
    margin: 20px 0;
  }
  .b-top {
    border-top: 1px solid var(--separator-color);
    padding-top: 20px;
  }
  .pointer {
    cursor: pointer;
  }
}
