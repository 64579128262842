.twp-body {
  display: flex;
  flex-direction: row;
  width: 100%;
  &__row {
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 8px 0;
    width: 100%;
    &:nth-child(odd) {
      background-color: var(--ch-table-even-row);
    }
    &:hover {
      background-color: var(--ch-table-row-hover);
    }
    &.selected {
      background-color: var(--ch-table-row-hover);
      border: 1px solid var(--secondary-theme-color);
    }
    &__cells {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      &__cell {
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
        line-height: 20px;
        max-width: 15%;
        min-height: 28px;
        min-width: 5%;
        padding: 4px;
        word-break: break-word;
        &__container {
          display: flex;
          gap: 4px;
          &__text {
            color: var(--ch-text);
            font-size: 12px;
            font-weight: 700;
          }
        }
      }
    }
    &__path {
      align-items: center;
      color: var(--ch-text);
      display: flex;
      font-size: 14px;
      font-weight: 400;
      justify-content: flex-start;
      padding-left: 36px;
      width: 100%;
    }
  }
  &__row-click-disabled {
    opacity: 0.5;
    .twp-body__row__cells {
      cursor: not-allowed;
    }
  }
}
.sticky {
  &__up,
  &__down {
    position: sticky;
    z-index: 1;
  }
  &__up {
    top: 56px;
  }
  &__down {
    bottom: 0;
  }
}
