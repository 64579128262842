@import '../../../../../common/styles/variables';

.time-series-graph-configuration {
  height: 100%;
  overflow: hidden;
  position: relative;
  &__graph-wrapper {
    width: 100%;
    height: 100%;
    &.with-right-padding {
      padding-right: $ml-time-series-graph-right-toolbar;
    }
  }
}
