.alarms-form-fields {
  .double {
    display: flex;
    .split-input:nth-child(1) {
      padding-right: 10px;
    }
    .split-input:nth-child(2) {
      padding-left: 10px;
    }
  }
  .split-input {
    width: 50%;
  }
  .without-padding {
    padding: 0;
  }
  &__alarm-wrapper {
    border-bottom: 1px solid var(--separator-color);
    margin-bottom: 18px;
    padding-bottom: 16px;
    .alarm-content {
      &__header {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .alarm-form-field {
    margin: 32px 0 16px;
  }
}
