.notification {
  align-items: center;
  animation-duration: 3s;
  animation-fill-mode: both;
  animation-name: notification;
  background-color: var(--notification-background);
  border-radius: 12px;
  border: 1px solid #b2dece;
  box-shadow: 0px 12px 16px -4px rgba(194, 204, 225, 0.08), 0px 4px 6px -2px rgba(194, 204, 225, 0.03);
  display: flex;
  height: 44px;
  justify-content: center;
  left: calc(50% - 250px);
  padding: 12px;
  position: absolute;
  top: 32px;
  width: 500px;
  z-index: 500;
  &--show {
    display: flex;
  }
  &--hide {
    display: none;
  }
  &--warning {
    border: 2px solid #f9d9a6;
  }
  &--error {
    border: 2px solid #e88aa1;
  }
  &--success {
    border: 2px solid #b2dece;
  }
  &--info {
    border: 2px solid #d0d0d0;
  }
  &__info {
    align-items: center;
    animation-duration: 3s;
    animation-fill-mode: both;
    animation-name: notification-info;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-width: 20px;
    margin-left: 12px;
  }
  &__icon {
    align-items: center;
    border-radius: 18px;
    display: flex;
    height: 20px;
    justify-content: center;
    width: 20px;
    &--warning {
      background-color: #f3ad3d;
    }
    &--error {
      background-color: #c03;
    }
    &--success {
      background-color: #58b895;
    }
    &--info {
      background-color: #b3b3b3;
    }
    .icon-close,
    .icon-warning,
    .icon-trash,
    .icon-info,
    .icon-check-toast {
      path {
        stroke: white;
      }
    }
  }
  &__info-text {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-width: calc(100% - 12px);
    &__content {
      overflow: hidden;
      padding: 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc(100% - 36px);
      color: #262626 !important;
      font-size: 14px;
    }
  }
  &__close {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 10%;
  }
  &__button {
    align-items: center;
    background: none;
    border: 0;
    cursor: pointer;
    width: 20px;
    .icon-close path {
      stroke: var(--notification-close-icon);
    }
  }
  &.full-width {
    left: calc(50% - 300px) !important;
    width: 600px !important;
  }
}

@keyframes notification {
  0% {
    height: 44px;
    left: calc(50% - 250px);
    padding: 0 26px 0 8px;
    top: 0;
    width: 500px;
  }
  50% {
    height: 44px;
    left: calc(50% - 250px);
    padding: 0 26px 0 8px;
    top: 32px;
    width: 500px;
  }
  75% {
    height: 44px;
    left: calc(50% - 250px);
    padding: 0 26px 0 8px;
    top: 32px;
    width: 500px;
  }
  100% {
    height: 44px;
    left: calc(50% - 250px);
    padding: 0 26px 0 8px;
    top: 32px;
    width: 500px;
  }
}
