.maintenance-regime-wrapper {
  .empty-state {
    background-color: transparent;
    height: 55vh;
    margin: auto;
  }
  .page-navigation {
    &__wrapper {
      padding: 0 0 16px;
    }
  }
  .maintenance-regime-table {
    height: calc(100vh - 220px);
    overflow: auto;
  }
  .header {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    padding-top: 16px;
    .search-wrapper {
      margin-right: auto;
      width: 320px;
      .search {
        padding-left: 0;
      }
    }
  }
  .work-order-section-checklist-details {
    .expandable-section {
      .header {
        margin-bottom: 0;
        padding-top: 0;
      }
    }
  }
  table {
    th:last-child {
      min-width: auto;
    }
  }
  .regime-priority {
    align-items: center;
    display: flex;
    .regime-priority-color {
      border-radius: 50%;
      height: 16px;
      margin-right: 4px;
      width: 16px;
    }
    .regime-hazard-icon {
      margin-left: 4px;
    }
  }
  .regime-popover {
    align-items: center;
    display: flex;
    p {
      margin-right: 4px;
    }
  }
  .rw-list {
    background-color: var(--background-color);
    .rw-list-option {
      color: var(--primary-font-color);
      &:hover {
        background-color: var(--background-color);
      }
    }
  }
  .checklist-details-modal {
    z-index: 100;
    .modal-wrapper {
      max-width: 50vw;
      min-width: 50vw;
    }
    .modal {
      max-width: 50vw;
      min-height: 70vh;
    }
  }
}
.create-regime-modal {
  .modal-wrapper {
    max-height: 100vh;
    overflow-y: auto;
    width: 800px;
  }
}
.generic-modal {
  .modal-wrapper {
    max-width: 500px;
    .modal {
      &__content {
        text-align: center;
      }
    }
  }
}
