.dropzone-wrapper {
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  .drop-field {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    outline: none;
    width: 100%;
    &.disabled {
      cursor: not-allowed;
    }
  }
}
