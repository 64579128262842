.select-container {
  position: relative;
  &.disabled {
    .rw-widget-input.rw-widget-picker.rw-widget-container {
      opacity: 0.3;
    }
  }
  .rw-state-readonly {
    .rw-widget-input {
      cursor: not-allowed;
    }
  }
  .rw-widget {
    &.error {
      .rw-widget-input.rw-widget-picker.rw-widget-container {
        border-bottom: 1px solid var(--validation-error-color);
      }
    }
  }
  .rw-state-focus .rw-widget-picker.rw-widget-input {
    box-shadow: none;
  }
  .rw-list-option:empty {
    display: none;
  }
  .rw-widget-input {
    background-color: var(--input-background-color);
    background-position: center right;
    background-repeat: no-repeat;
    border: 1px solid var(--input-border-color);
    border-radius: 4px;
    color: var(--primary-font-color);
    cursor: pointer;
    font-family: $primary-font-family;
    font-size: 14px;
    font-weight: normal;
    height: $input-size-sm;
    margin: 0;
    outline: none;
    overflow: hidden;
    transition: 0.2s all;
    width: 100%;
  }
  .rw-list-option.rw-state-selected {
    background-color: var(--secondary-font-color-dark);
    border: 0;
  }
  &.lg {
    .rw-widget-input {
      height: $input-size-lg;
    }
  }
  &.xl {
    height: $input-size-xl;
  }
  .rw-popup-container {
    left: 0;
    padding: 0;
    right: 0;
    width: 100%;
  }
}
