@import '../../styles/variables' ;
.single-radio {
  align-items: center;
  background-color: var(--box-items-theme-color);
  border: 1px solid var(--box-items-separator-color);
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  margin: 10px 0;
  padding: 20px 0;
  position: relative;
  &.disabled {
    opacity: 0.6;
  }
  .radio-label {
    padding-left: 10px;
  }
  &.active {
    border-color: var(--secondary-theme-color);
  }
  [type='radio']:checked,
  [type='radio']:not(:checked) {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  [type='radio']:checked + label,
  [type='radio']:not(:checked) + label {
    color: var(--secondary-font-color-dark);
    cursor: pointer;
    line-height: 20px;
    padding-left: 48px;
    position: relative;
  }
  //big circle
  [type='radio']:checked + label::before,
  [type='radio']:not(:checked) + label::before {
    background: var(--box-items-theme-color);
    border-radius: 100%;
    content: '';
    height: 18px;
    left: 20px;
    position: absolute;
    top: 0;
    width: 18px;
  }
  [type='radio']:not(:checked) + label::before {
    border: 1px solid var(--box-items-separator-color);
  }
  [type='radio']:checked + label::before {
    border: 1px solid var(--secondary-theme-color);
  }
  //end big circle

  //small circle
  [type='radio']:checked + label::after,
  [type='radio']:not(:checked) + label::after {
    background: var(--secondary-theme-color);
    border-radius: 100%;
    content: '';
    height: 12px;
    left: 24px;
    position: absolute;
    top: 4px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    width: 12px;
  }

  //end small circle
  [type='radio']:not(:checked) + label::after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type='radio']:checked + label::after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  [type='radio']:checked + label.custom-label::before,
  [type='radio']:not(:checked) + label.custom-label::before {
    top: auto;;
  }
  [type='radio']:checked + label.custom-label::after,
  [type='radio']:not(:checked) + label.custom-label::after {
    top: auto;
  }


}


