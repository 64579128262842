.work-order-files {
  .files-items-list {
    .file-container {
      &:hover {
        .handle-hover {
          path {
            stroke: var(--secondary-theme-color);
          }
        }
      }
      .image-container {
        cursor: pointer;
      }
      .filename {
        cursor: pointer;
      }
    }
    .files-empty-state {
      align-items: center;
      border: 1px solid #c6c6c6;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      padding: 32px 0;
      width: 100%;
      .upload-files-container {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        .upload-files-icon {
          margin-bottom: 4px;
        }
      }
      .upload-description {
        font-size: 12px;
        margin-top: 4px;
      }
    }
    .work-order-upload-btn {
      color: var(--secondary-theme-color);
      font-size: 14px;
      font-weight: 700;
      margin-top: 12px;
    }
    .work-order-file-name {
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 8px;
      margin-top: 8px;
    }
  }
}
