.change-password {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  &-container {
    background-color: var(--box-items-theme-color);
    min-width: 413px;
    padding: 30px;
    .buttons {
      display: flex;
      flex-flow: wrap row;
      justify-content: center;
      button {
        flex-grow: 1;
        margin: 10px;
        max-width: 40%;
      }
    }
  }
}
