.add-module-item-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 66vh;
  max-width: 50vw;
  min-height: 66vh;
  min-width: 50vw;
  .input-with-dropdown {
    .search {
      background-color: var(--input-background-color);
      border: 1px solid var(--input-border-color);
      margin-top: 4px;
      padding: 15px 14px;
      &__icon {
        fill: var(--secondary-font-color-dark);
        g,
        path {
          stroke: var(--secondary-font-color-dark);
        }
      }
      .items-dropdown {
        top: 52px;
        width: 100%;
      }
    }
    .search-input-label {
      color: var(--secondary-font-color-light);
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }
  &__popover-title {
    color: var(--ui-color-secondary);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 12px;
  }
  &__buttons {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
  }
}
