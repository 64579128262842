.measurement-point-details-form {
  .double {
    display: flex;
    .split-input:nth-child(1) {
      padding-right: 10px;
      .search {
        margin-top: 13px;
        border: 1px solid var(--input-border-color) !important;
      }
    }
    .split-input:nth-child(2) {
      padding-left: 10px;
    }
  }
  .split-input {
    width: 50%;
    .field-wrapper {
      height: 115px;
    }
  }
  .without-padding {
    padding: 0;
  }
  .unit-input {
    .items-dropdown {
      .empty-state {
        height: 100%;
      }
      border-radius: 4px;
      border: 1px solid var(--input-border-color);
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2), 0px 4px 4px 4px rgba(0, 0, 0, 0.05);

      z-index: 10000;
      overflow-y: auto;
      max-height: 400px;
      width: 385px;
      background-color: var(--dropdown-bg);

      .field-wrapper {
        height: unset;
      }

      .group-item-wrapper {
        align-items: center;
        cursor: pointer;
        display: flex;
        padding: 8px;
        &:hover {
          background-color: #e9ecef;
          border-color: #e9ecef;
        }
        .checkbox-wrapper {
          label {
            margin-left: 0;
          }
        }
      }
    }
  }
}
