$module-header-height: 132px;
.readings-and-gauges {
  height: 100%;
  width: 100%;
  .double-table {
    height: calc(100vh - #{$module-header-height});
    width: 100%;
    &__table {
      height: 50%;
      overflow: hidden;
      width: 100%;
      .single-item-table {
        height: 100%;
        &.location {
          .table-template {
            height: calc(100% - 53px);
          }
        }
        &.points {
          .table-template {
            height: 100%;
          }
        }
      }
    }
  }
}

.pick-and-link-components-modal {
  &__picker {
    max-height: 800px;
    min-width: 710px;
    width: 710px;
  }
}

.capitalized {
  text-transform: capitalize;
}
