.change-status-container {
  margin-bottom: 30px;
  padding: 0 20px;
  .rw-list {
    padding-top: 0;
  }
  .change-status-select-item-disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
  .change-status-select-item {
    .status-item-display {
      align-items: center;
      cursor: pointer;
      display: flex;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 20px;
      .certificate-status-icon {
        height: 20px;
        margin-right: 8px;
        width: 20px;
      }
      .certificate-status-icon-disabled {
        path,
        circle {
          fill: #b2b2b2;
          fill-opacity: 0.5;
          stroke: #b2b2b2;
          stroke-opacity: 0.5;
        }
      }
    }
    .display-value-item {
      align-items: center;
      cursor: pointer;
      display: flex;
      .certificate-status-icon {
        height: 20px;
        margin-right: 8px;
        width: 20px;
      }
    }
  }
  .field-wrapper {
    .inline-wrapper {
      .field-wrapper__label {
        font-size: 14px;
        font-weight: 500;
      }
    }
    .input-wrapper {
      .select-container {
        .rw-widget-input {
          background-color: var(--input-background-color) !important;
          border: 1px solid var(--input-border-color) !important;
          border-radius: 4px !important;
        }
      }
    }
  }
}
