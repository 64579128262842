.display-status {
  align-items: center;
  display: flex;
  &__title {
    font-size: 14px;
    line-height: 20px;
  }
  &__icon {
    margin-right: 4px;
  }
}
