// Import base styles first
@import './date-range-picker-base';

// Then import all picker styles
@import './year-range-picker.scss';
@import './month-range-picker.scss';
@import './week-range-picker.scss';
@import './day-range-picker.scss';

.date-range-picker-field {
  position: relative;
  transition: 0.2s all;
  width: 100%;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.sm {
    .date-range-picker-field__input {
      font-size: 12px;
      height: 32px;
    }
  }

  &.lg {
    .date-range-picker-field__input {
      font-size: 14px;
      height: 52px;
    }
  }

  &__input {
    align-items: center;
    background-color: var(--input-background-color);
    border: 1px solid var(--input-border-color);
    border-radius: 4px;
    color: var(--primary-font-color);
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: normal;
    justify-content: space-between;
    outline: none;
    padding: 0 12px;
    transition: all 0.2s ease;

    &.error {
      border-color: var(--validation-error-color);
    }

    .dropdown-icon {
      color: var(--primary-font-color);
      margin-left: 8px;
      path {
        stroke: var(--primary-font-color);
      }
    }
  }

  &__value {
    color: var(--primary-font-color);
    flex: 1;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:empty::before {
      color: var(--primary-font-color);
      content: attr(placeholder);
    }
  }

  &__dropdown {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 12px 16px -4px rgba(194, 204, 225, 0.08), 0 4px 6px -2px rgba(194, 204, 225, 0.03);
    left: 0;
    position: absolute;
    right: 0;
    top: calc(100% - 10px);
    z-index: 1000;
    font-family: var(--primary-font-family);
  }
}

// Error message styling
.error-message {
  &--visible {
    color: var(--validation-error-color);
    font-size: 12px;
    margin-top: 4px;
  }

  &.with-hidden-error-message {
    visibility: hidden;
  }
}
