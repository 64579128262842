@import '../../../common/styles/variables';
.pdf-with-drawing {
  background-color: var(--primary-theme-light-color);
  height: 100vh;
  max-width: 70vw;
  width: auto;
  z-index: 0;
  &__controls {
    bottom: 0;
    position: absolute;
    right: 0;
    transition: 0.7s all ease;
    z-index: 1;
    .reset-button {
      cursor: pointer;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
    &.right-padding {
      right: $inspection-right-toolbar;
    }
  }
  &__wrapper {
    max-height: 100%;
    max-width: 100%;
    position: relative;
    .loader {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      position: absolute;
      width: 100%;
    }
    .canvas-drawer {
      height: auto;
      left: 0;
      position: absolute;
      top: 0;
      width: auto;
      z-index: 1;
    }
  }
  &__loader {
    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
  }
  &__page-footer {
    position: relative;
    z-index: 99;
    &--page-number {
      align-items: center;
      color: var(--marker-inner);
      display: flex;
      font-size: 12px;
      justify-content: center;
      text-align: center;
    }
    &--page-navigation-buttons {
      align-items: center;
      display: flex;
      justify-content: center;
      svg {
        cursor: pointer;
      }
      .invert-rotate {
        transform: rotate(180deg);
      }
    }
  }
}
.react-transform-component {
  height: 100%;
  width: 100%;
}
