@import './variables';
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Michroma&display=swap');
* {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}
body {
  background-color: var(--primary-theme-light-color);
  box-sizing: border-box;
  color: var(--default-font-color);

  // default font settings
  font-family: $primary-font-family;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
}
h1 {
  color: var(--default-font-color);
  font-family: $primary-font-family;
  font-size: 48px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 72px;
}
h2 {
  color: var(--default-font-color);
  font-family: $primary-font-family;
  font-size: 32px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 48px;
}
h3 {
  color: var(--default-font-color);
  font-family: $primary-font-family;
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 36px;
}
h4 {
  color: var(--default-font-color);
  font-family: $primary-font-family;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 32px;
}
h5 {
  color: var(--default-font-color);
  font-family: $primary-font-family;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 28px;
}
h6 {
  color: var(--default-font-color);
  font-family: $primary-font-family;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 24px;
}
p {
  color: var(--default-font-color);
  font-family: $primary-font-family;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 20px;
}
label {
  color: var(--secondary-font-color-dark);
  font-family: $primary-font-family;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 20px;
}
ol {
  list-style-position: inside;
  list-style-type: decimal;
}
.f-primary {
  color: var(--primary-font-color);
}
.f-secondary-dark {
  color: var(--secondary-font-color-dark);
}
.f-secondary-light {
  color: var(--secondary-font-color-light);
}
.f-secondary-green {
  color: var(--secondary-theme-color);
}
.f-secondary-red {
  color: var(--validation-error-color);
}
.f-warning {
  color: var(--severity-orange);
}
.f-white {
  color: white;
}
table {
  td,
  th {
    font-family: $primary-font-family;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    padding: 10px 5px;
    text-align: left;
  }
  thead {
    tr {
      border-bottom: 1px solid var(--box-items-separator-color);
      color: var(--secondary-theme-color);
    }
  }
  tbody {
    tr {
      color: var(--secondary-font-color-light);
      &:first-child {
        td {
          padding-top: 15px;
        }
      }
    }
  }
}

// SVG FILL
// it will apply on g
.svg-primary-g {
  g {
    fill: var(--secondary-theme-color);
  }
  path {
    fill: var(--secondary-theme-color);
  }
  circle {
    fill: var(--secondary-theme-color);
  }
}
.svg-black-g {
  g {
    fill: var(--default-font-color);
  }
  path {
    fill: var(--default-font-color);
  }
  circle {
    fill: var(--default-font-color);
  }
}
.svg-white-g {
  g {
    fill: var(--primary-font-color);
  }
  path {
    fill: var(--primary-font-color);
  }
  circle {
    fill: var(--primary-font-color);
  }
}
.svg-gray-g {
  g {
    fill: var(--secondary-font-color-dark);
  }
  path {
    fill: var(--secondary-font-color-dark);
  }
  circle {
    fill: var(--secondary-font-color-dark);
  }
}
.svg-full-white-g {
  g {
    fill: white;
  }
  path {
    fill: white;
  }
  circle {
    fill: white;
  }
}
//it will apply on svg
.svg-primary {
  fill: var(--secondary-theme-color);
}
.svg-black {
  fill: var(--default-font-color);
}
.svg-white {
  fill: var(--primary-font-color);
}
.svg-gray {
  fill: var(--secondary-font-color-dark);
}
.svg-warning {
  fill: var(--warning-color);
}

// SVG STROKE
// it will apply on g
.stroke-primary-g {
  g {
    stroke: var(--secondary-theme-color);
  }
  path {
    stroke: var(--secondary-theme-color);
  }
}
.stroke-black-g {
  g {
    stroke: var(--default-font-color);
  }
  path {
    stroke: var(--default-font-color);
  }
}
.stroke-white-g {
  g {
    stroke: var(--primary-font-color);
  }
  path {
    stroke: var(--primary-font-color);
  }
}
.stroke-gray-g {
  g {
    stroke: var(--secondary-font-color-dark);
  }
  path {
    stroke: var(--secondary-font-color-dark);
  }
}
.stroke-full-white-g {
  g {
    stroke: white;
  }
  path {
    stroke: white;
  }
}
//it will apply on svg
.stroke-primary {
  stroke: var(--secondary-theme-color);
}
.stroke-black {
  stroke: var(--default-font-color);
}
.stroke-white {
  stroke: var(--primary-font-color);
}
.stroke-gray {
  stroke: var(--secondary-font-color-dark);
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
//logo color
.navigation-container,
.generic-container {
  .logo svg #Group-4 path:first-child {
    fill: var(--logo-color);
  }
}
.beta {
  color: red !important;
  pointer-events: none;
  position: relative;
  text-decoration: line-through !important;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  &::before {
    background-color: rgba(0, 0, 0, 0.1);
    bottom: 0;
    color: red;
    content: 'beta';
    display: inline-block;
    font-size: 12px;
    font-weight: 1000;
    left: 0;
    position: absolute;
    right: 0;
    top: -14px;
    width: 100%;
  }
}
.new-badge {
  position: relative;
  user-select: none;
  &::before {
    background-color: var(--secondary-theme-color);
    border-radius: 6px;
    color: var(--default-font-color);
    content: 'NEW';
    font-size: 6px;
    font-weight: bold;
    padding: 1px 5px;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(100%, -50%);
  }
}
.new-badge-md {
  position: relative;
  user-select: none;
  &::before {
    background-color: var(--secondary-theme-color);
    border-radius: 6px;
    color: var(--default-font-color);
    content: 'NEW';
    font-size: 8px;
    font-weight: bold;
    padding: 2px 5px;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-50%, -50%);
  }
}
.coming-soon,
.coming-soon-md {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: not-allowed;
  pointer-events: none;
  position: relative;
  user-select: none;
  ::before {
    background-color: var(--secondary-theme-color);
    border-radius: 6px;
    color: var(--default-font-color);
    content: 'SOON';
    font-size: 6px;
    font-weight: bold;
    left: 0;
    padding: 1px 5px;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: translate(60%, -20%);
  }
}
.coming-soon-md {
  &::before {
    font-size: 8px;
    font-weight: bold;
    padding: 2px 5px;
  }
}
.link {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }
}
.hidden-content {
  display: none !important;
  visibility: hidden;
}
.auto-scroll {
  overflow: auto;
}
.background-blurred {
  filter: blur(8px);
}
::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 100px;
  border-radius: 100px;
  height: $scrollbar-size-default;
  width: $scrollbar-size-default;
}
::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}
::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: rgba(78, 78, 78, 0.5);
  -webkit-border-radius: 100px;
  border-radius: 100px;
}
::-webkit-scrollbar-thumb:active {
  background: rgba(var(--secondary-theme-color-dark-rgb), 0.5);
  -webkit-border-radius: 100px;
  border-radius: 100px;
}
.bold {
  font-weight: 700;
}
.light-bold {
  font-weight: 500;
}
.default-background {
  background-color: var(--background-color) !important;
}
.text-transform-none {
  text-transform: none;
  &::placeholder {
    text-transform: none !important;
  }
}
.text-lowercase {
  text-transform: lowercase;
}

// flexbox helpers
.d-flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.justify-content-between {
  justify-content: space-between;
}

// margin helpers
.ml-auto {
  margin-left: auto;
}
.ml-8 {
  margin-left: 8px;
}
.mr-auto {
  margin-right: auto;
}
.mr-8 {
  margin-right: 8px;
}
.mr-10 {
  margin-right: 10px;
}

// margin top
.mt-0 {
  margin-top: 0px !important;
}
.mt-4 {
  margin-top: 4px !important;
}
.mt-12 {
  margin-top: 12px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-32 {
  margin-top: 32px;
}
.mt-unset {
  margin-top: unset;
}

// margin bottom
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-4 {
  margin-bottom: 4px !important;
}
.mb-8 {
  margin-bottom: 8px !important;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-16 {
  margin-bottom: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-32 {
  margin-bottom: 32px;
}
.mb-unset {
  margin-bottom: unset;
}

// padding helpers
.pr-10 {
  padding-right: 10px;
}
.pr-12 {
  padding-right: 12px;
}
.px-unset {
  padding-left: unset;
  padding-right: unset;
}

//legend-color

//search highlight
.search-term-text {
  background-color: rgba(var(--secondary-theme-color-rgb), 0.3);
}
.scroll-vertical {
  overflow-y: scroll;
}

//severity related
.severity-icon {
  align-items: center;
  border-radius: 12px;
  color: var(--default-font-color);
  display: flex;
  height: 21px;
  justify-content: center;
  width: 21px;
  &.severity-green {
    background-color: var(--severity-green);
  }
  &.severity-red {
    background-color: var(--severity-red);
    color: #fff;
  }
  &.severity-orange {
    background-color: var(--severity-orange);
  }
  &.severity-yellow {
    background-color: var(--severity-yellow);
  }
}

// alarm icon
.alarm-icon {
  width: 20px;
  height: 20px;
  &.lightOrange {
    svg {
      flex-shrink: 0;
      path {
        stroke: #000;
        stroke-width: 1.5;
        stroke-linecap: round;
        stroke-linejoin: round;
      }
      circle {
        fill: #f69833;
      }
    }
  }
  &.red {
    flex-shrink: 0;

    svg {
      flex-shrink: 0;
      path {
        stroke: #ffff;
        stroke-width: 1.5;
        stroke-linecap: round;
        stroke-linejoin: round;
      }
    }
    circle {
      fill: #cc0033;
    }
  }
  &.yellow {
    flex-shrink: 0;
    svg {
      path {
        stroke: #000;
        stroke-width: 1.5;
        stroke-linecap: round;
        stroke-linejoin: round;
      }
      circle {
        fill: #fecf33;
      }
    }
  }
}

//overflow
.overflow-inherit {
  overflow-y: inherit !important;
}

//text helpers
.break-text {
  word-break: break-all;
}
.three-dot-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.w-24-fixed {
  min-width: 24px;
  max-width: 24px;
}

.align-top {
  align-items: flex-start !important;
}

.font-700 {
  font-weight: 700;
}

.font-500 {
  font-weight: 500;
}

.font-400 {
  font-weight: 400;
}
