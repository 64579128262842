.screenshot-picker {
  display: flex;
  height: calc(100vh - 250px);
  width: calc(100vw - 250px);
  .inspection {
    flex: 1;
    height: inherit;
  }
  .potree-area {
    height: 100%;
    width: 100%;
  }
  .progress {
    background-color: rgba(var(--background-color-rgb), 0.4);
    border: 1px solid var(--secondary-theme-color-dark);
    border-radius: 12px;
    bottom: 15px;
    box-shadow: #a1a1a1 0 2px 2px inset;
    height: 20px;
    pointer-events: none;
    position: absolute;
    right: 50%;
    text-align: center;
    transform: translateX(50%);
    width: 200px;
    &__bar {
      background-image: linear-gradient(to bottom, var(--secondary-theme-color), var(--secondary-theme-color-dark));
      border-radius: 12px;
      position: absolute;
    }
    &__text {
      position: absolute;
      // color: white;
      text-transform: capitalize;
      width: 100%;
    }
  }
  .sidebar {
    padding: 0 20px;
    position: relative;
    width: 375px;
    display: flex;
    flex-direction: column;
    .point-images {
      &__section {
        padding: 0;
      }
    }
  }
  .screenshot {
    &::after {
      animation-duration: 0.4s;
      animation-iteration-count: 1;
      animation-name: takeScreenshot;
      background-color: transparent;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
  .screenshot-description {
    margin: 10px;
  }
  @keyframes takeScreenshot {
    0% {
      background-color: rgba(255, 255, 255, 0.8);
    }
    40% {
      background-color: transparent;
    }
    80% {
      background-color: rgba(255, 255, 255, 0.8);
    }
    100% {
      background-color: transparent;
    }
  }
}
