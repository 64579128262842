.change-measurement-group-modal {
  max-width: 45vw;
  min-width: 45vw;
  max-height: 65vh;
  min-height: 65vh;
  .table-template {
    .table-template-no-data-text {
      .empty-state-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50vh;
        .empty-state-container__content {
          p {
            width: 100%;
          }
        }
      }
    }
  }
  .groups-table {
    max-height: 60vh;
    min-height: 60vh;
    .twp {
      min-width: 100%;
    }
    .name-column {
      max-width: 40% !important;
      min-width: 40% !important;
      p {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
    .created-at-column,
    .created-by-column {
      // using 32px here, since it is split on 2 columns instead of 1
      max-width: calc(30% - 32px) !important;
      min-width: calc(30% - 32px) !important;
    }
    .action-column {
      max-width: 32px !important;
      min-width: 32px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.current-group-popover {
  border-radius: 4px;
  border: 1px solid var(--notification-close-icon);
  padding: 16px;
}
