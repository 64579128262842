.pagination-navigator {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btn {
    margin: 0;
    min-width: 220px;
    .button-text {
      font-weight: 500;
    }
  }
  .pagination-info {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
}
