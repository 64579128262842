.work-areas {
  flex: 1 0;
  overflow: auto;
  .items-table-renderer {
    display: flex;
    flex-direction: column;
    table {
      thead {
        tr {
          border: 0;
          th {
            color: var(--secondary-font-color-dark);
            outline: thin solid var(--background-color) !important;
          }
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid var(--box-items-separator-color);
          &:hover {
            border-color: unset;
            outline: unset;
            border-bottom: 1px solid var(--secondary-theme-color);
            &::after {
              background-color: unset;
            }
          }
        }
        .name-column {
          width: 100%;
        }
      }
    }
    .empty-state {
      background-color: unset;
      flex: 1;
      height: auto;
      margin: 0;
    }
  }
  .defect-form {
    padding: 10px;
  }
  &__table-actions {
    display: flex;
    svg {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
  &__name {
    align-items: center;
    display: flex;
    gap: 8px;
    width: 100%;
    &__status-icon {
      border-radius: 50%;
      height: 16px;
      width: 16px;
    }
    &__text {
      width: calc(100% - 13px - 8px);
    }
  }
}

.work-areas-action__delete {
  .modal-wrapper {
    padding: 20px 24px;
    p {
      margin-top: 16px;
      margin-bottom: 32px;
    }

    .button-text {
      font-weight: 500;
      text-transform: capitalize;
    }
  }
}
