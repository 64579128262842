$main-height: 235px;
.l-toolbar {
  display: grid;
  flex-direction: column;
  grid-template-rows: $main-height calc(100% - #{$main-height});
  height: 100%;
  padding: 100px 20px 0;
  .search {
    margin: 20px 0;
  }
  .field-wrapper {
    margin-top: 20px;
    .error-message {
      display: none;
    }
  }
  .components {
    .section-title {
      margin-bottom: 10px;
      &:first-child {
        margin-top: 0;
      }
    }
    .components__item.selected {
      background-color: unset;
      border-bottom: 1px solid var(--secondary-theme-color);
    }
  }
  .ml {
    margin-left: 10px;
  }
  .component-flter {
    .inline-wrapper {
      display: flex;
    }
    .info-tooltip {
      margin-left: 10px;
    }
  }
  .delete-icon {
    fill: var(--primary-font-color);
    height: 18px;
    width: 18px;
    &:hover {
      fill: var(--validation-error-color);
      g {
        fill: var(--validation-error-color);
      }
    }
  }
  .items-renderer {
    &__loader {
      display: flex;
      position: absolute;
    }
    &__loader + div {
      .item {
        pointer-events: none;
        visibility: hidden;
      }
    }
  }
  .section {
    position: relative;
    .ramaining {
      bottom: 0;
      font-size: 11px;
      position: absolute;
      right: 0;
    }
  }
}
