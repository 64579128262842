.ch-display {
  height: calc(100vh - 135px);
  padding-bottom: 25px;
  width: 100%;

  .twp {
    min-width: 100%;
    &-body {
      &__row {
        padding: 2px 0;
      }
    }
  }

  &__table {
    &.three-d-mode {
      .code-column {
        color: var(--primary-font-color);
        font-size: 14px;
        font-weight: 400;
        max-width: 100px !important;
        min-width: 90px !important;
        .component-name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 86px;
        }
      }
      .name-column {
        color: var(--primary-font-color);
        font-size: 14px;
        font-weight: 400;
        max-width: calc(100% - 72px - 100px - 46px) !important;
        min-width: calc(100% - 72px - 100px - 46px) !important;

        .assign-components__name__text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
        }
        .assign-components__name__status-icon {
          min-width: 16px;
          min-height: 16px;
        }
      }
      .icon-column {
        align-items: center;
        justify-content: center;
        max-width: 36px !important;
        min-width: 36px !important;
        &__button {
          background-color: inherit;
          border: 0;
          cursor: pointer;
        }
      }
      .action-menu-column {
        align-items: center;
        justify-content: flex-end;
        max-width: 82px !important;
        min-width: 36px !important;
        gap: 4px;
        &__button {
          background-color: inherit;
          border: 0;
          cursor: pointer;
        }
      }

      .warnings-column {
        .component-warnings {
          display: flex;
          gap: 8px;
          justify-content: flex-end;
          width: fit-content;
          max-width: none !important;
          min-width: none !important;
        }
      }
    }
    &.full-screen-mode {
      .code-column {
        color: var(--primary-font-color);
        font-size: 14px;
        font-weight: 400;
        max-width: 200px !important;
        min-width: 50px !important;
      }
      .name-column,
      .owner-column,
      .cost-centre-column,
      .maintenance-id-column,
      .location-column {
        color: var(--primary-font-color);
        font-size: 14px;
        font-weight: 400;
        max-width: calc((100% - 232px) / 5) !important;
        min-width: calc((100% - 232px) / 5) !important;
      }
      .icon-column {
        align-items: center;
        justify-content: center;
        max-width: 32px !important;
        min-width: 32px !important;
      }
    }
  }

  &__path {
    display: flex;
    &.hide {
      display: none;
    }
    &__component {
      height: 100%;
      width: calc(100% - 35px);
    }
    &__action {
      width: 35px;
      &.hide {
        display: none;
      }
      &__button {
        background-color: inherit;
        border: 0;
        cursor: pointer;
      }
    }
  }

  &__table {
    height: 100%;
    &.path-displayed {
      height: calc(100% - 50px);
    }
  }

  &__level {
    &__name {
      display: flex;
      height: 100%;
      width: 100%;
    }
    &__details {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      margin-left: 8px;
      .info-popover {
        z-index: 3 !important;
      }
    }
  }

  .level-popover {
    max-width: 200px;
    .single-detail-item {
      margin-top: 12px;
      .single-detail-item-title {
        margin-bottom: 4px;
      }
    }
  }

  .components-table {
    .code-column {
      color: var(--primary-font-color);
      font-size: 14px;
      font-weight: 400;
      max-width: 200px;
      min-width: 50px;

      .component-code {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 150px;
      }
    }

    .owner-column,
    .cost-centre-column,
    .maintenance-id-column,
    .location-column,
    .equipment-name-column,
    .new-column {
      color: var(--primary-font-color);
      font-size: 14px;
      font-weight: 400;
      max-width: calc((100% - 260px) / 6) !important;
      min-width: calc((100% - 260px) / 6) !important;
    }

    .icon-column {
      align-items: center;
      justify-content: center;
      max-width: 32px !important;
      min-width: 32px !important;
    }
  }
}
