.dms-labels {
  align-items: center;
  display: flex;
  &__wrapper {
    align-items: center;
    display: flex;
    overflow-x: auto;
    padding-left: 1px;
    padding-top: 5px;
    width: 100%;
    &__item {
      align-items: center;
      border: 1px solid var(--box-items-separator-color);
      border-radius: 70px;
      cursor: pointer;
      display: flex;
      margin-bottom: 10px;
      margin-right: 10px;
      min-width: fit-content;
      outline: 1px solid transparent;
      padding: 8px;
      transition: all 0.2s ease-in-out;
      &.disabled {
        opacity: 0.6;
        pointer-events: none;
      }
      &__color {
        border-radius: 8px;
        height: 16px;
        margin-right: 8px;
        width: 16px;
      }
    }
    &__item-active {
      border: 1px solid var(--secondary-theme-color);
      outline: 1px solid var(--secondary-theme-color);
    }
  }
  &__more-wrapper {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-bottom: 17px;
    padding-left: 15px;
    .icon-plus {
      margin-right: 2px;
    }
  }
  &__more-wrapper-adjusted {
    margin-bottom: 10px;
    padding-left: 0;
  }
  &-expanded {
    &__wrapper {
      flex-wrap: wrap;
      overflow: hidden;
    }
  }
}
