@import '../../../common/styles/variables';
.page-navigation {
  // background-color: var(--background-color);
  &__wrapper {
    padding: 20px 13px;
    &__back-button {
      width: fit-content;
      .back-button {
        margin-bottom: 2px;
      }
      &__icon {
        margin-right: 0 !important;
        path {
          stroke: var(--secondary-font-color-dark) !important;
        }
      }
    }
    &__title-container {
      align-items: center;
      display: flex;
      &__title {
        align-items: center;
        display: flex;
        h5 {
          font-weight: 500;
          line-height: 28px;
        }
        &__icon {
          margin-right: 4px;
          margin-left: 5px;
          path {
            stroke: var(--secondary-theme-color);
          }
        }
      }
      &__menu {
        align-items: center;
        display: flex;
        margin-left: auto;
        gap: 8px;
        button {
          margin-right: 8px;
          .button-text {
            font-weight: 500;
          }
          .icon-plus-circle {
            margin-left: 4px !important;
          }
        }
      }
    }
  }
}
.page-navigation--with-border {
  border-bottom: 1px solid var(--box-items-separator-color);
}
