.add-work-order-cp-templates {
  min-height: 320px;
  overflow-y: hidden;
  .added-items-list {
    max-height: 700px !important;
  }
  .checklist-component-picker {
    padding-right: 10px;
    .added-items-list {
      overflow-y: hidden !important;
    }
  }
  .added-items-list {
    margin-top: 0 !important;
  }
}
