@import '../../../../common/styles/variables';
.common-component-container {
  display: flex;
  position: relative;
  .common-component-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    transition: margin 0.5s ease, padding-left 0.5s ease;
    &.footer-bar-opened {
      height: calc(100vh - #{$upload-bar-height});
      .sidebar-container,
      .google-map,
      .inspection {
        height: calc(100vh - #{$upload-bar-height});
      }
    }
    &.nav-space {
      margin-left: #{$nav-width};
      transition: margin 1s ease, padding-left 1s ease;
    }
    &.left-space {
      padding-bottom: 20px;
      padding-left: $content-padding;
    }
    .central-align {
      text-align: center;
    }
    .main-title {
      padding: 30px 0;
    }
  }
}
body[class^='modal-opened'] {
  .common-component-container {
    .common-component-wrapper {
      overflow: hidden;
    }
  }
  .navigation,
  .toolbar-container {
    z-index: 1;
  }
}
body[class^='modal-opened_from-navi'] {
  .navigation {
    z-index: 100;
  }
  .toolbar-container__left--visible {
    z-index: 9;
  }
}
