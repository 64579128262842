.permit-template-management {
  height: 100%;
  width: 100%;
  &__container {
    max-width: 50%;
    padding: 24px 30px;
    &__title {
      font-weight: 500;
      margin-top: 4px;
    }
    &__stepper-wrapper {
      margin-bottom: 32px;
      margin-top: 20px;
    }
    &__first-step {
      &__color-picker-container {
        max-width: 50%;
        padding-bottom: 32px;
        .color-picker {
          background-color: var(--input-background-color);
          border: 1px solid var(--input-border-color);
          border-radius: 4px;
          margin-top: 4px;
          padding: 16px;
          .medium {
            margin-right: 0;
          }
        }
        .color-picker__cover {
          position: initial;
        }
        .color-picker__popover {
          transform: initial;
        }
        .color-picker__value {
          margin-left: 8px;
          width: auto;
          .text {
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
      &__checkbox-input {
        .field-wrapper {
          .input-wrapper {
            .checkbox-wrapper {
              input {
                width: 20px;
              }
              label {
                font-size: 14px;
              }
            }
          }
        }
      }
      &__inputs {
        .field-wrapper {
          .input-wrapper {
            .input-wrapper__input {
              border-radius: 4px;
              height: 52px;
            }
          }
        }
      }
      &__input_name {
        max-width: 50%;
      }
      &__buttons {
        align-items: center;
        display: flex;
        margin-top: 10px;
        &__submit-btn {
          margin-left: auto;
        }
      }
    }
    .back-button {
      svg {
        path {
          stroke: var(--secondary-font-color-dark);
        }
      }
    }
  }
  .field-wrapper__label,
  .input-wrapper__input::placeholder {
    text-transform: none;
  }
}
