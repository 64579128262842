@import '../../../common/styles/variables';
.filter-form {
  .react-datepicker__close-icon {
    &::after {
      background-color: var(--secondary-theme-color);
      font-size: 16px;
    }
  }
  .grid {
    column-gap: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .scrollable {
    min-height: 350px;
    overflow-y: auto;
    padding: 10px;
  }
  .buttons {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    width: 100%;
    button:first-child {
      margin-right: auto;
    }
    button:nth-child(2) {
      margin-right: 16px;
    }
    .btn .button-text {
      font-weight: 700;
      text-transform: capitalize;
    }
  }
  .rw-popup-container {
    width: 80%;
    .rw-popup {
      max-height: 150px;
    }
  }
  .search-field-wrapper {
    .search-input-stripped {
      &__icon {
        margin-right: 7px;
      }
      &__input::-webkit-input-placeholder {
        color: var(--ch-text) !important;
        font-weight: normal;
      }
    }
    .search-wrapper {
      &__label {
        display: block;
        margin-bottom: 13px;
      }
      .search {
        align-items: center;
        background: var(--input-background-color);
        background-color: var(--input-background-color);
        border: 1px solid var(--input-border-color);
        border-radius: 4px;
        color: var(--primary-font-color);
        font-size: 14px;
        font-weight: normal;
        height: 52px;
        margin-top: 5px;
        outline: none;
        transition: 0.2s all;
        width: 100%;
        .search__icon {
          fill: #999;
          g,
          path {
            stroke: #999;
          }
        }
      }
    }
  }
}
