$button-height: 52px;
.ndt-point-details {
  height: 430px;
  .ndt-point-form {
    height: 100%;
    .main-section {
      display: flex;
      height: calc(100% - #{$button-height} - 10px);
      .details-section {
        flex: 2;
        height: 100%;
        margin-right: 50px;
        overflow-y: auto;
        padding-right: 20px;
      }
      .files-section {
        flex: 3;
        height: 100%;
        overflow: auto;
      }
    }
    .thumbnail-assets {
      .files {
        display: flex;
        grid-gap: 0;
        overflow-x: auto;
        width: 100%;
        .file-container {
          flex: 0 0 calc(25% - 10px); // display 4 images
          margin-right: 10px;
        }
      }
    }
    .field-wrapper {
      .input-wrapper {
        padding-top: 0;
      }
    }
    .buttons {
      align-items: center;
      background-color: var(--primary-theme-light-color);
      display: flex;
      flex-direction: row;
      height: $button-height;
      justify-content: flex-end;
      margin-top: 10px;
      padding: 0;
      transform: translateX(-69px); // the padding of the content defined in ndt-modal
      width: calc(100% + 138px);
      .btn,
      .btn-medium {
        margin: 0;
        margin-right: 15px;
      }
    }
  }
}
