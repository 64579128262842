.create-button {
  padding-left: 16px !important;
  padding-right: 16px !important;
  .icon-plus-circle,
  .icon-plus-circle:hover,
  .icon-chevron-down {
    path {
      stroke: #fff;
      stroke-width: 1.5px;
    }
  }
  &__dropdown-button {
    width: inherit !important;
  }
}

.btn-success-outline {
  .icon-plus-circle,
  .icon-plus-circle:hover,
  .icon-chevron-down {
    path {
      stroke: var(--secondary-theme-color);
      stroke-width: 1.5px;
    }
  }
}
