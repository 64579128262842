
.image-with-drawing {
  height: 100%;
  position: relative;
  width: 100%;
  .loader {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
  }
  .image-in-proccess {
    visibility: hidden;
  }
  .image-gallery-image {
    background-color: black;
    height: 100%;
    width: 100%;
    > div {
      height: 100%;
      width: 100%;
      > div {
        height: 100%;
        width: 100%;
      }
    }
    &__container {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      position: absolute;
      width: 100%;
      img {
        margin: 0 auto;
        max-height: 100%;
        max-width: 100%;
        &.auto-resize {
          height: auto;
          width: auto;
        }
      }
      .canvas-drawer {
        height: auto;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        z-index: 1;
      }
    }
    .error-message {
      color: white;
      padding: 0 5%;
      text-align: center;
    }
  }
}
.image-gallery-content {
  &.fullscreen {
    .image-with-drawing {
      height: 100vh;
      width: 100vw;
      .image-gallery-image {
        background-color: black;
        height: 100vh;
        width: 100vw;
        > div {
          height: 100vh;
          width: 100vw;
          > div {
            height: 100vh;
            width: 100vw;
          }
        }
      }
    }
  }
}
