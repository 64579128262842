@import '../../../common/styles/variables';
.download-report-modal {
  .modal-wrapper {
    max-height: 95%;
    overflow: auto;
  }
  .modal {
    max-width: unset;
    min-height: 800px;
    overflow: unset;
    width: auto;
    .download-report {
      &__table,
      &__form {
        display: grid;
        grid-template-rows: 1.5fr 600px;
        height: 700px;
        max-width: 70vw;
        overflow-y: auto;
        width: 900px;
      }
      &__table {
        display: block;
        padding: 0 10px;
        tbody {
          tr {
            &.pointer {
              &:hover {
                background-color: var(--secondary-font-color-dark);
                cursor: pointer;
              }
            }
          }
        }
      }
      .custom-toolbar {
        margin-bottom: 10px;
      }
      .stepper {
        margin: 10px 20px 20px;
       
      }
      .split-field {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      .screenshot {
        &::after {
          animation-duration: 0.4s;
          animation-iteration-count: 1;
          animation-name: takeScreenshot;
          background-color: transparent;
          content: '';
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }
    }
    .download-report-form {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 600px;
      justify-content: space-between;
      position: relative;
      .defects-affected {
        bottom: 10px;
        position: absolute;
        right: 0;
      }
      .report-complete {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: inherit;
        justify-content: center;
        text-align: center;
        width: 100%;
      }
      .screenshot-description {
        margin: 10px;
      }
      .inspection {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: inherit;
        width: 100%;
      }
      .google-map {
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: column;
        height: inherit;
        width: 100%;
        .map-wrapper {
          height: 100%;
          position: absolute;
          width: 100%;
        }
      }
      .field-wrapper,
      .inspection-input {
        padding: 0 20px;
        width: 100%;
        textarea {
          border: 1px solid var(--box-items-separator-color);
          color: var(--default-font-color);
          min-height: 150px;
          width: 100% !important;
        }
        .rw-popup-container {
          padding: 0;
        }
        .error-message {
          min-height: 25px;
        }
      }
      .btn {
        margin-top: 20px;
      }
      .multiple-buttons {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr 1fr;
      }
      .cover-upload {
        p {
          padding-bottom: 20px;
        }
        .dropzone-wrapper {
          align-items: center;
          display: grid;
          grid-template-areas: 
            'header header header'
            'upload after after';
          .label-before-image { 
            grid-area: header; }
          .dropzone { grid-area: upload; }
          .label-after-image {
            padding: 0 10px;
            position: relative;
            .file-name {
              display: inline-block;
              margin: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 200px;
            
            }
            .close {
              position: absolute;
              right: 0;
              top: -5px;
            }
            .clickable {
              cursor: pointer;
            }
          }
          .dropzone {
            border: 1px solid var(--box-items-separator-color);
            margin: 10px 0;
            width: 150px;
            .drop-field {
              align-items: center;
              display: flex;
              justify-content: center;
              p {
                padding: 7px;
              }
            }
          }
        }
      }
      .potree-area {
        height: 100%;
        width: 100%;
      }
    }
    .custom-toolbar {
      justify-content: flex-start;
      margin: 20px 20px 30px;     
    }
    .three-dots {
      display: inline-block;
      overflow: hidden !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 145px;
    }
  }
}



@keyframes takeScreenshot {
  0% {background-color: rgba(255, 255, 255, 0.8);}
  40% {background-color: transparent;}
  80% {background-color: rgba(255, 255, 255, 0.8);}
  100% {background-color: transparent;}
}
