@import '../../../common/styles/variables';
.create-project-container {
  padding: 20px 20% 0;
  .create-project-form {
    .form-fields {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .field-wrapper {
        width: 32%;
      }
      .add-user {
        &__option {
          font-weight: bold;
        }
      }
      .custom-label {
        p {
          padding: 5px;
        }
      }
      .rw-state-selected {
        .custom-label {
          p {
            color: var(--modal-color);
          }
        }
      }
    }
    .contact-fields {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &__group-name {
        display: flex;
        margin-bottom: 20px;
        text-transform: capitalize;
      }
      .field-wrapper {
        width: 32%;
      }
    }
    .buttons {
      display: flex;
      flex-flow: wrap row;
      justify-content: center;
      button {
        flex-grow: 1;
        margin: 10px;
        max-width: 40%;
      }
    }
  }
  &__google-map {
    background-color: var(--box-items-theme-color);
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    flex: 1;
    height: auto;
    margin-bottom: 32px;
    margin-top: 9px;
    padding: 16px;

    // HIDE FULLSCREEN BUTTON
    .gm-fullscreen-control {
      display: none;
      visibility: hidden;
    }
    .marker-container {
      align-items: center;
      display: flex;
      height: 26px;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      transform: translate(-50%, -100%);
      width: 18px;
    }
    .address-input {
      border: solid 1px #ddd;
      border-radius: 18px;
      font-family: $primary-font-family;
      font-size: 14px;
      font-weight: normal;
      height: 36px;
      margin-bottom: 10px;
      outline: none;
      padding: 9px 14px;
      padding-bottom: 9px;
      transition: 0.2s all;
      width: 100%;
    }
    .map-view {
      height: 320px;
      margin-bottom: 16px;
      position: relative;
      &__move-pin {
        align-items: center;
        background-color: rgb(255, 255, 255);
        border-radius: 2px;
        box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
        cursor: pointer;
        display: flex;
        height: 40px;
        justify-content: center;
        position: absolute;
        right: 10px;
        top: 10px;
        width: 40px;
        svg {
          opacity: 0.6;
          transition: all 0.3s ease;
        }
        &:hover {
          svg {
            opacity: 1;
          }
        }
      }
    }
    .description {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-bottom: 28px;
      opacity: 0.5;
      &__wrapper {
        align-items: center;
        display: flex;
      }
      &__text {
        font-size: 12px;
        margin-left: 20px;
      }
    }
  }
}
