@import '../../../common/styles/variables';
.module-picker {
  display: flex;
  flex-direction: column;
  overflow-y: overlay;
  position: relative;
  &__half-width {
    width: calc(100% - #{$inspection-right-toolbar});
  }
  &.padding-horizontal,
  .padding-horizontal {
    padding-left: $left-toolbar-p-left;
    padding-right: $left-toolbar-p-right;
  }
  &.padding-vertical,
  .padding-vertical {
    padding-top: $left-toolbar-p-top;
    // padding-bottom: $left-toolbar-p-bottom;
  }
  &__header {
    display: flex;
    min-height: 50px;
    // padding-bottom: 8px;
    .header {
      align-items: center;
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      margin-right: auto;
      width: 100%;
      &__back-btn {
        cursor: pointer;
        margin: 0 8px;
        padding: 3px;
      }
      h4 {
        font-weight: 500;
      }
    }
    .editable-date {
      align-items: center;
      display: flex;
      margin-top: 10px;
      p {
        color: var(--secondary-font-color-dark);
        font-size: 14px;
      }
    }
  }
  &__search {
    margin-bottom: 10px;
  }
  &__filters {
    display: flex;
    margin-bottom: 24px;
    .text {
      margin-right: auto;
      p {
        font-size: 13px;
        font-weight: 500;
      }
    }
  }
  &__main-content {
    flex: 1;
    .full-width {
      transform: translate(-#{$left-toolbar-p-left}, 0);
      width: calc(100% + #{$left-toolbar-p-left} + #{$left-toolbar-p-right});
    }
    .create-new-plan {
      margin: 0 auto 18px;
    }
    .page-not-found {
      min-height: 80vh;
    }
  }
  &__inspection-button {
    bottom: 0;
    left: 0;
    padding: 10px 20px;
    position: absolute;
    right: 0;
  }
  .missing-pin {
    align-items: center;
    display: flex;
    margin-right: 4px;
    &__icon {
      margin-right: 8px;
    }
  }
  .m-horizontal-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .components-list .items-table-renderer {
    height: calc(100vh - 210px);
  }
  .defects-section .items-table-renderer {
    height: calc(100vh - 305px);
  }
  .ndt-measurements-list .items-table-renderer {
    height: calc(100vh - 200px);
  }
  .work-order-list .items-table-renderer {
    height: calc(100vh - 425px);
  }
  .notification-list .items-table-renderer {
    height: calc(100vh - 295px);
  }
  .items-table-renderer {
    table {
      min-width: 1000px;
      thead {
        th {
          min-width: 70px;
          &.medium {
            max-width: 150px;
            min-width: 150px;
          }
          &.big {
            max-width: 250px;
            min-width: 250px;
          }
          &.large {
            max-width: 350px;
            min-width: 350px;
          }
        }
      }
    }
  }
  .dots {
    max-width: 250px;
  }
}
