@import '../../../common/styles/variables';
.pick-component-screen {
  $border-width: 3px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 40px;
  .or {
    margin: 20px 0;
  }
  > .inline-wrapper {
    width: 276px;
  }
  .details-box {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    min-height: 200px;
    .details-items {
      display: grid;
      grid-row-gap: 6px;
      grid-template-columns: 1fr 1fr;
    }
    .empty-state {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      margin: auto;
      svg {
        margin-bottom: 10px;
      }
    }
  }
  .space-bottom {
    margin-bottom: 5px;
  }
  .component-name {
    align-items: center;
    border: $border-width dashed var(--status-blue-color);
    display: flex;
    height: 100px;
    justify-content: center;
    margin-bottom: 40px;
    position: relative;
    text-align: center;
    &::after {
      background: var(--status-blue-color);
      bottom: -$border-width;
      content: '';
      left: -$border-width;
      opacity: 0.4;
      position: absolute;
      right: -$border-width;
      top: -$border-width;
      z-index: -1;
    }
  }
}
