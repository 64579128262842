$split-list-height: 100px;
.maintenance-regime-create-modal {
  width: 100%;
  .step-title {
    margin-top: 20px;
  }
  .step-desc {
    margin: 20px 0;
  }
  .b-top {
    border-top: 1px solid var(--separator-color);
    padding-top: 20px;
  }
  .pointer {
    cursor: pointer;
  }
  .regime-form {
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding-top: 32px;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
    .work-order-components .added-items-list .item-r .info-tooltip {
      margin-right: auto;
    }
    .ends-label {
      margin: 16px 0;
    }
    .with-hidden-error-message {
      .input-wrapper {
        .error-message {
          display: none;
        }
        .error-message--visible {
          bottom: -23px;
          min-width: 300px;
          position: absolute;
        }
      }
    }
    .recurring-task-details {
      border-top: 1px solid var(--separator-color);
      margin-top: 10px;
      padding-top: 32px;
    }
    .recurring-task-details-disable {
      label {
        opacity: 0.3;
      }
    }
    .default-checkbox-container {
      .field-wrapper {
        .input-wrapper {
          .checkbox-wrapper {
            input {
              height: 20px;
              width: 20px;
            }
          }
        }
      }
    }
    .work-order-files {
      .added-items {
        .files-items-list {
          .file-container-with-border {
            border-bottom: 1px solid var(--separator-color);
          }
          .file-container {
            align-items: center;
            display: flex;
            padding: 12px 0;
            .icon-trash {
              margin-left: auto;
            }
            .filename {
              margin-left: 8px;
            }
            .image-container {
              cursor: pointer;
              img {
                height: 24px;
                width: 24px;
              }
              .filename {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    .upload-checklist {
      .title {
        margin-bottom: 4px !important;
      }
    }
    .wrench-time-container {
      padding-bottom: 24px;
      .field-wrapper--disabled {
        .time-picker-hours,
        .time-picker-minutes {
          opacity: 0.3;
        }
      }
    }
    .with-fake-label {
      margin-top: 20px;
    }
    .radio-button-container {
      .single-radio {
        border: 0 solid;
        padding: 0;
        .radio-label {
          color: var(--secondary-font-color-light) !important;
          padding-left: 25px;
        }
        .radio-label::before {
          left: 0;
        }
        .radio-label::after {
          left: 4px;
        }
      }
    }
    .after-inputs-container {
      margin-top: 20px;
      .label-transformation {
        text-transform: none;
      }
    }
    .react-datepicker-wrapper {
      input[disabled] {
        opacity: 0.3;
      }
    }
    .double {
      align-items: center;
      display: flex;
      .split-input {
        width: 50%;
        .select-d {
          padding-right: 0;
        }
      }
      .split-input:nth-child(1) {
        padding-right: 10px;
      }
      .split-input:nth-child(2) {
        padding-left: 10px;
      }
    }
    .rw-list {
      padding-bottom: 20px;
    }
    .priority-dropdown-item {
      align-items: center;
      cursor: pointer;
      display: flex;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 20px;
      .priority-icon {
        border-radius: 50%;
        height: 20px;
        margin-right: 4px;
        width: 20px;
      }
    }
    .without-padding {
      padding: 0;
    }
    .maintenance-regime-custom-select {
      .rw-widget-input {
        background-color: var(--input-background-color) !important;
        border: 1px solid var(--input-border-color) !important;
        border-radius: 4px !important;
        color: var(--primary-font-color) !important;
      }
    }

    .half-width-input {
      width: 50%;
      margin-top: 20px;
      padding-right: 10px;
    }

    .field-wrapper {
      .input-wrapper {
        input,
        textarea {
          background-color: var(--input-background-color) !important;
          border: 1px solid var(--input-border-color) !important;
          border-radius: 4px;
          height: 52px !important;
        }
      }
    }
    .select-d {
      padding-right: 15px;
    }
    .upload-handler {
      align-items: center;
      border-bottom: 1px solid var(--separator-color);
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
    }
    .items-dropdown {
      z-index: 1;
      .item {
        padding: 0;
        p {
          padding: 5px 0;
        }
        .disabled {
          background-color: var(--separator-color);
        }
      }
    }
    .upload-checklist {
      .title {
        margin: 20px 0;
      }
      .added-items-list {
        height: $split-list-height;
      }
      .empty-state {
        margin-top: 0 !important;
      }
    }
    .added-items-list,
    .added-items {
      margin-top: 20px;
    }
    .added-items-list {
      max-height: 350px;
      overflow: auto;
      &.checklists {
        height: $split-list-height;
        .empty-state {
          margin-top: 20px;
        }
      }
      .empty-state {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 150px;
      }
      .add-files {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 10px;
        > p {
          margin-right: 10px;
        }
      }
    }
    .item-r {
      align-items: center;
      border-bottom: 1px solid var(--separator-color);
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      margin-right: 10px;
      padding-bottom: 10px;
    }
    .left-side {
      align-items: center;
      display: flex;
      svg {
        &.disabled {
          path {
            fill: var(--separator-color);
          }
        }
      }
      .up {
        margin-left: 15px;
        transform: rotate(-90deg);
      }
      .down {
        margin: 0 15px;
        transform: rotate(90deg);
      }
      .c-title {
        min-width: 20px;
      }
    }
    .right-side {
      align-items: center;
      display: flex;
      svg {
        margin-left: 10px;
      }
    }
    .grouped-input {
      display: grid;
      grid-column-gap: 20px;
      grid-template-columns: 1fr 1fr;
      &.interval-section {
        grid-template-columns: 105px 105px 2fr;
      }
    }
    .email-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 15px;
      &.notifications {
        margin-bottom: 20px;
        margin-top: 0;
      }
      .email {
        align-items: center;
        background-color: var(--separator-color);
        display: flex;
        justify-content: space-between;
        margin: 5px;
        padding: 10px 20px;
        svg {
          margin-left: 10px;
        }
      }
    }
    .notification-section {
      margin-top: 20px;
    }
  }
  .buttons {
    display: flex;
    margin-top: auto;
    margin-top: 40px;
    .right-buttons {
      align-items: center;
      display: flex;
      margin-left: auto;
    }
    .submit-button {
      margin-left: 10px;
    }
  }
  .ghost-field {
    display: none;
  }
  .x-hover:hover {
    path {
      fill: var(--severity-red);
    }
  }
  .c-hover:hover {
    path {
      fill: var(--severity-green);
      stroke: var(--severity-green);
    }
  }
  .range-selector {
    padding-left: 9px;
    width: 98%;
  }
}
