.workflow-stepper {
  border-bottom: 1px solid var(--separator-color);
  display: grid;
  justify-content: center;
  padding: 15px 20px;
  .step {
    align-items: center;
    display: grid;
    grid-template-columns: 30px auto;
    padding: 0 10px;
    .circle {
      align-items: center;
      background-color: var(--separator-color);
      border-radius: 50%;
      display: flex;
      height: 21px;
      justify-content: center;
      width: 21px;
    }
    &.active {
      .circle {
        background-color: var(--secondary-theme-color);
      }
    }
  }
  .line {
    align-items: center;
    display: flex;
    justify-content: center;
    hr {
      background-color: var(--separator-color);
      border: unset;
      height: 1px;
      min-width: 20px;
      width: 100%;
    }
    &.active {
      hr {
        background-color: var(--secondary-theme-color);
      }
    }
  }
}

