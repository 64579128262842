$module-header-height: 132px;
.new-measurement-point-wrapper {
  height: 80vh;
  width: 80vw;
  &__container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: flex-start;
    overflow: hidden;
    width: 100%;
    &__left-side {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 70%;
      .single-item-table.group,
      .single-item-table.group .table-template {
        height: 100% !important;
      }
      .single-item-table__table-heading {
        margin-bottom: 16px;
      }
      .search-wrapper {
        .search-wrapper__input {
          border-bottom: 1px solid #eee;
          height: 24px;
          padding-bottom: 4px;
          svg.search__icon {
            height: 20px;
            width: 20px;
            g,
            path {
              stroke: var(--secondary-theme-color) !important;
            }
          }
          .search__input {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }
        }
      }
      .twp-header.sticky {
        border: none;
        height: 40px;
      }
      .single-item-table.group .table-full-screen {
        .name-column {
          max-width: 55% !important;
          min-width: 55% !important;
        }
      }
      .double-table {
        height: calc(100vh - #{$module-header-height});
        width: 100%;
        &__table {
          height: 50%;
          overflow: hidden;
          width: 100%;
          .single-item-table {
            height: 100%;
            &.location {
              .table-template {
                height: calc(100% - 53px);
              }
            }
            &.points {
              .table-template {
                height: 100%;
              }
            }
          }
        }
        .measurement-location-table {
          .name-column {
            max-width: calc(55% - 100px) !important;
            min-width: calc(55% - 100px) !important;
          }
          .link-all-mps-column {
            max-width: 30px !important;
            min-width: 30px !important;
            .circle {
              align-items: center;
              border-radius: 50%;
              display: flex;
              height: 20px;
              justify-content: center;
              width: 20px;
              &.success {
                background-color: var(--secondary-theme-color);
                svg {
                  path {
                    stroke: #fff;
                  }
                }
              }
              &.danger {
                background-color: #fff;
                border: 1px solid var(--severity-red);
                svg {
                  path {
                    stroke: var(--severity-red);
                  }
                }
              }
              &.disabled {
                cursor: not-allowed;
                opacity: 0.6;
              }
              svg {
                height: 16px;
                width: 16px;
              }
            }
          }
        }
        .measurement-points-table,
        .table-three-d {
          .name-column {
            max-width: calc(55% - 85px) !important;
            min-width: calc(55% - 85px) !important;
          }
          .range-column,
          .unit-column {
            max-width: 20% !important;
            min-width: 20% !important;
          }
          .link-mps-column {
            max-width: 30px !important;
            min-width: 30px !important;
            .circle {
              align-items: center;
              background-color: var(--secondary-theme-color);
              border-radius: 50%;
              display: flex;
              height: 20px;
              justify-content: center;
              width: 20px;
              &.disabled {
                cursor: not-allowed;
                opacity: 0.6;
              }
              svg {
                height: 16px;
                width: 16px;
                path {
                  stroke: #fff;
                }
              }
            }
          }
        }
      }
      .load-more {
        max-height: 165px;
        min-height: 150px;
      }
    }
    &__right-side {
      border-left: 1px var(--dropdown-border) solid;
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: hidden;
      padding: 0 5px 0 20px;
      width: 30%;
      &::-webkit-scrollbar-thumb {
        background: var(--secondary-theme-color);
        -webkit-border-radius: 100px;
        border-radius: 100px;
      }
      .added-mp-list {
        padding: 20px 0;
      }
    }
  }
  .confirmation-new-mp-modal {
    &__picker {
      .document-management__modal__action-modal {
        display: flex;
        flex-flow: column;
        .first-paragraph {
          word-break: break-word;
        }
        .button-text {
          font-weight: 500;
        }
        .modal-button-custom.btn-gray-outline {
          order: 1; /* sets the Cancel button to bottom */
        }
        .btn-success {
          order: 0; /* sets the Proceed botton to top */
        }
      }
    }
  }
}
