.work-order-section-evidence-files {
  .expandable-section {
    border: 0;
    padding: 0;
  }
  &-upload {
    border-top: 1px solid var(--box-items-separator-color);
    align-items: center;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    padding: 16px 14px;

    &__label-container {
      display: flex;
      align-items: center;
      flex-grow: 1;
      flex-shrink: 0;
    }
    &__button-container {
      display: flex;
      justify-content: flex-end;
      flex-grow: 1;
      flex-shrink: 0;
    }
  }
  &-list {
    padding: 16px 14px;
    .table-template {
      .table-template-no-data-text {
        .empty-state-container {
          .empty-state-container__content {
            p {
              text-wrap: nowrap;
            }
          }
        }
      }
      .twp-header {
        border: 0;
      }
      .name-column {
        display: block;
        max-width: calc(100% - 50px - 150px - 150px - 28px);
        .name-container {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .twp {
        min-width: unset;
      }
      .icon-column {
        max-width: 50px !important;
        min-width: 50px !important;
      }
      .date-column,
      .uploaded-by-column {
        max-width: 150px !important;
        min-width: 150px !important;
      }
      .action-column {
        align-items: center;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        max-width: 28px !important;
        min-width: 28px !important;
      }
    }
  }
}
