// naming convention in this file differs from others since the draft js mentions
// relies on camelCase instead of kebab-case
// also that is the reason this file is *.module.scss
.mention {
  color: var(--secondary-theme-color);
  text-decoration: none;
}
.mentionSuggestionsEntryContainer {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.mentionSuggestionsEntry {
  align-items: center;
  border: 1px solid transparent;
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: flex-start;
  overflow: hidden;
  padding: 7px 10px 3px;
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
  width: 100%;
}
.mentionSuggestionsEntryFocused {
  align-items: center;
  background-color: var(--mention-hover-bg);
  border: 1px solid var(--mention-hover-border);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: flex-start;
  overflow: hidden;
  padding: 7px 10px 3px;
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
  width: 100%;
}
.mentionSuggestionsEntryText {
  color: var(--primary-font-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mentionSuggestionsEntrySubText {
  color: var(--ch-text);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
