$split-list-height: 100px;
.contractor-create-modal {
  max-width: 900px;
  min-width: 740px;
  .step-title {
    margin-top: 20px;
  }
  .step-desc {
    margin: 20px 0;
  }
  .b-top {
    border-top: 1px solid var(--separator-color);
    padding-top: 20px;
  }
  .pointer {
    cursor: pointer;
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    padding-top: 20px;
    button {
      margin-left: 10px;
    }
  }
  .ghost-field {
    display: none;
  }
  .x-hover:hover {
    path {
      fill: var(--severity-red);
    }
  }
  .c-hover:hover {
    path {
      fill: var(--severity-green);
      stroke: var(--severity-green);
    }
  }
  .range-selector {
    padding-left: 9px;
    width: 98%;
  }
  .contractor-form {
    display: flex;
    flex-direction: column;
    max-height: 600px;
    min-height: 350px;
    overflow: auto;
    .form-fields {
      display: grid;
      flex-wrap: wrap;
      grid-gap: 20px;
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
      .rw-popup {
        max-height: 150px;
      }
    }
    .added-items {
      margin-top: 20px;
    }
    .added-items-list {
      height: 115px;
      margin: 10px 0;
      overflow: auto;
      .item-r {
        align-items: center;
        border-bottom: 1px solid var(--separator-color);
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        margin-right: 10px;
        padding-bottom: 10px;
      }
      .x-hover:hover {
        path {
          fill: var(--severity-red);
        }
      }
      .empty-state {
        background-color: unset;
        height: unset;
      }
    }
  }
}
