.sap-form {
  .cancel-button {
    margin-top: 20px;
  }
  .synchronise-description {
    display: flex;
    margin-top: 37px;
    padding: 0 10px;
    &__icon {
      height: 16px;
      margin-right: 15px;
      width: 16px;
    }
  }
  .buttons {
    .cancel-button {
      margin-bottom: 20px;
    }
  }
  .status {
    border-radius: 4px;
    padding: 4px 5px;
    text-align: center;
    width: 100%;
    &--red { // FAILED
      background-color: var(--validation-error-color);
    }
    &--green { // NOT STARTED
      background-color: var(--secondary-theme-color-dark);
    }
    &--gray { // IN PROGRESS
      background-color: var(--box-items-theme-color-light);
    }
    &--blue { // COMPLETED
      background-color: var(--status-blue-color);
    }
  }
}
