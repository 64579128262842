.empty-state-container {
  align-items: center;
  background-color: var(--ui-background);
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  &.transparent {
    background-color: transparent;
  }
  &__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    &__text {
      color: var(--ui-color-secondary);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-top: 12px;
      text-align: center;
      width: 90%;
    }
  }
  .btn {
    min-width: initial !important;
    max-width: initial !important;
    padding: 0px 16px;
    .button-text {
      font-weight: 500;
    }
    .with-icon {
      margin-left: 4px !important;
    }
  }
}
