.progress-bar {
  background-color: var(--secondary-font-color-dark);
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  width: 100%;
  .percentage-bars {
    display: flex;
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
    &__progress {
      cursor: pointer;
      height: 100%;
      transition: 0.5s ease;
      transition-delay: 0.3s;
      width: 0%;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  &.rounded {
    border-radius: 12px;
    .percentage-bars {
      &__progress {
        border-radius: 12px;
      }
    }
  }
}
