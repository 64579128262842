.notification-WO-tab {
  &__create-button {
    &__container {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      margin-top: 16px;
      width: 100%;
      &__button-width {
        min-width: 313px !important;
      }
    }
  }
  &__table {
    height: 81.5vh;
    .table-template-no-data-text {
      padding: 12px;
    }
    .table-template-container {
      background-color: var(--work-tab-table-bg);
      height: 100%;
      overflow-y: auto;
      padding: 0 12px;
      .twp-header {
        border: 0;
        &.sticky {
          height: 28px;
          margin-top: 12px;
        }
        &__cell {
          padding-top: 8px;
        }
      }
      &::-webkit-scrollbar {
        width: 4px;
      }
      .twp-body__row {
        background-color: var(--work-tab-row-bg);
        margin-top: 8px;
      }
      .load-more__results,
      .sticky {
        background-color: var(--work-tab-table-bg);
      }
    }
    .twp {
      min-width: 0 !important;
    }
    .icon-column {
      align-items: center;
      display: flex;
      justify-content: center;
      max-width: 10% !important;
      min-width: 10% !important;
    }
    .id-column {
      max-width: 20% !important;
      min-width: 20% !important;
    }
    .name-column {
      max-width: 70% !important;
      min-width: 70% !important;
    }
  }
}
