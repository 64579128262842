.document-management {
  height: 100%;
  transition: all 0.7s ease;
  width: 100%;
  &.toolbar-opened {
    width: calc(100% - 360px);
  }
  .action-buttons-icon {
    margin-left: 8px;
    path {
      stroke: #fff;
    }
  }
  .page-navigation {
    .button-text {
      text-transform: capitalize;
    }
  }
  &__container {
    align-items: center;
    display: flex;
    height: calc(100% - 110px);
    justify-content: center;
    width: 100%;
    &__filters {
      border-right: 1px var(--dropdown-border) solid;
      height: 100%;
      overflow-y: auto;
      width: 25%;
    }
    &__documents {
      height: 100%;
      padding: 16px 20px;
      width: 75%;
      &__path {
        height: 48px;
        width: 100%;
        .path-viewer__info {
          text-transform: capitalize;
        }
      }
      &__tables {
        height: calc(100% - 48px);
        width: 100%;
        .table-template-search {
          border-bottom: 1px solid #eee;
        }
        .twp-header {
          border-bottom: 0;
          padding-top: 8px;
        }
        .icon-column,
        .action-menu-column {
          align-items: center;
          justify-content: center;
          max-width: 36px !important;
          min-width: 36px !important;
        }
        .name-column {
          color: var(--primary-font-color);
          font-size: 14px;
          font-weight: 400;
          max-width: 50% !important;
          min-width: 50% !important;
        }
        .at-column,
        .by-column {
          color: var(--primary-font-color);
          font-size: 14px;
          font-weight: 400;
          max-width: calc((50% - 72px) / 2) !important;
          min-width: calc((50% - 72px) / 2) !important;
        }
        .name-column {
          color: var(--primary-font-color);
          font-size: 14px;
          font-weight: 400;
          max-width: 50% !important;
          min-width: 50% !important;
        }
        .file-name-column {
          color: var(--primary-font-color);
          font-size: 14px;
          font-weight: 400;
          max-width: 20% !important;
          min-width: 20% !important;
          &__text {
            height: 100%;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            white-space: nowrap !important;
            width: 95%;
          }
        }
        .up-at-column {
          color: var(--primary-font-color);
          font-size: 14px;
          font-weight: 400;
          max-width: 175px !important;
          min-width: 175px !important;
        }
        .up-by-column {
          color: var(--primary-font-color);
          font-size: 14px;
          font-weight: 400;
          max-width: 125px !important;
          min-width: 125px !important;
        }
        .size-column,
        .status-column,
        .type-column {
          color: var(--primary-font-color);
          font-size: 14px;
          font-weight: 400;
          max-width: calc((80% - 300px - 72px) / 3) !important;
          min-width: calc((80% - 300px - 72px) / 3) !important;
        }
      }
    }
  }
  &__modal {
    &__action-modal {
      max-width: 500px;
      min-width: 500px;
      &__paragraph {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 20px;
        word-break: break-word;
      }
      .modal-button-custom {
        min-width: 450px;
        width: 100%;
        &:first-child {
          margin-top: 20px;
        }
        &:not(:first-child) {
          margin-top: 12px;
        }
      }
    }
    &__create-folder {
      .error-message--visible {
        font-size: 14px !important;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
  .file-details-labels-modal {
    .edit-labels-modal {
      display: flex;
      flex-flow: column;
      height: 500px;
      min-width: 600px;
      .search-wrapper {
        .search__icon {
          g,
          path {
            stroke: var(--ch-text);
          }
        }
        .search__input,
        .search__input::placeholder {
          color: var(--ch-text);
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.14px;
          line-height: 20px;
        }
        .items-dropdown {
          height: 400px;
          max-height: 400px;
          width: 100%;
          .item {
            padding-top: 0;
          }
        }
      }
      .buttons-wrapper {
        margin-top: auto;
        .btn.modal-button-custom {
          margin-left: auto;
          min-width: auto;
        }
      }
      .added-items-renderer {
        align-items: center;
        display: flex;
        flex-flow: column;
        margin-top: 32px;
        .dropdown-render-item-container {
          border-bottom: 1px solid #eee;
          padding-left: 8px;
          padding-right: 8px;
          width: 100%;
          .icon-close {
            margin-left: auto;
          }
        }
      }
      .no-label-added {
        align-items: center;
        display: flex;
        font-size: 14px;
        font-weight: 500;
        justify-content: flex-start;
        line-height: 20px;
        margin-top: 24px;
      }
      .dropdown-render-item-container {
        cursor: pointer;
        padding-bottom: 8px;
        padding-top: 8px;
        &:hover {
          background-color: var(--ch-table-row-hover);
        }
        .label-checkbox-wrapper {
          align-items: center;
          display: flex;
          justify-content: flex-start;
          .field-wrapper {
            margin-left: 8px;
            margin-right: 8px;
            .input-wrapper {
              padding-top: 0;
              .checkbox-wrapper {
                input {
                  border-radius: 4px;
                  height: 20px;
                  width: 20px;
                }
              }
              .error-message {
                display: none;
              }
            }
          }
          .dms-labels__wrapper__item__color {
            border-radius: 8px;
            height: 16px;
            margin-right: 8px;
            width: 16px;
          }
          .module-name {
            color: var(--default-font-color);
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
          }
        }
      }
    }
  }
  .modal-button-custom {
    min-width: 450px;
    &:first-child {
      margin-top: 20px;
    }
    &:not(:first-child) {
      margin-top: 12px;
    }
  }
  .dms-right-side-form {
    .header-container {
      padding: 0;
    }
    .change-status-container {
      padding: 0;
    }
    .border-bottom-dropdown {
      border-bottom: 1px var(--dropdown-border) solid;
    }
    .uneditable-info + .border-bottom-dropdown {
      .f-primary.light-bold {
        padding-bottom: 20px;
      }
    }
    &__cta-wrapper {
      margin-bottom: 32px;
      margin-top: 10px;
      .btn {
        width: 100%;
        &:first-child {
          margin-bottom: 12px;
        }
        svg path {
          stroke: var(--secondary-theme-color);
        }
      }
    }
    &__save-cta-wrapper {
      background-color: var(--background-color);
      bottom: 0;
      box-shadow: 0 1px 0 0 #eee inset;
      margin-top: auto;
      padding-bottom: 20px;
      padding-top: 20px;
      position: sticky;
      width: 100%;
      .btn {
        width: 100%;
      }
    }
    &__location-wrapper {
      border-bottom: 1px var(--dropdown-border) solid;
    }
    &__section-wrapper {
      margin-bottom: 20px;
    }
    &__section-title {
      color: var(--dms-grey-section-title);
      font-weight: 400;
    }
    &__change-location-cta {
      color: var(--dms-green-section-title);
      cursor: pointer;
      font-weight: 500;
    }
    .expandable-section {
      .header {
        &__wrapper {
          padding: 16px 0;
        }
      }
    }
    .expandable-section.section-Labels {
      .section-header {
        margin-left: auto;
        margin-right: 8px;
      }
      .labels-empty-state {
        color: var(--ch-text);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        padding-left: 0;
      }
      .section-content {
        padding-left: 0;
      }
    }
    .critical-equipment-info-container {
      padding: 20px 0;
    }
    .critical-tooltip {
      .info-popover {
        min-height: 0;
        width: 158px;
      }
    }
  }
  .folder_name {
    align-items: center;
    display: flex;
    gap: 8px;
  }
  .dm-upload-file-modal {
    .modal-wrapper {
      min-width: 400px;
    }
    .modal {
      max-width: none;
    }
    .modal-action-items {
      top: 42px;
    }
    .modal {
      &__title {
        margin-right: auto;
      }
    }
    .twp-header.sticky {
      border-bottom: 0;
    }
    .twp-header__cell__container__text {
      color: var(--grey-background-icon);
      font-size: 13px;
    }
    .twp-body__row__cells {
      border-bottom: 1px solid #eee;
      padding-bottom: 12px;
    }
    .twp-body__row:nth-child(odd) {
      background-color: transparent;
    }
    &__content {
      min-width: 60vw;
      .files-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 12px;
      }
      .dropzone-wrapper {
        .project-dropzone {
          height: 70px;
        }
      }
    }
    .icon-column,
    .action-menu-column {
      align-items: center;
      justify-content: center;
      max-width: 36px !important;
      min-width: 36px !important;
    }
    .name-column {
      color: var(--primary-font-color);
      font-size: 14px;
      font-weight: 400;
      max-width: 35% !important;
      min-width: 35% !important;
    }
    .status-column {
      max-width: 25% !important;
      min-width: 25% !important;
    }
    .smart-drawing-column,
    .file-size-column,
    .status-column {
      color: var(--primary-font-color);
      font-size: 14px;
      font-weight: 400;
    }
    .smart-drawing-column,
    .file-size-column {
      max-width: calc((40% - 72px) / 2) !important;
      min-width: calc((40% - 72px) / 2) !important;
    }
    .files-cta-wrapper {
      align-items: center;
      display: flex;
      justify-content: space-between;
      .btn.modal-button-custom {
        max-width: 120px;
        min-width: 120px;
      }
    }
    .field-wrapper.inline {
      margin-bottom: 0;
    }
    .modal__button-container.ok {
      display: none;
    }
    .change-status-container {
      margin-bottom: 0;
      padding: 0;
      width: 100%;
      .inline-wrapper {
        display: none;
      }
    }
  }
  .dms-pdf-viewer {
    .modal,
    .modal-wrapper {
      overflow: hidden;
    }
    .modal {
      max-width: 80vw;
    }
  }
  .link-components-modal {
    &__picker {
      max-height: 800px;
      overflow-y: scroll;
      width: 55vw;
    }
  }
}
