@import '../../../common/styles/variables';
.image-description {
  align-items: center;
  background: rgba(15, 17, 19, 0.7);
  display: flex;
  height: $image-description-height;
  padding: 0 20px;
  width: 100%;
  &__left {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }
  .image-details {
    margin-left: 10px;
  }
}
