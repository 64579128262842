.maintenance-regime {
  padding: 24px 20px;
  .toolbar-container {
    z-index: 91;
  }
  .tabs {
    &__navigation {
      border-bottom: 1px solid var(--box-items-separator-color);
    }
  }
  .maintenance-regime-right-side {
    height: 100%;
    overflow-y: auto;
    .creates-wrapper {
      align-items: flex-start;
      display: flex;
      flex-flow: column;
      margin-bottom: 32px;
      &__label {
        margin-bottom: 8px;
      }
      &__type {
        color: var(--primary-font-color);
      }
    }
    .double {
      align-items: center;
      display: flex;
      .split-input {
        width: 100%;
        .select-d {
          padding-right: 0;
        }
        .split-input:nth-child(1) {
          padding-right: 10px;
        }
        .split-input:nth-child(2) {
          padding-left: 10px;
        }
      }
    }
    .with-fake-label {
      margin-top: 20px;
    }
    .wrench-time-container {
      padding-bottom: 24px;
      .field-wrapper--disabled {
        .time-picker-hours,
        .time-picker-minutes {
          opacity: 0.3;
        }
      }
    }
    .sections-container .sections-item {
      margin-bottom: 16px;
    }
    .sections-container .sections-item .expanded-section .header {
      margin-bottom: 0;
    }
    .sections-container .sections-item .expanded-section .header__wrapper {
      width: 100%;
    }
    .work-order-section-components {
      padding-bottom: 32px;
    }
    .work-order-other-files {
      margin-top: 0;
    }
    .maintenance-regime-section-scheduler {
      margin-bottom: 32px;
      .scheduler-row {
        margin-bottom: 20px;
        .f-secondary-dark {
          margin-bottom: 8px;
        }
        .f-primary-dark {
          color: var(--primary-font-color);
          font-weight: 500;
        }
      }
    }
    .change-status-select-item {
      p {
        color: var(--primary-font-color);
      }
    }
    .statuses-filter__wrapper__item {
      margin-right: 8px;
      padding: 4px 12px;
      p {
        font-size: 12px;
      }
    }
    .regimes-table {
      .items-table-renderer {
        table {
          thead .sticky.clickable.medium {
            display: none;
          }
          thead > tr {
            align-items: center;
            display: flex;
            justify-content: space-around;
            margin-left: -4px;
            width: calc(100% + 8px);
          }
          thead tr {
            border-bottom: 0;
          }
          tbody {
            display: flex;
            flex-flow: column;
            flex-wrap: wrap;
            width: 100%;
            tr.pointer {
              align-items: center;
              background-color: var(--background-color-light);
              display: flex;
              margin-bottom: 8px;
              margin-left: -4px;
              padding: 8px;
              width: calc(100% + 8px);
              td:last-child {
                margin-left: auto;
              }
            }
          }
        }
      }
    }
  }
}
