.permits-display-container {
  .permit-item {
    align-items: center;
    display: flex;
    padding: 16px 0;
    .permit-status-wrapper {
      align-items: center;
      display: flex;
      margin-left: auto;
      .permit-status-icon {
        margin-right: 4px;
      }
    }
  }
}
