.image-loader {
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  display: flex;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  svg {
    height: 30px;
    margin: auto !important;
    width: 30px;
  }
}
