.tabs {
  &__navigation {
    border-bottom: 1px var(--comments-tab-bg) solid;
    display: flex;
    margin-right: 20px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }
  }
  &__navigation-wrap {
    align-items: center;
    display: flex;
    border-bottom: 1px var(--comments-tab-bg) solid;
    .tabs__navigation {
      border: 0;
    }
    .small-dropdown__settings {
      margin-right: 13px;
      width: 1%;
    }
  }
  &__navigation-item {
    display: flex;
    gap: 4px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    padding: 7px 4px;
    transition: all 0.3s ease-in-out;
    &:not(:last-child) {
      margin-right: 20px;
    }
    &.active {
      border-bottom: 2px solid var(--secondary-theme-color);
      p {
        color: var(--primary-font-color);
      }
    }
    &.disabled {
      cursor: not-allowed;
    }
  }
}
.tabs-dropdown {
  p {
    color: var(--secondary-theme-color) !important;
  }
  svg {
    path {
      stroke: var(--secondary-theme-color) !important;
    }
  }
}
