.sections-container {
  padding: 0 20px;
  .sections-item {
    margin-bottom: 35px;
    .expanded-section {
      .header {
        background-color: transparent;
        box-shadow: none;
        &__wrapper {
          display: block;
          padding: 0;
        }
      }
      .section-content--visible {
        max-height: fit-content;
        overflow-y: hidden;
      }
      .section-content {
        border-bottom: 0;
        padding: 0;
        .section-empty-state {
          display: flex;
          flex-direction: row;
        }
        .section-content-item {
          padding-top: 20px;
          .field-wrapper {
            label {
              font-size: 14px;
              font-weight: 500;
            }
            .input-wrapper {
              .select-container {
                .rw-widget-container {
                  background-color: var(--input-background-color);
                  border: 1px solid var(--input-border-color);
                  border-radius: 4px;
                  color: var(--primary-font-color);
                  .rw-picker-caret {
                    svg {
                      path {
                        fill: var(--primary-font-color);
                      }
                    }
                  }
                }
              }
            }
          }
          .input-wrapper {
            .react-datepicker-wrapper {
              input {
                background-color: var(--input-background-color);
                border: 1px solid var(--input-border-color);
                border-radius: 4px;
                color: var(--primary-font-color);
                padding: 9px 14px;
                padding-bottom: 9px;
              }
            }
          }
        }
        .custom-section-form {
          .question-field-container {
            padding-bottom: 30px;
            .question-label {
              margin-bottom: 20px;
            }
            .question-description {
              margin-bottom: 8px;
            }
            .multi-answers-container {
              .question-option {
                margin-bottom: 10px;
              }
              .input-wrapper {
                .error-message {
                  display: none;
                }
                .checkbox-wrapper {
                  input {
                    height: 20px;
                    width: 20px;
                  }
                  label {
                    font-size: 14px;
                  }
                }
              }
            }
            .checkboxes-question {
              align-items: center;
              display: flex;
              .input-wrapper {
                .error-message {
                  display: none;
                }
                .checkbox-wrapper {
                  input {
                    -webkit-appearance: none;
                    appearance: none;
                    border: 1px solid #c6c6c6;
                    border-radius: 50%;
                    cursor: pointer;
                    height: 28px;
                    outline: none;
                    vertical-align: middle;
                    width: 28px;
                  }
                  label {
                    font-size: 14px;
                  }
                  input:checked {
                    appearance: auto;
                    clip-path: circle(46% at 50% 50%);
                    filter: hue-rotate(240deg);
                  }
                }
                .checkbox-wrapper:nth-of-type(1) {
                  margin-right: 20px;
                }
              }
            }
          }
        }
      }
      .section-title {
        align-items: center;
        display: flex;
        .section-title-icon {
          margin: 0 8px;
        }
        .authorized-status-icon {
          path:nth-child(1) {
            stroke: var(--secondary-theme-color);
          }
        }
        .deisolated-status-icon {
          path {
            stroke: var(--grey-background-icon);
          }
        }
        .section-name {
          margin-left: 5px;
        }
        .section-toggle {
          cursor: pointer;
          margin-left: auto;
        }
        .section-title-actions {
          display: flex;
          margin-left: auto;
          .toggle-action {
            cursor: pointer;
          }
          .toggle-action-edit {
            margin-right: 15px;
          }
          .toggle-action-expand {
            path {
              stroke: var(--secondary-font-color-dark);
            }
          }
        }
      }
    }
  }
}
