.isolation-components-order-modal {
  margin-top: 10px;
  width: 100%;
  .modal-wrapper__title {
    padding: 0 24px;
    position: relative;
  }
  .modal-action-items {
    right: 24px;
  }
  .modal {
    padding-top: 0 !important;
  }
  .components-list-container {
    margin-bottom: 30px;
    .components-list {
      .column-item {
        align-items: center;
        display: flex;
        padding: 12px 0;
        .list-column {
          font-size: 12px;
        }
        .order-item {
          width: 50px;
        }
        .action-column {
          align-items: center;
          display: flex;
          margin-left: auto;
          padding-left: 15px;
          .sort-icon {
            cursor: pointer;
          }
          .sort-icon-disabled {
            cursor: not-allowed;
            pointer-events: none;
            path {
              stroke-opacity: 0.5;
            }
          }
        }
      }
    }
  }
  .modal-action-buttons {
    align-items: center;
    display: flex;
    .save-btn {
      margin-left: auto;
    }
  }
}
