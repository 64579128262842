.work-order-completion-form {
  .section-content--visible {
    max-height: fit-content;
    overflow-y: hidden;
  }
}
.image-container {
  &__file-image {
    height: 28px;
    width: 28px;
  }
}
.file-container {
  align-items: center;
  display: flex;
  margin-bottom: 12px;
}
.filename {
  cursor: pointer;
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.work-order-files-title {
  margin-bottom: 12px;
}
