.color-picker {
  align-items: center;
  display: flex;
  &.disabled {
    pointer-events: none;
  }
  &__circle {
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px var(--primary-theme-light-color);
    cursor: pointer;
    margin-right: 10px;
    position: relative;
    &.large {
      height: 50px;
      width: 50px;
    }
    &.medium {
      height: 30px;
      width: 30px;
    }
    &.small {
      height: 16px;
      width: 16px;
    }
  }
  &__cover {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }
  &__popover {
    bottom: 0;
    left: 0;
    position: absolute;
    transform: translate(-100%, 100%);
    z-index: 2;
  }
  &__value {
    text-align: right;
    width: 60px;
  }
  .text {
    font-size: 12px;
    font-weight: normal;
  }
}
