.contractors-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  .contractors-list-search-wrapper {
    padding-left: 0;
  }
  .contractors-list-table-container {
    height: calc(100vh - 310px);
    margin-top: 20px;
    overflow: auto;
  }
  .contractor-table {
    thead {
      tr {
        th {
          color: var(--secondary-font-color-dark);
          min-width: auto;
          &:last-child {
            min-width: auto;
            text-align: center;
          }
        }
      }
    }
    .small-dropdown__settings {
      padding-left: 15px;
      width: auto;
    }
  }
  .empty-state {
    background-color: transparent;
    height: 40vh;
    margin: auto;
  }
}
