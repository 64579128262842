@import '../../../common/styles/variables';
$page-header-height: '101px';
.keybox-wrapper {
  background-color: var(--background-color);
  .toolbar-container {
    z-index: 92;
  }
  .keyboxes {
    display: flex;
    flex-direction: column;
    height: 100vh;
    .empty-state {
      height: 50vh;
      margin: auto;
    }
    &.sidebar-open {
      margin-right: $inspection-right-toolbar;
    }
    &__search-input-wrapper {
      padding-left: 10px;
      padding-top: 24px;
    }
    &__table-wrapper {
      margin: 20px;
      overflow-x: auto;
    }
    &__load-more-wrapper {
      margin-top: auto;
      width: 100%;
    }
  }
  .status-wrapper {
    display: flex;
  }
  .status {
    background-color: var(--severity-green);
    border-radius: 50%;
    height: 16px;
    margin-right: 10px;
    width: 16px;
    &.used {
      background-color: var(--severity-red);
    }
  }
  .isolation-certificates-popover {
    align-items: center;
    display: flex;
    p {
      margin-right: 4px;
    }
  }
}
