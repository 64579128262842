.set-password-wrapper {
  .f-primary.bold {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 160% */
    margin-bottom: 20px;
  }
  .field-wrapper {
    .field-wrapper__label {
      color: var(--primary-font-color);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 0;
    }
    .input-wrapper__input {
      background: #fff;
      border: 1px solid #c6c6c6;
      border-radius: 4px;
      height: 52px;
    }
  }
  .field-wrapper .checkbox-wrapper {
    align-items: flex-start;
    height: auto;
    .input-wrapper__input {
      height: 20px;
      width: 20px;
    }
  }
  .terms-and-conditions-wrapper {
    color: var(--primary-font-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin-left: 4px;
    .clickable {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .btn.btn-success {
    border-radius: 4px;
    height: 52px;
    padding: 16px 32px;
    .button-text {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: -0.14px;
      line-height: 20px; /* 142.857% */
    }
  }
}
