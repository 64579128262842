.checklists {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--background-color);
  .id-column {
    min-width: 50px;
    max-width: 50px;
  }
  .name-column {
    min-width: 330px !important;
    max-width: 330px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 330px;
  }

  .status-column {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .questions-column,
  .submissions-column {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .date-column {
    min-width: 180px !important;
    max-width: 180px !important;
  }

  .created-by-column {
    min-width: 120px !important;
    max-width: 120px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__search-input-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 24px 20px 0;
    padding-top: 0px;
    padding-bottom: 4px;
    .search-input-stripped {
      padding-left: 0px;
      &__icon {
        margin-right: 4px;
      }
      .search-wrapper__input {
        svg {
          g {
            stroke: var(--ui-color-primary);
            path {
              stroke: var(--ui-color-primary);
            }
          }
        }
      }
    }
    p {
      cursor: pointer;
      text-transform: capitalize;
    }
  }
  &__container {
    height: calc(100vh - 270px);
    overflow: auto;
    &__table-wrapper {
      padding: 0px 20px 0px 20px;
      &__table {
        table {
          border-collapse: collapse;
        }
        thead {
          tr {
            border-bottom: none;
            th {
              font-weight: 700;
              color: var(--secondary-font-color-dark);
              min-width: auto;
              background-color: var(--background-color);
            }
          }
        }

        tbody {
          tr {
            &:nth-child(even) {
              background-color: var(--ch-table-even-row);
              border-radius: 4px;
            }
            &:hover {
              background-color: var(--ch-table-row-hover);
            }
          }
        }
        .small-dropdown__settings {
          padding-left: 15px;
          width: auto;
        }
        &__inline-right {
          text-align: right;
        }
        &__inline-flex-right {
          display: flex;
          justify-content: flex-end;
        }
        &__icon-grey {
          path:nth-child(1) {
            fill: var(--grey-background-icon);
          }
          path:nth-child(2) {
            stroke: #fff;
          }
        }
        &__icon-green {
          path {
            fill: var(--secondary-theme-color);
          }
        }
      }
    }
  }
  &__load-more-wrapper {
    margin-top: auto;
    width: 100%;
  }
  .checklist-procedures-list-confirmation-modal {
    .modal-wrapper {
      max-width: 50vw;
    }
  }
}
