.read-more-cell__container {
  display: flex;

  .info-tooltip {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.read-more-tooltip {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  position: relative;
  max-width: 300px;
}

.read-more-span {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4.5em;
  word-wrap: break-word;
  position: relative;
  max-width: 200px;
}

.read-more-info__container {
  align-self: flex-end;
}

.info-icon {
  display: inline;
}

.hidden {
  display: none;
}
