@import './theme-files/light-theme';
@import './theme-files/dark-theme';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
body[data-theme='light'],
body {
  @each $label, $value, $i in $light-theme {
    #{$label}: #{$value};
  }
}

// THEME COLORS - DARK THEME
body[data-theme='dark'] {
  @each $label, $value, $i in $dark-theme {
    #{$label}: #{$value};
  }
}

// modal is the same on every theme ( same as on light theme )
.modal-container {
  &:not(.dark) {
    .modal-wrapper {
      @each $label, $value, $i in $light-theme {
        #{$label}: #{$value};
      }
    }
  }
}

// FONTS

$primary-font-family: 'DM Sans', sans-serif;

// ELEMENT SIZE

//dimension constants
@import './dimensions/dimensions';
