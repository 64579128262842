$top-part-height: 250px;
.ndt-measurements-list {
  .items-table-renderer {
    height: calc(100vh - #{$top-part-height});
    overflow: auto;
    h5 {
      border-bottom: 1px solid transparent;
      cursor: pointer;
      padding-bottom: 5px;
      &.active {
        border-bottom-color: var(--secondary-theme-color);
        color: var(--secondary-theme-color);
      }
    }
  }
  .advanced-filter {
    align-items: center;
    display: flex;
    justify-content: space-between;
    p {
      cursor: pointer;
    }
  }
  .add-ndt-point {
    align-items: center;
    display: flex;
    margin-right: 15px;
    &.disabled {
      .add-ndt-point__icon {
        cursor: not-allowed;
        opacity: 0.7;
      }
    }
  }
}
.name {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  .dots {
    display: inline-block;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.status-icon {
  background-color: red;
  border-radius: 50%;
  height: 13px;
  margin-right: 15px;
  width: 13px;
}
.defects__selection {
  cursor: pointer;
  margin-bottom: 15px;
  svg {
    margin-right: 6px;
  }
}
.filter-wrapper {
  display: flex;
  flex-wrap: wrap;
  .field-wrapper {
    flex: 1;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
