.work-order-components {
  .input-with-dropdown {
    .question-field {
      .search {
        height: 52px;
        svg {
          margin-top: 6px;
          g {
            stroke: var(--secondary-font-color-dark);
            path {
              stroke: var(--secondary-font-color-dark);
            }
          }
        }
        &__input::placeholder {
          color: var(--secondary-font-color-dark);
        }
      }
      .items-dropdown {
        top: 51px;
      }
    }
  }
  .field-wrapper {
    .input-wrapper {
      .checkbox-wrapper {
        input {
          height: 20px;
          width: 20px;
        }
        label {
          text-transform: none;
        }
      }
    }
  }
  .added-items-list {
    .item-r {
      align-items: center;
      justify-content: initial;
      .info-tooltip {
        margin-left: 5px;
        margin-top: 3px;
      }
      .icon-close {
        margin-left: auto;
      }
    }
  }
}
