@import '../../../common/styles/mixins';
.isolation-certificates-modal {
  display: flex;
  flex-direction: column;
  height: 500px;
  min-width: 600px;
  .search {
    align-items: center;
    border: 1px solid var(--box-items-separator-color);
    height: 52px;
    margin-bottom: 32px;
    margin-top: 4px;
    z-index: 2;
    &__input {
      @include placeholder {
        color: var(--secondary-font-color-dark);
        font-weight: 500;
      }
    }
    &__icon {
      g {
        stroke: var(--secondary-font-color-dark);
      }
      path {
        stroke: var(--secondary-font-color-dark);
      }
    }
    .items-dropdown {
      z-index: 1;
      .item {
        padding: 0;
        p {
          padding: 5px 0;
        }
        .pointer {
          cursor: pointer;
        }
        .disabled {
          background-color: var(--box-items-separator-color);
          cursor: not-allowed;
        }
      }
    }
  }
  .table-container {
    flex: 1;
    overflow: auto;
    thead {
      tr {
        border-bottom: 0;
      }
      th {
        background-color: var(--modal-color) !important;
        outline: var(--modal-color) !important;
      }
    }
    .empty-state {
      background-color: unset;
      height: auto;
    }
  }
}
