@import '../../../common/styles/variables';
.component-details {
  .custom-toolbar {
    margin-left: 10px;
  }
  .component-related-defects {
    height: calc(100vh - 100px);
    overflow-y: auto;
    padding: 0 24px 0 27px;
    &__items-wrapper {
      height: 100%;
      margin-top: 20px;
      width: 100%;
      &__defect {
        padding-bottom: 15px;
        transition: height 0.5s;
      }
      &__item {
        align-items: center;
        display: flex;
        margin-bottom: 10px;
        &__status {
          margin-left: 8px;
        }
        &__chevron {
          cursor: pointer;
          margin-left: auto;
          path {
            stroke: var(--primary-font-color);
          }
        }
      }
      &__content {
        border-bottom: 1px solid var(--secondary-font-color-dark);
        padding: 20px 0;
        &__title {
          align-items: center;
          display: flex;
          &__clickable {
            cursor: pointer;
          }
          &__type-wrapper {
            align-items: center;
            display: flex;
            margin-left: auto;
            &__icon {
              path {
                stroke: var(--primary-font-color);
              }
            }
            &__type {
              margin-left: 8px;
            }
          }
        }
        &__bottom {
          display: block;
          margin-top: 8px;
        }
      }
      &--renderer {
        height: 100%;
        overflow-y: scroll;
      }
    }
    .info-tooltip {
      display: flex;
      margin-right: 10px;
    }
    .info-popover {
      min-height: 200px;
      width: 280px;
    }
  }
  .search {
    margin-top: 10px;
  }
  &__tabs {
    margin-left: 20px;
  }
  .section-header {
    margin-left: auto;
    margin-right: 12px;
  }
  .header-container {
    margin: 0;
    padding: 10px 20px 4px;
  }
}
